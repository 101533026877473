<template>
  <div>
    <card>
      <data-table
        :table-headers="tableHeaders"
        :table-data="requests"
        :table-data-keys="tableDataKeys"
        :my-first-loader="myFirstLoader"
        :page-size-prop="pageSize"
        :page-no="pageNo"
        :is-pageable="false"
        :total-elements="totalElements"
        :total-pages="totalPages"
        :is-first="isFirst"
        :is-last="isLast"
        :page-number="pageNumber"
        :date-prop="'Created At '"
        :has-manage-button="true"
        :has-search-form="false"
        @goToApplication="goToApplication"
        :loading-title="'Please wait while system is loading assignments'"
      />
      <div class="row">
        <div class="col-md-12">
          <button
            class="btn btn-default btn-fill btn-space"
            style="float:right;"
            @click.prevent="goBack"
          >
            <font-awesome-icon icon="undo" /> &nbsp; Back
          </button>
        </div>
      </div>
    </card>

    <global-modal v-if="myLoader">
      <div slot="globalLoader">
        <div class="row text-center">
          <div class="col-md-12">
            Loading, Please wait
          </div>
          <div class="col-md-12">
            <br>
          </div>
          <div class="col-md-12">
            <pulse-loader
              :loading="loading"
              :color="color"
              :size="size"
            />
          </div>
        </div>
      </div>
    </global-modal>
  </div>
</template>

<script>
import PulseLoader from 'vue-spinner/src/PulseLoader';
import GlobalModal from '../../../components/globalLoader';
import DataTable from '../../../components/DataTable/updatedTable';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'ShippingOrderAllActivities',
  components: {
    PulseLoader,
    GlobalModal,
    DataTable
  },
  created () {
    this.$store.dispatch('pagenames/currentPageName', 'Shipping Order Activities Assignments');
    this.shippingOrderId = this.$route.params.shippingOrderId;

    this.finalUserPermissions = JSON.parse(localStorage.getItem('browser-local-permits'));
    this.loadShippingOrderActivities();
  },
  data () {
    return {

      shippingOrderId: '',

      pageNo: 0,
      pageSize: 15,
      totalElements: '',
      totalPages: '',
      isFirst: false,
      isLast: false,
      pageNumber: '',
      isPageable: true,
      requests: [],
      myLoader: false,
      myFirstLoader: false,
      reloadRequests: false,
      tableHeaders: ['Cargo Name', 'Activity Name', 'Cargo Status', 'Completion Status', 'Assigned Officer'],
      tableDataKeys: ['cargoName', 'activityName', 'shippingOrderStatus', 'completionStatus', 'officerDetails']

    };
  },
  computed: {
    ...mapGetters('shippingOrder', [

      'getMyLoader',
      'getPageNo',
      'getRequestList',
      'getNoDataFound',
      'getSuccessAlert',
      'getErrorMessage',
      'getErrorAlert',
      'getSuccessMessage',
      'getMyFirstLoader'

    ])
  },
  methods: {
    ...mapActions('shippingOrder', [
      'getAllShippingOrderActivityAssignmentByShippingOrderId'
    ]),
    loadParameters () {
      return new Promise((resolve) => {
        this.$store.dispatch('shippingOrder/requestid', this.shippingOrderId);
        resolve();
      });
    },

    loadShippingOrderActivities () {
      this.loadParameters().then(() => {
        this.getAllShippingOrderActivityAssignmentByShippingOrderId({}).then(() => {
          this.myFirstLoader = this.getMyFirstLoader;
          this.requests = this.formatCargoActivities(JSON.parse(JSON.stringify(this.getRequestList)));
          this.noDataFound = this.getNoDataFound;
          this.ErrorAlert = this.getErrorAlert;
          this.ErrorMessage = 'Sorry, something went wrong';
        }).catch(() => {});
      });
    },
    formatCargoActivities (data) {
      if (data !== null) {
        for (let i = 0; i < data.length; i++) {
          data[i].officerDetails = data[i].officer.firstName + ' ' +
            data[i].officer.middleName + ' ' + data[i].officer.lastName;
          data[i].activityName = data[i].activity.name;
          data[i].completionStatus = data[i].completed ? 'YES' : 'NO';
          data[i].officerEmail = data[i].officer.email;
          data[i].cargoName = data[i].shippingOrderGood.cargoName;
          data[i].shippingOrderStatus = data[i].shippingOrderGood.shippingOrderStatus;
        }
        return data;
      } else {
        this.noDataFound = true;
        return data;
      }
    },

    goBack () {
      history.back();
    },

    goToApplication (data) {
      this.$router.push('/shipping-agency/booking/view/shippingorder/cargo-details/activity-progress/' + data.id);
      // this.$router.push('/shipping-agency/delivery-orders/order-details/' + data.deliveryOderId);
    }
  }

};
</script>

<style scoped>

</style>
