import { render, staticRenderFns } from "./billOfLadingDetails.vue?vue&type=template&id=09c8475c&scoped=true&"
import script from "./billOfLadingDetails.vue?vue&type=script&lang=js&"
export * from "./billOfLadingDetails.vue?vue&type=script&lang=js&"
import style0 from "./billOfLadingDetails.vue?vue&type=style&index=0&id=09c8475c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "09c8475c",
  null
  
)

export default component.exports