<template>
  <data-table
    :tableHeaders="tableHeaders"
    :tableData="filteredRequests"
    :tableDataKeys="tableDataKeys"
    :pageSizeProp="pageSize"
    :pageNo="pageNo"
    :hasFilter="false"
    :myLoader="loading"
    :myFirstLoader="loading"
    :noDataFound="noDataFound"
    :searchParameterProp="searchParameter"
    :loadingTitle="'Please wait while system is loading Statement'"
    :hasManageButton="true"
    :totalElements="totalElements"
    :totalPages="totalPages"
    :isFirst="isFirst"
    :isLast="isLast"
    :isHovered="[]"
    :pageNumber="pageNumber"
    :is-hovered="false"
    :permissions="[ {key: 'disbursement_list', value: 'DISBURSEMENT_SUB_LIST_GET'} ]"
    :isPageable="false"
    @onChange="onChange"
    @goToPage="manage"
    @goToApplication="manage"
    @goToBillGenerate="goToApplication"
  >
    <div slot = "pagination">
      <paginate
        v-model="getIsPage"
        :page-count=parseInt(totalPages)
        :page-range="3"
        :margin-pages="2"
        :click-handler="goToPage"
        :prev-text="'Prev'"
        :next-text="'Next'"
        :container-class="'pagination'"
        :page-class="'page-item'">
      </paginate>
    </div>
  </data-table>
</template>

<script>
  import AGENCYAPI from "../../../../api/agency"

  export default {
        name: "allSubDisbursement",

      components:{
        DataTable: () => import('../../../../components/DataTable/dataTable'),

      },

      created() {
        this.$store.dispatch('pagenames/currentPageName', 'Sub Disbursements');
        this.getAllSubDisbursement();
      },

      computed:{
        getIsPage: {

          get: function () {
            return this.isPage
          },
          set: function (newValue) {
            this.isPage = newValue;
          }

        }
      },

      data(){
        return{
          tableHeaders:['Disbursement Type','Invoice No','Principal','Amount(USD)','Deposited Amount(USD)', 'Status'],
          tableDataKeys:['disbursementType','invoiceNo','principal','disbursementAmount','depositAmount', 'status'],
          list:[],
          filteredRequests:[],
          pageNo: 0,
          pageSize: 15,
          totalElements: "",
          totalPages: "",
          isFirst: false,
          isLast: false,
          pageNumber: '',
          pageCount: 1,
          noDataFound:false,
          searchParameter: '',
          isPage: 0,

          params:"",
          color: 'green',
          size: '12px',
          size1: '9px',
          loading:false,
        }
      },

      methods:{


        manage(data){
          console.log(data);
          this.$router.push('/shipping-agency/sub-disbursement-accounts/account-details/' + data.subDisbursementAccountId)
        },

        filterRequests(data) {
          console.log(data);
          this.filteredRequests=[];
          data.map((value,index)=>{
            this.filteredRequests.push({
              ...value,
              principal:value.principalParticularDto.name,
              depositAmount:this.putComma(value.depositAmount),
              disbursementAmount:this.putComma(value.disbursementAmount)
            })
          })
        },

        getAllSubDisbursement(){

          this.loading=true;

          AGENCYAPI.allDisbursementAccount(this.pageNo,this.pageSize).then((response)=>{
            this.loading=false;

            if(response.status){
              this.totalPages = response.data.data.totalPages;
              this.totalElements = response.data.data.totalElements;
              this.pageNumber = response.data.data.number;
              this.isFirst = response.data.data.first;
              this.isLast = response.data.data.isLast;



              if (response.data.data.content.length > 0) {

                this.noDataFound = false;
                this.list=response.data.data.content;
                this.filterRequests(this.list);

              }else {

                this.noDataFound = true;

              }

            }

          }).catch((err)=>{
            console.log(err);
            this.loading=false;
            this.loadNotification('error', 4000, 'Loading Statement',
              'Failed To Load Sub Disbursement List',
              'response', 1000 , true  , true)
          });
        },


        onChange(event) {
          this.pageSize=event;
          this.pageNo=0;
        },

        goToPage(page) {

          if(page > 0) {page = page - 1;}
          this.isPage = page + 1;
          this.pageNo=page;

          this.getAllSubDisbursement();

        },

        goToApplication(){

        }
      },

    }
</script>

<style scoped>

</style>
