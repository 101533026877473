<template xmlns="http://www.w3.org/1999/html">
  <card>
    <div class="row">
      <div class="col-md-12">
        <crud-notification
          :error-message="ErrorMessage"
          :success-message="SuccessMessage"
          :error-alert="ErrorAlert"
          :success-alert="SuccessAlert"
          @fadeMe="fadeMe"
          @enter="enter"
        />
      </div>
    </div>

    <div class="row">
      <div class="col-12 text-center">
        <pulse-loader
          :loading="loading"
          :color="color"
          :size="size"
        />
      </div>
      <div class="col-md-12">
        <!--        <button-->
        <!--          class="btn btn-dark mr-2 custom-btn"-->
        <!--          @click.prevent="viewOrderCargo($route.params.shippingOrderId)"-->
        <!--        >-->
        <!--          View Cargo-->
        <!--        </button>-->
        <button
          class="btn btn-primary mr-2 custom-btn"
          @click.prevent="editShippingOrder($route.params.shippingOrderId)"
        >
          Edit Shipping Order
        </button>
        <button
          class="btn btn-success mr-2 custom-btn"
          @click="assignVessel=true"
        >
          Assign Vessel
        </button>
        <button class="btn btn-success mr-2 custom-btn" @click.prevent="assignContainers($route.params.shippingOrderId)"
                :disabled="!shippingOrder.containerized" :class="shippingOrder.containerized? 'btn-fill':''"
                v-if="typeof shippingOrder!== 'undefined'">
          Assign Containers
        </button>
        <button
          class="btn btn-dark mr-2 custom-btn"
          @click.prevent="isreject=true"
        >
          Reject
        </button>
        <button
          class="btn btn-success mr-2 custom-btn"
          @click.prevent="printShippingOrder"
        >
          Print Shipping Order
        </button>
        <button
          class="btn btn-info mr-2 custom-btn"
          @click.prevent="generateBillofLading"
        >
          Generate Bill of lading
        </button>
      </div>
    </div>
    <br>
    <!--shipping order details-->
    <div class="legend" v-if=" typeof shippingOrder!== 'undefined'">
      <h6 class="legend-title">
        Shipping Order Details
      </h6>
      <div class="row div-table-row">
        <div class="col-md-2 div-table div-table-title">
          Shipping Order No.
        </div>
        <div class="col-md-2 div-table div-table-value">
          {{ shippingOrder.shippingOrderNo }}
        </div>
        <div class="col-md-2 div-table div-table-title">
          Shipper Tin
        </div>
        <div class="col-md-2 div-table div-table-value">
          {{ shippingOrder.shipperCodeTin }}
        </div>
        <div class="col-md-2 div-table div-table-title">
          Shipping Order Status
        </div>
        <div class="col-md-2 div-table div-table-value" style="color: #651fff; ">
          {{ shippingOrder.shippingOrderStatus }}
        </div>

      </div>
      <br>
      <div class="row div-table-row">
        <div class="col-md-1 div-table div-table-title">
          Shipper Name
        </div>
        <div class="col-md-3 div-table div-table-value">
          {{ shippingOrder.shipperName }}
        </div>
        <div class="col-md-1 div-table div-table-title">
          Shipper Address
        </div>
        <div class="col-md-4 div-table div-table-value">
          {{ shippingOrder.shipperAddress }}
        </div>

        <div class="col-md-1 div-table div-table-title">
          Shipper City
        </div>
        <div class="col-md-2 div-table div-table-value">
          {{ shippingOrder.shipperCity }}
        </div>
      </div>
      <br>
      <div class="row div-table-row">
        <div class="col-md-1 div-table div-table-title">
          Shipper Zip Code
        </div>
        <div class="col-md-1 div-table div-table-value">
          {{ shippingOrder.shipperZipCode }}
        </div>

        <div class="col-md-1 div-table div-table-title">
          Shipper Country
        </div>
        <div class="col-md-2 div-table div-table-value">
          {{ shippingOrder.shipperCountry }}
        </div>

        <div class="col-md-2 div-table div-table-title">
          Shipper Contact Person
        </div>
        <div class="col-md-5 div-table div-table-value">
          {{ shippingOrder.shipperContactPerson }}
        </div>
      </div>
      <br>
      <div class="row div-table-row">
        <div class="col-md-2 div-table div-table-title ">
          Shipper Phone No.
        </div>
        <div class="col-md-2 div-table div-table-value ">
          {{ shippingOrder.shipperTel }}
        </div>

        <div class="col-md-1 div-table div-table-title ">
          Shipper Email
        </div>
        <div class="col-md-4 div-table div-table-value ">
          {{ shippingOrder.email }}
        </div>

        <div class="col-md-1 div-table div-table-title">
          House BL
        </div>
        <div class="col-md-2 div-table div-table-value">
          {{ shippingOrder.houseBl }}
        </div>
      </div>
      <div class="row div-table-row mt-3">
        <div class="col-md-1 div-table div-table-title">
          Consignee Name
        </div>
        <div class="col-md-4 div-table div-table-value">
          {{ shippingOrder.consigneeName }}
        </div>
        <div class="col-md-1 div-table div-table-title">
          Consignee Tin
        </div>
        <div class="col-md-6 div-table div-table-value">
          {{ shippingOrder.consigneeCodeTin }}
        </div>
      </div>
      <div class="row div-table-row mt-3">
        <div class="col-md-2 div-table div-table-title">
          Consignee Address
        </div>
        <div class="col-md-6 div-table div-table-value">
          {{ shippingOrder.consigneeAddress }}
        </div>

        <div class="col-md-1 div-table div-table-title">
          Consignee City
        </div>
        <div class="col-md-3 div-table div-table-value">
          {{ shippingOrder.consigneeCity }}
        </div>
      </div>
      <div class="row div-table-row mt-3">
        <div class="col-md-2 div-table div-table-title">
          Consignee Zip Code
        </div>
        <div class="col-md-1 div-table div-table-value">
          {{ shippingOrder.shipperZipCode }}
        </div>

        <div class="col-md-2 div-table div-table-title">
          Consignee Country
        </div>
        <div class="col-md-2 div-table div-table-value">
          {{ shippingOrder.consigneeCountry }}
        </div>

        <div class="col-md-2 div-table div-table-title">
          Consignee Contact Person
        </div>
        <div class="col-md-3 div-table div-table-value">
          {{ shippingOrder.shipperContactPerson }}
        </div>
      </div>
      <div class="row div-table-row mt-3">
        <div class="col-md-2 div-table div-table-title ">
          Consignee Phone No.
        </div>
        <div class="col-md-2 div-table div-table-value ">
          {{ shippingOrder.consigneeTel }}
        </div>

        <div class="col-md-1 div-table div-table-title ">
          Consignee Email
        </div>
        <div class="col-md-3 div-table div-table-value ">
          {{ shippingOrder.consigneeEmail }}
        </div>
        <div class="col-md-1 div-table div-table-title ">
          Consignee Name
        </div>
        <div class="col-md-3 div-table div-table-value ">
          {{ shippingOrder.consigneeName }}
        </div>
      </div>

      <div class="row div-table-row mt-3">
        <div class="col-md-1 div-table div-table-title">
          Notify Address
        </div>
        <div class="col-md-8 div-table div-table-value">
          {{ shippingOrder.notiyAddress }}
        </div>

        <div class="col-md-1 div-table div-table-title">
          Notify City
        </div>
        <div class="col-md-2 div-table div-table-value">
          {{ shippingOrder.notiyCity }}
        </div>
      </div>
      <div class="row div-table-row mt-3">
        <div class="col-md-1 div-table div-table-title">
          Notify Zip Code
        </div>
        <div class="col-md-1 div-table div-table-value">
          {{ shippingOrder.notiyCodeTin }}
        </div>

        <div class="col-md-1 div-table div-table-title">
          Notify Country
        </div>
        <div class="col-md-2 div-table div-table-value">
          {{ shippingOrder.notiyCountry }}
        </div>

        <div class="col-md-2 div-table div-table-title">
          Notify Contact Person
        </div>
        <div class="col-md-5 div-table div-table-value">
          {{ shippingOrder.notiyContactPerson }}
        </div>
      </div>
      <div class="row div-table-row mt-3">
        <div class="col-md-1 div-table div-table-title ">
          Notify Phone No.
        </div>
        <div class="col-md-2 div-table div-table-value ">
          {{ shippingOrder.notiyTel }}
        </div>

        <div class="col-md-1 div-table div-table-title ">
          Notify Email
        </div>
        <div class="col-md-2 div-table div-table-value ">
          {{ shippingOrder.notiyEmail }}
        </div>

        <div class="col-md-1 div-table div-table-title ">
          Notify Name
        </div>
        <div class="col-md-3 div-table div-table-value ">
          {{ shippingOrder.notiyName }}
        </div>
        <div class="col-md-1 div-table div-table-title ">
          Created Date
        </div>
        <div class="col-md-1 div-table div-table-value">
          {{ shippingOrder.createdAt ? dateToHuman(shippingOrder.createdAt): '' }}
        </div>
      </div>
    </div>

    <!--Vessel Details-->
    <div class="legend" v-if="shippingOrder.vesselParticular">
      <h6 class="legend-title">
        Vessel Details
      </h6>

      <div class="row div-table-row">
        <div class="col-md-2 div-table div-table-title">
          Voyage No.
        </div>
        <div class="col-md-2 div-table div-table-value">
          {{ shippingOrder.vesselParticular.voyageNo }}
        </div>
        <div class="col-md-2 div-table div-table-title">
          Vessel Name
        </div>
        <div class="col-md-2 div-table div-table-value">
          {{ shippingOrder.vesselParticular.vesselName }}
        </div>

        <div class="col-md-2 div-table div-table-title">
          Vessel Type
        </div>
        <div class="col-md-2 div-table div-table-value">
          {{ shippingOrder.vesselParticular.vesselType }}
        </div>
      </div>
      <br>

      <div class="row div-table-row">
        <div class="col-md-1 div-table div-table-title">
          Carrier Code.
        </div>
        <div class="col-md-2 div-table div-table-value">
          {{ shippingOrder.vesselParticular.carrierCode }}
        </div>
        <div class="col-md-1 div-table div-table-title">
          Arrival Port
        </div>
        <div class="col-md-2 div-table div-table-value">
          {{ shippingOrder.vesselParticular.arrivalPort }}
        </div>

        <div class="col-md-1 div-table div-table-title">
          Contract Type
        </div>
        <div class="col-md-2 div-table div-table-value">
          {{ shippingOrder.vesselParticular.contractType }}
        </div>

        <div class="col-md-1 div-table div-table-title">
          Berth
        </div>
        <div class="col-md-2 div-table div-table-value">
          {{ shippingOrder.vesselParticular.berth }}
        </div>
      </div>
      <br>

      <div class="row div-table-row">
        <div class="col-md-1 div-table div-table-title">
          Vessel ETA
        </div>
        <div class="col-md-2 div-table div-table-value">
          {{ shippingOrder.vesselParticular.eta ? dateTimeToHuman(shippingOrder.vesselParticular.eta): "N/A" }}
        </div>
        <div class="col-md-1 div-table div-table-title">
          Vessel ETD
        </div>
        <div class="col-md-2 div-table div-table-value">
          {{ shippingOrder.vesselParticular.etd ? dateTimeToHuman(shippingOrder.vesselParticular.etd): "N/A" }}
        </div>

        <div class="col-md-2 div-table div-table-title">
          Expected Stay Time (Hrs.)
        </div>
        <div class="col-md-1 div-table div-table-value">
          {{ shippingOrder.vesselParticular.stayTime }}
        </div>
        <div class="col-md-2 div-table div-table-title">
          Vessel Factor
        </div>
        <div class="col-md-1 div-table div-table-value">
          {{ shippingOrder.vesselParticular.factor }}
        </div>
      </div>
      <br>

      <div class="row div-table-row">
        <div class="col-md-2 div-table div-table-title">
          Vessel Dead Weight Tonnage
        </div>
        <div class="col-md-1 div-table div-table-value">
          {{ shippingOrder.vesselParticular.dwt }}
        </div>
        <div class="col-md-2 div-table div-table-title">
          Vessel Gross Weight Tonnage
        </div>
        <div class="col-md-1 div-table div-table-value">
          {{ shippingOrder.vesselParticular.grt }}
        </div>

        <div class="col-md-2 div-table div-table-title">
          Vessel Net Weight Tonnage
        </div>
        <div class="col-md-1 div-table div-table-value">
          {{ shippingOrder.vesselParticular.nrt }}
        </div>
        <div class="col-md-2 div-table div-table-title">
          Length Overall (metres)
        </div>
        <div class="col-md-1 div-table div-table-value">
          {{ shippingOrder.vesselParticular.loa }}
        </div>
      </div>
      <br>

      <div class="row div-table-row">
        <div class="col-md-2 div-table div-table-title">
          Vessel Draft
        </div>
        <div class="col-md-1 div-table div-table-value">
          {{ shippingOrder.vesselParticular.draft }}
        </div>

        <div class="col-md-2 div-table div-table-title">
          Vessel Beam
        </div>
        <div class="col-md-1 div-table div-table-value">
          {{ shippingOrder.vesselParticular.beam }}
        </div>
        <div class="col-md-1 div-table div-table-title">
          Vessel ATA
        </div>
        <div class="col-md-2 div-table div-table-value">
          {{ shippingOrder.vesselParticular.ata ? dateTimeToHuman(shippingOrder.vesselParticular.ata):'N/A' }}
        </div>

        <div class="col-md-1 div-table div-table-title">
          Vessel ATD
        </div>
        <div class="col-md-2 div-table div-table-value">
          {{ shippingOrder.vesselParticular.atd ? dateTimeToHuman(shippingOrder.vesselParticular.atd): 'N/A' }}
        </div>
      </div>
      <br>
    </div>

    <!--  Shipping Order Cargo-->
    <div class="legend">
      <h6 class="legend-title">
        Shipping Order Cargo
      </h6>
      <application-cargo-v2 :shipping-order-id="this.$route.params.shippingOrderId"/>
    </div>
    <div class="legend">
      <h6 class="legend-title">
        Containers
      </h6>
      <containers :shippingOderId="$route.params.shippingOrderId"/>
    </div>
    <!-- Assigning Vessel -->
    <modal v-if="assignVessel">
      <div slot="header"></div>
      <div slot="body">
        <div class="row">
          <div class="col-12 text-center">
            <pulse-loader
              :loading="loading"
              :color="color"
              :size="size"
              v-if="loading"
            />
          </div>
          <div class="col-md-12">
            <model-list-select
              :list="vesselList"
              v-model="selectedVessel"
              option-value="vesselParticularId"
              :custom-text="fullName"
              class="form-control"
              placeholder="Select Vessel"
            />
            <span
              class="text-danger"
              v-if="selectedVesselError"
            >This Field is Required</span>
          </div>
        </div>
        <div class="row mt-5">
          <div class="col-md-12">
            <button
              class="btn btn-fill btn-default float-right"
              @click="assignVessel=false"
            >
              Close
            </button>
            <button
              class="btn btn-success mr-3 float-right"
              @click="AssignVessel"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </modal>
    <modal v-if="isreject">
      <div slot="body">
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label for="reject">Reject Reason</label>
              <div class="form-group">
                <textarea class="form-control" rows="3" id="reject" v-model="rejectReason"></textarea>
              </div>
            </div>
            <button class="btn btn-info float-right custom-btn" @click="isreject=false">Close</button>
            <button class="btn btn-info float-right custom-btn" @click="reject">Submit</button>

          </div>
        </div>
      </div>
    </modal>

    <modal v-if="viewBillOfLadingForm">
      <div slot="body">
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label class="custom-font">Cargo Type</label>
              <model-list-select :list="cargoTypes"
                                 v-model="tempBL.cargoType"
                                 option-value="name"
                                 :custom-text="cargoTypeText"
                                 placeholder="Select Cargo Type">
              </model-list-select>
            </div>
          </div>

          <div class="col-md-12 legend" v-if="tempBL.cargoType != null">
            <h6 class="legend-title">
              Shipper Details
            </h6>

            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label for="shipperName" class="custom-font">Shipper Name</label>
                  <input type="text" class="form-control" id="shipperName"
                         placeholder="Shipper Name" v-model="tempBL.shipperName">
                  <div v-if="shipperError">
                    <div class="error text-danger">
                      <span v-if="!$v.tempBL.shipperName.required">Field is required</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group custom-font">
                  <label
                    for="shipperAddress"
                    class="custom-font">Shipper Address</label>
                  <input
                    type="text"
                    class="form-control"
                    id="shipperAddress"
                    placeholder="Street Address"
                    v-model="tempBL.shipperAddress"
                  >
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label for="shipper_city" class="custom-font">Shipper City</label>
                  <input
                    type="text"
                    class="form-control"
                    id="shipper_city"
                    placeholder="City"
                    v-model="tempBL.shipperCity"
                  >
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label for="shipper_zip" class="custom-font">Shipper Zip Code</label>
                  <input
                    type="text"
                    class="form-control"
                    id="shipper_zip"
                    placeholder="Postal/Zip Code"
                    v-model="tempBL.shipperZipCode"
                  >
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label class="custom-font">Select Country</label>
                  <model-list-select :list="countries"
                                     v-model="tempBL.shipperCountry"
                                     option-value="name"
                                     :custom-text="codeAndCountry"
                                     placeholder="select Shipper Country">
                  </model-list-select>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label for="shippere_mail" class="custom-font">Shipper Email</label>
                  <input
                    type="email"
                    class="form-control"
                    id="shippere_mail"
                    placeholder="Email"
                    v-model="tempBL.shipperEmail"
                  >
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label for="shipper_phone" class="custom-font">Shipper Telephone</label>
                  <input
                    type="text"
                    class="form-control"
                    id="shipper_phone"
                    placeholder="phone"
                    v-model="tempBL.shipperTel"
                  >
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label for="shipperContactPerson" class="custom-font">Shipper Contact Person</label>
                  <input
                    type="text"
                    class="form-control"
                    id="shipperContactPerson"
                    placeholder="Shipper Contact Person"
                    v-model="tempBL.shipperContactPerson"
                  >
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label for="shipperCodeTin" class="custom-font">Shipper Tin</label>
                  <input
                    type="text"
                    class="form-control"
                    id="shipperCodeTin"
                    placeholder="Shipper Code Tin"
                    v-model="tempBL.shipperCodeTin"
                  >
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-12 legend" v-if="tempBL.cargoType != null">
            <h6 class="legend-title">
              Consignee Details
            </h6>

            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label for="consigneeName" class="custom-font">Consignee Name</label>
                  <input type="text" class="form-control" id="consigneeName"
                         placeholder="Consignee Name" v-model="tempBL.consigneeName">
                  <div v-if="consigneeError">
                    <div class="error text-danger">
                      <span v-if="!$v.tempBL.consigneeName.required">Field is required</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group custom-font">
                  <label
                    for="consigneeAddress"
                    class="custom-font"
                  >Consignee Address</label>
                  <input
                    type="text"
                    class="form-control"
                    id="consigneeAddress"
                    placeholder="Street Address"
                    v-model="tempBL.consigneeAddress"
                  >
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label for="consignee_city" class="custom-font">Consignee City</label>
                  <input
                    type="text"
                    class="form-control"
                    id="consignee_city"
                    placeholder="City"
                    v-model="tempBL.consigneeCity"
                  >
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label for="consignee_zip" class="custom-font">Consignee Zip Code</label>
                  <input
                    type="text"
                    class="form-control"
                    id="consignee_zip"
                    placeholder="Postal/Zip Code"
                    v-model="tempBL.consigneeZipCode"
                  >
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label class="custom-font">Select Country</label>
                  <model-list-select :list="countries"
                                     v-model="tempBL.consigneeCountry"
                                     option-value="name"
                                     :custom-text="codeAndCountry"
                                     placeholder="select Consignee Country">
                  </model-list-select>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label for="consigneee_mail" class="custom-font">Consignee Email</label>
                  <input
                    type="email"
                    class="form-control"
                    id="consigneee_mail"
                    placeholder="Email"
                    v-model="tempBL.consigneeEmail"
                  >
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label for="Consignee_phone" class="custom-font">Consignee Telephone</label>
                  <input
                    type="text"
                    class="form-control"
                    id="Consignee_phone"
                    placeholder="phone"
                    v-model="tempBL.consigneeTel"
                  >
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label for="consigneeContactPerson" class="custom-font">Consignee Contact Person</label>
                  <input
                    type="text"
                    class="form-control"
                    id="consigneeContactPerson"
                    placeholder="Consignee Contact Person"
                    v-model="tempBL.consigneeContactPerson"
                  >
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label for="consigneeCodeTin" class="custom-font">Consignee Tin</label>
                  <input
                    type="text"
                    class="form-control"
                    id="consigneeCodeTin"
                    placeholder="Shipper Code Tin"
                    v-model="tempBL.consigneeCodeTin"
                  >
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-12 legend" v-if="tempBL.cargoType != null">
            <h6 class="legend-title">
              Notify Details
            </h6>

            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label for="notifyName" class="custom-font">Notify Name</label>
                  <input type="text" class="form-control" id="notifyName"
                         placeholder="Notify Name" v-model="tempBL.notifyName">
                  <div v-if="notifyError">
                    <div class="error text-danger">
                      <span v-if="!$v.tempBL.notifyName.required">Field is required</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group custom-font">
                  <label
                    for="notifyAddress"
                    class="custom-font"
                  >Notify Address</label>
                  <input
                    type="text"
                    class="form-control"
                    id="notifyAddress"
                    placeholder="Street Address"
                    v-model="tempBL.notifyAddress"
                  >
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label for="notify_city" class="custom-font">Notify City</label>
                  <input
                    type="text"
                    class="form-control"
                    id="notify_city"
                    placeholder="City"
                    v-model="tempBL.notifyCity"
                  >
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label for="notify_zip" class="custom-font">Notify Zip Code</label>
                  <input
                    type="text"
                    class="form-control"
                    id="notify_zip"
                    placeholder="Postal/Zip Code"
                    v-model="tempBL.notifyZipCode"
                  >
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label class="custom-font">Select Country</label>
                  <model-list-select :list="countries"
                                     v-model="tempBL.notifyCountry"
                                     option-value="name"
                                     :custom-text="codeAndCountry"
                                     placeholder="select Notify Country">
                  </model-list-select>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label for="notifye_mail" class="custom-font">Notify Email</label>
                  <input
                    type="email"
                    class="form-control"
                    id="notifye_mail"
                    placeholder="Email"
                    v-model="tempBL.notifyEmail"
                  >
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label for="notify_phone" class="custom-font">Notify Telephone</label>
                  <input
                    type="text"
                    class="form-control"
                    id="notify_phone"
                    placeholder="phone"
                    v-model="tempBL.notifyTel"
                  >
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label for="notifyContactPerson" class="custom-font">Notify Contact Person</label>
                  <input
                    type="text"
                    class="form-control"
                    id="notifyContactPerson"
                    placeholder="Notify Contact Person"
                    v-model="tempBL.notifyContactPerson"
                  >
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label for="notifyCodeTin" class="custom-font">Notify Tin</label>
                  <input
                    type="text"
                    class="form-control"
                    id="notifyCodeTin"
                    placeholder="Shipper Code Tin"
                    v-model="tempBL.notifyCodeTin"
                  >
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-12 legend" v-if="tempBL.cargoType != null">
            <h6 class="legend-title">
              Port Information
            </h6>

            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label class="custom-font">Select Loading Port</label>
                  <model-select :options="SeaPorts"
                                v-model="tempBL.originPort"
                                placeholder="Select Loading Port">
                  </model-select>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label class="custom-font">Select Discharge Port</label>
                  <model-select :options="SeaPorts"
                                v-model="tempBL.destinationPort"
                                placeholder="Select Discharge Port">
                  </model-select>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group">
                  <label for="destinationPlace">Destination Place</label>
                  <input
                    type="text"
                    class="form-control"
                    id="destinationPlace"
                    placeholder="Destination Place"
                    v-model="tempBL.destinationPlace"
                  >
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-12 legend" v-if="tempBL.cargoType === 'General Product'">
            <h6 class="legend-title">
              Weights and Measurement
            </h6>

            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label for="grossWeight" class="custom-font">Gross Weight</label>
                  <input
                    type="text"
                    class="form-control"
                    id="grossWeight"
                    placeholder="Gross Weight"
                    v-model="tempBL.grossWeight"
                  >
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label for="netWeight" class="custom-font">Net Weight</label>
                  <input
                    type="text"
                    class="form-control"
                    id="netWeight"
                    placeholder="Net Weight"
                    v-model="tempBL.netWeight">
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label for="volume" class="custom-font">Volume</label>
                  <input
                    type="text"
                    class="form-control"
                    id="volume"
                    placeholder="Volume"
                    v-model="tempBL.volume">
                </div>
              </div>

              <div class="col-md-4">
                <div class="form-group">
                  <label for="numberOfPackages" class="custom-font">Number Of Packages</label>
                  <input
                    type="text"
                    class="form-control"
                    id="numberOfPackages"
                    placeholder="Number Of Packages"
                    v-model="tempBL.numberOfPackages">
                </div>
              </div>

              <div class="col-md-4">
                <div class="form-group">
                  <label>Package Type</label>
                  <model-list-select :list="packageTypes"
                                     v-model="tempBL.packageType"
                                     class="form-control"
                                     option-value="packageTypeName"
                                     option-text="packageTypeName"
                                     placeholder="Package Type">
                  </model-list-select>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-12 legend" v-if="tempBL.cargoType === 'Bulk Oil and Gas'">
            <h6 class="legend-title">
              Volume and Measurement
            </h6>

            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label for="volumeAtTwenty" class="custom-font">Volume At ..</label>
                  <input
                    type="text"
                    class="form-control"
                    id="volumeAtTwenty"
                    placeholder="Volume At .."
                    v-model="tempBL.volumeAtTwenty"
                  >
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label for="volumeAtThirty" class="custom-font">Volume At ..</label>
                  <input
                    type="text"
                    class="form-control"
                    id="volumeAtThirty"
                    placeholder="Volume At .."
                    v-model="tempBL.volumeAtThirty">
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label for="volumeAtForty" class="custom-font">Volume At ..</label>
                  <input
                    type="text"
                    class="form-control"
                    id="volumeAtForty"
                    placeholder="Volume At .."
                    v-model="tempBL.volumeAtForty">
                </div>
              </div>
            </div>
          </div>

        <div class="col-md-12 legend" v-if="tempBL.cargoType != null">
            <h6 class="legend-title">
              Cargo Description
            </h6>

            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label for="cargoDescription" class="custom-font">Cargo Description</label>
                  <textarea
                    type="text"
                    class="form-control"
                    id="cargoDescription"
                    placeholder="Description"
                    v-model="tempBL.cargoDescription">
                  </textarea>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <button
              class="btn btn-fill btn-default float-right mr-2"
              @click="closeModal">
              Close
            </button>
            <button class="btn btn-fill btn-default float-right mr-2" @click="submitBlDetails">Submit</button>
          </div>
        </div>
      </div>
    </modal>
    <!--    Assigning Task Master ? -->
    <modal v-if="assignActivityToPerson">
      <div
        slot="header"
        class="modal-title col-md-12"
      >
        <div class="row">
          <div class="col-md-12">
            <h6>
              <b v-if="isSave"> Assign Activity for All Cargo in shipping Order [{{shippingOrder.shippingOrderNo}}]</b>
              <b v-if="!isSave"> Edit Activity Assignment</b>
            </h6>
          </div>
          <div class="col-md-12">
            <hr>
          </div>
        </div>
      </div>
      <div slot="body">
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label>Activity</label>
              <model-list-select
                :list="getactivity"
                v-model="activity.activityId"
                option-value="id"
                :custom-text="activityName"
                class="form-control"
                placeholder="Select Activity"
              />
              <div
                class="error text-danger"
                v-if="activityIdError"
              >
                <span v-if="!$v.activity.activityId.required">Field is required</span>
              </div>
            </div>
            <div class="form-group">
              <label>Officer</label>
              <model-list-select
                :list="allUsers"
                v-model="activity.officerId"
                option-value="id"
                :custom-text="OfficerName"
                class="form-control"
                placeholder="Select Officer"
              />
              <div
                class="error text-danger"
                v-if="officerIdError"
              >
                <span v-if="!$v.activity.officerId.required">Field is required</span>
              </div>
            </div>
            <div class="form-group">
              <label for="description">Description</label>
              <textarea
                class="form-control"
                id="description"
                placeholder="Description"
                v-model="activity.descrition"
                rows="3"></textarea>
              <div
                class="error text-danger"
                v-if="descritionError"
              >
                <span v-if="!$v.activity.descrition.required">Field is required</span>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-5">
          <div class="col-md-12">
            <button
              class="btn btn-fill btn-default float-right mr-2" @click="closeModal"> Close
            </button>
            <button
              type="submit"
              class="btn btn-primary mr-3 float-right"
              v-if="isSave"
              @click.prevent="assignActivityFunc"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </modal>
  </card>
</template>
<script>
  import AGENYAPI from '../../../api/agency';

  import Modal from '../../../components/Inputs/Modal';
  import {ModelListSelect, ModelSelect} from "vue-search-select";
  import getCountriesMap from "./countries";
  import PulseLoader from 'vue-spinner/src/PulseLoader.vue';
  import Table from '../../../components/DataTable/table';
  import ApplicationCargo from './applicationCargo';
  import CrudNotification from '../../../components/DataTable/crudNotification';
  import API from "../../../api";
  import ports from "./ports";
  import {mapActions, mapGetters} from "vuex";
  import {required} from "vuelidate/lib/validators";
  import Containers from "../container/containers";
  import applicationCargoV2 from "./applicationCargoV2";

  export default {
    name: 'ShippingOrder',

    components: {
      Containers,
      ApplicationCargo,
      applicationCargoV2,
      Table,
      Modal,
      ModelListSelect,
      ModelSelect,
      PulseLoader,
      CrudNotification
    },

    validations: {
      activity: {
        descrition: {required},
        officerId: {required},
        activityId: {required}
      }
    },
    computed: {
      ...mapGetters('shippingOrder', [
        'getRequestList',
        'getRequestList2',
        'getPageCount',
        'getMyFirstLoader',
        'getNoDataFound',
        'getSuccessAlert',
        'getErrorMessage',
        'getErrorAlert',
        'getSuccessMessage',
        'getReloadAllRequest',
        'getMyLoader',
        'getPageNo'
      ])
    },

    created() {
      this.$store.dispatch('pagenames/currentPageName', 'Shipping Order Details');
      this.getShippingOrderById();
      this.getVesselParticulars();
      this.getCountries();
      this.getSeaPorts();
      this.getPackageTypes();
      this.getAllUsers();
      this.shippingOrderId = this.$route.params.shippingOrderId;
    },

    data() {
      return {
        shippingOrder: {
          containerized: ""
        },
        gg: true,
        assignVessel: false,
        vesselList: [],
        SeaPorts: [],
        packageTypes: [],
        selectedVessel: '',
        selectedVesselError: false,

        shippingOrderId: '',
        viewBillOfLadingForm: false,

        color: 'green',
        size: '15px',

        ErrorMessage: '',
        SuccessAlert: false,
        ErrorAlert: false,
        cargoTypes: [{name: 'General Product'}, {name: 'Bulk Oil and Gas'}],
        SuccessMessage: '',
        loading: false,
        getDataPorts: ports,
        countries: [],
        tempBL: {
          cargoType: null,

          consigneeName: null,
          consigneeCity: null,
          consigneeCodeTin: null,
          consigneeAddress: null,
          consigneeContactPerson: null,
          consigneeEmail: null,
          consigneeTel: null,
          consigneeZipCode: null,

          shipperName: null,
          shipperCity: null,
          shipperCodeTin: null,
          shipperAddress: null,
          shipperContactPerson: null,
          shipperEmail: null,
          shipperTel: null,
          shipperZipCode: null,

          notifyName: null,
          notifyCity: null,
          notifyCodeTin: null,
          notifyAddress: null,
          notifyContactPerson: null,
          notifyEmail: null,
          notifyTel: null,
          notifyZipCode: null,

          originPort: null,
          destinationPort: null,
          destinationPlace: null,

          grossWeight: null,
          netWeight: null,
          volume: null,
          numberOfPackages: null,
          packageType: null,
          cargoDescription: null,

          volumeAtTwenty: null,
          volumeAtThirty: null,
          volumeAtForty: null
        },
        shipperNameError: false,
        consigneeError: false,
        shipperError: false,
        notifyError: false,

        cargoType: '',
        isreject: false,
        rejectReason: "",
        isSave: true,
        assignActivityToPerson: false,
        getactivity: [],
        allUsers: [],
        descritionError: false,
        officerIdError: false,
        activityIdError: false,
        activity: {
          activityId: '',
          descrition: '',
          officerId: '',
          shippingOrderId: ''
        },

      };
    },

    methods: {
      ...mapActions('shippingOrder', [
        'assignShippingOrderCargoActivity',
        'updateShippingOrderCargoActivityAssignment'
      ]),

      goToAssignActivity() {
        this.assignActivityToPerson = true;

      },

      assignActivityFunc() {
        if (this.activity.descrition === '' ||
          this.activity.activityId === '' ||
          this.activity.officerId === '') {
          this.activityIdError = true;
          this.officerIdError = true;
          this.descritionError = true;
        } else {
          this.activityIdError = false;
          this.officerIdError = false;
          this.descritionError = false;

          this.activity.shippingOrderId = this.shippingOrderId;

          this.$store.dispatch('shippingOrder/savingDetails', this.activity);

          this.assignShippingOrderCargoActivity({}).then(() => {
            this.SuccessMessage = 'Activity Assignment Saved Successfully';
            this.ErrorMessage = 'Sorry, Something went wrong...';
            this.ErrorAlert = this.getErrorAlert;
            this.SuccessAlert = this.getSuccessAlert;
            this.myFirstLoader = this.getMyFirstLoader;
            // this.loadCargoActivitiesOnCreated();
            this.activity = {};
            this.closeModal();
          });
        }
      },

      reject() {
        this.isreject = false;
        AGENYAPI.rejectShippingOrder({
          rejectReason: this.rejectReason
        }, this.$route.params.shippingOrderId).then(() => {
          this.$router.push({name: "shippingorderList"});
        }).catch(() => {
          this.ErrorMessage = 'Failed To Reject Shipping Order';
          this.SuccessAlert = false;
          this.ErrorAlert = true;
          this.SuccessMessage = '';
        })
      },

      getCountries() {
        getCountriesMap.map((country) => {
          this.countries.push(country);
        })
      },

      printShippingOrder() {
        this.loading = true;
        AGENYAPI.printShippingOrder(this.$route.params.shippingOrderId).then((response) => {
          this.loading = false;
          const blobUrl = window.URL.createObjectURL(new Blob([response.data], {type: 'application/pdf'}));
          const link = document.createElement('a');

          link.href = blobUrl;

          link.download = "Shipping_order.pdf";

          document.body.appendChild(link);
          link.click();

        }).catch((e) => {
          this.loading = false;
        })
      },

      printBillofLadding() {
        this.loading = true;
        AGENYAPI.printBillofLadding(this.$route.params.shippingOrderId).then((response) => {
          this.loading = false;
          const blobUrl = window.URL.createObjectURL(new Blob([response.data], {type: 'application/pdf'}));
          const link = document.createElement('a');

          link.href = blobUrl;

          link.download = "Shipping_order.pdf";

          document.body.appendChild(link);
          link.click();

        }).catch((e) => {
          this.loading = false;
        })
      },

      generateBillofLading() {
        this.viewBillOfLadingForm = true;
      },

      submitBlDetails() {
        this.loading = true;
        AGENYAPI.generateBillofLading(this.$route.params.shippingOrderId, this.tempBL).then((response) => {
          this.loading = false;

          this.SuccessMessage = 'Activity Assignment Saved Successfully';
          this.SuccessAlert = this.getSuccessAlert;
          this.myFirstLoader = this.getMyFirstLoader;
          this.closeModal();
        }).catch((e) => {
          this.loading = false;

          this.ErrorMessage = 'Sorry, Something went wrong...';
          this.ErrorAlert = this.getErrorAlert;
          this.myFirstLoader = this.getMyFirstLoader;
        })
      },

      codeAndCountry(data) {
        return `${data.name}(${data.code})`
      },

      printBillOfLading() {
        this.loading = true;
        AGENYAPI.printBillofLading(this.$route.params.shippingOrderId).then((response) => {
          this.loading = false;
          const blobUrl = window.URL.createObjectURL(new Blob([response.data], {type: 'application/pdf'}));
          const link = document.createElement('a');

          link.href = blobUrl;

          link.download = `BillOfLading${this.$route.params.shippingOrderId}.pdf`;

          document.body.appendChild(link);
          link.click();

        }).catch((e) => {
          this.loading = false;
        })
      },

      closeModal() {
        this.showModal = false;
        this.assignActivityToPerson = false;
        this.viewBillOfLadingForm = false;
        this.isView = false;
      },

      activityName(data) {
        return data.name;
      },

      fullName(data) {
        return `Vessel : ${data.vesselName},Port : ${data.arrivalPort} ,ETD:- ${this.dateTimeToHuman(data.etd)} ,Carrier Code :- ${data.carrierCode}`;
      },

      /**
       * @return {string}
       */
      OfficerName(data) {
        return `${data.firstName}  ${data.lastName}`;
      },

      getAllUsers() {
        API.getUsers().then((response) => {
          this.allUsers = response.data.data;
        }).catch(() => {

        });
      },

      getPackageTypes() {
        API.getPackageTypes().then((response) => {
          for (let i = 0; i < response.data.data.length; i++) {
            this.packageTypes.push({
              packageTypeCode: response.data.data[i].packageTypeCode,
              packageTypeId: response.data.data[i].packageTypeId,
              packageTypeName: "(" + response.data.data[i].packageTypeCode + ")" + response.data.data[i].packageTypeName
            });
          }
        }).catch((e) => {
          this.loadNotification('error', 4000, 'Error', 'Failed To Load Package Types ', 1000, true, true);
        })
      },

      AssignVessel() {
        this.loading = true;
        if (this.selectedVessel !== '') {
          this.selectedVesselError = false;

          AGENYAPI.assignVesselToShippingOrder(this.selectedVessel, this.$route.params.shippingOrderId).then((response) => {
            this.loading = false;
            this.getShippingOrderById();
            this.assignVessel = false;
          }).catch(() => {
            this.loading = false;
          });
        } else {
          this.selectedVesselError = true;
        }
      },

      dateToHuman(stringDate) {
        if (stringDate !== null || stringDate !== undefined) {
          const splittedDate = stringDate.split('T');
          return splittedDate[0];
        }
      },

      getShippingOrderById() {
        this.loading = true;
        AGENYAPI.getShippingOrderById(this.$route.params.shippingOrderId).then((response) => {
          this.loading = false;
          this.shippingOrder = response.data.data;
        }).catch(() => {
          this.loading = false;
        });
      },

      changed(e) {
        console.log(e);
      },

      viewOrderCargo(shippingOrderId) {
        this.$router.push({name: 'shippingorderCargo', params: {shippingOrderId}});
      },

      editShippingOrder(shippingOrderId) {
        this.$router.push({name: 'editShippingOrder', params: {shippingOrderId}});
      },

      assignContainers(shippingOrderId) {
        this.$router.push({name: 'assignContainers', params: {shippingOrderId}});
      },

      cargoTypeText(data) {
        return `${data.name}`
      },

      goToShippingOrderActivities(shippingOrderId) {
        this.$router.push({name: 'shippingOrderActivities', params: {shippingOrderId}});
      },

      goToShippingOrderAttachments(shippingOrderId) {
        this.$router.push({name: 'shippingOrderAttachments', params: {shippingOrderId}});
      },
      // getVesselParticularById () {
      //   if (this.shippingOrder.vessel !== null) {
      //     AGENYAPI.getVesselParticularById(this.shippingOrder.vessel.vesselParticularId).then((response) => {
      //       this.getVessel = response.data.data;
      //     }).catch(() => {
      //
      //     });
      //   }
      // },

      getVesselParticulars() {
        AGENYAPI.listAllVesselParticularNoPaging().then((response) => {
          this.vesselList = response.data.data;
          //this.getVesselParticularById();
        }).catch((error) => {
          console.log(error);
        });
      },

      getSeaPorts() {
        this.getDataPorts.map((port) => {
          this.SeaPorts.push(
            {value: port, text: port}
          );
        })
      },

      enter: function (el, done) {
        var that = this;

        setTimeout(function () {
          that.SuccessAlert = false;
          that.ErrorAlert = false;
        }, 3000); // hide the message after 3 seconds
      },

      fadeMe: function () {
        if (this.SuccessAlert) {
          this.SuccessAlert = !this.SuccessAlert;
        } else {
          this.ErrorAlert = !this.ErrorAlert;
        }
      }
    },
  }
</script>

<style lang="scss">
  .menu {
    .item {
      font-weight: bold;
    }
  }
</style>

<style scoped>
  .custom-btn {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
    padding: 5px 9px 5px 9px !important;
    background-color: #FFFFFF !important;
  }

  .legend {
    position: relative;
    border: 1px solid #DCDCDC;
    padding: 1.5rem 1rem 1rem 1rem;
    margin-bottom: 1rem;
    border-radius: 3px;
    margin-top: 1rem;
  }

  .legend-title {
    background-color: inherit;
    position: absolute;
    top: -0.6rem;
    padding: 0.2rem 1rem;
    background-color: white;
    color: #d59a18;
  }

  .div-table-value {
    border: 1px solid #DCDCDC;
  }

  .div-table-row {
    padding: 0 15px 0 15px;
  }

  .div-table {
    padding: 9px 8px 9px 8px;
  }

  .div-table-title {
    width: 250px;
    background-color: rgba(153, 186, 221, .5);
    font-weight: normal;
  }

  .div-table-value {
    border: 1px solid #DCDCDC;
    font-weight: 900;
  }

  h3, h4 {
    width: 100%;
    text-align: center;
    border-bottom: 1px solid green;
    line-height: 0.1em;
    margin: 10px 0 20px;
  }

  h3 span, h4 span {
    background: #fff;
    padding: 0 10px;
  }

</style>
