<template>
  <div>
    <modal v-if="showAddSubDisbursement">
    <div slot="header" class="modal-title col-md-12">
      <div class="row">
        <div class="col-md-12">
          <b> {{ title }}</b>
        </div>
        <div class="col-md-12">
          <hr>
        </div>
      </div>
    </div>
    <div slot="body">
      <form  method="POST" @submit.prevent="saveSubDisbursementAccount">
        <div class="row">
          <div class="col-md-12">
            <label>Select Principal</label>
            <model-list-select
              v-model="principleParticularId"
              :list="listOfPrincipals"
              class="form-control"
              option-value="principalParticularId"
              option-text="name"
              placeholder="Principal"
            />
            <div v-if="!$v.principleParticularId.required && principleParticularIdError" class="text-danger">
              This field is required
            </div>

          </div>
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-12">
                <hr />
              </div>
              <div class="col-md-10 text-right pt-3">
                <pulse-loader :loading="myLoader" :color="color" :size="size" />
              </div>
              <div class="col-md-2 pr-3">
                <button
                  type="button"
                  class="btn btn-fill btn-outline-info custom-btn-all float-right"
                  :disabled="myLoader"
                  @click.prevent="closeModal"
                >
                  Close
                </button>
                <button
                  type="submit"
                  class="btn btn-primary btn-space custom-btn float-right mr-2"
                  :disabled="myLoader"
                  data-dismiss="modal">
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import { ModelListSelect } from 'vue-search-select'
import Modal from '@/components/Inputs/Modal'
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'

export default {

  name: 'SubDisbursementModal',

  components: {

    ModelListSelect,
    Modal,
    PulseLoader

  },

  props: {

    showAddSubDisbursement: {

      type: Boolean,
      default: () => false

    },

    title: {

      type: String,
      default: () => 'Add Sub-Disbursement Account'
      
    },

    disbursementAccountId: {

      type: Number,
      default: () => 0

    }

  },

  data() {

    return {

      color: 'green',
      size: '12px',
      loading: true,

      listOfPrincipals: [],
      principleParticularIdError: false,

      selectExisting: true,

      saveDetails: {},
      principleParticularId: '',
      myLoader: false,

    }
  },
  computed: {

    ...mapGetters('subdisbursment', [

      'getMyLoader'

    ]),

    ...mapGetters('principal', [

      'getPrincipalList'

    ])

  },

  validations: {

    principleParticularId: { required }


  },

  created() {

    this.getAllPrincipalAccounts()

  },

  methods: {

    ...mapActions('subdisbursment', [

      'createSubDisbursementAccount'

    ]),

    ...mapActions('principal', [
      
      'LoadListAllPrincipalAccounts'
    ]),

    getAllPrincipalAccounts() {

      this.LoadListAllPrincipalAccounts({}).then(() => {

        this.listOfPrincipals = this.getPrincipalList

      }).catch(() => {})
    },

    setAllGetters() {

      this.myLoader = this.getMyLoader

    },

    loadSubDisbursementAccountParams () {

      let subDisbursmentObject = {

        disbursementAccountId: this.disbursementAccountId,
        principleParticularId: this.principleParticularId

      }

      if(this.$v.principleParticularId.$invalid) {

        this.principleParticularIdError = true

      } else {

        this.principleParticularIdError = false

        return new Promise((resolve) => {

        this.$store.dispatch("subdisbursment/savingDetails", subDisbursmentObject)

        resolve();

      })

      }

    },

    saveSubDisbursementAccount() {

      this.loadSubDisbursementAccountParams().then(() => {

        this.createSubDisbursementAccount({}).then((response) => {

          this.setAllGetters()

          if(response.data.status) {

            this.loadNotification('success', 4000, 'Sub Disbursment Message',' Sub disbursment account has been successfully created',
            'response', 1000 , true  , true)

            this.closeModal()
            this.loadPageableRequestOnCreated()

          } else {

            this.loadNotification('error', 4000, 'Sub Disbursment Message',' Sub disbursment account not created, please retry',
            'response', 1000 , true  , true)

          }

        }).catch(() => {})

        this.myLoader = this.getMyLoader

      }).catch(() => {})

    },

    closeModal() {
      
      return this.$emit('closeModal')

    },

    loadPageableRequestOnCreated() {

      return this.$emit('loadPageableRequestOnCreated')

    }
  }
}
</script>

<style scoped >

.custom-btn {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
    padding: 5px 9px 5px 9px !important;
    font-size: 14px !important;
}

.custom-btn-all {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
    padding: 5px 9px 5px 9px !important;
    font-size: 14px !important;
    width: auto;
}

</style>
