<template>
  <div>
    <card>
      <data-table
        :table-headers="tableHeaders"
        :table-data="ediMessages"
        :table-data-keys="tableDataKeys"
        :my-first-loader="myFirstLoader"
        :is-pageable="isPageable"
        :no-data-found="noDataFound"
        :has-pagination="false"
        :has-filter="false"
        :buttonList="[ {'code' : 'Download' , 'name' : 'Download' , 'class' : 'btn-primary'} ]"
        :has-manage-button="false"
        :loading-title="currentLoadingTitle"
        :permissions="[ {key: 'searchPermission', value: 'CLEARINGFORWARDING_SEARCH_REQUESTS_GET'} ]"
        @goToDownload="downloadMinioObject">
      </data-table>
    </card>
  </div>
</template>

<script>
  import PulseLoader from 'vue-spinner/src/PulseLoader';
  import GlobalModal from '../../components/globalLoader';
  import DataTable from '../../components/DataTable/dataTable';
  import API_INDEX from '../../api/index';
  import { mapActions, mapGetters } from 'vuex';

  export default {
      name: "FailedEdiMessages",

    components: {
      PulseLoader,
      GlobalModal,
      DataTable
    },

    computed: {
      ...mapGetters('global', [
        'getGlobalLoader'
      ])
    },

    created() {
      this.getEdiMessages();
    },

    data() {
      return {
        tableHeaders: ['Received Date', 'Error Message', 'Original File Name'],
        tableDataKeys: ['receivedDate', 'error', 'originalFileName'],
        ediMessages: [],
        myFirstLoader: false,
        pageNo: 0,
        pageSize: 15,
        isPageable: false,
        totalElements: '',
        totalPages: '',
        isFirst: false,
        isLast: false,
        myLoader: false,
        noDataFound: false,
        pageNumber: '',
        reloadRequests: false,
        currentLoadingTitle: '',
        redStatusKey: {
          key: 'status',
          value: "REJECTED"
        },
      }
    },

    methods: {
      ...mapActions('global', [
        'loadFileFromMinio'
      ]),

      getEdiMessages() {
        API_INDEX.getFailedEDIMessages().then(resp => {
          console.log(resp);
          if (resp.data.status) {
            this.ediMessages = resp.data.data
          }
        })
      },

      downloadMinioObject(object) {
        let attachName = object.fileUrl;
        let fileName = 'Cuscar-edi';

        this.loadParameters(attachName, fileName).then(() => {
          this.loadFileFromMinio({}).then((res) => {
            if(res.status === 204) {
              this.loadNotification('warn', 4000, 'File Download',
                'File not found, please contact system admin',
                'response', 1000 , true  , true)
            } else {
              this.loadNotification('success', 4000, 'File Download',
                'File has been successfully downloaded, please have a look at it',
                'response', 1000 , true  , true)
            }
            this.globalLoader = this.getGlobalLoader
          }).catch(() => {
            this.loadNotification('error', 4000, 'File Download',
              'File not downloaded, please check your internet connection',
              'response', 1000 , true  , true);
            this.globalLoader = this.getGlobalLoader
          });
          this.globalLoader = this.getGlobalLoader
        }).catch(() => {})

      },

      loadParameters (fileUrl, fileName) {
        return new Promise((resolve) => {
          this.$store.dispatch('global/fileUrling', fileUrl);
          this.$store.dispatch('global/fileNaming', fileName);
          resolve()
        })
      },


      getCurrentLoadingTitle(){
        this.currentLoadingTitle =  "Please wait while the system is loading failed edi messages"
      }
    }
  }
</script>

<style scoped>

</style>
