<template>
  <card>
    <data-table
      :table-headers="tableHeaders"
      :table-data="manifest"
      :table-data-keys="tableDataKeys"

      :my-first-loader="myFirstLoader"
      :page-size-prop="pageSize"
      :page-no="pageNo"
      :page-number="pageNumber"

      :total-elements="totalElements"
      :total-pages="totalPages"

      :is-first="isFirst"
      :is-last="isLast"
      :no-data-found="noDataFound"
      :has-manage-button="false"
      :is-pageable="isPageable"
      :has-view-button="false"
      custom-button-name="DO Request"
      @fireCustomMethod="viewRequest"
      :has-custom-button="true"
      :has-search-form="true"
      :placeholder="'Search by Container No, Seal No or Bill of Lading'"
      :reload-all-request="reloadAllRequest"
      @searchRequest="searchReference"
      @goToPage="goToPage"
      @onChange="onChange"
      @reloadByIcon="reloadByIcon"
      :loading-title="'Please wait while system is loading manifest details'"
    />
    <modal v-if="showModalDoRequest">
      <div slot="body">
        <div class="col-12 text-center">
          <pulse-loader
            :loading="loading"
            :color="color"
            :size="size"
            v-if="loading"
          />
        </div>

        <div class="legend" v-for="(requests,index) in doCustomerRequests">
          <h6 class="legend-title">
            Request {{"0" + (index + 1)}}
          </h6>
            <form>
          <div class="form-row">
            <div class="form-group col-md-6">
              <label for="bill">Bill Of Lading</label>
              <input type="text" class="form-control" id="bill" :value="requests.blNumber" disabled>
            </div>
            <div class="form-group col-md-6">
              <label for="agent">Agent</label>
              <input type="text" class="form-control" id="agent" :value="requests.cfaAgent?requests.cfaAgent.agentName:''" disabled>
            </div>
          </div>
          <div class="form-group">
            <label for="inputAddress">Delivery Point</label>
            <input type="text" class="form-control" id="inputAddress" placeholder="Delivery Point" :value="requests.destination" disabled>
          </div>
<!--          <button type="submit" class="btn btn-primary mr-2" @click.prevent="createDeliveryOrder(requests)">Create Delivery Order</button>-->
        </form>
        </div>
        <div class="col-12 text-center" v-if="noBLRequest">
          <h4>No Request For this Bill of Lading</h4>
        </div>
        <button type="submit" class="btn btn-primary text-right float-right" @click="closeModal">Close</button>
      </div>
    </modal>
  </card>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'
  import AGENCYAPI from "../../../api/agency";
  import DataTable from '../../../components/DataTable/updatedTable';
  import Modal from '../../../components/Inputs/Modal'
  import PulseLoader from 'vue-spinner/src/PulseLoader.vue';
  import {required} from "vuelidate/lib/validators";
  import AGENCY_APi from '../../../api/agency'


  export default {
        name: "blList",
    components: {
      DataTable,
      Modal,
      PulseLoader,
    },

    validations: {

    },

      computed:{
          ...mapGetters('manifest',[
            'getSelectedManifest'
          ])
      },
    created() {
          this.getSelectedManifestDetails();
    },

    data(){
      return{
        manifest:[],
        ogManifestBl:[],
        showDeliveryOrderModal:false,
        deliveryOrderRequests:[],
        blDetails: {
          agent: '',
          arrivalPort: '',
          billOfLading: '',
          blType: '',
          cancelled: '',
          companyCode: '',
          consignee: '',
          consolidationItemNumber: '',
          containerNo: '',
          createDateTime: '',
          deliveryPort: '',
          description: '',
          destinationPlace: '',
          dimensions: '',
          dischargePort: '',
          documentId: '',
          documentMessageNo: '',
          fobPrice: '',
          fobPriceUnit: '',
          grossVolume: '',
          grossVolumeUnit: '',
          grossWeight: '',
          grossWeightUnit: '',
          hsCode: '',
          insurancePrice: '',
          insurancePriceUnit: '',
          internationalFreightPrice: '',
          internationalFreightPriceUnit: '',
          isCancelled: '',
          isSpecified: '',
          loadPort: '',
          manifestBlId: '',
          marksNumbers: '',
          masterBillOfLading: '',
          measurement: '',
          netVolume: '',
          netVolumeUnit: '',
          netWeight: '',
          netWeightUnit: '',
          notifyName: '',
          notifyName1: '',
          notifyName2: '',
          numberOfPackage: '',
          operationType: '',
          originalLoadPort: '',
          packageType: '',
          sealNumber: '',
          shipper: '',
          specified: '',
          tareWeight: '',
          tareWeightUnit: '',
          temperature: '',
          updateDateTime: ''
        },
        doRequest:{
          agentId: null,
          blNo: null,
          deliveryPoint: null
        },
        doRequestError:false,
        totalPages: 1,
        pageNo: 0,
        pageSize: 15,
        pageCount: 1,
        pageNumber: 0,
        myFirstLoader: false,
        totalElements: 0,
        isFirst: true,
        isLast: false,
        noDataFound: false,
        reloadAllRequest: false,
        color: 'green',
        size: '12px',
        loading: false,
        myLoader: false,
        showModalDoRequest:false,
        doCustomerRequests:[{}],
        noBLRequest:true,
        isPageable: true,
        isPagable:true,
        tableHeaders: ['Master Bl Of Lading','Bill of Lading', 'Container No.', 'Seal No.', 'Load Port', 'Package Type'],
        tableDataKeys: ['masterBillOfLading','billOfLading', 'containerNo', 'sealNumber', 'loadPort', 'pktyDetails']
      }
    },

    methods:{



      loadBlDetails(BLdetails){

        AGENCYAPI.getCustomerShippingOrderRequestByBlNumber(BLdetails).then((response)=>{
          this.doCustomerRequests=response.data.data;

          this.pageNo = 0;
          this.pageNumber = 0;
          this.ogManifestBl=this.doCustomerRequests.manifestBLList;
          this.totalElements = this.ogManifestBl.length + 1;
          this.totalPages = Math.ceil(this.ogManifestBl.length / this.pageSize);
          this.paginate(this.ogManifestBl, this.pageSize, 1);
        }).catch(()=>{

        });
      },

      createDeliveryOrder(data){
        this.doRequest={
          agentId: data.cfaAgent.cfAgentId,
          blNo: data.blNumber,
          deliveryPoint: data.destination
        };

        if(this.doRequest.agentId!=null && this.doRequest.blNo!=null && this.doRequest.deliveryPoint!=null){
          this.loading=true;
          AGENCY_APi.saveDeliveryOrder(this.doRequest, data.id).then(()=>{
            this.loading=false;
            this.loadBlDetails(this.doRequest.blNo);
            this.closeModal();
            this.doRequestError=false;
            this.notifyUser('success', 'Success', `Delivery order Created Successfully` );

          }).catch(()=>{
            this.notifyUser('error', 'Failed', `Failed to create Delivery Order` );
          })
        }else {
          this.notifyUser('error', 'Failed', `Make Sure All required Details are present` );
        }

      },
      isEmpty(obj) {
        for(var prop in obj) {
          if(obj.hasOwnProperty(prop)) {
            return false;
          }
        }
      return JSON.stringify(obj) === JSON.stringify({});
    },

      getSelectedManifestDetails(){
        let data=this.getSelectedManifest;

        if(this.isEmpty(data)){
          data=JSON.parse(window.localStorage.getItem('getSelectedManifest'));
        }else {
          window.localStorage.setItem('getSelectedManifest', JSON.stringify(this.getSelectedManifest));
        }

        this.pageNo = 0;
        this.pageNumber = 0;
        this.ogManifestBl=data.manifestBLList;
        this.totalElements = this.ogManifestBl.length + 1;
        this.totalPages = Math.ceil(this.ogManifestBl.length / this.pageSize);
        this.paginate(this.ogManifestBl, this.pageSize, 1);
      },
      reloadByIcon () {
        this.reloadAllRequest = false;
        //this.paymentRequest = [];
        // this.manifestBl = this.ogManifestBl;
        this.paginate(this.ogManifestBl, this.pageSize, 1);
        this.isPageable = true;
      },
      viewRequest(BLdetails){
        this.doCustomerRequests={};
        this.noBLRequest=false;
        this.loading=true;
        AGENCYAPI.getCustomerShippingOrderRequestByBlNumber(BLdetails.masterBillOfLading).then((response)=>{
          this.doCustomerRequests=response.data.data;
          this.loading=false;
          if(this.doCustomerRequests.length < 1){
            this.noBLRequest=true;
          }
          this.openModal("showDoModal");
        }).catch(()=>{

        });
        this.showModalDoRequest=true;

      },

      openModal(type){

        if(type==="showDoModal"){
          this.showModalDoRequest=true;
        }
      },

      closeModal(){
        event.preventDefault();
        this.doRequest={
            agentId: null,
            blNo: null,
            deliveryPoint: null
        };
        this.showModalDoRequest=false;
      },

      searchReference (searchParam) {
        this.isPagable = false;
        this.reloadAllRequest = true;
        function search (arr, s) {
          const matches = []; let i; let key;

          for (i = arr.length; i--;) {
            if ((arr[i].hasOwnProperty('containerNo') && arr[i].containerNo.indexOf(s) > -1) ||
              (arr[i].hasOwnProperty('sealNumber') && arr[i].sealNumber.indexOf(s) > -1) ||
              (arr[i].hasOwnProperty('billOfLading') && arr[i].billOfLading.indexOf(s) > -1) ||
              (arr[i].hasOwnProperty('masterBillOfLading') && arr[i].billOfLading.indexOf(s) > -1)
            )
            {
              matches.push(arr[i])
            }
          }
          return matches
        };
        this.isPageable = false

        this.manifestBl = search(this.ogManifestBl, searchParam)
      },
      paginate (array, page_size, page_number) {
        this.manifest = array.slice((page_number - 1) * page_size, page_number * page_size)
      },
      goToPage (page) {
        // this.pageNumber = this.pageNumber + 1;
        this.pageNo = page
        this.pageNumber = this.pageNo
        this.paginate(this.ogManifestBl, this.pageSize, (page + 1))
      },
      onChange (event) {
        this.pageSize = event
        this.totalPages = Math.ceil(this.manifestBl.length / this.pageSize)
        this.paginate(this.ogManifestBl, event, this.pageNo + 1)
      },
      fadeMe: function () {
        if (this.SuccessAlert) {
          this.SuccessAlert = !this.SuccessAlert
        } else {
          this.ErrorAlert = !this.ErrorAlert
        }
      },
      enter: function (el, done) {
        var that = this

        setTimeout(function () {
          that.SuccessAlert = false
          that.ErrorAlert = false
        }, 3000) // hide the message after 3 seconds
      }

    },


    }
</script>

<style scoped>
  .legend {
    position: relative;
    border: 1px solid #DCDCDC;
    padding: 1.5rem 1rem 1rem 1rem;
    margin-bottom: 1rem;
    border-radius: 3px;
    margin-top: 1rem;
  }

  .legend-title {
    position: absolute;
    top: -0.6rem;
    padding: 0.2rem 1rem;
    background-color: #fafafa;
    color: #d59a18;
  }

</style>
