<template>
  <card>
    <div class="container-fluid">
      <div class="row">
        <div class="col-12 text-center">
          <pulse-loader
            :loading="loading"
            :color="color"
            :size="size"
            v-if="loading"
          />
          <crud-notification
            :error-message="ErrorMessage"
            :success-message="SuccessMessage"
            :error-alert="ErrorAlert"
            :success-alert="SuccessAlert"
            @fadeMe="fadeMe"
            @enter="enter"
          />
        </div>
        <ship-order-sub @detailsData="this.receivingDetailsData" ref="sinkData"></ship-order-sub>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <hr />
        </div>
      </div>
      <div class="row text-right">
        <div class="col-md-12">
          <button
            class="btn btn-primary custom-btn"
            @click="submit"
          >
            Submit
          </button>
        </div>
      </div>
  </card>
</template>

<script>
  import API from '../../../api/index';
  import { ModelListSelect,ModelSelect } from 'vue-search-select';
  import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
  import AGENYAPI from '../../../api/agency';
  import CrudNotification from '../../../components/DataTable/crudNotification';
  import containerSize from "./containerSize";
  import ports from "./ports";
  import getcountries from "./countries";
  import shipOrderSub from "./shipOrderSub";


  export default {
    name: 'BookingAplication',

    components: {
      ModelListSelect,
      CrudNotification,
      PulseLoader,
      ModelSelect,
      shipOrderSub
    },

    created () {
      this.getcustomers();
      this.getSeaPorts();
      this.getCountries();
      this.getShipingOrder();
      this.$store.dispatch('pagenames/currentPageName', 'Application Booking');

    },

    data () {
      return {

        containerSize:containerSize,
        SeaPorts:[],
        countries:[],
        selectedPort:"",
        details: {
          cityCode:null,
          id:this.$route.params.shippingOrderId,
          countryCode:null,
          destinationPort:null,
          destinationPlace:null,
          originPort:null,
          containerized:false,
          containerSize:null,
          shippingOrderNo:null,
          shipperCodeTin: null,
          shipperName: null,
          shipperAddress: null,
          shipperCity: null,
          shipperZipCode: null,
          shipperCountry: null,
          shipperContactPerson: null,
          shipperTel: null,
          shipperEmail: null,
          consigneeCodeTin: null,
          consigneeName: null,
          consigneeAddress: null,
          consigneeCity: null,
          consigneeZipCode: null,
          consigneeCountry: null,
          consigneeContactPerson: null,
          consigneeTel: null,
          consigneeEmail: null,
          expectedDepatureDate: null,
          expectedArrivalDate: null,

          notiyAddress: null,
          notiyCity: null,
          notiyCodeTin: null,
          notiyContactPerson: null,
          notiyCountry: null,
          notiyEmail: null,
          notiyName: null,
          notiyTel: null,
          notiyZipCode: null,
          termOfDelivery: null,
          shippingOrderGoodsDtos: [],
          shipperNameForInvoice:null,
          shipperTelForInvoice:null
        },
        numberOfCurgoes: 0,
        customerOptions: [],

        color: 'green',
        size: '15px',
        loading: false,

        ErrorMessage: '',
        SuccessAlert: false,
        ErrorAlert: false,
        SuccessMessage: '',

      }
    },

    methods: {

      receivingDetailsData(data){
        console.log(data);
        this.details=data;
      },

      getShipingOrder () {
        AGENYAPI.getShippingOrderById(this.$route.params.shippingOrderId).then((response) => {
          this.details = response.data.data;
          this.$refs.sinkData.populateDetails(this.details);

          // this.details.expectedArrivalDate = this.dateToHuman(this.details.expectedArrivalDate)
          // this.details.expectedDepatureDate = this.dateToHuman(this.details.expectedDepatureDate)
        }).catch((error) => {
          console.log(error);
          this.ErrorMessage = 'Failed to Fetch Shipping Order';
          this.SuccessAlert = false;
          this.ErrorAlert = true;
          this.SuccessMessage = ''
        })
      },

      getContainerName(data){
        return `${data.descriptionofCodeGroup} - ${data.containerCode}`
      },

      getCountries(){

        getcountries.map((country)=>{
          this.countries.push(country);
        })
      },

      codeAndCountry(data){
        return `${data.name}(${data.code})`
      },

      getSeaPorts(){

        ports.map((port)=>{
          this.SeaPorts.push(
            { value: port, text: port}
          );
        })
      },

      changed(e){
        console.log(e);
      },



      getcustomers () {
        API.searchCustomers(name).then((response) => {
          this.customerOptions = response.data.data
        })
      },

      submit () {
          this.ErrorMessage = '';
          this.SuccessAlert = false;
          this.ErrorAlert = false;
          this.SuccessMessage = '';
        let data={
            packageType:this.details.packageType,
            cityCode: this.details.cityCode,
            countryCode: this.details.countryCode,
            destinationPort: this.details.destinationPort,
            originPort: this.details.originPort,
            destinationPlace: this.details.destinationPlace,
            containerized: this.details.containerized,
            containerSize: this.details.containerSize,
            shippingOrderNo: this.details.shippingOrderNo,
            shipperCodeTin: this.details.shipperCodeTin,
            shipperName: this.details.shipperName,
            shipperAddress: this.details.shipperAddress,
            shipperCity: this.details.shipperCity,
            shipperZipCode: this.details.shipperZipCode,
            shipperCountry: this.details.shipperCountry,
            shipperContactPerson: this.details.shipperContactPerson,
            shipperTel: this.details.shipperTel,
            shipperEmail: this.details.shipperEmail,
            consigneeCodeTin: this.details.consigneeCodeTin,
            consigneeName: this.details.consigneeName,
            consigneeAddress: this.details.consigneeAddress,
            consigneeCity: this.details.consigneeCity,
            consigneeZipCode: this.details.consigneeZipCode,
            consigneeCountry: this.details.consigneeCountry,
            consigneeContactPerson: this.details.consigneeContactPerson,
            consigneeTel: this.details.consigneeTel,
            consigneeEmail: this.details.consigneeEmail,
            expectedDepatureDate: this.details.expectedDepatureDate,
            expectedArrivalDate: this.details.expectedArrivalDate,

            notiyAddress: this.details.notiyAddress,
            notiyCity: this.details.notiyCity,
            notiyCodeTin: this.details.notiyCodeTin,
            notiyContactPerson: this.details.notiyContactPerson,
            notiyCountry: this.details.notiyCountry,
            notiyEmail: this.details.notiyEmail,
            notiyName: this.details.notiyName,
            notiyTel: this.details.notiyTel,
            notiyZipCode: this.details.notiyZipCode,
            termOfDelivery: this.details.termOfDelivery,
            shipperNameForInvoice: this.details.shipperNameForInvoice,
            shipperTelForInvoice: this.details.shipperTelForInvoice,
        };


          AGENYAPI.EditShippingOrder(data).then(() => {
            this.loadNotification('success', 4000, 'Success','Shipping order Edited Successfully','response', 1000 , true  , true);
            this.loading = false;
          }).catch((error) => {
            this.loadNotification('error', 4000, 'Error','Shipping order failed to be Edited','response', 1000 , true  , true);
            this.loading = false
          })

      },

      fadeMe: function () {
        if (this.SuccessAlert) {
          this.SuccessAlert = !this.SuccessAlert;
        } else {
          this.ErrorAlert = !this.ErrorAlert;
        }
      },
      enter: function (el, done) {
        var that = this;

        setTimeout(function () {
          that.SuccessAlert = false;
          that.ErrorAlert = false;
        }, 3000); // hide the message after 3 seconds
      },

      removeCargo (index) {
        const mm = this.details.shippingOrderGoodsDtos.splice(index, 1)
      },
      ordinal_suffix_of (i) {
        const j = i % 10;
        const k = i % 100;
        if (j === 1 && k !== 11) {
          return i + 'st'
        }
        if (j === 2 && k !== 12) {
          return i + 'nd'
        }
        if (j === 3 && k !== 13) {
          return i + 'rd'
        }
        return i + 'th'
      }
    }
  }
</script>

<style lang="scss" scoped>

  label {
    // color: rgba(213,154,24, .9) !important;
    color: black !important;
  }

  .custom-btn {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
    padding: 5px 9px 5px 9px !important;
    font-size: 14px !important;
    width: auto;
    background-color: #FFFFFF !important;
  }

  .custom-font {
    // color: #4c4c4c !important;
    font-size: 14px !important;
  }

  ::placeholder {

  }

  h3 {
    width: 100%;
    text-align: center;
    border-bottom: 1px solid green;
    line-height: 0.1em;
    margin: 10px 0 20px;
  }

  h3 span {
    background: #fff;
    padding: 0 10px;
  }

  .cargo {

  }

  .legend {
    position: relative;
    border: 1px solid #dcdcdc;
    padding: 1.5rem 1rem 1rem 1rem;
    margin-bottom: 1rem;
    border-radius: 3px;
    margin-top: 1rem;
  }

  .legend-title {
    position: absolute;
    top: -0.6rem;
    padding: 0.2rem 1rem;
    background-color: white;
    color: #d59a18;
  }

</style>
