<template>
  <div>
    <card style="font-size: 12px">
      <div class="row">
        <div class="col-md-12">
          <ul class="nav-justified">
            <li>
              <button
                @click.prevent="openModal"
                class="btn btn-olive btn-olive-fill mr-2 custom-btn"
              >
                <font-awesome-icon icon="luggage-cart" /> &nbsp; Create Charge
              </button>
            </li>
          </ul>
        </div>
      </div>
    </card>
    <card>
      <data-table
        :table-headers="tableHeaders"
        :table-data="requests"
        :table-data-keys="tableDataKeys"
        :my-first-loader="myFirstLoader"
        :no-data-found="noDataFound"
        :has-search-form="false"
        :loading-title="'Please wait while system is loading stuffing tallied containers'"
        :has-manage-button="false"
        :has-custom-button="true"
        :custom-button-name="'View Goods'"
        :is-pageable="false"
        @fireCustomMethod="openModal"
      />
    </card>
    <global-modal v-if="myLoader">
      <div slot="globalLoader">
        <div class="row text-center">
          <div class="col-md-12">
            Loading, Please wait
          </div>
          <div class="col-md-12">
            <br>
          </div>
          <div class="col-md-12">
            <pulse-loader
              :loading="loading"
              :color="color"
              :size="size"
            />
          </div>
        </div>
      </div>
    </global-modal>

    <modal v-if="createCharge">
      <div slot="body">
        <div class="row">
          <div
            class="col-md-12"
            v-if="createCharge"
          >
            Charge will be created here
          </div>

          <div
            class="col-md-12"
            v-if="createCharge"
          >
            <hr>
          </div>

          <div
            class="col-md-12"
            v-if="createCharge"
          >
            <div class="row">
              <div class="col-md-7 text-right loader-div" />
              <div class="col-md-2 left loader-div-gif">
                <pulse-loader
                  :loading="loading"
                  :color="color"
                  :size="size"
                />
              </div>
              <div class="col-md-3">
                <button
                  type="button"
                  class="btn btn-fill btn-outline-info float-right"
                  @click="closeModal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
    <large-modal v-if="viewGoods">
      <div slot="body">
        <div class="row">
          <div
            class="col-md-12"
            v-if="viewGoods"
          >
            <card>
              <data-table
                :table-headers="tableHeadersGoods"
                :table-data="requestsGoods"
                :table-data-keys="tableDataKeysGoods"
                :my-first-loader="myFirstLoader"
                :no-data-found="noDataFound"
                :has-search-form="false"
                :loading-title="'Please wait while system is loading stuffing tallied containers'"
                :has-manage-button="false"
                :has-custom-button="false"
                :is-pageable="false"
              />
            </card>
          </div>

          <div
            class="col-md-12"
            v-if="viewGoods"
          >
            <hr>
          </div>

          <div
            class="col-md-12"
            v-if="viewGoods"
          >
            <div class="row">
              <div class="col-md-9 left loader-div-gif" />
              <div class="col-md-3">
                <button
                  type="button"
                  class="btn btn-fill btn-outline-info float-right"
                  @click="closeModal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </large-modal>
  </div>
</template>

<script>

import { mapGetters, mapActions } from 'vuex'
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import GlobalModal from '../../../components/globalLoader'
import Modal from '../../../components/Inputs/Modal'
import LargeModal from '../../../components/Inputs/ModalPlanAndCostEstimation'
import DataTable from '../../../components/DataTable/updatedTable'

export default {

  name: 'TalliedContainer',

  components: {

    PulseLoader,
    GlobalModal,
    DataTable,
    Modal,
    LargeModal

  },

  created () {
    this.$store.dispatch('pagenames/currentPageName', 'Tallied containers')

    this.finalUserPermissions = JSON.parse(localStorage.getItem('browser-local-permits'))

    this.loadRequestOnCreated()
  },

  data () {
    return {

      pageNo: 0,
      pageSize: 15,
      pageCount: 1,
      sortBy: 'createdAt',

      color: 'green',
      size: '12px',
      loading: true,

      createCharge: false,
      viewGoods: false,

      totalElements: '',
      totalPages: '',
      isFirst: false,
      isLast: false,
      pageNumber: '',

      searchParameter: '',
      hasSearchedVariable: false,
      reloadAllRequest: false,
      noDataFound: false,
      myLoader: false,
      myFirstLoader: false,
      isSearchable: false,
      requests: [],
      requestsGoods: [],
      stuffingType: 'STUFFING',

      ifThereIsParam: '',

      finalUserPermissions: [],
      tableHeaders: [
        'container No', 'seals', 'container Size', 'Container Type', 'gross Weight', 'tare Weight', 'description'
      ],
      tableDataKeys: [
        'containerNo', 'seals', 'containerSize', 'typeOfContainer', 'grossWeight', 'tareWeight', 'description'
      ],

      tableHeadersGoods: [
        'goods Item No', 'goods Item Name', 'good Category', 'hsCode', 'net Weight', 'source Weight Measurement', 'volume',
        'description', 'package Quantity', 'package Type', 'tallying Method', 'vehicle VIN', 'vehicle Model', 'vehicle Make'
      ],
      tableDataKeysGoods: [
        'goodsItemNo', 'goodsItemName', 'goodCategory', 'hsCode', 'netWeight', 'sourceWeightMeasurement', 'volume',
        'description', 'packageQuantity', 'packageType', 'tallyingMethod', 'vehicleVIN', 'vehicleModel', 'vehicleMake'
      ]

    }
  },

  computed: {

    ...mapGetters('talliedStuffingOrStripping', [

      'getRequestList',
      'getPageCount',
      'getMyFirstLoader',
      'getNoDataFound',
      'getSuccessAlert',
      'getErrorMessage',
      'getErrorAlert',
      'getSuccessMessage',
      'getReloadAllRequest',
      'getMyLoader',
      'getSearchParameter',
      'getHasSearchedVariable',
      'getPageNo',
      'getRequestId',
      'getTotalElement',
      'getTotalPage',
      'getPageNumber',
      'getIsFirst',
      'getIsLast',
      'getStuffingOrStrippingId'

    ])

  },

  methods: {

    ...mapActions('talliedStuffingOrStripping', [

      'loadTalliedContainersStuffingOrStrippingOnCreated'
    ]),

    loadParameters () {
      return new Promise((resolve) => {
        if (this.$route.params.StuffingOrStrippingId) {
          this.$store.dispatch('talliedStuffingOrStripping/StuffingOrStrippingId', this.$route.params.StuffingOrStrippingId)
        }

        resolve()
      })
    },

    setAllGetters () {
      return new Promise((resolve) => {
        this.requests = this.getRequestList
        this.myFirstLoader = this.getMyFirstLoader
        this.noDataFound = this.getNoDataFound
        this.SuccessMessage = this.getSuccessAlert
        this.ErrorMessage = this.getErrorMessage
        this.ErrorAlert = this.getErrorAlert
        this.SuccessAlert = this.getSuccessMessage

        resolve()
      })
    },

    loadRequestOnCreated () {
      this.loadParameters().then(() => {
        this.loadTalliedContainersStuffingOrStrippingOnCreated({}).then(() => {
          this.setAllGetters()
        }).catch(() => {

        })

        this.myFirstLoader = this.getMyFirstLoader
      }).catch(() => {

      })
    },

    openModal (object) {
      if (object.containerNo !== null && typeof object.containerNo !== 'undefined') {
        this.viewGoods = true
        this.requestsGoods = object.goodsDeStuffings
      } else {
        this.createCharge = true
      }
    },

    closeModal () {
      this.createCharge = false
      this.viewGoods = false
    }

  }

}

</script>

<style scoped>
    ul.nav-justified {
        list-style-type: none;
        margin: 0;
        padding: 0;
        overflow: hidden;
        background-color: #ededee;
        border-radius: 3px;
    }

    ul.nav-justified li {
        float: left;
    }

    ul.nav-justified li a:hover {
        color: #d59a18;
    }

    ul.nav-justified li a {
        display: block;
        text-align: center;
        padding: 16px 20px 10px 21px;
        text-decoration: none;
        color: blue;
        font-weight: bold;
        text-transform: uppercase;
    }

    .custom-btn {
        margin-top: 5px !important;
        margin-bottom: 5px !important;
        padding: 5px 9px 5px 9px !important;
        font-size: 14px !important;
    }

    .btn-blue:hover:hover #icon-gb , .btn-olive:hover:hover #icon-sr , .btn-success-custom:hover #icon-dr ,
    .btn-purple:hover #icon-vpr , .btn-blue-fill:hover #icon-d {

        fill: #000000;
    }

    .btn-olive:hover {
        border-color: #4B0082;
        color: #4B0082;
        background-color: #ffffff;
    }

    .btn-olive {
        border-color: #6A5ACD;
        color: #6A5ACD;
    }

    .btn-olive-fill {
        background-color: #6A5ACD;
        opacity: 1;
        color: #FFFFFF;
    }
</style>
