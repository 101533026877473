<template>
  <div>
      <card v-if="myFirstLoader">
        <div class="row">
            <div class="col-md-12 text-center">
                <b>Please wait while system is loading vessel call details </b>
            </div>
            <div class="col-md-12 text-center">
                <br>
            </div>
            <div class="col-md-12 text-center">
                <pulse-loader
                :color="color"
                :size="size"
                />
            </div>
        </div>
      </card>
      <card style="font-size: 12px" v-if="!myFirstLoader">
        <div class="row">
          <div class="col-md-12 text-center">
            <pulse-loader
              :loading="loading"
              :color="color"
              :size="size"
            />
          </div>
            <div class="col-md-12">
              <ul
                class="nav-justified"
                style="padding-left: 10px;padding-right: 10px">
                <li>
                  <button @click.prevent="submitTesws"
                    class="btn btn-success-custom btn-success-custom-fill mr-2 custom-btn">
                    Submit to TESWS
                  </button>
                </li>

                <li v-if="checkVesselStatus">
                  <button @click="closeVessel"
                    class="btn btn-danger-custom btn-danger-custom-fill mr-2 custom-btn">
                    Close Vessel
                  </button>
                </li>

                <li v-if="vesselDetails.status === 'CREATED' && SOFDetails.length === 0">
                  <button @click.prevent="cancelNomination"
                    class="btn btn-yellow btn-yellow-fill mr-2 custom-btn">
                    Cancel Nomination
                  </button>
                </li>

                <li v-if="checkVesselStatus">
                  <button @click.prevent="showModalSof=true"
                    class="btn btn-linen btn-linen-fill mr-2 custom-btn">
                    Upload SOF
                  </button>
                </li>
                <li v-if="vesselDetails.manifestExists">
                  <button @click.prevent="goToManifest"
                          class="btn btn-purple btn-purple-fill mr-2 custom-btn">
                    View Manifest Details
                  </button>
                </li>
                <li v-if="!vesselDetails.manifestExists" class=" float-right">
                  <div class="fileUpload btn btn-purple btn-purple-fill custom-btn" style="position:relative; top:2%;heigh:200px !important;">
                  <span>Upload Approved Manifest</span>
                  <input
                    type="file"
                    name="attachment"
                    label="Upload File"
                    ref="file"
                    class="upload"
                    id="first_upload"
                    @change.prevent="uploadFile"
                  >
                </div>
                </li>
                <li v-if="vesselDetails.manifestExists" class=" float-right">
                  <div class="fileUpload btn btn-purple btn-purple-fill custom-btn" style="position:relative; top:2%;heigh:200px !important;">
                  <span>Upload Ammended Manifest</span>
                  <input
                    type="file"
                    name="attachment"
                    label="Upload File"
                    ref="file"
                    class="upload"
                    id="first_upload"
                    @change.prevent="uploadFile"
                  >
                </div>
                </li>
              </ul>
            </div>
          </div>
        </card>
        <card style="font-size: 12px" v-if="!myFirstLoader">
        <div class="row">
        <div class="col-md-12">
          <div class="legend">
            <h6 class="legend-title">
              Vessel Details
            </h6>

            <div class="row div-table-row">
              <div class="col-md-2 div-table div-table-title">
                Voyage No.
              </div>
              <div class="col-md-2 div-table div-table-value">
                {{ vesselDetails.voyageNo }}
              </div>
              <div class="col-md-2 div-table div-table-title">
                Vessel Name
              </div>
              <div class="col-md-2 div-table div-table-value">
                {{ vesselDetails.vesselName }}
              </div>

              <div class="col-md-2 div-table div-table-title">
                Vessel Type
              </div>
              <div class="col-md-2 div-table div-table-value">
                {{ vesselDetails.vesselType }}
              </div>
            </div>
            <br>

            <div class="row div-table-row">
              <div class="col-md-2 div-table div-table-title">
                Vessel Operations
              </div>
              <div class="col-md-2 div-table div-table-value">
                {{ vesselDetails.operation }}
              </div>
              <div class="col-md-2 div-table div-table-title">
                Vessel Status.
              </div>
              <div class="col-md-2 div-table div-table-value" :class="[checkStatus(vesselDetails.status) ]" style="text-transform: uppercase">
                {{ vesselDetails.status }}
              </div>
              <div class="col-md-2 div-table div-table-title">
                Arrival Port
              </div>
              <div class="col-md-2 div-table div-table-value">
                {{ vesselDetails.arrivalPort }}
              </div>
            </div>
            <br>

            <div class="row div-table-row">
              <div class="col-md-2 div-table div-table-title">
                Contract Type
              </div>
              <div class="col-md-2 div-table div-table-value">
                {{ vesselDetails.contractType }}
              </div>

              <div class="col-md-2 div-table div-table-title">
                Berth
              </div>
              <div class="col-md-2 div-table div-table-value">
                {{ vesselDetails.berth }}
              </div>
              <div class="col-md-2 div-table div-table-title">
                Vessel ETA
              </div>
              <div class="col-md-2 div-table div-table-value">
                {{ vesselDetails.eta ? dateTimeToHuman(vesselDetails.eta) : '' }}
              </div>
            </div>
            <br>

            <div class="row div-table-row">
              <div class="col-md-2 div-table div-table-title">
                Vessel ETB
              </div>
              <div class="col-md-2 div-table div-table-value">
                {{ vesselDetails.etb ? dateTimeToHuman(vesselDetails.etb) : '' }}
              </div>
              <div class="col-md-2 div-table div-table-title">
                Vessel ETD
              </div>
              <div class="col-md-2 div-table div-table-value">
                {{ vesselDetails.etd ? dateTimeToHuman(vesselDetails.etd) : '' }}
              </div>

              <div class="col-md-2 div-table div-table-title">
                Expected Stay Time (Hrs.)
              </div>
              <div class="col-md-2 div-table div-table-value">
                {{ vesselDetails.stayTime }}
              </div>
            </div>
            <br>

            <div class="row div-table-row">
              <div class="col-md-2 div-table div-table-title">
                Vessel Dead Weight Tonnage
              </div>
              <div class="col-md-2 div-table div-table-value">
                {{ vesselDetails.dwt }}
              </div>
              <div class="col-md-2 div-table div-table-title">
                Vessel Gross Weight Tonnage
              </div>
              <div class="col-md-2 div-table div-table-value">
                {{ vesselDetails.grt }}
              </div>

              <div class="col-md-2 div-table div-table-title">
                Vessel Net  Weight Tonnage
              </div>
              <div class="col-md-2 div-table div-table-value">
                {{ vesselDetails.nrt }}
              </div>
            </div>
            <br>

            <div class="row div-table-row">
              <div class="col-md-2 div-table div-table-title">
                Length Overall (Metres)
              </div>
              <div class="col-md-2 div-table div-table-value">
                {{ vesselDetails.loa }}
              </div>
              <div class="col-md-2 div-table div-table-title">
                Vessel Factor
              </div>
              <div class="col-md-2 div-table div-table-value">
                {{ vesselDetails.factor }}
              </div>

              <div class="col-md-2 div-table div-table-title">
                Vessel Draft (Metres)
              </div>
              <div class="col-md-2 div-table div-table-value">
                {{ vesselDetails.draft }}
              </div>
            </div>
            <br>

            <div class="row div-table-row">
              <div class="col-md-2 div-table div-table-title">
                Vessel Beam (Metres)
              </div>
              <div class="col-md-2 div-table div-table-value">
                {{ vesselDetails.beam }}
              </div>
              <div class="col-md-2 div-table div-table-title">
                Vessel ATA
              </div>
              <div class="col-md-2 div-table div-table-value">
                {{ vesselDetails.ata ? dateTimeToHuman(vesselDetails.ata): '' }}
              </div>

              <div class="col-md-2 div-table div-table-title">
                Vessel ATB
              </div>
              <div class="col-md-2 div-table div-table-value">
                {{ vesselDetails.atb ? dateTimeToHuman(vesselDetails.atb): '' }}
              </div>

            </div>
            <br>
            <div class="row div-table-row">
              <div class="col-md-2 div-table div-table-title">
                Vessel ATC
              </div>
              <div class="col-md-2 div-table div-table-value">
                {{ vesselDetails.atc ? dateTimeToHuman(vesselDetails.atc): '' }}
              </div>

              <div class="col-md-2 div-table div-table-title">
                Vessel ATD
              </div>
              <div class="col-md-2 div-table div-table-value">
                {{ vesselDetails.atd ? dateTimeToHuman(vesselDetails.atd) : '' }}
              </div>

              <div class="col-md-2 div-table div-table-title">
                Pilot In
              </div>
              <div class="col-md-2 div-table div-table-value">
                {{ vesselDetails.pilotIn ? dateTimeToHuman(vesselDetails.pilotIn) : '' }}
              </div>
            </div>
            <br>
<!--            <div class="row">-->
<!--              <div class="col-md-12">-->
<!--                <button-->
<!--                  class="btn btn-primary mr-2 custom-btn float-left"-->
<!--                  @click.prevent="viewVesselDetails"-->
<!--                >-->
<!--                  View All Details-->
<!--                </button>-->
<!--              </div>-->
<!--            </div>-->

          </div>
        </div>
      </div>

      <div class="legend">
        <h6 class="legend-title">
          Sub Disbursement Account Details
        </h6>
          <list-sub-disbursement :vessel-id="vesselId" :disbursement-account-id="mainDisbursmentId"/>
      </div>

      <div class="row">
        <div class="col-md-12">
          <button
            class="btn btn-default btn-fill btn-space custom-btn-all"
            style="float:right;"
            @click.prevent="goBack"
          >
            <font-awesome-icon icon="undo" /> &nbsp; Back
          </button>
        </div>
      </div>
    </card>

    <!-- view Disbursement details  -->
    <!--  TO VIEW DISBURSEMENT ACCOUNT DETAILS  -->
    <modal v-if="showModal">
      <div
        slot="header"
        class="modal-title col-md-12"
      >
        <div class="row">
          <div class="col-md-12">
            <b> {{ modalTitle }}</b>
          </div>
          <div class="col-md-12">
            <hr>
          </div>
        </div>
      </div>

      <div slot="body">
        <card v-if="ViewModalDiv">
          <div class="row">
            <div class="col-md-4">
              <base-input
                v-model="accountDetails.invoiceNo"
                type="text"
                label="Principals' Name"
                placeholder="Principals' Name"
                :value="accountDetails.invoiceNo"
                class="special-bottom"
                :readonly="true"
              />
            </div>
            <div class="col-md-4">
              <base-input
                v-model="accountDetails.voyageNo"
                type="text"
                label="Voyage No."
                placeholder="Voyage No."
                :value="accountDetails.voyageNo"
                class="special-bottom"
                :readonly="true"
              />
            </div>
            <div class="col-md-4">
              <base-input
                v-model="accountDetails.invoiceNo"
                type="text"
                label="Invoice No."
                placeholder="Invoice no."
                :value="accountDetails.invoiceNo"
                class="special-bottom"
                :readonly="true"
              />
            </div>
          </div>
          <!--   row 2   -->
          <div class="row">
            <div
              v-if="accountDetails.status === 'Not Approved'"
              class="col-md-4"
            >
              <base-input
                v-model="accountDetails.status"
                type="text"
                label="Account Status"
                placeholder="Account Status"
                :value="accountDetails.status"
                class="special-bottom"
                :readonly="true"
              />
            </div>
            <div class="col-md-4">
              <base-input
                v-model="accountDetails.disbursementType"
                type="text"
                label="Disbursement Type"
                placeholder="Disbursement Type"
                :value="accountDetails.disbursementType"
                class="special-bottom"
                :readonly="true"
              />
            </div>
            <div class="col-md-4">
              <base-input
                v-model="accountDetails.disbursementAmount"
                type="text"
                label="Disbursement Amount"
                placeholder="Disbursement Amount"
                :value="accountDetails.disbursementAmount"
                class="special-bottom"
                :readonly="true"
              />
            </div>
          </div>
          <!--   row 3   -->
          <div class="row">
            <div class="col-md-3">
              <base-input
                v-model="accountDetails.depositAmount"
                type="text"
                label="Deposit Amount"
                placeholder="Deposit Amount"
                :value="accountDetails.depositAmount"
                class="special-bottom"
                :readonly="true"
              />
            </div>
            <div class="col-md-3">
              <base-input
                v-model="accountDetails.depositDate"
                type="text"
                label="Deposit Date"
                placeholder="Deposit Date"
                :value="accountDetails.depositDate"
                class="special-bottom"
                :readonly="true"
              />
            </div>
            <div class="col-md-3">
              <base-input
                v-model="accountDetails.depositDetails"
                type="text"
                label="Deposit Details"
                placeholder="Deposit Details"
                :value="accountDetails.depositDetails"
                class="special-bottom"
                :readonly="true"
              />
            </div>
            <div class="col-md-3">
              <base-input
                v-model="accountDetails.bankReference"
                type="text"
                label="Bank Reference"
                placeholder="Bank Reference"
                :value="accountDetails.bankReference"
                class="special-bottom"
                :readonly="true"
              />
            </div>
          </div>
          <!--  row 4     -->
          <div class="row">
            <div class="col-md-3">
              <base-input
                v-model="accountDetails.preparedBy"
                type="text"
                label="Prepared By:"
                placeholder="Prepared By:"
                :value="accountDetails.preparedBy"
                class="special-bottom"
                :readonly="true"
              />
            </div>
            <div class="col-md-3">
              <base-input
                v-model="accountDetails.preparedDate"
                type="text"
                label="Prepared Date"
                placeholder="Prepared Date"
                :value="accountDetails.preparedDate"
                class="special-bottom"
                :readonly="true"
              />
            </div>
            <div class="col-md-3">
              <base-input
                v-model="accountDetails.approvedBy"
                type="text"
                label="Approved By:"
                placeholder="Approved By:"
                :value="accountDetails.approvedBy"
                class="special-bottom"
                :readonly="true"
              />
            </div>
            <div class="col-md-3">
              <base-input
                v-model="accountDetails.approvedDate"
                type="text"
                label="Approval Date"
                placeholder="Approval Date"
                :value="accountDetails.approvedDate"
                class="special-bottom"
                :readonly="true"
              />
            </div>
          </div>

          <br>
          <div class="text-center row">
            <div class="col-md-7 text-right loader-div">
              <span v-if="editMyLoader">Please wait ....</span>
            </div>
            <div class="col-md-2 left loader-div-gif">
              <pulse-loader
                v-if="editMyLoader"
                :loading="loading"
                :color="color"
                :size="size"
              />
            </div>
            <div class="col-md-3">
              <button
                type="button"
                class="btn btn-fill btn-outline-info float-right"
                style="margin-inline-start:20px"
                @click="closeModal()"
              >
                Close
              </button>
              <button
                type="submit"
                class="btn btn-primary btn-space  float-right"
                data-dismiss="modal"
              >
                Save
              </button>
            </div>
          </div>
        </card>
        <card v-if="AddModalDiv">
          <form>
            <form
              method="POST"
              @submit.prevent="registerAccount()"
            >
              <div>
                <!--   -->
                <div class="row">
                  <div class="col-md-12">
                    <label>Account Type</label>
                    <model-list-select
                      v-model="accountRegistrationDetails.disbursementType"
                      :list="accountEnum"
                      class="form-control"
                      option-value="disbursementType"
                      option-text="name"
                      placeholder="Disbursement Account Type"
                    />

                    <div v-if="hasSubmissionError">
                      <div
                        v-if="!$v.category.required"
                        class="error"
                      >
                        This field is required
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="text-center row">
                <div class="col-md-2 text-right loader-div">
                  <span v-if="editMyLoader">Please wait ....</span>
                </div>
                <div class="col-md-2 left loader-div-gif">
                  <pulse-loader
                    v-if="editMyLoader"
                    :loading="loading"
                    :color="color"
                    :size="size"
                  />
                </div>
                <div
                  v-if="AddModalDiv"
                  class="col-md-8"
                >
                  <button
                    type="button"
                    class="btn btn-fill btn-outline-info float-right"
                    @click="closeModal()"
                  >
                    Close
                  </button>
                  <button
                    type="submit"
                    class="btn btn-success btn-space  float-right"
                    data-dismiss="modal"
                    @click.prevent="registerAccount()"
                  >
                    Register Account
                  </button>
                </div>
              </div>
            </form>
          </form>
        </card>
      </div>
    </modal>
    <!--  TO SHOW ALL SOFs -->
    <modal v-if="showSOF">
      <div slot="header" class="modal-title col-md-12">
        <div class="row">
          <div class="col-md-12">
            <b v-if="isViewSOF">Statement of Facts Entry</b>
            <b v-if="!isViewSOF && isEditSOF">Edit Statement of Facts Entry</b>
            <b v-if="!isViewSOF && !isEditSOF">Add Statement of Facts Entry</b>
          </div>
          <div class="col-md-12">
            <hr>
          </div>
        </div>
      </div>

      <div slot="body">
        <card>
          <form method="POST">
            <div class="row">

              <div class="col-md-4">
                <base-input
                  v-model="vesselSOF.date"
                  type="date"
                  label="Date of Activity"
                  placeholder="Date of Activity"
                  class="special-bottom"
                  :readonly="isViewSOF"
                />
                <div
                  v-if="!$v.vesselSOF.date.required && isTested"
                  class="error"
                >
                  This field is required
                </div>
              </div>
              <div class="col-md-4">
                <base-input
                  v-model="vesselSOF.activityStartTime"
                  type="time"
                  label="Activity Start Time"
                  placeholder="Activity Start Time"
                  class="special-bottom"
                  :readonly="isViewSOF"
                />
                <div
                  v-if="!$v.vesselSOF.activityStartTime.required && isTested"
                  class="error"
                >
                  This field is required
                </div>
              </div>
              <div class="col-md-4">
                <base-input
                  v-model="vesselSOF.activityEndTime"
                  type="time"
                  label="Activity End Time"
                  placeholder="Activity End Time"
                  :value="vesselSOF.activityEndTime"
                  class="special-bottom"
                  :readonly="isViewSOF"
                />
                <div
                  v-if="!$v.vesselSOF.activityEndTime.required && isTested"
                  class="error"
                >
                  This field is required
                </div>
              </div>

            </div>
            <div class="row">
              <div class="col-md-12">
                <base-input
                  v-model="vesselSOF.remarks"
                  type="text"
                  :is-text-area="true"
                  label="Remarks"
                  placeholder="Remarks"
                  class="special-bottom"
                  :readonly="isViewSOF"
                />
                <div
                  v-if="!$v.vesselSOF.remarks.required && isTested"
                  class="error"
                >
                  This field is required
                </div>
              </div>

            </div>
            <div class="text-center row">
              <div class="col-md-7 text-right loader-div">
                <span v-if="editMyLoader">Please wait ....</span>
              </div>
              <div class="col-md-2 left loader-div-gif">
                <pulse-loader
                  v-if="editMyLoader"
                  :loading="loading"
                  :color="color"
                  :size="size"
                />
              </div>
              <div
                class="col-md-3"
              >
                <button
                  type="button"
                  class="btn btn-fill btn-outline-info float-right pr-2"
                  style="margin-inline-start:20px"
                  @click="closeModal()"
                >
                  Close
                </button>
                <button
                  v-if="!isEditSOF && !isViewSOF"
                  type="submit"
                  class="btn btn-primary btn-space  float-right"
                  data-dismiss="modal"
                  @click.prevent="addSOFData"
                >
                  Save
                </button>
                <button
                  v-if="isEditSOF && !isViewSOF"
                  type="submit"
                  class="btn btn-primary btn-space  float-right"
                  data-dismiss="modal"
                  @click.prevent="updateSOFData"
                >
                  Edit
                </button>

              </div>
            </div>

          </form>
        </card>
      </div>

    </modal>

    <!-- <create-sub-disbursement
      :show-add-sub-disbursement="showAddSubDisbursement"
      :title="modalTitle"
      :disbursement-account-id="mainDisbursmentId"
      @closeModal="closeModal"
      @requestRefresh="requestRefresh"
    /> -->

    <vessel-details
      :vessel-data="vesselDetails"
      :view-all-details="viewAllDetails"
      @closeModal="closeModal"
      @requestRefresh="requestRefresh"
    />

  </div>
</template>

<script>
import PulseLoader from 'vue-spinner/src/PulseLoader'
import uploadsA from '../subComponents/uploadSA'
import DataTable from '../../../components/DataTable/updatedTable'
import Modal from '../../../components/Inputs/Modal'
import { ModelListSelect } from 'vue-search-select'
import { mapActions, mapGetters } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import VesselDetails from './vesselDetails'
import CreateSubDisbursement from '../disbursement/sub-disbursment-account/createSubDisbursement'
import ListSubDisbursement from '../disbursement/sub-disbursment-account/listSubDisbursmentAccounts'
import AGENCY_APi from "@/api/agency";

export default {
  name: 'VesselOperations',
  components: {
    CreateSubDisbursement,
    ListSubDisbursement,
    VesselDetails,
    PulseLoader,
    DataTable,
    Modal,
    ModelListSelect,
    uploadsA
  },
  data() {
    return {

      selectExisting: true,
      showAddSubDisbursement: false,
      hasMainDisbursementAccount:false,
      principalDetailsNameError: false,
      principalDetailsCarrierCodeError: false,
      principalDetailsAddressError: false,

      principalDetails: {
        accountNo: '',
        address: '',
        carrierCode: '',
        name: ''
      },
      listOfPrincipals: [],
      filteredSubDisbursementAccounts: [],
      selectedPrincipalId: '',
      subDisbursmentData: [],
      noDataFound: false,
      vesselTypeEnum: [
        { vesselType: 'Dry Bulk' },
        { vesselType: 'Pure car Carrier' },
        { vesselType: 'Tanker' },
        { vesselType: 'Military' },
        { vesselType: 'Cruise' },
        { vesselType: 'Exhibition' },
        { vesselType: 'Other' }

      ],
      // Charter, Liner, Other
      contractTypeEnum: [
        { contractType: 'Charter' },
        { contractType: 'Liner' },
        { contractType: 'Other' }
      ],
      showStart: true,
      viewAllDetails: false,
      noSOFDataFound: false,
      SOFMyFirstLoader: false,
      SOFDetails: [],
      SOFTableHeaders: ['Date', 'Start Time', 'End Time', 'Remarks'],
      SOFTableKeys: ['date', 'activityStartTime', 'activityEndTime', 'remarks'],
      isEditSOF: false,
      isTested: false,
      isViewSOF: false,
      showSOF: false,
      vesselSOF: {
        date: '',
        activityEndTime: '',
        remarks: '',
        shiftId: '',
        activityStartTime: '',
        vesselParticularId: ''
      },
      disbursementAccountDetails: {
        approvedBy: '',
        approvedDate: '',
        bankReference: '',
        createdBy: '',
        depositAmount: '',
        depositDate: '',
        depositDetails: '',
        disbursementAccountId: '',
        disbursementAmount: '',
        disbursementType: '',
        invoiceNo: '',
        lastModifiedBy: '',
        preparedBy: '',
        preparedDate: '',
        status: '',
        vesselParticularId: ''
      },
      accountDetails: {
        voyageNo: 'N/A',
        accountNo: 'N/A',
        address: 'N/A',
        carrierCode: 'N/A',
        name: 'N/A',
        disbursementAccountId: 'N/A',
        vesselParticularId: 'N/A',
        invoiceNo: 'N/A',
        disbursementType: 'N/A',
        disbursementAmount: 'N/A',
        depositAmount: 'N/A',
        preparedBy: 'N/A',
        approvedBy: 'N/A',
        preparedDate: 'N/A',
        approvedDate: 'N/A',
        depositDate: 'N/A',
        depositDetails: 'N/A',
        bankReference: 'N/A',
        status: 'N/A'
      },
      disbursementData: [],
      accountRegistrationDetails: {
        disbursementType: '',
        vesselParticularId: ''

      },
      vesselDetails: {

        arrivalPort: '',
        ata: '',
        atd: '',
        beam: '',
        berth: '',
        carrierCode: '',
        contractType: '',
        // Enum:
        //   [ Charter, Liner, Other ]
        draft: '',
        dwt: '',
        eta: '',
        etd: '',
        factor: '',
        grt: '',
        loa: 0,
        nrt: 0,
        principalParticularId: '',
        stayTime: '',
        vesselName: '',
        vesselParticularId: '',
        vesselType: '',
        // Enum:
        //   [ Dry Bulk, Pure car Carrier, Tanker, Military, Cruise, Exhibition, Other ]
        voyageNo: ''

      },
      accountEnum: [
        { name: 'Final', disbursementType: 'Final' },
        { name: 'Other', disbursementType: 'Other' },
        { name: 'Proforma', disbursementType: 'Proforma' }
      ],
      hasSubmissionError: false,
      vesselId: '',
      AddModalDiv: false,
      EditModalDiv: false,
      ViewModalDiv: false,
      showModal: false,
      editMyLoader: false,
      modalTitle: '',
      principalId: '',

      pageNo: 0,
      pageSize: 15,
      pageCount: 1,
      totalElements: '',
      totalPages: '',
      isFirst: false,
      isLast: false,
      pageNumber: '',
      myFirstLoader: false,
      isPageable: true,

      hasAction: false,
      color: 'green',
      size: '12px',
      loading: false,
      editable: true,
      searchParameter: '',
      hasSearchedVariable: false,
      reloadAllRequest: false,
      myLoader: false,
      status: 'PRINCIPAL_VESSEL',
      isSearchable: false,
      requests: [],
      hasSaveAction: true,
      SuccessAlert: false,
      ErrorAlert: false,

      SuccessMessage: '',
      ErrorMessage: '',
      fileData: '',
      sofFileData: '',
      showModalSof: false,
      saveDetails: {},
      finalUserPermissions: [],
      tableHeaders: ['Account Status', 'Disbursement Type', 'Proforma No.'],
      tableDataKeys: ['status', 'disbursementType', 'invoiceNo'],
      subDisbursementDetailsForm: {
        disbursementAccountId: '',
        principleParticularId: ''
      }
    }
  },
  created() {

    this.$store.dispatch('pagenames/currentPageName', 'Vessel Call Details');
    this.vesselId = this.$route.params.vesselId;
    this.finalUserPermissions = JSON.parse(localStorage.getItem('browser-local-permits'));
    this.loadPageableVesselDisbursementAccountOnCreated();
    // this.getVesselDisbursementAccounts()
    this.getAssociatedStatementOfFacts();
    this.getAllPrincipalAccounts()
    // this.getAllSubDisbursementAccounts()

  },
  validations: {
    accountRegistrationDetails: {
      disbursementType: { required },
      vesselParticularId: { required }
    },
    subDisbursementDetailsForm: {
      disbursementAccountId: { required },
      principleParticularId: { required }
    },
    disbursementAccountDetails: {
      approvedBy: { required },
      approvedDate: { required },
      bankReference: { required },
      createdBy: { required },
      depositAmount: { required },
      depositDate: { required },
      depositDetails: { required },
      disbursementAccountId: { required },
      disbursementAmount: { required },
      disbursementType: { required },
      invoiceNo: { required },
      lastModifiedBy: { required },
      preparedBy: { required },
      preparedDate: { required },
      status: { required },
      vesselParticularId: { required }
    },
    vesselSOF: {
      date: { required },
      activityEndTime: { required },
      remarks: { required },
      shiftId: { required },
      activityStartTime: { required },
      vesselParticularId: { required }
    },
    vesselDetails: {

      arrivalPort: { required },
      carrierCode: { required },
      contractType: { required },
      draft: { required },
      dwt: { required },
      eta: { required },
      etb: { required }, // new field from UAT 3.
      etd: { required },
      factor: { required },
      grt: { required },
      loa: { required },
      nrt: { required },
      principalParticularId: { required },
      stayTime: { required },
      operation: { required },
      vesselName: { required },
      vesselType: { required },
      voyageNo: { required }
    },
    principalDetails: {
      accountNo: { required },
      address: { required },
      carrierCode: { required },
      name: { required }
    }
  },
  computed: {
    mainDisbursmentId() {
      return this.disbursementData?.disbursementAccountId
    },

    checkVesselStatus() {
      if (this.vesselDetails) {
        return !(this.vesselDetails.status === 'EXECUTED' || this.vesselDetails.status === 'CANCELLED')
      } else {
        return false
      }
    },
    getExpectedStayTime() {
      if (this.vesselDetails.etb && this.vesselDetails.etd) {
        const etaCalc = new Date(this.vesselDetails.etb); // TODO: change to "etb" // DONE!
        const etdCalc = new Date(this.vesselDetails.etd);

        const expectedStayTimeMS = Math.abs(etdCalc.getTime() - etaCalc.getTime());
        // convert milliseconds to hrs then round to integer
        const expectedStayTimeHrs = Math.ceil(expectedStayTimeMS / (1000 * 60 * 60));

        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.vesselDetails.stayTime = expectedStayTimeHrs;
        return expectedStayTimeHrs
      } else {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.vesselDetails.stayTime = 0;
        return 0
      }
    },
    ...mapGetters('notifications', [

      'getNewAppPage'

    ]),
    ...mapGetters('vesselParticulars', [

      'getReloadAllRequest',
      'getMyLoader',
      'getSearchParameter',
      'getHasSearchedVariable',
      'getPageNo',
      'getRequestList',
      'getVesselDetails',
      'getSuccessAlertPrint',
      'getErrorAlertPrint',
      'getQueryDate',
      'getSuccess',
      'getError'

    ]),

    ...mapGetters('disbursement', [

      'getSaveDetails',
      'getRequestList2',
      'getPageCount',
      'getMyFirstLoader',
      'getNoDataFound',
      'getSuccessAlert',
      'getErrorMessage',
      'getErrorAlert',
      'getSuccessMessage',
      'getReloadAllRequest',
      'getSearchParameter',
      'getHasSearchedVariable',
      'getPageNo',
      // "getCustomerName",
      'getSaveDetails'
      // "getRequestId",
      // "getBlNumber",

    ]),

    ...mapGetters('vesselActivityReport', [

      'getSOFVesselId',
      'getSOFSaveDetails',
      'getSOFMyFirstLoader',
      'getSOFNoDataFound',
      'getSOFSuccessAlert',
      'getSOFErrorMessage',
      'getSOFErrorAlert',
      'getSOFSuccessMessage',
      'getSOFRequestId',
      'getStatementOfFacts'

    ]),

    ...mapGetters('v2DocumentControl', [

      'getDCSuccessAlert',
      'getDCErrorAlert',
      'getDCMyLoader'

    ]),

    ...mapGetters('principal', [

      'getPrincipalList'

    ]),

    ...mapGetters({

      getSubDisbursementAccountList: 'subdisbursment/getSubDisbursementRequestList',
      getMyLoader: "subdisbursment/getMyLoader",
      getPageNo: "subdisbursment/getPageNo",
      getPageSize: "subdisbursment/getPageSize",
      getTotalElement: "subdisbursment/getTotalElement",
      getTotalPage: "subdisbursment/getTotalPage",
      getPageNumber: "subdisbursment/getPageNumber",
      getIsFirst: "subdisbursment/getIsFirst",
      getIsLast: "subdisbursment/getIsLast",


    })
  },
  methods: {

    ...mapActions('disbursement', [

      'registerDisbursementAccount',
      'getDisbursementAccountByVesselId',

    ]),

    ...mapActions('vesselParticulars', [

      'loadVesselParticularsById',
      'printVesselParticularsAndSOF',
      'closeVesselOperations',
      'cancelVesselNomination',
      'submitDetailsToTesws',
      'updateVesselParticulars'

    ]),

    ...mapActions('vesselActivityReport', [

      'getStatementOfFactsByVesselParticularId',
      'addStatementOfFacts',
      'updateStatementOfFacts'

    ]),

    ...mapActions('v2DocumentControl', [

      'uploadApprovedManifest'

    ]),

    ...mapActions('principal', [

      'loadPrincipalsParticularsPageableOnCreated',
      'LoadListAllPrincipalAccounts'
    ]),

    requestRefresh(){

      this.loadPageableVesselDisbursementAccountOnCreated()

    },

    sortingSubDisbursementAccountsData(data, type) {

      for(let x = 0 ; x < data.length ; x++) {

        const requestObject = {

          ...data[x],

        };

        this.filteredSubDisbursementAccounts.push(requestObject)

      }

    },

    setAllSubDisbursementAccountsGetters() {

      this.myFirstLoader = this.getMyFirstLoader;
      this.noDataFound = this.getNoDataFound;
      this.pageNo = this.getPageNo;
      this.pageSize = this.getPageSize;

      this.totalElements = this.getTotalElement;
      this.totalPages = this.getTotalPage;
      this.pageNumber = this.getPageNumber;
      this.isFirst = this.getIsFirst;
      this.isLast = this.getIsLast;

      this.sortingSubDisbursementAccountsData(this.getSubDisbursementAccountList, 'SUB_PDA')

    },

    // getAllSubDisbursementAccounts() {

    //   this.$store.dispatch('subdisbursment/vesselParticularIding').then(() => {

    //     // this.subDisbursmentData = this.getSubDisbursementRequestList.content

    //     // if (!this.subDisbursmentData.length > 1) {

    //     //   this.noDataFound = true

    //     // } else {

    //     //   this.noDataFound = false

    //     // }

    //     this.getSubDisBursemntAccountsByVessel({}).then(() => {

    //       this.setAllSubDisbursementAccountsGetters()

    //     }).catch(() => {})

    //     this.myLoader = this.getMyLoader

    //   })
    // },

    showFileInput(isManifest) {

      this.isManifestInputSelected = isManifest

    },

    loadParameters() {

      return new Promise((resolve) => {

        this.$store.dispatch('vesselParticulars/pageSizing', this.pageSize);
        this.$store.dispatch('vesselParticulars/pageNumbering', this.pageNo);
        this.$store.dispatch('vesselParticulars/requestid', this.vesselId);
        this.$store.dispatch('disbursement/requestid', this.vesselId);
        this.$store.dispatch('vesselActivityReport/vesselId', this.vesselId);
        // this.$store.dispatch('subdisbursment/requestid', this.vesselId)

        resolve()

      })
    },
    loadSaveDetailsParameters() {
      return new Promise((resolve) => {
        this.$store.dispatch('vesselParticulars/savingDetails', this.saveDetails);
        resolve()
      })
    },

    getAllPrincipalAccounts() {
      this.LoadListAllPrincipalAccounts({}).then(() => {
        this.listOfPrincipals = this.getPrincipalList?.content
      }).catch(() => {
        this.ErrorMessage = 'Sorry, something went wrong'
        // this.ErrorAlert = true
      })
    },
    loadPageableVesselDisbursementAccountOnCreated() {

      this.loadParameters().then(() => {
        this.myFirstLoader = true;
        this.myLoader = true;
        this.loadVesselParticularsById({}).then(() => {
          this.noDataFound = this.getNoDataFound;
          this.myLoader = this.getMyLoader;
          this.myFirstLoader = false;
          this.vesselDetails = JSON.parse(JSON.stringify(this.getVesselDetails))
        }).catch(() => {
          this.myFirstLoader = false;
          this.myLoader = this.getMyLoader;
          this.ErrorMessage = 'Sorry, something went wrong';
          this.ErrorAlert = this.getErrorAlert
        })
      })
    },
    // getVesselDisbursementAccounts() {
    //   this.$store.dispatch('disbursement/requestid', this.vesselId)
    //   AGENCY_APi.getDisbursementAccountByVesselId(this.vesselId).then(response =>{
    //     this.disbursementData = response.data.data
    //     if (response.data.status){
    //       this.hasMainDisbursementAccount = true
    //     this.noDataFound = false
    //     this.myFirstLoader = false
    //     }else{
    //       this.hasMainDisbursementAccount = false
    //     }
    //   }).catch(_ =>{
    //     this.notifyUser('error', 'An error occurred', 'Sorry, something went wrong, please try again later')
    //   })
    // },
    getAssociatedStatementOfFacts() {
      this.$store.dispatch('vesselActivityReport/vesselId', this.vesselId);
      this.getStatementOfFactsByVesselParticularId({}).then(() => {
        this.SOFDetails = this.getStatementOfFacts;
        this.noSOFDataFound = this.getSOFNoDataFound;
        this.SOFMyFirstLoader = this.getSOFMyFirstLoader
      }).catch(() => {
        this.SOFMyFirstLoader = false;
        this.ErrorAlert = this.getErrorAlert;
        if (this.ErrorAlert) {
          this.notifyUser('error', 'An error occurred', 'Sorry, something went wrong, please try again later')
        }
      })
    },
    saveSelectedPrincipal() {
      return new Promise(resolve => {
        this.$v.subDisbursementDetailsForm.$touch();
        if (!this.$v.subDisbursementDetailsForm.$invalid) {

        }
      })
    },
    editPrincipalItems() {
      const tempVessel = this.saveDetails;

      this.loadSaveDetailsParameters().then(() => {
        this.updateVesselParticulars({}).then(() => {
          // this.requests = this.getRequestList;
          // this.pageCount = this.getPageCount;
          this.myFirstLoader = this.getMyFirstLoader;
          this.ErrorAlert = this.getError;
          if (this.ErrorAlert) {
            this.notifyUser('error', 'An Error Occurred', 'Sorry, something went wrong');
            this.ErrorAlert = false
          }

          this.SuccessAlert = this.getSuccess;
          if (this.SuccessAlert) {
            this.notifyUser('success', 'Vessel Edited Successfully!', `Marine vessel ${tempVessel.vesselName} has been edited successfully.`);
            this.SuccessAlert = false
          }
          this.loadPageableVesselDisbursementAccountOnCreated();
          this.myFirstLoader = false
        }).catch(() => {
          this.ErrorAlert = this.getError;
          if (this.ErrorAlert) {
            this.notifyUser('error', 'An Error Occurred', 'Sorry, something went wrong');
            this.ErrorAlert = false
          }
        });
        this.myFirstLoader = this.getMyFirstLoader
      })
    },
    uploadSof(event) {
      this.sofFileData = event.target.files[0];

      if (this.sofFileData !== '') {
        const messageConfirm = {
          'title': 'Uploading Approved Manifest',
          'body': 'Are you sure you want to upload SOF Document <b>' + this.fileData.name + '!'
        };

        this.$dialog.confirm(messageConfirm, {
          loader: true,
          backdropClose: true,
          okText: 'Yes',
          html: true
        }).then((dialog) => {
          this.myLoader = true;
          this.loading=true;
          const formData = new FormData();
          formData.append('file', this.fileData);

          dialog.close()
          // this.myLoader = false
        }).catch((e) => {
          this.myLoader = false;
          this.loading=false;
        })
      }
    },
    uploadFile(event) {
      this.fileData = event.target.files[0];

      if (this.fileData && !this.loading) {

        const messageConfirm = {
          'title': 'Uploading Approved Manifest',
          'body': 'Are you sure you want to upload the manifest <b>' + this.fileData.name + '</b>. This action is <b>irreversible</b>!'
        };

        this.$dialog.confirm(messageConfirm, {
          loader: true,
          backdropClose: true,
          okText: 'Yes',
          html: true
        }).then((dialog) => {
          this.myLoader = true;

          const formData = new FormData();

          formData.append('file', this.fileData);

          setTimeout(() => {
            this.loading = true;
            this.$store.dispatch('v2DocumentControl/manifestIdValue', formData);

            this.uploadApprovedManifest({}).then(() => {
              this.loading = false;
              this.SuccessAlert = this.getDCSuccessAlert;
              this.ErrorAlert = this.getDCErrorAlert;
              if (this.SuccessAlert) {
                this.notifyUser('success', 'Approved Manifest uploaded successfully!', `The Approved Manifest has been uploaded successfully `);
                this.SuccessAlert = false;
                this.loading=false;
                this.loadPageableVesselDisbursementAccountOnCreated();

              }
              if (this.ErrorAlert) {
                this.notifyUser('error', 'An error occurred', 'Sorry, something went wrong, please try again later');
                this.loading=false;
              }

            }).catch(() => {
              this.loading = false;
              this.notifyUser('error', 'An error occurred', 'Sorry, something went wrong, please try again later')

            })
          }, 10000);
          dialog.close()
          // this.myLoader = false
        }).catch((e) => {
          this.loading = false
        })
      }
    },


    cleanValues() {
      this.vesselDetails.stayTime = parseInt(this.vesselDetails.stayTime, 10);
      this.vesselDetails.principalParticularId = parseInt(this.vesselDetails.principalParticularId, 10);

      this.vesselDetails.factor = parseFloat(this.vesselDetails.factor);
      this.vesselDetails.grt	= parseFloat(this.vesselDetails.grt);
      this.vesselDetails.loa	= parseFloat(this.vesselDetails.loa);
      this.vesselDetails.nrt	= parseFloat(this.vesselDetails.nrt);
      this.vesselDetails.beam = parseFloat(this.vesselDetails.beam);
      this.vesselDetails.draft = parseFloat(this.vesselDetails.draft);
      this.vesselDetails.dwt = parseFloat(this.vesselDetails.dwt);
    },
    editSaveFormDetails() {
      this.$v.vesselDetails.$touch();
      this.isTested = true;

      this.$v.vesselDetails.$touch();
      if (!this.$v.vesselDetails.$invalid) {
        this.cleanValues();
        this.saveDetails = this.vesselDetails;

        this.editPrincipalItems();
        // this.vesselDetails = {}
        this.saveDetails = {};
        this.closeModal();
      }
    },
    registerAccount() {

      this.$store.dispatch('disbursement/savingDetails', this.accountRegistrationDetails);
      this.registerDisbursementAccount({}).then(() => {
        this.saveDetails = {};
        this.SuccessAlert = this.getSuccessAlert;
        this.ErrorAlert = this.getErrorAlert;
        this.SuccessMessage = 'Vessel Disbursement Account saved successfully';
        this.ErrorMessage = 'Sorry, something went wrong';
        if (this.SuccessAlert) {
          this.notifyUser('success', 'Vessel Disbursement Account saved successfully!', `Vessel Disbursement Account for ${this.vesselDetails.vesselName} saved successfully`);
          this.SuccessAlert = false
        }
        if (this.ErrorAlert) {
          this.notifyUser('error', 'An error occurred', `Sorry, something went wrong, the disbursement account for ${this.vesselDetails.vesselName} please try again later`);
          this.ErrorAlert = false
        }
        // this.getVesselDisbursementAccounts()
        this.closeModal()
      }).catch(() => {

        this.ErrorAlert = this.getErrorAlert;
        if (this.ErrorAlert) {
          this.notifyUser('error', 'An error occurred', 'Sorry, something went wrong, please try again later')
        }
      })

    },
    editItemsDetails(details) {
      this.vesselDetails = { ...details }; // Spread Syntax

      this.$v.vesselDetails.$touch();

      this.openModal('EDIT')
    },
    viewFormDetails(details) {
      this.vesselDetails = details;

      this.openModal('VIEW')
    },
    saveSOFDetails() {
      this.$store.dispatch('vesselActivityReport/savingDetails', this.saveDetails);
      this.addStatementOfFacts({}).then(() => {
        this.SuccessAlert = this.getSOFSuccessAlert;
        this.ErrorAlert = this.getSOFErrorAlert;
        if (this.SuccessAlert) {
          this.notifyUser('success', 'SOF Added successfully!', 'Statement of Facts Entry added successfully');
          this.SuccessAlert = false
        }
        if (this.ErrorAlert) {
          this.notifyUser('error', 'SOF Not Added', 'Sorry, something went wrong, Statement of Facts was not added');
          this.ErrorAlert = false
        }
        this.myLoader = this.getSOFMyFirstLoader;
        this.SOFMyFirstLoader = this.getSOFMyFirstLoader;
        this.getAssociatedStatementOfFacts()
      }).catch(() => {
        this.ErrorAlert = this.getErrorAlert;
        if (this.ErrorAlert) {
          this.notifyUser('error', 'An error occurred', 'Sorry, something went wrong, please try again later')
        }
        this.SOFMyFirstLoader = this.getSOFMyFirstLoader;
        this.myLoader = this.getSOFMyFirstLoader
      })
    },
    editSOFDetails() {
      this.$store.dispatch('vesselActivityReport/savingDetails', this.saveDetails);
      this.updateStatementOfFacts({}).then(() => {
        this.SuccessAlert = this.getSOFSuccessAlert;
        this.ErrorAlert = this.getSOFErrorAlert;
        if (this.SuccessAlert) {
          this.notifyUser('success', 'SOF Edited successfully!', 'Statement of Facts Entry edited successfully');
          this.SuccessAlert = false
        }
        if (this.ErrorAlert) {
          this.notifyUser('error', 'SOF Not Edited', 'Sorry, something went wrong, Statement of Facts was not edited');
          this.ErrorAlert = false
        }
        this.myLoader = this.getSOFMyFirstLoader;
        this.getAssociatedStatementOfFacts()
      }).catch(() => {
        this.ErrorAlert = this.getErrorAlert;
        if (this.ErrorAlert) {
          this.notifyUser('error', 'An error occurred', 'Sorry, something went wrong, please try again later')
        }
        this.SOFMyFirstLoader = this.getSOFMyFirstLoader;
        this.myLoader = this.getSOFMyFirstLoader
      })
    },
    openModal(modalType) {

      // if (modalType === 'ADD') {

      //   this.modalTitle = 'REGISTER DISBURSEMENT ACCOUNT'
      //   this.EditModalDiv = false
      //   this.hasSaveAction = true

      //   this.AddModalDiv = true
      //   this.showModal = true
      //   this.accountRegistrationDetails.vesselParticularId = this.vesselId

      // }
      // if (modalType === 'ADD_SUB') {

      //   this.showAddSubDisbursement = true
      //   this.modalTitle = 'CREATE SUB DISBURSMENT ACCOUNT'

      // }
      if (modalType === 'EDIT') {

        this.modalTitle = 'VIEW ACCOUNT DETAILS';
        this.EditModalDiv = true;
        this.hasSaveAction = true;

        this.AddModalDiv = false;
        this.showModal = true

      }
      if (modalType === 'VIEW') {
        this.modalTitle = 'VIEW ACCOUNT DETAILS';
        this.EditModalDiv = false;

        this.hasSaveAction = false;
        this.ViewModalDiv = true;
        this.AddModalDiv = false;
        this.showModal = true
      }
    },
    closeModal() {
      this.showAddSubDisbursement = false;
      this.showModal = false;
      this.showModalSof = false;
      this.EditModalDiv = false;
      this.AddModalDiv = false;
      this.UploadModalDiv = false;
      this.hasSaveAction = false;
      this.vesselSOF = {
        date: '',
        activityEndTime: '',
        remarks: '',
        shiftId: '',
        activityStartTime: '',
        vesselParticularId: ''
      };
      this.showSOF = false;
      this.isEditSOF = false;
      this.isViewSOF = false;
      this.showStart = true;
      this.viewAllDetails = false;
      this.hasSaveAction = false;
      this.isTested = false
    },
    closeVessel() {
      const messageConfirm = {
        'title': 'Closing Vessel with voyage no. <em>' + this.vesselDetails.voyageNo + '</em>',
        'body': 'Please ensure all details pertaining to this vessel are correct'
      };
      const messageError = {
        'title': 'Closing Vessel with voyage no. <em>' + this.vesselDetails.voyageNo + '</em>',
        'body': 'You are unable to close this vessel since it contains incomplete details'
      };
      if (!this.vesselDetails.ata || !this.vesselDetails.atb || !this.vesselDetails.atc ||
        !this.vesselDetails.atd || !this.vesselDetails.pilotIn) {
        this.$dialog.alert(messageError, {
          okText: 'Okay',
          html: true
        })
      } else {
        this.$dialog.confirm(messageConfirm, {
          loader: true,
          backdropClose: true,
          okText: 'Close All Vessel Operations ',
          html: true
        }).then((dialog) => {
          this.$store.dispatch('vesselParticulars/requestid', this.vesselId);
          this.myLoader = true;
          this.closeVesselOperations({}).then(() => {
            this.myLoader = this.getMyLoader;
            this.vesselDetails = this.getVesselDetails;
            this.SuccessAlert = this.getSuccess;
            this.ErrorAlert = this.getError;

            if (this.SuccessAlert) {
              this.notifyUser('success', 'All Vessel Operations Closed successfully!', `All Vessel Operations for ${this.vesselDetails.vesselName} have been closed successfully`);
              this.SuccessAlert = false
            }
            if (this.ErrorAlert) {
              this.notifyUser('error', 'An Error Occurred', 'Sorry, something went wrong');
              this.ErrorAlert = false
            }
          }).catch((e) => {
            this.myLoader = false;
            this.ErrorAlert = this.getError;
            this.ErrorMessage = 'Sorry, something went wrong'
          });
          dialog.close()
        }).catch((e) => {
        })
      }
    },

    cancelNomination() {
      const cancelMessage = {
        'title': 'Canceling Vessel Nomination for Vessel <em>' + this.vesselDetails.voyageNo + '</em>',
        'body': 'Are you sure you want to cancel this vessel\'s nomination? This Action <b> CAN NOT</b> be reversed!'
      };
      this.$dialog.confirm(cancelMessage, {
        loader: true,
        okText: 'Yes',
        html: true
      }).then((dialog) => {
        this.$store.dispatch('vesselParticulars/requestid', this.vesselId);
        this.myLoader = true;
        this.cancelVesselNomination({}).then(() => {
          this.myLoader = this.getMyLoader;
          this.vesselDetails = this.getVesselDetails;
          this.SuccessAlert = this.getSuccess;
          this.ErrorAlert = this.getError;
          if (this.SuccessAlert) {
            this.notifyUser('success', 'Vessel Cancelled successfully!', 'The Vessel has been Cancelled successfully');
            this.SuccessAlert = false
          }
          if (this.ErrorAlert) {
            this.notifyUser('error', 'An Error Occurred', 'Sorry, something went wrong');
            this.ErrorAlert = false
          }
        }).catch(() => {
          this.myLoader = false;
          this.ErrorAlert = this.getError;
          if (this.ErrorAlert) {
            this.notifyUser('error', 'An Error Occurred', 'Sorry, something went wrong');
            this.ErrorAlert = false
          }
        });
        dialog.close()
      })
    },
    submitTesws: function() {
      const submitMessage = {
        'title': 'Submitting Vessel details for Vessel <em>' + this.vesselDetails.voyageNo + ' </em> to TESWS',
        'body': 'Are you sure you want to submit this vessel\'s details to TESWS?'
      };
      this.$dialog.confirm(submitMessage, {
        loader: true,
        okText: 'Yes',
        html: true
      }).then((dialog) => {
        this.$store.dispatch('vesselParticulars/requestid', this.vesselId);
        this.myLoader = true;
        this.submitDetailsToTesws({}).then(() => {
          this.myLoader = this.getMyLoader;
          // this.vesselDetails = this.getVesselDetails;
          this.SuccessAlert = this.getSuccess;
          this.ErrorAlert = this.getError;
          if (this.SuccessAlert) {
            this.notifyUser('success', 'Details Submitted successfully!', 'The Vessel\'s details have successfully submitted to TESWS');
            this.SuccessAlert = false
          }
          if (this.ErrorAlert) {
            this.notifyUser('error', 'An Error Occurred', 'Sorry, something went wrong');
            this.ErrorAlert = false
          }
        }).catch(() => {
          this.myLoader = false;
          this.ErrorAlert = this.getError;
          if (this.ErrorAlert) {
            this.notifyUser('error', 'An Error Occurred', 'Sorry, something went wrong');
            this.ErrorAlert = false
          }
        });
        dialog.close()
      })
    },
    printSOF() {
      const noSOFDataFoundMessage = {
        'title': 'Printing SOF for voyage no. <em>' + this.vesselDetails.voyageNo + '</em>',
        'body': 'No Statement of Facts entries available for this vessel'
      };
      const messageConfirm = {
        'title': 'Printing SOF for voyage no. <em>' + this.vesselDetails.voyageNo + '</em>',
        'body': 'Please ensure all details pertaining to this vessel are correct'
      };
      if (this.noSOFDataFound) {
        return this.$dialog.alert(noSOFDataFoundMessage, {
          html: true
        })
      } else {
        this.$dialog.confirm(messageConfirm, {
          loader: true,
          okText: 'Okay',
          html: true
        }).then((dialog) => {
          this.$store.dispatch('vesselParticulars/requestid', this.vesselId);
          this.$store.dispatch('vesselParticulars/queryingDate', String(this.vesselDetails.voyageNo + '_' + this.vesselDetails.vesselName));
          this.myLoader = true;
          this.printVesselParticularsAndSOF({}).then(() => {
            this.ErrorAlert = this.getErrorAlertPrint;
            this.SuccessMessage = 'Statement of Facts generated successfully';
            if (this.SuccessAlert) {
              this.notifyUser('success', 'Statement of Facts generated successfully!', `Statement of Facts for vessel ${this.vesselDetails.vesselName} has been generated successfully`);
              this.SuccessAlert = false
            }
            if (this.ErrorAlert) {
              this.notifyUser('error', 'An Error Occurred', 'Sorry, something went wrong');
              this.ErrorAlert = false
            }
            this.myLoader = this.getMyLoader
          }).catch(() => {
            this.myLoader = this.getMyFirstLoader;
            this.ErrorAlert = this.getErrorAlertPrint;
            this.ErrorMessage = 'Sorry, something went wrong'
          });
          dialog.close()
        }).catch(() => {
          this.myLoader = this.getMyFirstLoader;
          this.ErrorMessage = 'Sorry, something went wrong';
          this.ErrorAlert = this.getErrorAlertPrint
        })
      }
    },
    saveFormDetails() {
      // using create new --
      this.$v.accountRegistrationDetails.$touch();
      if (this.$v.accountRegistrationDetails.$invalid) {
        return this.hasSubmissionError
      } else {
        this.accountRegistrationDetails.vesselParticularId = parseInt(this.accountRegistrationDetails.vesselParticularId);

        this.saveDetails = this.accountRegistrationDetails;
        this.registerAccount();

        this.accountRegistrationDetails = {};
        this.saveDetails = {};
        this.closeModal()
      }
    },
    addSOFData() {
      this.isTested = true;
      this.vesselSOF.shiftId = 1;
      this.vesselSOF.vesselParticularId = parseInt(this.vesselId);
      this.$v.vesselSOF.$touch();
      if (!this.$v.vesselSOF.$invalid) {
        this.saveDetails = this.vesselSOF;
        this.saveSOFDetails();
        this.closeModal()
      }
    },
    updateSOFData() {
      this.isTested = true;
      this.vesselSOF.shiftId = 1;
      this.vesselSOF.vesselParticularId = parseInt(this.vesselId);
      this.$v.vesselSOF.$touch();
      if (!this.$v.vesselSOF.$invalid) {
        this.saveDetails = this.vesselSOF;
        this.editSOFDetails();
        this.closeModal()
      }
    },
    goToEditSOF(data) {
      this.vesselSOF = JSON.parse(JSON.stringify(data));
      this.showSOF = true;
      this.isEditSOF = true;
      this.isViewSOF = false
    },
    goToViewSOF(data) {
      this.vesselSOF = data;
      this.showSOF = true;
      this.isEditSOF = false;
      this.isViewSOF = true
    },

    fadeMe: function() {
      if (this.SuccessAlert) {
        this.SuccessAlert = !this.SuccessAlert
      } else {
        this.ErrorAlert = !this.ErrorAlert
      }
    },

    goToApplication(details) {
      let id = '';
      if (typeof (details) === 'number'){
        localStorage.setItem('disbursementType', 'main');
        id = details
      }else if (typeof (details) === "object"){
        localStorage.setItem('disbursementType', 'sub');
        id = details.subDisbursementAccountId
      }
      document.cookie = 'browser-local-cashed-url-back=' + this.$route.fullPath + ';Path = /;SameSite = Strict';
      this.$router.push('/shipping-agency/sub-disbursement-accounts/account-details/' + id)
    },

    viewMainDisbursement(details){
      console.log(details)
    },
    goToManifest() {
      document.cookie = 'browser-local-cashed-url-back=' + this.$route.fullPath + ';Path = /;SameSite = Strict';
      this.$router.push('/shipping-agency/manifest/voyageDetails/' + this.vesselDetails.voyageNo)
    },
    addSOF() {
      this.vesselSOF = {};
      this.showSOF = true;
      this.isViewSOF = false;
      this.isEditSOF = false
    },
    enter: function(el, done) {
      var that = this;

      setTimeout(function() {
        this.SuccessAlert = false;
        this.ErrorAlert = false;
        that.SuccessAlert = false;
        that.ErrorAlert = false
      }, 2000) // hide the message after 2 seconds
    },
    goBack() {
      history.back()
    },

    viewVesselDetails() {

      this.hasSaveAction = false;
      this.showStart = true;
      this.viewAllDetails = true

    },

    checkStatus(status) {
      if (status === 'Created') {
        return 'text-primary'
      }
      if (status === 'Active') {
        return 'text-success'
      }
      if (status === 'Executed') {
        return 'text-warning'
      }
      if (status === 'Cancelled') {
        return 'text-danger'
      }
      if (status === 'Other') {
        return 'text-default'
      }
    }
  }
}
</script>

<style scoped>

  ul.nav-justified {
        list-style-type: none;
        margin: 0;
        padding: 0;
        overflow: hidden;
        background-color: #ededee;
        border-radius: 3px;
    }

    ul.nav-justified li {
        float: left;
    }

    ul.nav-justified li a:hover {
        color: #d59a18;
    }

    .text-link {
        color: blue;
    }

    .text-link:hover {
        color: #d59a18;
        cursor: pointer;
    }

    ul.nav-justified li a {
        display: block;
        text-align: center;
        padding: 16px 20px 10px 21px;
        text-decoration: none;
        color: blue;
        font-weight: bold;
        text-transform: uppercase;
    }

  .is-active-main-details, .is-active-main-details :hover {
    color: #ffffff !important;
    padding: 0 0 2px 0;
    background-color: #3498db !important;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
  }

  .is-active-additional-details, .is-active-additional-details :hover {
    color: #ffffff !important;
    padding: 0 0 2px 0;
    background-color: green !important;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
  }
  .modal-mask {
    position: fixed;
    z-index: 4910;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .8);
    display: table;
    transition: opacity .3s ease;
    overflow-y: auto;
  }
  .medium-modal-dialog {
    /*width: calc(80vw);*/
    /*min-width: calc(60vw);*/
    max-width: calc(90vw) !important;
  }
.legend {
  position: relative;
  border: 1px solid #DCDCDC;
  padding: 1.5rem 1rem 1rem 1rem;
  margin-bottom: 1rem;
  border-radius: 3px;
  margin-top: 1rem;
}

.legend-title {
  background-color: inherit;
  position: absolute;
  top: -0.6rem;
  padding: 0.2rem 1rem;
  background-color: white;
  color: #d59a18;
}

.btn-space {
  margin-right: 10px !important;
}

.error{
  color:#dc3545;
  margin-left: 2px;
  margin-top: 2px;
}
.loader-div {
  line-height: 40px;
}

.loader-div-gif {
  line-height: 50px;
}
.div-table-row {
  padding: 0 15px 0 15px;
}

.div-table {
  padding: 9px 8px 9px 8px;
}

.div-table-title {
  width: 250px;
  background-color: rgba(153, 186, 221, .5);
  font-weight: normal;
}

.div-table-value {
  border: 1px solid #DCDCDC;
  font-weight: 900;
}

label {
  position: relative;
  top: -7px;
  right: 0;
  margin: 0;
  padding: 0;
}

.special-bottom {
  margin-bottom: -1px;
}
  input.hidden-file {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    padding: 0;
    cursor: pointer;
    z-index: -1;
    opacity: 0;
    filter: alpha(opacity=0);
  }
  input.hidden-file:focus + label {
    outline: 2px solid;  /* example focus style */
  }
  .custom-label{
    text-transform: none;
    top: 0px;
    font-size: 1em;
    color: #888888;
    cursor: pointer;
  }
  .radio-labels {
    top: -1px !important;
    padding-left: 10px !important;
  }

  .custom-btn {
      margin-top: 5px !important;
      margin-bottom: 5px !important;
      padding: 5px 9px 5px 9px !important;
      font-size: 14px !important;
  }

  .btn-danger-custom:hover {
      border-color: #ff0000;
      color: #ff0000;
      background-color: #ffffff;
  }

  .btn-danger-custom {
      border-color: rgba(255, 0, 0, .5);
      color: rgba(255, 0, 0, .5);
  }

  .btn-danger-custom-fill {
      background-color: rgba(255, 0, 0, .5);
      opacity: 1;
      color: #FFFFFF;
  }

  .btn-success-custom:hover {
      border-color: #008000;
      color: #008000;
      background-color: #ffffff;
  }

  .btn-success-custom {
      border-color: rgba(0, 128, 0, .5);
      color: rgba(0, 128, 0, .5);
  }

  .btn-success-custom-fill {
      background-color: rgba(0, 128, 0, .8);
      opacity: 1;
      color: #FFFFFF;
  }

  .btn-linen:hover {
      border-color: #D2691E;
      color: #D2691E;
      background-color: #ffffff;
  }

  .btn-linen {
      border-color: rgba(210, 105, 30, .5);
      color: rgba(210, 105, 30, .5);
  }

  .btn-linen-fill {
      background-color: rgb(210, 105, 30);
      opacity: 1;
      color: #FFFFFF;
  }

  .btn-purple:hover {
      border-color: #800080;
      color: #800080;
      background-color: #ffffff;
  }

  .btn-purple {
      border-color: #9932cc;
      color: #9932cc;
  }

  .btn-purple-fill {
      background-color: #9932cc;
      opacity: 1;
      color: #FFFFFF;
  }

  .btn-yellow:hover {
      border-color: #8b4513;
      color: #8b4513;
      background-color: #ffffff;
  }

  .btn-yellow {
      border-color: #a0522d;
      color: #a0522d;
  }

  .btn-yellow-fill {
      background-color: #a0522d;
      opacity: 1;
      color: #FFFFFF;
  }

  .fileUpload {
      position: relative;
      overflow: hidden;
      margin: 0.5em 5px;
      font-size: 1em;
      font-weight: 700;
      letter-spacing: 0.02em;
      line-height: 1.26;
      text-decoration: none;
      top: 16px;
      font-weight: normal;
  }

  .fileUpload input.upload {
      position: absolute;
      top: 0;
      right: 0;
      margin: 0;
      padding: 0;
      cursor: pointer;
      opacity: 0;
      filter: alpha(opacity=0);
  }

  .fileUpload span {
      cursor: pointer;
  }
</style>
