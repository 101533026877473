<template>
  <div>
    <card style="font-size: 12px">
      <div class="row">
        <div class="col-md-12">
          <ul class="nav-justified">
            <li v-if="testDetails.status === 'CREATED'">
              <button
                class="btn btn-purple btn-purple-fill mr-2 custom-btn"
                @click.prevent="openModal('ADD-DO')">
                Update Details
              </button>
            </li>
            <li v-if="testDetails.status === 'CREATED'">
              <button
              class="btn btn-linen btn-linen-fill mr-2 custom-btn"
              @click.prevent="openModal('ADD-DO-CHARGE')">
              Add Delivery Order Charge
            </button>
            </li>
            <li v-if="testDetails.status === 'CHECKED'">
              <button
              class="btn btn-rosy-brown btn-rosy-brown-fill mr-2 custom-btn"
              @click.prevent="approveDo">
              Approve Delivery Order
            </button>
            </li>
            <li v-if="showCancelDoForm === false && testDetails.cancelled === false">
              <button
                class="btn btn-danger-custom btn-danger-custom-fill mr-2 custom-btn"
                @click="showCancelDoForm = true"
              >
                Cancel Delivery Order
              </button>
            </li>
            <li v-if="testDetails.status === 'CREATED'">
              <button
                class="btn btn-khaki btn-khaki-fill mr-2 custom-btn"
                @click.prevent="sendForApproval"
              >
                Submit for Approval
              </button>
            </li>
            <li>
              <button
              @click="downloadPdf(deliveryOrderId)"
              class="btn btn-blue btn-blue-fill mr-2 custom-btn">
              Print Delivery Order
            </button>
            </li>
          </ul>
        </div>
      </div>
    </card>
    <card>
      <div>
        <!-- <div class="row">
          <div class="col-md-12">
            <button
              class="btn btn-success mr-2 custom-btn"
              style="float:right; width: 160px; margin-inline-start: 10px"
              @click.prevent="openModal('ADD-DO')"
              v-if="testDetails.status === 'CREATED'"
            >
              <b> Update Details </b>
            </button>
            <button
              class="btn btn-primary mr-2 custom-btn"
              style="float:right; width: 220px; margin-inline-start: 10px"
              @click.prevent="openModal('ADD-DO-CHARGE')"
              v-if="testDetails.status === 'CREATED'"
            >
              <b> Add Delivery Order Charge </b>
            </button>
            <button
              class="btn btn-secondary mr-2 custom-btn"
              style="width: 210px; margin-inline-start: 10px"
              v-if="testDetails.status === 'CHECKED'"
              @click.prevent="approveDo"
            >
              <b> Approve for DO </b>
            </button>
            <button
              v-if="showCancelDoForm===false && testDetails.cancelled===false"
              class="btn btn-danger mr-2 custom-btn"
              style="width: 210px; margin-inline-start: 10px"
              @click="showCancelDoForm=true"
            >
              <b> Cancel DO</b>
            </button>
            <button
              class="btn btn-secondary mr-2 custom-btn"
              style="width: 210px; margin-inline-start: 10px"
              v-if="testDetails.status === 'CREATED'"
              @click.prevent="sendForApproval"
            >
              <b> Submit for Approval </b>
            </button>
            <button
              @click="downloadPdf(deliveryOrderId)"
              class="btn btn-olive mr-2 custom-btn"
            >
              <font-awesome-icon icon="print" /> &nbsp; Print
            </button>
          </div>
        </div> -->
        <div class="row">
          <div class="col-md-12 text-center">
            <pulse-loader
              :loading="caneclLoader"
              :color="color"
              :size="size"
            />

          </div>
        </div>
        <div class="row" v-if="showCancelDoForm">
          <div class="col-md-12 mt-2 mb-2">
            <div class="form-group">
              <label for="cancel"><strong>Cancel Reason</strong></label>
              <textarea class="form-control" id="cancel" rows="3" placeholder="Cancel Reason" v-model="cancelReason"></textarea>
            </div>
            <button class="btn btn-danger btn-fill btn-block" @click="cancelDo">Cancel</button>
            <button class="btn btn-info btn-fill btn-block" @click="showCancelDoForm=false">Close</button>
          </div>
        </div>

        <div class="legend">
          <h6 class="legend-title">
            Delivery Order Details
          </h6>
          <div class="row div-table-row">
            <div class="col-md-3 div-table div-table-title mb-2" v-if="testDetails.cancelled">
              Cancellation Reason
            </div>
            <div class="col-md-9 div-table div-table-value mb-2" :class="testDetails.cancelled? 'red':'' " v-if="testDetails.cancelled">
              {{ testDetails.cancelReason }}
            </div>
          </div>
          <br />
          <div class="row div-table-row">
            <div class="col-md-3 div-table div-table-title">
              Delivery Order Number
            </div>
            <div class="col-md-3 div-table div-table-value">
              {{ testDetails.doNumber }}
            </div>
            <div class="col-md-3 div-table div-table-title">
              Bill of Lading Number
            </div>
            <div class="col-md-3 div-table div-table-value">
              {{ testDetails.blNo }}
            </div>
          </div>
          <br />
          <div class="row div-table-row">
            <div class="col-md-3 div-table div-table-title">
              Delivery Order Date.
            </div>
            <div class="col-md-3 div-table div-table-value">
              {{ testDetails.doDate ? dateTimeToHuman(testDetails.doDate) : '' }}
            </div>

            <div class="col-md-3 div-table div-table-title">
              Delivery Order Expiry Date
            </div>
            <div class="col-md-3 div-table div-table-value">
              {{ testDetails.expireDate ? dateTimeToHuman(testDetails.expireDate) : '' }}
            </div>
          </div>
          <br />
          <div class="row div-table-row">
              <div class="col-md-3 div-table div-table-title">
                Delivery Order Destination
              </div>
              <div class="col-md-3 div-table div-table-value">
                {{ testDetails.deliveryPoint }}
              </div>
              <div class="col-md-3 div-table div-table-title">
                Delivery Order Status
              </div>
              <div class="col-md-3 div-table div-table-value" :class="getColoredStatus(testDetails.status)">
                {{ testDetails.status }}

              </div>
          </div>
          <br>
          <div class="row div-table-row">
            <div class="col-md-3 div-table div-table-title">
              Delivery Agent
            </div>
            <div class="col-md-9 div-table div-table-value">
              {{  testDetails.agent ? testDetails.agent.agentName + ", " + testDetails.agent.agentEmail + ", " + testDetails.agent.agentPhone  : ''}}
            </div>
          </div>
        </div>


        <div class="legend" v-if="manifestDetails !== null">


          <h6 class="legend-title">
            Vessel Details
          </h6>
          <br>

          <div class="row div-table-row">
            <div class="col-md-3 div-table div-table-title">
              Carrier Name
            </div>
            <div class="col-md-3 div-table div-table-value">
              {{ manifestDetails.carrierName }}
            </div>
            <div class="col-md-3 div-table div-table-title">
              Ship Name
            </div>
            <div class="col-md-3 div-table div-table-value">
              {{ manifestDetails.shipName }}
            </div>
          </div>
          <br />
          <div class="row div-table-row">
            <div class="col-md-3 div-table div-table-title">
              Master Name
            </div>
            <div class="col-md-3 div-table div-table-value">
              {{ manifestDetails.masterName }}
            </div>

            <div class="col-md-3 div-table div-table-title">
              Control Ref
            </div>
            <div class="col-md-3 div-table div-table-value">
              {{ manifestDetails.controlRef }}
            </div>
          </div>
          <br>
          <div class="row div-table-row">
            <div class="col-md-3 div-table div-table-title">
              Flag State of Ship
            </div>
            <div class="col-md-3 div-table div-table-value">
              {{ manifestDetails.flagStateOfShip }}
            </div>
            <div class="col-md-3 div-table div-table-title">
              IMO No.
            </div>
            <div class="col-md-3 div-table div-table-value">
              {{ manifestDetails.imoNumber }}
            </div>
          </div>
          <br />
          <div class="row div-table-row">
            <div class="col-md-3 div-table div-table-title">
              Voyage No
            </div>
            <div class="col-md-3 div-table div-table-value">
              {{ manifestDetails.voyageNumber }}
            </div>
            <div class="col-md-3 div-table div-table-title">
              TASAC Control No.
            </div>
            <div class="col-md-3 div-table div-table-value">
              {{ manifestDetails.tasacControlNumber }}
            </div>
          </div>
          <br>
          <div class="row div-table-row">
            <div class="col-md-3 div-table div-table-title">
              Sender
            </div>
            <div class="col-md-3 div-table div-table-value">
              {{ manifestDetails.sender }}
            </div>

            <div class="col-md-3 div-table div-table-title">
              Receiver
            </div>
            <div class="col-md-3 div-table div-table-value">
              {{ manifestDetails.receiver }}
            </div>
          </div>
          <br />
          <div class="row div-table-row">
            <div class="col-md-3 div-table div-table-title">
              Arrival Time {{ manifestDetails.arrivalTime }}
            </div>
            <div class="col-md-3 div-table div-table-value">
              {{ manifestDetails.arrivalTime  ?  dateTimeToHuman(manifestDetails.arrivalTime) : ''  }}
            </div>
            <div class="col-md-3 div-table div-table-title">
              Departure Time
            </div>
            <div class="col-md-3 div-table div-table-value">
              {{ manifestDetails.departureTime ? dateTimeToHuman(manifestDetails.departureTime) : ''  }}
            </div>
          </div>
          <br />
          <div class="row div-table-row">
            <div class="col-md-3 div-table div-table-title">
              Call Sign
            </div>
            <div class="col-md-3 div-table div-table-value">
              {{ manifestDetails.callSign }}
            </div>
            <div class="col-md-3 div-table div-table-title">
              Number of Containers
            </div>
            <div class="col-md-3 div-table div-table-value">
              {{ manifestDetails.noOfContainer }}
            </div>
          </div>
          <br>
          <div class="row div-table-row">
            <div class="col-md-3 div-table div-table-title">
              Bank Reference
            </div>
            <div class="col-md-3 div-table div-table-value">
              {{ manifestDetails.bankReference }}
            </div>
          </div>
          <br>
        </div>

<!--        <div class="legend">-->
<!--          <h6 class="legend-title">-->
<!--            Delivery Order Charges-->
<!--          </h6>-->

<!--          <div>-->
<!--            <data-table-->
<!--              :table-headers="doChargesHeader"-->
<!--              :table-data="addedDeliveryOrderCharges"-->
<!--              :table-data-keys="doChargesKeys"-->

<!--              :my-first-loader="myFirstLoader"-->
<!--              :no-data-found="noDataFound2"-->
<!--              :has-manage-button="false"-->
<!--              :is-pageable="false"-->
<!--              :has-view-button="false"-->
<!--              :has-edit-button="false"-->
<!--              @goToEdit="goToEditDOCharges"-->
<!--              :has-search-form="false"-->
<!--              :loading-title="'Please wait while system is loading delivery order charge details'"-->
<!--            />-->
<!--          </div>-->
<!--        </div>-->

        <div class="legend">
          <h6 class="legend-title">
            Delivery Order Items
          </h6>

<!--          <div>-->
<!--            <data-table-->
<!--              :table-headers="tableHeaders"-->
<!--              :table-data="manifestBl"-->
<!--              :table-data-keys="tableDataKeys"-->

<!--              :my-first-loader="myFirstLoader"-->
<!--              :page-size-prop="15"-->
<!--              :page-no="pageNo"-->
<!--              :page-number="0"-->

<!--              :total-elements="totalElements"-->
<!--              :total-pages="totalPages"-->
<!--              :is-first="isFirst"-->
<!--              :is-last="isLast"-->
<!--              :no-data-found="noDataFound"-->
<!--              :has-manage-button="false"-->
<!--              :is-pageable="false"-->
<!--              :has-view-button="true"-->
<!--              @goToView="viewDetails"-->
<!--              :has-search-form="true"-->
<!--              :placeholder="'Search by Container No, Seal No or Bill of Lading'"-->
<!--              :reload-all-request="reloadAllRequest"-->
<!--              @searchRequest="searchReference"-->
<!--              @reloadByIcon="reloadByIcon"-->
<!--              :loading-title="'Please wait while system is loading manifest details'"-->
<!--            />-->
<!--          </div>-->
          <consinment-details :manifest-details="manifestDetails"></consinment-details>
        </div>
        <div class="row">
          <div class="col-md-12">
            <button
              class="btn btn-default btn-fill btn-space custom-btn"
              style="float:right;"
              @click.prevent="goBack"
            >
              <font-awesome-icon icon="undo" /> &nbsp; Back
            </button>
          </div>
        </div>
      </div>
    </card>
    <global-modal v-if="myLoader">
      <div slot="globalLoader">
        <div class="row text-center">
          <div class="col-md-12">
            Loading, Please wait
          </div>
          <div class="col-md-12">
            <br>
          </div>
          <div class="col-md-12">
            <pulse-loader
              :loading="loading"
              :color="color"
              :size="size"
            />
          </div>
        </div>
      </div>
    </global-modal>
    <transition
      name="addItemModal col-md-12"
      v-if="showModal"
    >
      <div>
        <div
          class="modal  modal-mask"
          style="display: block"
        >
          <div
            class="modal-dialog medium-modal-dialog"
            role="document"
          >
            <div class="modal-content col-md-12">
              <div class="modal-header col-md-12">
                <div class="modal-title col-md-12">
                  <div class="row">
                    <div
                      class="col-md-12"
                      v-if="ViewModalDiv"
                    >
                      <b> Bill of Lading Details</b>
                    </div>
                    <div
                      class="col-md-12"
                      v-if="AddModalDiv"
                    >
                      <b> Edit Delivery Order</b>
                    </div>
                    <div class="col-md-12">
                      <hr>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="modal-body col-md-12">
                  <!--  VIEW BILL OF LADING DETAILS  -->
                  <card v-if="ViewModalDiv">
                    <div class="row">
                      <div class="col-md-3">
                        <base-input
                          type="text"
                          label="Bill of Lading"
                          placeholder="Bill of Lading"
                          v-model="blDetails.billOfLading"
                          :value="blDetails.billOfLading"
                          class="special-bottom"
                          :readonly="true"
                        />
                      </div>
                      <div class="col-md-2">
                        <base-input
                          type="text"
                          label="Container No."
                          placeholder="Container No."
                          v-model="blDetails.containerNo"
                          :value="blDetails.containerNo"
                          class="special-bottom"
                          :readonly="true"
                        />
                      </div>
                      <div class="col-md-2">
                        <base-input
                          type="text"
                          label="Seal Number"
                          placeholder="Seal Number"
                          v-model="blDetails.sealNumber"
                          :value="blDetails.sealNumber"
                          class="special-bottom"
                          :readonly="true"
                        />
                      </div>
                      <div class="col-md-3">
                        <base-input
                          type="text"
                          label="Document Message No."
                          placeholder="Document Message No."
                          v-model="blDetails.documentMessageNo"
                          :value="blDetails.documentMessageNo"
                          class="special-bottom"
                          :readonly="true"
                        />
                      </div>
                      <div class="col-md-2">
                        <base-input
                          type="text"
                          label="Load Port"
                          placeholder="Load Port"
                          v-model="blDetails.loadPort"
                          :value="blDetails.loadPort"
                          class="special-bottom"
                          :readonly="true"
                        />
                      </div>
                    </div>
                    <!--   row 2   -->
                    <div class="row">
                      <div class="col-md-3">
                        <base-input
                          type="text"
                          label="Package Type"
                          placeholder="Package Type"
                          v-model="blDetails.pktyDetails"
                          :value="blDetails.pktyDetails"
                          class="special-bottom"
                          :readonly="true"
                        />
                      </div>
                      <div class="col-md-3">
                        <base-input
                          type="text"
                          label="Company Code"
                          placeholder="Company Code"
                          v-model="blDetails.companyCode"
                          :value="blDetails.companyCode"
                          class="special-bottom"
                          :readonly="true"
                        />
                      </div>
                      <div class="col-md-2">
                        <base-input
                          type="text"
                          label="Arrival Port"
                          placeholder="Arrival Port"
                          v-model="blDetails.arrivalPort"
                          :value="blDetails.arrivalPort"
                          class="special-bottom"
                          :readonly="true"
                        />
                      </div>
                      <div class="col-md-2">
                        <base-input
                          type="text"
                          label="Discharge Port"
                          placeholder="Discharge Port"
                          v-model="blDetails.dischargePort"
                          :value="blDetails.dischargePort"
                          class="special-bottom"
                          :readonly="true"
                        />
                      </div>

                      <div class="col-md-2">
                        <base-input
                          type="text"
                          label="Consolidation Item No."
                          placeholder="Consolidation Item No."
                          v-model="blDetails.consolidationItemNumber"
                          :value="blDetails.consolidationItemNumber"
                          class="special-bottom"
                          :readonly="true"
                        />
                      </div>
                    </div>
                    <!--   row 3   -->
                    <div class="row">
                      <div class="col-md-2">
                        <base-input
                          type="text"
                          label="Number of Packages"
                          placeholder="Number of Packages"
                          v-model="blDetails.numberOfPackage"
                          :value="blDetails.numberOfPackage"
                          class="special-bottom"
                          :readonly="true"
                        />
                      </div>
                      <div class="col-md-2">
                        <base-input
                          type="text"
                          label="Gross Weight"
                          placeholder="Gross Weight"
                          :value="blDetails.grossWeight + ' ' + blDetails.grossWeightUnit"
                          class="special-bottom"
                          :readonly="true"
                        />
                      </div>
                      <div class="col-md-2">
                        <base-input
                          type="text"
                          label="Tare Weight"
                          placeholder="Tare Weight"
                          :value="blDetails.tareWeight + ' ' + blDetails.tareWeightUnit"
                          class="special-bottom"
                          :readonly="true"
                        />
                      </div>
                      <div class="col-md-2">
                        <base-input
                          type="text"
                          label="Net Weight"
                          placeholder="Net Weight"
                          :value="blDetails.netWeight + ' ' + blDetails.netWeightUnit"
                          class="special-bottom"
                          :readonly="true"
                        />
                      </div>
                      <div class="col-md-2">
                        <base-input
                          type="text"
                          label="Net Volume"
                          placeholder="Net Volume"
                          :value="blDetails.netVolume + ' ' + blDetails.netVolumeUnit"
                          class="special-bottom"
                          :readonly="true"
                        />
                      </div>
                      <div class="col-md-2">
                        <base-input
                          type="text"
                          label="Temperature"
                          placeholder="Temperature"
                          :value="blDetails.temperature"
                          class="special-bottom"
                          :readonly="true"
                        />
                      </div>
                    </div>
                    <!--  row 4     -->
                    <div class="row">
                      <div class="col-md-3">
                        <base-input
                          type="text"
                          label="HS Code"
                          placeholder="HS Code"
                          v-model="blDetails.hsCode"
                          :value="blDetails.hsCode"
                          class="special-bottom"
                          :readonly="true"
                        />
                      </div>
                      <div class="col-md-9">
                        <base-input
                          type="text"
                          label="Marks Numbers"
                          placeholder="Marks Numbers"
                          v-model="blDetails.marksNumbers"
                          :value="blDetails.marksNumbers"
                          class="special-bottom"
                          :readonly="true"
                        />
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <base-input
                          type="text"
                          label="Shipper"
                          placeholder="Shipper"
                          v-model="blDetails.shipper"
                          :value="blDetails.shipper"
                          class="special-bottom"
                          :readonly="true"
                        />
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-12">
                        <base-input
                          type="text"
                          label="Consignee"
                          placeholder="Consignee"
                          v-model="blDetails.consignee"
                          :value="blDetails.consignee"
                          class="special-bottom"
                          :readonly="true"
                        />
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <base-input
                          type="text"
                          label="Description"
                          placeholder="Description"
                          v-model="blDetails.description"
                          :value="blDetails.description"
                          class="special-bottom"
                          :readonly="true"
                        />
                      </div>
                    </div>

                    <br>
                    <div class="text-center row">
                      <div class="col-md-7 text-right loader-div">
                        <!--                      <span v-if="editMyLoader">Please wait ....</span>-->
                      </div>
                      <div class="col-md-2 left loader-div-gif">
                        <!--                      <pulse-loader :loading="loading" :color="color" :size="size" v-if="editMyLoader"></pulse-loader>-->
                      </div>
                      <div class="col-md-3">
                        <button
                          type="button"
                          class="btn btn-fill btn-outline-info float-right"
                          style="margin-inline-start:20px"
                          @click="closeModal()"
                        >
                          Close
                        </button>
                      </div>
                    </div>
                  </card>

                  <!--  EDIT DELIVERY ORDER   -->
                  <card v-if="AddModalDiv">
                    <form @submit.prevent="editDeliveryOrder" />
                    <div class="row">
                      <div class="col-md-6">
                        <form
                          @submit.prevent="searchAgent"
                          v-if="hasSearchAgent"
                        >
                          <div class="row">
                            <div class="col-md-10">
                              <base-input
                                type="text"
                                label="Search CF Agent"
                                placeholder="Agent Name"
                                v-model="searchAgentParam"
                                :value="doDetails.agent.agentName"
                                class="special-bottom"
                              />
                            </div>
                            <div
                              class="col-md-2"
                              title="Search Agent"
                              style="padding-top: 43px"
                            >
                              <button
                                type="button"
                                class="input-group-addon"
                                style="float: left"
                                @click.prevent="searchAgent"
                              >
                                <font-awesome-icon icon="search" />
                              </button>
                            </div>
                          </div>
                        </form>

                        <div v-if="!hasSearchAgent">
                          <div class="row">
                            <div
                              class="col-md-10"
                              style="margin-top: 10px"
                            >
                              <label>Select Agent </label>
                              <model-list-select
                                :list="agentOptions"
                                v-model="agentIdentification"
                                class="form-control special-bottom"
                                option-value="cfAgentId"
                                option-text="agentName"
                                placeholder="Select Agent"
                              />
                              <div v-if="hasAgentError">
                                <div
                                  class="error"
                                  v-if="!$v.deliveryOrderDetails.agentId.required"
                                >
                                  This field is required
                                </div>
                              </div>
                            </div>
                            <div
                              class="col-md-2"
                              style="padding-top: 43px"
                            >
                              <button
                                type="button"
                                title="Reload Search"
                                class="input-group-addon"
                                style="float: left"
                                @click.prevent="reloadSearchAgent"
                              >
                                <font-awesome-icon icon="sync-alt" />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-6">
                        <base-input
                          type="datetime-local"
                          label="Delivery order expiry date"
                          placeholder="Delivery order expiry date"
                          style="padding-top: 6px"
                          v-model="deliveryOrderDetails.expireDate"
                          class="special-bottom"
                        />
                        <div v-if="hasExpireDateError">
                          <div
                            class="error"
                            v-if="!$v.deliveryOrderDetails.expireDate.required"
                          >
                            This field is required
                          </div>
                        </div>
                      </div>
                    </div>

                    <br>
                    <div class="row">
                      <div class="col-md-12">
                        <base-input
                          type="text"
                          label="Delivery Order Destination"
                          placeholder="Delivery Order Destination"
                          v-model="deliveryOrderDetails.deliveryPoint"
                          :value="deliveryOrderDetails.deliveryPoint"
                          class="special-bottom"
                        />
                        <div v-if="hasDeliveryPointError">
                          <div
                            class="error"
                            v-if="!$v.deliveryOrderDetails.deliveryPoint.required"
                          >
                            This field is required
                          </div>
                        </div>
                      </div>
                    </div>
                    <br>
                    <div class="text-center row">
                      <div class="col-md-7 text-right loader-div">
                        <span v-if="editMyLoader">Please wait ....</span>
                      </div>
                      <div class="col-md-2 left loader-div-gif">
                        <pulse-loader
                          :loading="loading"
                          :color="color"
                          :size="size"
                          v-if="editMyLoader"
                        />
                      </div>
                      <div class="col-md-3">
                        <button
                          type="button"
                          class="btn btn-fill btn-outline-info float-right"
                          style="margin-inline-start:20px"
                          @click="closeModal()"
                        >
                          Close
                        </button>
                        <button
                          type="button"
                          class="btn btn-success btn-outline-info float-right"
                          style="margin-inline-start:20px"
                          @click="editDeliveryOrder()"
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </card>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <modal v-if="addChargeModal">
      <div slot="header" class="modal-title col-md-12">
        <div class="row">
          <div class="col-md-12">
            <b v-if="isEdit">Edit Delivery Order Charge</b>
            <b v-if="!isEdit">Add Delivery Order Charge</b>
          </div>
          <div class="col-md-12">
            <hr>
          </div>
        </div>
      </div>

      <div slot="body">
        <card>
          <form method="POST" >
            <div class="row">
              <div class="col-md-12">
                <label> Select Non-Mandatory Delivery Order Charge</label>
                <list-select
                  :list="specificDeliveryOrderCharges"
                  option-value="agencyChargeId"
                  option-text="mandatory"
                  placeholder="Select Associated Non-Mandatory Delivery Order Charge"
                  :custom-text="formatingTariffDetails"
                  :selected-item="doChargeParticular"
                  @select="onSelect"
                >
                </list-select>
                <div v-if="tariffFeeError">
                  <div class="error" v-if="!$v.doChargeParticular.required">
                    This field is required
                  </div>
                </div>
              </div>

            </div>
            <div class="text-center row">
              <div class="col-md-7 text-right loader-div">
                <span v-if="editMyLoader">Please wait ....</span>
              </div>
              <div class="col-md-2 left loader-div-gif">
                <pulse-loader
                  :loading="loading"
                  :color="color"
                  :size="size"
                  v-if="editMyLoader"
                />
              </div>
              <div
                class="col-md-3"
              >
                <button
                  type="button"
                  class="btn btn-fill btn-outline-info float-right pr-2"
                  style="margin-inline-start:20px"
                  @click="closeModal()"
                >
                  Close
                </button>
                <button
                  type="submit"
                  class="btn btn-primary btn-space  float-right"
                  data-dismiss="modal"
                  @click.prevent="addDOCharge"
                  v-if="!isEdit"
                >
                  Save
                </button>
                <button
                  type="submit"
                  class="btn btn-primary btn-space  float-right"
                  data-dismiss="modal"
                  @click.prevent="updateDOCharge"
                  v-if="isEdit"
                >
                  Edit
                </button>

              </div>
            </div>

          </form>
        </card>
      </div>
    </modal>


  </div>
</template>
<script>
/* eslint-disable no-prototype-builtins */

import { mapActions, mapGetters } from 'vuex'
import DataTable from '../../../components/DataTable/updatedTable'
import GlobalModal from '../../../components/globalLoader'
import { ModelSelect, ModelListSelect, ListSelect } from 'vue-search-select'
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import { required } from 'vuelidate/lib/validators'
import CrudNotification from '../../../components/DataTable/crudNotification'
import Modal from '../../../components/Inputs/Modal';
import AGENCYAPI from '../../../api/agency';
import  consinmentDetails from "./consinmentDetails";

export default {
  name: 'DeliveryOrderDetails',
  components: {
    DataTable,
    GlobalModal,
    ModelListSelect,
    PulseLoader,
    ModelSelect,
    ListSelect,
    CrudNotification,
    Modal,
    consinmentDetails
  },
  props: {
    voyageNo: {
      type: String,
      default: ''
    },
    callId: {
      type: String,
      default: ''
    },
    documentId: {
      type: String,
      default: ''
    },
    requestFlag: {
      type: String,
      default: 'ROUTE'
    }

  },
  created () {
    this.$store.dispatch('pagenames/currentPageName', 'Delivery Order Details')

    this.finalUserPermissions = JSON.parse(localStorage.getItem('browser-local-permits'))

    this.deliveryOrderId = this.$route.params.doId
    this.loadDeliveryOrderDetailsOnCreated();
    this.getDeliveryOrderCharges();
  },
  data () {
    return {
      isPageable: true,
      deliveryOrderId: '',

      showCancelDoForm:false,
      deliveryOrderDetails: {
        deliveryPoint: '',
        agentId: '',
        blNo: '',
        data: {},
        data2: {}
      },
      doChargeParticular:{},
      noDataFound2: false,
      isEdit: false,
      tariffFeeError:false,
      tariffItemError:false,
      tariffFees:[],
      tariffItems:[],
      specificDeliveryOrderCharges:[],
      doChargesHeader:['Tariff Fee', 'Tariff Item', 'Billed'],
      doChargesKeys:['tariffFeeName','tariffItemName','isBilled'],
      deliveryOrderChargeDetails:{
        deliveryOderId: '',
        tariffFeeId: '',
        tariffItemId: ''
      },
      addedDeliveryOrderCharges:[],
      agentName: '',
      agentIdentification: '',
      hasAgentError: false,
      hasDeliveryPointError: false,
      hasExpireDateError: false,
      SuccessMessage: '',
      ErrorMessage: '',
      SuccessAlert: false,
      ErrorAlert: false,
      totalPages: 1,
      totalElements: 0,
      isFirst: true,
      isLast: false,
      myFirstLoader: false,
      pageNo: 0,
      noDataFound: false,
      reloadAllRequest: false,
      color: 'green',
      size: '12px',
      loading: true,
      caneclLoader:false,
      cancelReason:"",
      myLoader: false,
      editMyLoader: false,
      showModal: false,
      ViewModalDiv: false,
      AddModalDiv: false,
      searchAgentParam: '',
      modal_title: '',
      addChargeModal: false,
      hasSearchAgent: true,
      agentOptions: [],
      blDetails: {
        agent: '',
        arrivalPort: '',
        billOfLading: '',
        blType: '',
        cancelled: '',
        companyCode: '',
        consignee: '',
        consolidationItemNumber: '',
        containerNo: '',
        createDateTime: '',
        deliveryPort: '',
        description: '',
        destinationPlace: '',
        dimensions: '',
        dischargePort: '',
        documentId: '',
        documentMessageNo: '',
        fobPrice: '',
        fobPriceUnit: '',
        grossVolume: '',
        grossVolumeUnit: '',
        grossWeight: '',
        grossWeightUnit: '',
        hsCode: '',
        insurancePrice: '',
        insurancePriceUnit: '',
        internationalFreightPrice: '',
        internationalFreightPriceUnit: '',
        isCancelled: '',
        isSpecified: '',
        loadPort: '',
        manifestBlId: '',
        marksNumbers: '',
        masterBillOfLading: '',
        measurement: '',
        netVolume: '',
        netVolumeUnit: '',
        netWeight: '',
        netWeightUnit: '',
        notifyName: '',
        notifyName1: '',
        notifyName2: '',
        numberOfPackage: '',
        operationType: '',
        originalLoadPort: '',
        packageType: '',
        sealNumber: '',
        shipper: '',
        specified: '',
        tareWeight: '',
        tareWeightUnit: '',
        temperature: '',
        updateDateTime: ''
      },
      manifestDetails: {},
      doDetails: {},
      manifestBl: [],
      ogManifestBl: [],
      testDetails:{},
      finalUserPermissions: [],
      tableHeaders: ['Bill of Lading', 'Container No.', 'Seal No.', 'Load Port', 'Package Type'],
      tableDataKeys: ['billOfLading', 'containerNo', 'sealNumber', 'loadPort', 'pktyDetails'],

      tariffFormatCompleted:false,

    }
  },
  validations: {
    deliveryOrderDetails: {
      agentId: { required },
      expireDate: { required },
      deliveryPoint: { required }
    },
    deliveryOrderChargeDetails:{
      deliveryOderId: { required },
      tariffFeeId: { required },
      tariffItemId: { required }
    },
    doChargeParticular : {required}
  },
  computed: {
    ...mapGetters('deliveryOrder', [

      'getMyLoader',
      'getHasSearchedVariable',
      'getPageNo',
      'getRequestList',
      'getNoDataFound',
      "getMyLoader",
      'getSuccessAlert',
      'getErrorMessage',
      'getErrorAlert',
      'getSuccessMessage'
    ]),
    ...mapGetters('CFAgent', [
      'getAgentRequestList',
    ]),
    ...mapGetters('tariffDetails',[
      "getTariffFeeDetails",
      "getTariffItemsDetails",
      "getTariffSuccessAlert",
      "getTariffErrorAlert",
      "loadingTariffFeeDetails",
      "loadingTariffItemsDetails"
    ]),
    ...mapGetters('agencyCharge',[
      "getAgencyChargeDetails",
      "getloadingAgencyCharges"
    ])
  },
  methods: {
    ...mapActions('deliveryOrder', [
      'getDeliveryOrder',
      'getDeliveryOderPDF',
      'updateDeliveryOrder',
      'saveDeliveryOrder',
      "updateDeliveryOrderCharge",
      "addDeliveryOrderCharge",
      "getDeliveryOrderCharge",
      "setDeliveryOrderAsCharged"
    ]),
    ...mapActions('CFAgent', [
      'searchCfAgentByAgentName'
    ]),
    ...mapActions('tariffDetails',[
      'getTariffItems',
      'getTariffFees'
    ]),
    ...mapActions('agencyCharge',[
      "getAllAgencyCharges"
    ]),

    formatingTariffDetails (doChargeParticular) {
      return `${doChargeParticular.tariffFee.tariffFeeName} - ${doChargeParticular.tariffItem.tariffItemName} `
    },
    formatDeliveryOrderCharges(data){
      if (data.length > 0){
        for (let i = 0; i < data.length; i++) {
          data[i].tariffFeeName =  data[i].tariffFee.tariffFeeName;
          data[i].tariffItemName = data[i].tariffItem.tariffItemName;
        }
        return data
      }else{
        this.noDataFound2 = true;
        return [];
      }
      // return data;
    },
    checkStatus(status){

      if (status === 'CREATED'){
        return "text-primary"
      }
      if (status === 'Paid'){
        return "text-success"
      }
      if (status === 'Billed'){
        return "text-info"
      }
      if (status === 'Canceled'){
        return "text-danger"
      }
      if (status === 'CHARGED'){
        return "chargedStatus"
      }else{
        return "text-primary"
      }
    },
    onSelect(data){
      this.doChargeParticular = data;
    },
    extractNonMandatoryCharges(data){
      if (data.length > 1){
        data = data.filter(obj => {
          return obj.mandatory !== true;
        })
          this.tariffFormatCompleted = true;
      }
      return data
    },
    loadDeliveryOrderDetailsOnCreated () {
      this.$store.dispatch('deliveryOrder/requestid', this.deliveryOrderId)
      this.myLoader = true
      this.getDeliveryOrder({}).then(() => {
        this.noDataFound = this.getNoDataFound
        this.doDetails = this.getRequestList
        this.testDetails = this.getRequestList
        //this.addedDeliveryOrderCharges = this.formatDeliveryOrderCharges(this.doDetails.deliveryOderCharges);
        this.manifestDetails = this.doDetails?.manifestBlDto
        this.doDetails = this.extractAgentFromObject(JSON.parse(JSON.stringify(this.doDetails)))

        if (this.manifestDetails !== null &&
          this.manifestDetails.manifestBL !== null &&
          this.manifestDetails.manifestBL !== 'undefined') {
          this.ogManifestBl = JSON.parse(JSON.stringify(this.getPackageTypeCodeDetails(this.manifestDetails.manifestBL)))
          //this.ogManifestBl = this.removeCommaForArray(this.ogManifestBl, 'sealNumber')
          this.manifestBl = JSON.parse(JSON.stringify(this.ogManifestBl));

          this.pageNo = 0
          this.pageSize = this.manifestBl.length
          this.totalElements = this.manifestBl.length + 1
        } else {
          this.noDataFound = true
        }
        this.myLoader = false

      }).catch((e) => {
        
        this.noDataFound = this.getNoDataFound
        this.myLoader = false
      })
    },
    getDeliveryOrderCharges(){
      if (this.getAgencyChargeDetails.length < 1){
        this.getAllAgencyCharges({}).then(()=>{
          this.specificDeliveryOrderCharges = this.extractNonMandatoryCharges(this.getAgencyChargeDetails);
        }).catch(()=>{})
      }else {

        this.specificDeliveryOrderCharges = this.extractNonMandatoryCharges(this.getAgencyChargeDetails);
      }
    },
    addDOCharge() {

      if (this.$v.doChargeParticular.$invalid ){
        this.tariffFeeError =true;
      }else{
        let temp = this.doChargeParticular;
        this.deliveryOrderChargeDetails = {
          deliveryOderId: this.deliveryOderId,
          tariffFeeId: '',
          tariffItemId: ''
        };
        this.deliveryOrderChargeDetails.deliveryOderId = parseInt(this.deliveryOrderId);
        this.deliveryOrderChargeDetails.tariffFeeId = parseInt(temp.tariffFee.tariffFeeId);
        this.deliveryOrderChargeDetails.tariffItemId = parseInt(temp.tariffItem.tariffItemId);

        this.$store.dispatch("deliveryOrder/savingDetails", this.deliveryOrderChargeDetails);
        this.addDeliveryOrderCharge({}).then(()=>{
          this.SuccessAlert = this.getSuccessAlert;
          this.ErrorAlert = this.getErrorAlert;
          this.ErrorAlert = this.getError;
          if  (this.SuccessAlert){
            this.notifyUser('success', 'Delivery order charge added successfully!', `Delivery order charge has been added successfully` )
            this.SuccessAlert = false;
          }
          if (this.ErrorAlert){
            this.notifyUser('error', 'An Error Occurred', 'Sorry, something went wrong')
            this.ErrorAlert = false;
          }

          this.loadDeliveryOrderDetailsOnCreated();
          this.closeModal();
        }).catch((e)=>{
          this.ErrorAlert = this.getErrorAlert;
          if (this.ErrorAlert){
            this.notifyUser('error', 'An Error Occurred', 'Sorry, something went wrong')
            this.ErrorAlert = false;
          }
        })
      }
    },
    editDeliveryOrder () {

      this.deliveryOrderDetails.agentId = this.agentIdentification
      if (this.$v.deliveryOrderDetails.expireDate.$invalid || this.$v.deliveryOrderDetails.agentId.$invalid || this.$v.deliveryOrderDetails.deliveryPoint.$invalid) {
        if (this.$v.deliveryOrderDetails.expireDate.$invalid) {
          this.hasExpireDateError = true
        }
        if (this.$v.deliveryOrderDetails.deliveryPoint.$invalid) {
          this.hasDeliveryPointError = true
        }
        if (this.$v.deliveryOrderDetails.agentId.$invalid) {
          this.hasAgentError = true
        }
      } else {
        delete (this.deliveryOrderDetails.manifestBLDto)
        delete (this.deliveryOrderDetails.agent)
        delete  (this.deliveryOrderDetails.deliveryOderCharges)
        this.$store.dispatch('deliveryOrder/savingDetails', this.deliveryOrderDetails)
        this.updateDeliveryOrder({}).then(() => {
          this.editMyLoader = this.getMyLoader;
          this.SuccessAlert = this.getSuccessAlert;
          this.ErrorAlert = this.getErrorAlert;

          if  (this.SuccessAlert){
            this.notifyUser('success', 'Delivery order edited successfully!', `The Delivery order has been edited successfully` )
            this.SuccessAlert = false;
          }
          if (this.ErrorAlert){
            this.notifyUser('error', 'An Error Occurred', 'Sorry, something went wrong')
            this.ErrorAlert = false;
          }

          this.loadDeliveryOrderDetailsOnCreated()
        }).catch(()=>{
          this.ErrorAlert = this.getErrorAlert;
          if (this.ErrorAlert){
            this.notifyUser('error', 'An Error Occurred', 'Sorry, something went wrong, try again later')
            this.ErrorAlert = false;
          }
        })
        this.closeModal()
      }
    },

    cancelDo(){
      this.caneclLoader=true;
      AGENCYAPI.cancelDeliveryOrder(this.$route.params.doId,this.cancelReason).then((resp)=>{
        this.cancelReason="";
        this.caneclLoader=false;
        this.loadDeliveryOrderDetailsOnCreated();

        this.notifyUser('success','Success','Delivery Order Cancelled');
        this.showCancelDoForm=false;
      }).catch((err)=>{
        this.caneclLoader=false;

        this.notifyUser('error','Error','Failed to cancel Delivery order');

      })
    },

    downloadPdf () {
      this.$dialog.confirm('Have attachments and charges pertaining to the delivery order been verified? ',{
        loader: true ,
        okText: 'Yes',
        html: true
      }).then((dialog)=>{

        this.$store.dispatch('deliveryOrder/requestid', this.deliveryOrderId)
        this.$store.dispatch('deliveryOrder/categoryIding', this.doDetails.blNo)
        // this.myLoader = true
        this.getDeliveryOderPDF({}).then(() => {
          this.myLoader = this.getMyLoader
          this.ErrorAlert = this.getErrorAlert;
          this.SuccessAlert =this.getSuccessAlert;
          if  (this.SuccessAlert){
            this.notifyUser('success', 'Delivery order details generated successfully!', `The Delivery order details have been generated and downloaded successfully` )
            this.SuccessAlert = false;
          }
          if (this.ErrorAlert){
            this.notifyUser('error', 'An Error Occurred', 'Sorry, something went wrong')
            this.ErrorAlert = false;
          }
          this.myLoader = false;
        }).catch(() => {
          this.myLoader = false;
          this.ErrorAlert = this.getErrorAlert;
          if (this.ErrorAlert){
            this.notifyUser('error', 'An Error Occurred', 'Sorry, something went wrong')
            this.ErrorAlert = false;
          }
        })

        dialog.close();
      }).catch(()=>{});

    },

    sendForApproval(){
      this.myLoader = true;
      AGENCYAPI.updateStatusDeliveryOder({
        deliveryOrderId: this.$route.params.doId,
        description: null,
        status: "CHECKED"
      }).then((resp)=>{
        this.myLoader = false;
        this.loadDeliveryOrderDetailsOnCreated();
      }).catch(()=>{
        this.myLoader = false;
      })
    },

    approveDo(){
      this.myLoader = true;
      AGENCYAPI.updateStatusDeliveryOder({
        deliveryOrderId: this.$route.params.doId,
        description: null,
        status: "APPROVED"
      }).then((resp)=>{
        this.myLoader = false;
        this.loadDeliveryOrderDetailsOnCreated();
      }).catch(()=>{
        this.myLoader = false;
      })
    },

    sendToBilling(){
      this.$dialog.confirm('Submit` delivery order <b>[' + this.doDetails.doNumber + '] </b>with associated bill of lading No. <b>[' + this.doDetails.blNo + '] </b>for billing ?',{
        loader: true,
        okText: 'Yes',
        html: true
      }).then((dialog)=>{
        this.$store.dispatch('deliveryOrder/requestid', this.deliveryOrderId);
        this.setDeliveryOrderAsCharged({}).then(()=>{
          this.ErrorAlert = this.getErrorAlert;
          this.SuccessAlert =this.getSuccessAlert;
          this.ErrorMessage = 'Sorry, something went wrong.';
          this.SuccessMessage = 'Delivery Order submitted for billing Successfully.';
          // this.loadDeliveryOrderDetailsOnCreated();
          if  (this.SuccessAlert){
            this.notifyUser('success', 'Delivery order sent to Billing!', `The Delivery order has been submitted for billing successfully` )
            this.SuccessAlert = false;
          }
          if (this.ErrorAlert){
            this.notifyUser('error', 'An Error Occurred', 'Sorry, something went wrong')
            this.ErrorAlert = false;
          }
          this.loadDeliveryOrderDetailsOnCreated()

        }).catch(()=>{
          this.ErrorAlert = this.getErrorAlert;
          if (this.ErrorAlert){
            this.notifyUser('error', 'An Error Occurred', 'Sorry, something went wrong')
            this.ErrorAlert = false;
          }
        })
        dialog.close();

      }).catch(()=>{})
    },
    formatTariffDetails(){
      if (!this.tariffFormatCompleted &&
        this.specificDeliveryOrderCharges.length > 1 &&
        this.tariffFees.length > 1 &&
        this.tariffItems.length > 1)  {
        let temp = []
        temp = this.specificDeliveryOrderCharges.filter(obj => {
          return obj.mandatory !== true;
        })

        if (temp.length > 0) {
          let teFee = [];
          let teItem = [];
          for (let i = 0; i < temp.length; i++) {
            // for Tariff fees
            let tariffFeesTemp = this.tariffFees.filter(obj => {
              return obj.tariffFeeId === temp[i].tariffFeeId
            })
            teFee = [...new Set([...teFee, ...tariffFeesTemp])];

            //for Tariff Items
            let tariffItemsTemp = this.tariffItems.filter(obj => {
              return obj.tariffItemId === temp[i].tariffItemId
            })
            teItem = [... new Set([...teItem, ...tariffItemsTemp])];

          }
          this.tariffItems = teItem;
          this.tariffFees = teFee;
          this.tariffFormatCompleted = true
        }
      }
    },

    // out of work
    // updateDOCharge() {
    //   if (this.$v.deliveryOrderChargeDetails.tariffFeeId.$invalid || this.$v.deliveryOrderChargeDetails.tariffItemId.$invalid){
    //     if (this.$v.deliveryOrderChargeDetails.tariffItemId.$invalid){
    //       this.tariffItemError = true;
    //     }
    //     if (this.$v.deliveryOrderChargeDetails.tariffFeeId.$invalid){
    //       this.tariffFeeError =true;
    //     }
    //   }else{
    //     console.log(this.deliveryOrderChargeDetails)
    //     this.$store.dispatch("deliveryOrder/savingDetails", this.deliveryOrderChargeDetails);
    //     this.updateDeliveryOrderCharge({}).then(()=>{
    //       this.SuccessAlert = this.getSuccessAlert;
    //       this.ErrorAlert = this.getErrorAlert;
    //       this.SuccessMessage = 'Delivery Order Charge updated successfully';
    //       this.ErrorMessage = 'Sorry, something went wrong';
    //       this.loadDeliveryOrderDetailsOnCreated();
    //       this.closeModal();
    //     }).catch((e)=>{
    //       this.ErrorAlert = this.getErrorAlert;
    //       this.ErrorMessage = 'Sorry, something went wrong'
    //     })
    //   }
    // },

    goBack () {
      history.back()
    },
    dateRepresent(data){
      let stringDate = ''
      if (data){
        stringDate = new Date(data)
      }
      return stringDate
    },
    viewDetails (details) {
      this.blDetails = details
      this.openModal('VIEW')
    },
    closeModal () {
      this.showModal = false
      this.ViewModalDiv = false
      this.AddModalDiv = false
      this.addChargeModal = false;
      this.reloadSearchAgent()
      this.tariffItemError = false;
      this.tariffFeeError = false;
      this.deliveryOrderChargeDetails = {
        deliveryOderId: this.deliveryOderId,
        tariffFeeId: '',
        tariffItemId: ''
      };
      this.doChargeParticular = {};
    },
    goToEditDOCharges(data){
      this.isEdit = true;
      this.deliveryOrderChargeDetails = data;
      this.openModal('ADD-DO-CHARGE');
    },
    openModal (modalType) {
      if (modalType === 'VIEW') {
        this.showModal = true
        this.ViewModalDiv = true
        this.modal_title = 'Bill of Lading Details'
      }
      if (modalType === 'ADD-DO') {
        this.showModal = true
        this.AddModalDiv = true
        this.modal_title = 'Edit Delivery Order Details'
        this.deliveryOrderDetails = this.doDetails
        const temp = this.deliveryOrderDetails.expireDate ? this.deliveryOrderDetails.expireDate.split(' ') : ''
        this.deliveryOrderDetails.expireDate = temp[0]
        this.searchAgentParam = this.deliveryOrderDetails.agent.agentName ? this.deliveryOrderDetails.agent.agentName : ''
        this.deliveryOrderDetails.agentId = this.deliveryOrderDetails.agent.cfAgentId ? this.deliveryOrderDetails.agent.cfAgentId : ''
        this.agentIdentification = this.deliveryOrderDetails.agent.cfAgentId ?this.deliveryOrderDetails.agent.cfAgentId : ''
      }
      if (modalType === 'ADD-DO-CHARGE'){
        this.deliveryOrderChargeDetails.deliveryOderId  = this.deliveryOrderId;
        this.addChargeModal  = true;
        // this.formatTariffDetails();
      }
    },
    searchReference (searchParam) {
      this.isPagable = false
      this.reloadAllRequest = true
      function search (arr, s) {
        const matches = []; let i

        for (i = arr.length; i--;) {
          if ((arr[i].hasOwnProperty('containerNo') && arr[i].containerNo.indexOf(s) > -1) ||
            (arr[i].hasOwnProperty('sealNumber') && arr[i].sealNumber.indexOf(s) > -1) ||
            (arr[i].hasOwnProperty('billOfLading') && arr[i].billOfLading.indexOf(s) > -1)) {
            matches.push(arr[i])
          }
        }
        return matches
      }
      this.manifestBl = search(this.ogManifestBl, searchParam)
    },
    reloadByIcon () {
      this.reloadAllRequest = false
      this.paymentRequest = []
      this.manifestBl = this.ogManifestBl
      this.isPageable = true
    },
    searchAgent () {
      if (this.searchAgentParam !== '') {
        this.hasSearchAgent = false
        // console.log(this.searchAgentParam)
        this.editMyLoader = true
        this.$store.dispatch('CFAgent/searchingParameter', this.searchAgentParam)
        this.searchCfAgentByAgentName({}).then(() => {
          this.agentOptions = this.getAgentRequestList
          this.editMyLoader = false
        }).catch(() => {
          this.editMyLoader = false
        })
      }
    },
    reloadSearchAgent () {
      this.hasSearchAgent = true
      this.searchAgentParam = ''
    },
    fadeMe: function () {
      if (this.SuccessAlert) {
        this.SuccessAlert = !this.SuccessAlert
      } else {
        this.ErrorAlert = !this.ErrorAlert
      }
    },
    enter: function (el, done) {
      var that = this

      setTimeout(function () {
        that.SuccessAlert = false
        that.ErrorAlert = false
      }, 3000) // hide the message after 3 seconds
    },
    extractAgentFromObject (requestList) {
      requestList.doDate = requestList.doDate.split(".")[0];
      requestList.expireDate = requestList.expireDate.split(".")[0];
      return requestList
    }
  }

}
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 4910;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .8);
  display: table;
  transition: opacity .3s ease;
  overflow-y: auto;
}

.medium-modal-dialog {
  max-width: calc(80vw) !important;
}

tbody tr {
  color: #212529 !important;
/* font-weight: 400 !important; */
}
thead th {
  font-weight: 600 !important;
}
.legend {
  position: relative;
  border: 1px solid #DCDCDC;
  padding: 1.5rem 1rem 1rem 1rem;
  margin-bottom: 1rem;
  border-radius: 3px;
  margin-top: 1rem;
}

.legend-title {
  background-color: inherit;
  position: absolute;
  top: -0.6rem;
  padding: 0.2rem 1rem;
  background-color: white;
  color: #d59a18;
}

.btn-space {
  margin-right: 10px !important;
}

ul.nav-justified {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    background-color: #ededee;
    border-radius: 3px;
}

ul.nav-justified li {
    float: left;
}

ul.nav-justified li a:hover {
    color: #d59a18;
}

ul.nav-justified li a {
    display: block;
    text-align: center;
    padding: 16px 20px 10px 21px;
    text-decoration: none;
    color: blue;
    font-weight: bold;
    text-transform: uppercase;
}

.custom-btn {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
    padding: 5px 9px 5px 9px !important;
    font-size: 14px !important;
}

/* .custom-btn {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
  padding: 5px 9px 5px 9px !important;
  font-size: 14px !important;
  width: auto;
  background-color: #FFFFFF !important;
} */

.btn-linen:hover {
    border-color: #D2691E;
    color: #D2691E;
    background-color: #ffffff;
}

.btn-linen {
    border-color: rgba(210, 105, 30, .5);
    color: rgba(210, 105, 30, .5);
}

.btn-linen-fill {
    background-color: rgb(210, 105, 30);
    opacity: 1;
    color: #FFFFFF;
}

.btn-rosy-brown:hover {
    border-color: #775b5b;
    color: #775b5b;
    background-color: #ffffff;
}

.btn-rosy-brown {
    border-color: rgba(119,91,91, .5);
    color: rgba(119,91,91, .5);
}

.btn-rosy-brown-fill {
    background-color: rgb(119,91,91);
    opacity: 1;
    color: #FFFFFF;
}

.btn-khaki:hover {
    border-color: #989259;
    color: #989259;
    background-color: #ffffff;
}

.btn-khaki {
    border-color: rgba(152,146,89, .5);
    color: rgba(152,146,89, .5);
}

.btn-khaki-fill {
    background-color: rgb(152,146,89);
    opacity: 1;
    color: #FFFFFF;
}

.btn-dark-blue-like:hover {
    border-color:  #154360 ;
    color:  #154360 ;
    background-color: #ffffff;
}

.btn-dark-blue-like {
    border-color: rgba( 21, 67, 96 , .5);
    color: rgba( 21, 67, 96 , .5);
}

.btn-dark-blue-like-fill {
    background-color: rgb( 21, 67, 96 );
    opacity: 1;
    color: #FFFFFF;
}

.btn-olive:hover {
    border-color: #4B0082;
    color: #4B0082;
    background-color: #ffffff;
}

.btn-olive {
    border-color: #6A5ACD;
    color: #6A5ACD;
}

.btn-olive-fill {
    background-color: #6A5ACD;
    opacity: 1;
    color: #FFFFFF;
}

.btn-blue:hover {
    border-color: #0000ff;
    color: #0000ff;
    background-color: #ffffff;
}

.btn-blue {
    border-color: #4169e1;
    color: #4169e1;
}

.btn-blue-fill {
    background-color: #4169e1;
    opacity: 1;
    color: #FFFFFF;
}

.btn-purple:hover {
    border-color: #800080;
    color: #800080;
    background-color: #ffffff;
}

.btn-purple {
    border-color: #9932cc;
    color: #9932cc;
}

.btn-purple-fill {
    background-color: #9932cc;
    opacity: 1;
    color: #FFFFFF;
}

.btn-success-custom:hover {
    border-color: #008000;
    color: #008000;
    background-color: #ffffff;
}

.btn-success-custom {
    border-color: rgba(0, 128, 0, .5);
    color: rgba(0, 128, 0, .5);
}

.btn-success-custom-fill {
    background-color: rgba(0, 128, 0, .8);
    opacity: 1;
    color: #FFFFFF;
}

.btn-danger-custom:hover {
    border-color: #ff0000;
    color: #ff0000;
    background-color: #ffffff;
}

.btn-danger-custom {
    border-color: rgba(255, 0, 0, .5);
    color: rgba(255, 0, 0, .5);
}

.btn-danger-custom-fill {
    background-color: rgba(255, 0, 0, .5);
    opacity: 1;
    color: #FFFFFF;
}

.chargedStatus{
  color: darkgoldenrod;
}
.error{
  color:#dc3545;
  margin-left: 2px;
  margin-top: 2px;
}
.loader-div {
  line-height: 40px;
}

.loader-div-gif {
  line-height: 50px;
}
.div-table-row {
  padding: 0 15px 0 15px;
}

.div-table {
  padding: 9px 8px 9px 8px;
}

.div-table-title {
  width: 250px;
  background-color: rgba(153, 186, 221, .5);
  font-weight: normal;
}

.div-table-value {
  border: 1px solid #DCDCDC;
  font-weight: 900;
}

label {
  position: relative;
  top: -7px;
  right: 0;
  margin: 0;
  padding: 0;
}

.special-bottom {
  margin-bottom: -1px;
}

  .red{
    color: red;
  }
</style>
