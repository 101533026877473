<template>
  <div>
  <div class="mb-6 col-md-12">
    <data-table
      :table-headers="tableHeaders"
      :table-data="modNotification"
      :table-data-keys="tableDataKeys"
      :my-first-loader="myFirstLoader"
      :my-loader="loader"
      :page-size-prop="pageSize"
      :page-no="pageNo"
      :total-elements="totalElements"
      :total-pages="totalPages"
      :is-first="isFirst"
      :is-last="isLast"
      :has-pagination="true"
      :no-data-found="noDataFound"
      :page-number="pageNumber"
      @goToPage="goToPage"
      :date-prop="'Created At '"
      :has-manage-button="false"
      :has-filter="false"
      :reload-all-request="getSmsNotifications"
      @reloadByIcon="reloadByIcon"
      @onChange="onChange"
      :permissions="[ {key: 'searchPermission', value: 'CLEARINGFORWARDING_SEARCH_REQUESTS_GET'} ]"
      :loading-title="currentLoadingTitle">
      <div slot = "pagination">
        <paginate
          v-model="getIsPage"
          :page-count=parseInt(totalPages)
          :page-range="3"
          :margin-pages="2"
          :click-handler="goToPage"
          :prev-text="'Prev'"
          :next-text="'Next'"
          :container-class="'pagination'"
          :page-class="'page-item'">
        </paginate>
      </div>
    </data-table>

    <global-modal v-if="loader">
      <div slot="globalLoader">
        <div class="row text-center">
          <div class="col-md-12">
            Loading, Please wait
          </div>
          <div class="col-md-12">
            <br>
          </div>
          <div class="col-md-12">
            <pulse-loader
              :loading="loader"
              :color="color"
              :size="size"/>
          </div>
        </div>
      </div>
    </global-modal>
  </div>
  </div>
</template>

<script>
  import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
  import GlobalModal from '../../components/globalLoader'
  import DataTable from '../../components/DataTable/dataTable';
  import REVENUE_API from '../../api/rev'

  export default {
    name: "RevenueMessages",

    components: {
      PulseLoader,
      GlobalModal,
      DataTable
    },

    computed:{
      getIsPage: {
        get: function () {
          return this.pageNo
        },
        set: function (newValue) {
          this.pageNo = newValue;
        }
      }
    },

    data() {
      return {
        tableHeaders: ['Customer', 'Tin', 'Mobile', 'Control Number', 'Due Date', 'Type'],
        tableDataKeys: ['companyName', 'companyTin', 'mobile', 'controlNumber', 'dueDate', 'notificationType'],
        notifications: [],
        modNotification: [],
        myFirstLoader: true,
        loader: false,
        pageNo: 0,
        pageSize: 15,
        params: '',
        isPageable: true,
        totalElements: '',
        totalPages: '',
        isFirst: false,
        isLast: false,
        noDataFound: false,
        pageNumber: '',
        reloadRequests: false,
        currentLoadingTitle: '',
        color: 'green',
        size: '12px',
      }
    },

    created() {
      this.getSmsNotifications();
      this.myFirstLoader = false;
    },

    methods: {
      goToPage(page) {
        this.pageNo = page;
        this.getSmsNotifications();
      },

      getSmsNotifications() {
        this.loader = true;
        this.setParams();
        REVENUE_API.getBillNotifications(this.params).then(resp => {
          if (resp.data.status) {
            console.log(resp.data.data.content);
            this.loader = false;
            this.notifications = resp.data.data.content;
            this.totalPages = resp.data.data.totalPages;
            this.totalElements = resp.data.data.totalElements;
            this.pageNumber = resp.data.data.number;
            this.isFirst = resp.data.data.first;
            this.isLast = resp.data.data.isLast;
            this.noDataFound = resp.data.data.content.length <= 0;
            this.sortingData(this.notifications);
          }
        })
      },

      reloadByIcon() {
        this.reloadRequests = false;
        this.isPageable = true;
        this.requests = [];
        this.getSmsNotifications();
      },

      setParams() {
        this.modNotification = [];
        this.params = 'size=' + this.pageSize + '&page=' + this.pageNo;
      },

      onChange(event) {
        this.pageSize = event;
        this.pageNo = 0;
        this.getSmsNotifications();
      },

      getCurrentLoadingTitle() {
        this.currentLoadingTitle = "Please wait while the system is loading " + this.currentStatus.toLowerCase() + " delivery orders"
      },

      sortingData(data) {

        for(let x = 0 ; x < data.length ; x++) {

          const requestObject = {
            ...data[x],
            companyName: data[x].bill !== null ? data[x].bill.company.name : '',
            companyTin: data[x].bill !== null ? data[x].bill.company.tin : '',
            mobile: data[x].bill !== null ? data[x].bill.company.mobile : '',
            controlNumber: data[x].bill !== null ? data[x].bill.controlNumber : '',
            dueDate: data[x].bill !== null ? this.dateTimeToHuman(data[x].bill.dueDate) : '',
          };
          this.modNotification.push(requestObject)
        }

      },
    }


  }
</script>

<style scoped>
  .back-color-vat {
    background-color: rgba(216, 225, 232, .3);
  }

  .custom-btn, .custom-btn-all {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .custom-btn:hover, .custom-btn-all:hover {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
</style>
