<template>
  <div>
    <card>
      <data-table
        :table-headers="tableHeaders"
        :table-data="ediMessages"
        :table-data-keys="tableDataKeys"
        :my-first-loader="myFirstLoader"
        :is-pageable="isPageable"
        :no-data-found="noDataFound"
        :has-pagination="false"
        :has-filter="false"
        :buttonList="[ {'code' : 'Download' , 'name' : 'Download' , 'class' : 'btn-primary'} ]"
        :has-manage-button="false"
        :loading-title="currentLoadingTitle"
        :permissions="[ {key: 'searchPermission', value: 'CLEARINGFORWARDING_SEARCH_REQUESTS_GET'} ]"
        @goToDownload="downloadMinioObject">
      </data-table>
      <global-modal v-if="myLoader">
        <div slot="globalLoader">
          <div class="row text-center">
            <div class="col-md-12">
              Loading, Please wait
            </div>
            <div class="col-md-12">
              <br>
            </div>
            <div class="col-md-12">
              <pulse-loader
                :loading="loading"
                :color="color"
                :size="size"
              />
            </div>
          </div>
        </div>
      </global-modal>
    </card>
  </div>
</template>

<script>
  import PulseLoader from 'vue-spinner/src/PulseLoader';
  import GlobalModal from '../../components/globalLoader';
  import DataTable from '../../components/DataTable/dataTable';
  import API_INDEX from '../../api/index';
  import { mapActions, mapGetters } from 'vuex';

  export default {
    name: "EdiMessages",

    components: {
      PulseLoader,
      GlobalModal,
      DataTable
    },

    computed: {
      ...mapGetters('global', [
        'getGlobalLoader'
      ])
    },

    created() {
      this.getEdiMessages();
    },

    data() {
      return {
        tableHeaders: ['Arrival Date', 'Carrier Name', 'Control Reference', 'Message Function', 'Voyage Number', 'Message Type', 'Ship Name'],
        tableDataKeys: ['arrivalDate', 'carrierName', 'controlReference', 'messageFunction', 'voyageNumber', 'messageType', 'shipName'],
        ediMessages: [],
        myFirstLoader: false,
        isPageable: false,
        globalLoader: false,
        myLoader: false,
        noDataFound: false,
        pageNumber: '',
        reloadRequests: false,
        currentLoadingTitle: '',
        redStatusKey: {
          key: 'status',
          value: "REJECTED"
        },
      }
    },

    methods: {
      ...mapActions('global', [
        'loadFileFromMinio'
      ]),

      getEdiMessages() {
        API_INDEX.getEDIMessage().then(resp => {
          if (resp.data.status) {
            this.ediMessages = resp.data.data
          }
        })
      },

      downloadMinioObject(object) {
        let attachName = object.fileUrl;
        let fileName = 'Cuscar-edi';

        this.loadParameters(attachName, fileName).then(() => {
          this.loadFileFromMinio({}).then((res) => {
            if(res.status === 204) {
              this.loadNotification('warn', 4000, 'File Download',
                'File not found, please contact system admin',
                'response', 1000 , true  , true)
            } else {
              this.loadNotification('success', 4000, 'File Download',
                'File has been successfully downloaded, please have a look at it',
                'response', 1000 , true  , true)
            }
            this.globalLoader = this.getGlobalLoader
          }).catch(() => {
            this.loadNotification('error', 4000, 'File Download',
              'File not downloaded, please check your internet connection',
              'response', 1000 , true  , true);
            this.globalLoader = this.getGlobalLoader
          });
          this.globalLoader = this.getGlobalLoader
        }).catch(() => {})

      },

      loadParameters (fileUrl, fileName) {
        return new Promise((resolve) => {
          this.$store.dispatch('global/fileUrling', fileUrl);
          this.$store.dispatch('global/fileNaming', fileName);
          resolve()
        })
      },

      getCurrentLoadingTitle(){
        this.currentLoadingTitle =  "Please wait while the system is loading edi messages orders"
      },
    }
  }
</script>

<style scoped>

</style>
