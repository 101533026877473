<template>
  <div id="toPrint">
    <card style="font-size: 12px">
      <div class="row">
        <div class="col-md-12">
          <ul class="nav-justified">
            <li v-if="balanceFilled">
              <button
                @click="openModal('TALLY_SHEET')"
                class="btn btn-blue btn-blue-fill mr-2 custom-btn">
                <!-- <svg-icon
                  icon="download"
                  :has-fill="true"
                  class="icons"
                  id="icon-d"/> &nbsp; -->
                Download Tally Sheet
              </button>
            </li>
            <li v-if="balanceFilled">
              <button
                @click.prevent="openModal('TALLY_SHIFT')"
                class="btn btn-olive btn-olive-fill mr-2 custom-btn">
                <!-- <svg-icon
                  icon="shift"
                  :has-fill="true"
                  class="icons"
                  id="icon-sr"/> &nbsp; -->
                Shift Report
              </button>
            </li>

            <li v-if="balanceFilled">
              <button
                @click.prevent="openModal('TALLY_DAILY')"
                class="btn btn-success-custom btn-success-custom-fill mr-2 custom-btn">
                <!-- <svg-icon
                  icon="daily"
                  :has-fill="true"
                  class="icons"
                  id="icon-dr"/> &nbsp; -->
                Daily Report
              </button>
            </li>

            <li v-if="(stuffingType === 'LOADING' || stuffingType==='DISCHARGING') && balanceFilled">
              <button
                @click.prevent="openModal('TALLY_VESSEL_PERFORMANCE')"
                class="btn btn-purple btn-purple-fill mr-2 custom-btn">
                <!-- <svg-icon
                  icon="vessel"
                  :has-fill="true"
                  class="icons"
                  id="icon-vpr"/> &nbsp; -->
                Vessel Performance Report
              </button>
            </li>
            <li v-if="!balanceFilled">
              <button @click.prevent="addBalanceBf"
                      class="btn btn-olive btn-olive-fill mr-2 custom-btn">
                <!-- <svg-icon
                  icon="vessel"
                  :has-fill="true"
                  class="icons"
                  id="icon-vps"/> -->
                Add Cargo Balance
              </button>
            </li>
            <li v-if="balanceFilled">
              <button
                @click.prevent="downloadGeneralReport"
                class="btn btn-success-custom btn-success-custom-fill mr-2 custom-btn">
                <!-- <svg-icon
                  icon="daily"
                  :has-fill="true"
                  class="icons"
                  id="icon-ds"/> &nbsp; -->
                General Report
              </button>
            </li>
            <li>
              <button
                @click.prevent="downloadReconciliationReport(documentId)"
                class="btn btn-blue btn-blue-fill mr-2 custom-btn">
                <!-- <svg-icon
                  icon="daily"
                  :has-fill="true"
                  class="icons"
                  id="icon-ds"/> &nbsp; -->
                Download Reconciliation Report
              </button>
            </li>
          </ul>
        </div>
      </div>
    </card>
    <card style="font-size: 12px">
      <div class="row">
        <div class="col-md-12">
          <div class="legend">
            <h6 class="legend-title">
              Tallying Sheet Details
            </h6>

            <br>

            <div class="row div-table-row">
              <div class="col-md-2 div-table div-table-title">
                Voyage
              </div>
              <div class="col-md-2 div-table div-table-value">
                {{ singleRequest.notification.voyage }}
              </div>
              <div class="col-md-2 div-table div-table-title">
                Operation Type
              </div>
              <div class="col-md-2 div-table div-table-value">
                {{ singleRequest.operationType ? singleRequest.operationType : stuffingType.toUpperCase() }}
              </div>

              <div class="col-md-2 div-table div-table-title"
                   v-if="stuffingType === 'STUFFING' || stuffingType==='DESTUFFING' ">
                Liner Name
              </div>
              <div class="col-md-2 div-table div-table-value"
                   v-if="stuffingType === 'STUFFING' || stuffingType==='DESTUFFING' ">
                {{ singleRequest.linerName }}
              </div>
              <div class="col-md-2 div-table div-table-title"
                   v-if="stuffingType === 'DISCHARGING' || stuffingType ==='LOADING' ">
                Vessel Name
              </div>
              <div class="col-md-2 div-table div-table-value"
                   v-if="stuffingType === 'DISCHARGING' || stuffingType ==='LOADING' ">
                {{ singleRequest.notification.vesselName }}
              </div>


            </div>

            <br>

            <div class="row div-table-row">


              <div class="col-md-2 div-table div-table-title"
                   v-if="stuffingType === 'STUFFING' || stuffingType==='DESTUFFING' ">
                Vessel Name
              </div>
              <div class="col-md-2 div-table div-table-value"
                   v-if="stuffingType === 'STUFFING' || stuffingType==='DESTUFFING' ">
                {{ singleRequest.vesselName }}
              </div>

              <div class="col-md-2 div-table div-table-title"
                   v-if="stuffingType === 'DISCHARGING' || stuffingType ==='LOADING' ">
                Vessel Type
              </div>
              <div class="col-md-2 div-table div-table-value"
                   v-if="stuffingType === 'DISCHARGING' || stuffingType ==='LOADING' ">
                {{ vesselType !== null ? vesselType !== undefined ? vesselType.replace("_", " ") : '' : '' }}
                <!--                {{ vesselType !== null ? vesselType.replace("_", " ") : '' }}-->
              </div>

              <div class="col-md-2 div-table div-table-title">
                Created At
              </div>
              <div class="col-md-2 div-table div-table-value">
                {{ dateTimeToHuman(singleRequest.createdAt) }}
              </div>

              <div class="col-md-2 div-table div-table-title">
                Completion Status
              </div>
              <div class="col-md-2 div-table div-table-value">
                {{ singleRequest.completed ? 'YES' : 'NO' }}
              </div>
            </div>

            <br>
          </div>
        </div>

        <div class="col-md-12">
          <div class="legend">
            <h6 class="legend-title">
              Tallied Bills of Lading
            </h6>

            <br>

            <data-table
              :table-headers="cardTableHeaders"
              :table-data="tableDetails"
              :table-data-keys="cardTableKeys"
              :page-size-prop="pageSize"
              :page-no="pageNo"
              :is-hovered="isHovered"
              :my-loader="myLoader"
              :page-count="pageCount"
              :total-elements="pageCount"
              :reload-all-request="reloadAllRequest"
              :my-first-loader="myFirstLoader"
              :no-data-found="noDataFound"
              :search-parameter-prop="searchParameter"
              :is-pageable="false"
              :loading-title="'Please wait while system is loading tally sheets'"
              :has-date-query="true"
            />
          </div>
        </div>
      </div>

      <div class="row noprint">
        <div class="col-md-12 text-right">
          <div class="col-md-12 text-right">
            <!--                        print vessel tally-->

            <button
              class="btn btn-default btn-fill btn-space"
              @click.prevent="goBack">
              <font-awesome-icon icon="undo"/> &nbsp; Back
            </button>
          </div>
        </div>
      </div>
    </card>
    <global-modal v-if="myLoader">
      <div slot="globalLoader">
        <div class="row text-center">
          <div class="col-md-12">
            Downloading requested {{ reportName }}, Please wait
          </div>
          <div class="col-md-12">
            <br>
          </div>
          <div class="col-md-12">
            <pulse-loader
              :loading="loading"
              :color="color"
              :size="size"/>
          </div>
        </div>
      </div>
    </global-modal>
    <modal v-if="tallyReports">
      <div slot="header" class="modal-title col-md-12">
        <div class="row">
          <div class="col-md-12 text-center pl-5">
            <h5><b>{{ reportName }} Report</b></h5>
          </div>
          <div class="col-md-12">
            <hr/>
          </div>
        </div>
      </div>
      <div slot="body">
        <div class="row">
          <div class="col-md-12">
            <crud-notification
              :SuccessMessage="SuccessMessage"
              :ErrorMessage="ErrorMessage"
              :SuccessAlert="SuccessAlert"
              :ErrorAlert="ErrorAlert"
              @fadeMe="fadeMe"
              @enter="enter">

            </crud-notification>
          </div>
          <div class="col-md-12" v-if="SuccessAlert || ErrorAlert">
            <br/>
          </div>
          <div class="col-md-12">
            <tally-reports-form
              :tallyDaily="tallyDaily"
              :tallySheet="tallySheet"
              :tallyShift="tallyShift"
              :tallyVesselPerformance="tallyVesselPerformance"
              :operation-type="stuffingType"
              @closeModal="closeModal"
              @downloadReports="downloadReports">
            </tally-reports-form>
          </div>
        </div>
      </div>
    </modal>
    <modal v-if="addBalance">
      <!--<div slot="header" class="modal-title col-md-12">-->
      <!--<div class="row">-->
      <!--<div class="col-md-12">-->
      <!--<h6>-->
      <!--<b>Charge Bill Of Lading</b>-->
      <!--</h6>-->
      <!--</div>-->
      <!--<div class="col-md-12">-->
      <!--<hr>-->
      <!--</div>-->
      <!--</div>-->
      <!--</div>-->
      <div slot="body">
        <tally-balance-model :tallyDocument="singleRequest" @closeModal="closeModal"></tally-balance-model>
      </div>
    </modal>
  </div>
</template>

<script>

  import {mapGetters, mapActions} from "vuex";
  import PulseLoader from "vue-spinner/src/PulseLoader.vue";
  import TallyBalanceModel from "./tallyBalanceModel";
  import GlobalModal from "../../../components/globalLoader";
  import DataTable from "../../../components/DataTable/updatedTable";
  import CrudNotification from "../../../components/DataTable/crudNotification";
  import TallyReportsForm from "../../../components/Form/tallySheetShiftDailyVesselPerformance";
  import Modal from "../../../components/Inputs/Modal";
  import SvgIcon from '../../../components/SvgLoader/svgLoader';
  import TALLT_API from '../../../api/tally'

  export default {

    name: "tallying",

    components: {

      PulseLoader,
      Modal,
      DataTable,
      GlobalModal,
      TallyBalanceModel,
      SvgIcon,
      CrudNotification,
      TallyReportsForm


    },
    created() {

      this.documentId = this.$route.params.documentId;
      this.stuffingType = this.$route.params.stuffingType;
      this.loadTallySheetDetailsOnCreated(this.documentId);
      this.$store.dispatch("pagenames/currentPageName", "Tally Sheet Details");

      this.$store.dispatch("tallyshift/erroralerting", false);
      this.$store.dispatch("tallyshift/successalerting", false);

    },

    computed: {
      ...mapGetters("notifications", [
        "getNewAppPage"
      ]),

      ...mapGetters("shippingtallying", [
        "getRequestList",
        "getPageCount",
        "getMyFirstLoader",
        "getNoDataFound",
        "getSuccessAlert",
        "getErrorMessage",
        "getErrorAlert",
        "getSuccessMessage",
        "getReloadAllRequest",
        "getMyLoader",
        "getSearchParameter",
        "getHasSearchedVariable",
        "getPageNo",
        "getRequestList2",
        "getRequestId",
      ]),

      ...mapGetters({
        getTallyShiftList: "tallyshift/getRequestList",
        getSuccessMessageR: "tallyshift/getSuccessMessage",
        getErrorAlertR: "tallyshift/getErrorAlert",
        getErrorMessageR: "tallyshift/getErrorMessage",
        getSuccessAlertR: "tallyshift/getSuccessAlert",
        getMyLoaderR: "tallyshift/getMyLoader",
      }),
    },

    data() {
      return {
        vesselType: '',

        stuffingType: '',

        color: "green",
        size: "12px",
        loading: true,

        finalUserPermissions: [],

        singleRequest: [],
        tableDetails: [],

        searchParameter: "",
        reloadAllRequest: false,
        noDataFound: false,
        myLoader: false,
        myFirstLoader: false,
        pageNo: 0,
        pageSize: 15,
        pageCount: 1,
        isHovered: [],
        addBalance: false,
        balanceFilled: false,
        SuccessAlert: false,
        ErrorAlert: false,
        SuccessMessage: "",
        ErrorMessage: "",

        cardTableHeaders: ['cargo Type', 'Container', 'Chassis Number', 'Container Size'],
        cardTableKeys: ['cargoType', 'containerNumber', 'chassisNumber', 'containerSize'],

        tallyReports: false,
        tallySheet: false,
        tallyShift: false,
        tallyDaily: false,
        tallyVesselPerformance: false,
        reportName: "",
      }
    },
    methods: {

      ...mapActions("shippingtallying", [
        "loadShippingTallySheetsOnCreated",
        "loadAllShippingTallyingSheets",
        "getShippingTallySheetsDetails",
        "getStuffingOrStrippingDetails",
        "getTalliedContainersAllPageable"
      ]),

      ...mapActions("tallyshift", [
        "downloadTallySheetReport",
        "downloadTallyDailyReport",
        "downloadTallyShiftReport",
        "downloadVesselPerformanceReport",
        "downloadTallySheetStuffing",
        "downloadTallyDailyReportStuffing",
        "downloadTallyShiftReportStuffing",
      ]),

      downloadReconciliation () {



      },

      loadBlDetails(vesselType) {
        switch (vesselType) {
          case "CONTAINER":
            this.cardTableHeaders = ['cargo Type', 'Container', 'Chassis Number', 'Container Size'];
            this.cardTableKeys = ['cargoType', 'containerNumber', 'chassisNumber', 'containerSize'];
            break;

          case "VEHICLE":
            this.cardTableHeaders = ['cargo Type', 'Container', 'Chassis Number', 'Container Size'];
            this.cardTableKeys = ['cargoType', 'containerNumber', 'chassisNumber', 'containerSize'];
            break;

          case "DRY_BULK":
            this.cardTableHeaders = ['cargo Type', 'Container', 'Chassis Number', 'Container Size'];
            this.cardTableKeys = ['cargoType', 'containerNumber', 'chassisNumber', 'containerSize'];
            break;

          case "GENERAL_CARGO":
            this.cardTableHeaders = ['cargo Type', 'Container', 'Chassis Number', 'Container Size'];
            this.cardTableKeys = ['cargoType', 'containerNumber', 'chassisNumber', 'containerSize'];
            break;

          default: //LIQUID_BULK
            this.cardTableHeaders = ['cargo Type', 'Container', 'Chassis Number', 'Container Size'];
            this.cardTableKeys = ['cargoType', 'containerNumber', 'chassisNumber', 'containerSize'];
            break;
        }
      },

      loadParameters() {
        return new Promise((resolve) => {
          this.$store.dispatch("shippingtallying/pageSizing", this.pageSize);
          this.$store.dispatch("shippingtallying/pageNumbering", this.pageNo);
          this.$store.dispatch("shippingtallying/requestid", this.documentId);
          this.$store.dispatch("shippingtallying/stuffingtyping", this.stuffingType);
          resolve();
        })
      },

      loadTallySheetPayload(payload) {
        return new Promise((resolve) => {
          this.$store.dispatch("tallyshift/reportSearchAction", payload);
          resolve();
        })
      },

      downloadReports(operationDate, cargoTypeName, shiftId, operationLocation, reportType) {

        const payload = {
          cargoType: cargoTypeName,
          containerNumbers: [],
          day: operationDate !== '' ? operationDate : null,
          documentId: this.documentId,
          endDate: null,
          reportType: reportType,
          shiftId: shiftId,
          startDate: null
        };

        if (this.tallySheet) {
          this.loadTallySheetPayload(payload).then(() => {
            this.downloadTallySheetReport({}).then(() => {
              this.myLoader = this.getMyLoaderR;
              if (this.getSuccessAlertR) {
                this.loadNotification('success', 4000, 'Request Message',
                  'Your tally sheet report has been successfully downloaded.',
                  'response', 1000, true, true);
              }

              if (this.getErrorAlertR) {
                this.loadNotification('warn', 4000, 'Request Message',
                  'No content found for requested tally sheet, please try with different category options',
                  'response', 1000, false, true);
              }
            }).catch(() => {

            });
            this.myLoader = this.getMyLoaderR;
          }).catch(() => {

          });
        } else if (this.tallyDaily) {
          this.loadTallySheetPayload(payload).then(() => {
            this.downloadTallyDailyReport({}).then(() => {
              this.myLoader = this.getMyLoaderR;
              if (this.getSuccessAlertR) {
                this.loadNotification('success', 4000, 'Request Message',
                  'Your tally sheet report has been successfully downloaded, please have a look at it',
                  'response', 1000, true, true);
              }

              if (this.getErrorAlertR) {
                this.loadNotification('warn', 4000, 'Request Message',
                  'No content found for requested tally sheet, please try with different category options',
                  'response', 1000, false, true);
              }
            }).catch(() => {

            });
            this.myLoader = this.getMyLoaderR;
          }).catch(() => {

          });
        } else if (this.tallyShift) {
          this.loadTallySheetPayload(payload).then(() => {
            this.downloadTallyShiftReport({}).then(() => {
              this.myLoader = this.getMyLoaderR;
              if (this.getSuccessAlertR) {
                this.loadNotification('success', 4000, 'Request Message',
                  'Your tally sheet report has been successfully downloaded, please have a look at it',
                  'response', 1000, true, true);
              }
              if (this.getErrorAlertR) {
                this.loadNotification('warn', 4000, 'Request Message',
                  'No content found for requested tally sheet, please try with different category options',
                  'response', 1000, false, true);
              }
            }).catch(() => {

            });
            this.myLoader = this.getMyLoaderR;
          }).catch(() => {

          });
        } else { // TALLY_VESSEL_PERFORMANCE
          this.loadTallySheetPayload(payload).then(() => {
            this.downloadVesselPerformanceReport({}).then(() => {
              this.myLoader = this.getMyLoaderR;
              if (this.getSuccessAlertR) {
                this.loadNotification('success', 4000, 'Request Message',
                  'Your tally sheet report has been successfully downloaded, please have a look at it',
                  'response', 1000, true, true, 'successAlertNew');
              }

              if (this.getErrorAlertR) {
                this.loadNotification('warn', 4000, 'Request Message',
                  'No content found for requested tally sheet, please try with different category options',
                  'response', 1000, false, true);
              }

            }).catch(() => {

            });
            this.myLoader = this.getMyLoaderR;
          }).catch(() => {

          });
        }
      },

      loadTallySheetDetailsOnCreated() {
        this.loadParameters().then(() => {

          this.getShippingTallySheetsDetails({}).then(() => {

            this.singleRequest = this.getRequestList;
            this.pageCount = this.getPageCount;
            this.myFirstLoader = this.getMyFirstLoader;
            this.noDataFound = this.getNoDataFound;
            this.SuccessMessage = this.getSuccessMessage;
            this.ErrorMessage = this.getErrorMessage;
            this.ErrorAlert = this.getErrorAlert;
            this.SuccessAlert = this.getSuccessAlert;
            this.pageNo = this.getPageNo;
            this.pageSize = this.getPageSize;
            this.reloadAllRequest = this.getReloadAllRequest;
            this.vesselType = "CONTAINER";

            console.log(this.singleRequest);
            this.checkIfBalancesFilled(this.singleRequest);

            this.loadBlDetails(this.vesselType);

            this.singleRequest.billOfLadings.map((data) => {
              data = Object.assign(data, {
                containerNumber: data.containers.length !== 0 ? data.containers[0].containerNo : "N/A",
                containerSize: data.containers.length !== 0 ? data.containers[0].containerSize : "N/A",
                chassisNumber: data.goodDetails.length !== 0 ? data.goodDetails[0].vehicleVIN : "N/A",

              });

              this.tableDetails.push(data)
            });

            this.pageCount = this.tableDetails.length;
            this.pageCount = this.tableDetails.length;

          }).catch(() => {

          });
        })
      },

      downloadGeneralReport() {
        this.globalLoader = true;
        TALLT_API.downloadGeneralReport(this.singleRequest.id).then((response) => {

          this.globalLoader = false;

          if (response.status === 200) {

            const url = window.URL.createObjectURL(new Blob([response.data], {
              type: 'application/octet-stream'
            }));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "General Tallying Report.xlsx");
            document.body.appendChild(link);
            link.click();
            this.loadNotification('success', 4000, '',
              'General Tallying Report has been successfully downloaded, please have a look at it',
              'response', 1000 , true  , true);
            this.globalLoader = false

          } else {

            this.loadNotification('error', 4000, '',
              'No content found for General Tallying report, please try with different options',
              'response', 1000 , true  , true);
            this.globalLoader = false

          }
        })
          .catch(() => {

            this.loadNotification('error', 4000, 'ged Receivable Report',
              'No report found, please check your internet connection and try',
              'response', 1000, true, true);
            this.globalLoader = false

          });
      },

      downloadReconciliationReport(documentId) {

        this.myLoader = true;
        this.reportName = 'Reconciliation Report'
        TALLT_API.downloadReconciliationReport(documentId).then((response) => {

          this.myLoader = false;

          if (response.status === 200) {

            const url = window.URL.createObjectURL(new Blob([response.data], {
              type: 'application/octet-stream'
            }));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "Reconciliation Report.xlsx");
            document.body.appendChild(link);
            link.click();
            this.loadNotification('success', 4000, '',
              'Reconciliation Report has been successfully downloaded, please have a look at it',
              'response', 1000 , true  , true);
            this.myLoader = false

          } else {

            this.loadNotification('error', 4000, '',
              'No content found for Reconciliation report, please try with different options',
              'response', 1000 , true  , true);
            this.myLoader = false

          }
        })
          .catch(() => {

            this.loadNotification('error', 4000, '',
              'Something went wrong, please check your internet connection and try or contact system admin',
              'response', 1000, true, true);
            this.myLoader = false

          });

      },

      checkIfBalancesFilled(obj) {
        if (obj.notification.feuEmptyQuantity !== null && obj.notification.feuFullQuantity !== null
          && obj.notification.teuEmptyQuantity !== null && obj.notification.teuFullQuantity !== null
          && obj.notification.packageQuantity !== null && obj.notification.vehicleQuantity !== null) {
          this.balanceFilled = true;
        }
      },

      addBalanceBf() {
        this.addBalance = true;
      },

      goBack() {
        if (window.$cookies.isKey('browser-local-cashed-url-back')) {
          this.$router.push(window.$cookies.get('browser-local-cashed-url-back'));
          window.$cookies.remove('browser-local-cashed-url-back');

        }
      },

      goToApplication(cargoType) {
        this.voyageID = this.singleRequest.voyage;
        localStorage.setItem('accessedFrom', 'shippingTallyBlnDetails');
        document.cookie = "browser-local-cashed-url-back-next=" + this.$route.fullPath + ";Path = /;SameSite = Strict";
        this.$router.push("/shipping-tallying/tally-sheet-bln-details/" + cargoType + "/" + this.voyageID).catch(error => {
        });
      },

      openModal(flag) {

        this.tallyReports = true;
        this.$store.dispatch("tallyshift/erroralerting", false);
        this.$store.dispatch("tallyshift/successalerting", false);
        this.$store.dispatch("tallyshift/operationTypeValue", this.stuffingType);

        switch (flag) {

          case 'TALLY_SHEET':
            this.tallySheet = true;
            this.tallyShift = false;
            this.tallyDaily = false;
            this.tallyVesselPerformance = false;
            this.reportName = "Tally Sheet ";
            break;

          case 'TALLY_SHIFT':
            this.tallyShift = true;
            this.tallySheet = false;
            this.tallyDaily = false;
            this.tallyVesselPerformance = false;
            this.reportName = "Tally Shift ";
            break;

          case 'TALLY_DAILY':
            this.tallyDaily = true;
            this.tallyShift = false;
            this.tallySheet = false;
            this.tallyVesselPerformance = false;
            this.reportName = "Tally Daily ";
            break;

          default: // TALLY_VESSEL_PERFORMANCE
            this.tallyVesselPerformance = true;
            this.tallyShift = false;
            this.tallySheet = false;
            this.tallyDaily = false;
            this.reportName = "Tally Vessel Performance ";
            break;
        }
      },

      closeModal() {

        this.tallyReports = false;
        this.tallySheet = false;
        this.tallyShift = false;
        this.tallyDaily = false;
        this.tallyVesselPerformance = false;
        this.addBalance = false;

      },

      fadeMe: function () {
        if (this.SuccessAlert) {
          this.SuccessAlert = !this.SuccessAlert;
          this.$store.dispatch("tallyshift/successalerting", this.SuccessAlert);

        } else {
          this.ErrorAlert = !this.ErrorAlert;
          this.$store.dispatch("tallyshift/erroralerting", this.ErrorAlert);
        }
      },

      enter: function (el, done) {
        let that = this;
        setTimeout(function () {
          that.SuccessAlert = false;
          that.ErrorAlert = false;
          this.$store.dispatch("tallyshift/erroralerting", that.ErrorAlert);
          this.$store.dispatch("tallyshift/successalerting", that.SuccessAlert);
        }, 3000); // hide the message after 3 seconds
      },
    }

  }
</script>

<style scoped>

  .legend {
    position: relative;
    border: 1px solid #DCDCDC;
    padding: 1.5rem 1rem 1rem 1rem;
    margin-bottom: 1rem;
    border-radius: 3px;
    margin-top: 1rem;
  }

  .legend-title {
    background-color: inherit;
    position: absolute;
    top: -0.6rem;
    padding: 0.2rem 1rem;
    background-color: white;
    color: #d59a18;
  }

  .btn-space {
    margin-right: 10px !important;
  }

  ul.nav-justified {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    background-color: #ededee;
    border-radius: 3px;
  }

  ul.nav-justified li {
    float: left;
  }

  ul.nav-justified li a:hover {
    color: #d59a18;
  }

  ul.nav-justified li a {
    display: block;
    text-align: center;
    padding: 16px 20px 10px 21px;
    text-decoration: none;
    color: blue;
    font-weight: bold;
    text-transform: uppercase;
  }

  .div-table-row {
    padding: 0 15px 0 15px;
  }

  .div-table {
    padding: 9px 8px 9px 8px;
  }

  .div-table-title {
    width: 250px;
    background-color: rgba(153, 186, 221, .5);
    font-weight: normal;
  }

  .div-table-value {
    border: 1px solid #DCDCDC;
    font-weight: 900;
  }

  .custom-btn {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
    padding: 5px 9px 5px 9px !important;
    font-size: 14px !important;
  }

  .btn-blue:hover {
    border-color: #0000ff;
    color: #0000ff;
    background-color: #ffffff;
  }

  .btn-blue:hover:hover #icon-gb, .btn-olive:hover:hover #icon-sr, .btn-success-custom:hover #icon-dr,
  .btn-purple:hover #icon-vpr, .btn-blue-fill:hover #icon-d {

    fill: #000000;
  }

  .btn-blue {
    border-color: #4169e1;
    color: #4169e1;
  }

  .btn-blue-fill {
    background-color: #4169e1;
    opacity: 1;
    color: #FFFFFF;
  }

  .btn-purple:hover {
    border-color: #800080;
    color: #800080;
    background-color: #ffffff;
  }

  .btn-purple {
    border-color: #9932cc;
    color: #9932cc;
  }

  .btn-purple-fill {
    background-color: #9932cc;
    opacity: 1;
    color: #FFFFFF;
  }

  .btn-olive:hover {
    border-color: #4B0082;
    color: #4B0082;
    background-color: #ffffff;
  }

  .btn-olive {
    border-color: #6A5ACD;
    color: #6A5ACD;
  }

  .btn-olive-fill {
    background-color: #6A5ACD;
    opacity: 1;
    color: #FFFFFF;
  }

  .btn-linen:hover {
    border-color: #D2691E;
    color: #D2691E;
    background-color: #ffffff;
  }

  .btn-linen {
    border-color: rgba(210, 105, 30, .5);
    color: rgba(210, 105, 30, .5);
  }

  .btn-linen-fill {
    background-color: rgb(210, 105, 30);
    opacity: 1;
    color: #FFFFFF;
  }

  .btn-success-custom:hover {
    border-color: #008000;
    color: #008000;
    background-color: #ffffff;
  }

  .btn-success-custom {
    border-color: rgba(0, 128, 0, .5);
    color: rgba(0, 128, 0, .5);
  }

  .btn-success-custom-fill {
    background-color: rgba(0, 128, 0, .8);
    opacity: 1;
    color: #FFFFFF;
  }

  .icons {
    margin-top: 5px;
    fill: #FFFFFF;
    font-size: 14px;
  }

  .category-title {
    background-color: #f2f3f4;
    border-radius: 3px;
    margin-bottom: 10px
  }

  .tally-shift:hover, .cargo-type:hover {
    background-color: #f8f8ff;
    border-radius: 3px;
  }

  .error {
    color: red;
  }

</style>
