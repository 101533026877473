<template>
 <div>
   <card>
     <div class="row" v-if="accountDetails && isRefundData">
       <div class="col-md-12">
         <button class="btn btn-success-custom btn-success-custom-fill mr-2 custom-btn" v-if="refundData.status!=='Approved' && refundData.transactionType.charAt(0)" @click="approveRefund">
           Approve {{refundData.transactionType.charAt(0).toUpperCase() + refundData.transactionType.slice(1)}}
         </button>
         <button @click.prevent="openPDAPdfViewer('view')"
                 class="btn btn-success-custom btn-success-custom-fill mr-2 custom-btn">
           Download PDA
         </button>
       </div>
     </div>
   </card>
   <card>
    <div class="legend">
      <h6 class="legend-title">
        CREDIT/DBIT Details
      </h6>
      <div class="row div-table-row mb-2">
        <div class="col-md-2 div-table div-table-title mb-2">
          Transaction Type
        </div>
        <div class="col-md-2 div-table div-table-value mb-2">
          {{ refundData.transactionType.toUpperCase() }}
        </div>
        <div class="col-md-4 div-table div-table-title mb-3">
          Status
        </div>
        <div class="col-md-4 div-table div-table-value mb-2">
          {{ refundData.status.toUpperCase() }}
        </div>
        <div class="col-md-3 div-table div-table-title mb-2">
          Amount
        </div>
        <div class="col-md-2 div-table div-table-value mb-2">
          {{ refundData.total }}
        </div>
        <div class="col-md-3 div-table div-table-title mb-2">
          PaymentMethod
        </div>
        <div class="col-md-4 div-table div-table-value mb-2">
          {{ refundData.paymentMethod }}
        </div>
        <div class="col-md-2 div-table div-table-title mb-2">
          Reference Number
        </div>
        <div class="col-md-2 div-table div-table-value mb-2">
          {{ refundData.referenceNo }}
        </div>
        <div class="col-md-2 div-table div-table-title mb-2">
          GEPG control Number
        </div>
        <div class="col-md-6 div-table div-table-value mb-2">
          {{ refundData.gepgControlNo }}
        </div>
        <div class="col-md-4 div-table div-table-title mb-2">
          BANK Reference
        </div>
        <div class="col-md-2 div-table div-table-value mb-2">
          {{ refundData.bankReference }}
        </div>
        <div class="col-md-4 div-table div-table-title mb-2">
          Invoice Number
        </div>
        <div class="col-md-2 div-table div-table-value mb-2">
          {{ refundData.invoiceNo }}
        </div>
        <div class="col-md-2 div-table div-table-title mb-2">
          Recipient
        </div>
        <div class="col-md-10 div-table div-table-value mb-2">
          {{ refundData.recipient }}
        </div>
        <div class="col-md-2 div-table div-table-title mb-2">
          Created By
        </div>
        <div class="col-md-2 div-table div-table-value mb-2">
          {{ refundData.generatedBy }}
        </div>
        <div class="col-md-2 div-table div-table-title mb-2">
          Created Date
        </div>
        <div class="col-md-2 div-table div-table-value mb-2">
          {{ refundData.generatedDate }}
        </div>
        <div class="col-md-2 div-table div-table-title mb-2">
          Approved By
        </div>
        <div class="col-md-2 div-table div-table-value mb-2">
          {{ refundData.approvedBy }}
        </div>
        <div class="col-md-2 div-table div-table-title mb-2">
          Approved Date
        </div>
        <div class="col-md-10 div-table div-table-value mb-2">
          {{ dateTimeToHuman(refundData.approveDate) }}
        </div>
        <div class="col-md-2 div-table div-table-title mb-2">
          Description
        </div>
        <div class="col-md-2 div-table div-table-value mb-2">
          {{ refundData.description }}
        </div>
      </div>
    </div>
<!--     <div class="legend">-->
<!--       <h6 class="legend-title">-->
<!--         CREDIT/DBIT Attachments-->
<!--       </h6>-->
<!--     </div>-->
   </card>
   <card>
     <div class="row">
       <div class="col-md-12">
         <div class="legend">
           <sub-disbursement-deatils :account-details="disbursementData"></sub-disbursement-deatils>
         </div>
       </div>
     </div>
     <div class="row">
       <div class="col-md-12">
         <div class="legend">
           <h6 class="legend-title">
             Recent Proforma Transactions
           </h6>
           <transactions :reference-id="disbursementData.invoiceNo" v-if="accountDetails"></transactions>
         </div>
       </div>
     </div>
   </card>
   <global-modal v-if="globalLoader">
     <div slot="globalLoader">
       <div class="row text-center">
         <div class="col-md-12">Preparing requested file, Please wait</div>
         <div class="col-md-12">
           <br/>
         </div>
         <div class="col-md-12">
           <pulse-loader :color="color" :size="size"></pulse-loader>
         </div>
       </div>
     </div>
   </global-modal>

   <pdf-viewer :mySrc="scr" v-if="showPdfViewer" :maxWidth="1800" @closePdfViewer="closePdfViewer"
               @downloadPdf="downloadPdf"></pdf-viewer>
 </div>

</template>

<script>
  import AGENCYAPI from "../../../api/agency";
  import subDisbursementDeatils from "../disbursement/sub-disbursment-account/subDisbursmentDetails";
  import transactions from "../disbursement/sub-disbursment-account/sub-disbersmentTransactions";
  import PdfViewer from '../../../components/pdfViewer.vue';
  import GlobalModal from "../../../components/globalLoader"
  import PulseLoader from 'vue-spinner/src/PulseLoader.vue'


  import {mapActions, mapGetters} from "vuex";
    export default {
        name: "principalRefundsManage",

      created() {
        this.$store.dispatch('pagenames/currentPageName', 'Principal Credit/Debit Details');

        this.getRefundById();
      },

      components:{
        subDisbursementDeatils,
        transactions,
        PdfViewer,
        GlobalModal,
        PulseLoader
      },

      computed: {

        ...mapGetters('global', [

          'getGlobalLoader',
          'getFileName'

        ]),

      },

      data(){

          return{
            refundData:{},
            accountDetails:false,
            isRefundData:false,
            disbursementData:{},
            color: 'green',
            size: '12px',
            loading:false,
            scr: '',
            showPdfViewer:false,
            globalLoader:false,
          }
      },

      methods:{


        ...mapActions('global', [

          'downloadBlobDocument'

        ]),

        approveRefund(){
          this.loading=true;

          AGENCYAPI.approveRefund(this.$route.params.refundId).then((resp)=>{
            this.loading=false;
            this.loadNotification('success', 4000, 'Refund Approval',
              'Refund Approval',
              'response', 1000 , true  , true);
            this.getRefundById();

          }).catch((err)=>{
            this.loading=false;
            this.loadNotification('error', 4000, 'Refund Approval',
              'Failed To Approve Refund',
              'response', 1000 , true  , true)
          })
        },

        downloadPdf () {

          this.openPDAPdfViewer()

        },

        closePdfViewer () {

          this.showPdfViewer = false
          this.scr = ''

        },


        openPDAPdfViewer (action) {

          this.downloadDaPdf(action).then(() => {

            this.showPdfViewer = true

          }).catch(() => {})

        },

        downloadDaPdf(action) {

          return new Promise((resolve, reject) => {

            this.loadDownloadDocumentParams('pdf').then(() => {

              this.downloadBlobDocument({action}).then((response) => {

                if(response[1].status === 204) {

                  this.loadNotification('warn', 4000, '',
                    'File not found, please contact system admin',
                    'response', 1000 , true  , true)

                } else {

                  this.scr = response[0]
                  resolve()


                }

                this.globalLoader = this.getGlobalLoader

              }).catch(() => {

                // this.loadNotification('error', 4000, 'File Download',
                //   'File not downloaded, please check your internet connection',
                //   'response', 1000, true, true);

                this.globalLoader = this.getGlobalLoader

              });

              this.globalLoader = this.getGlobalLoader

            })

          })

        },
        loadDownloadDocumentParams(extension) {

          return new Promise((resolve) => {

            this.$store.dispatch('global/requestid', this.$route.params.subDisbursementId);
            this.$store.dispatch('global/fileNaming', 'PDA-' + this.disbursementData.invoiceNo);
            this.$store.dispatch('global/fileExtensioning', extension);

            resolve();

          })

        },


          getRefundById(){

            this.loading=true;

            AGENCYAPI.getRefundById(this.$route.params.refundId).then((resp)=>{
              this.refundData=resp.data.data;
              this.isRefundData=true;
              this.loadSubDisbursementDetails();
            }).catch((err)=>{
              this.loading=false;
              this.accountDetails=false;
              this.isRefundData=false;
              this.loadNotification('error', 4000, 'Loading Refund',
                'Failed To Load Refund Data',
                'response', 1000 , true  , true)
            })
          },

        loadSubDisbursementDetails() {

          AGENCYAPI.getSubDisbursementAccountById(this.$route.params.subDisbursementId).then((resp)=>{
            this.accountDetails=true;
            this.loading=false;
            this.disbursementData=resp.data.data;

          }).catch((err)=>{
            this.loading=false;
            this.accountDetails=false;
            this.loadNotification('error', 4000, 'Loading Disbursement Data',
              'Failed To Load Disbursement Data',
              'response', 1000 , true  , true)
          })
        },
      }
    }
</script>

<style scoped>
  .legend {
    position: relative;
    border: 1px solid #DCDCDC;
    padding: 1.5rem 1rem 1rem 1rem;
    margin-bottom: 1rem;
    border-radius: 3px;
    margin-top: 1rem;
  }

  .legend-title {
    background-color: inherit;
    position: absolute;
    top: -0.6rem;
    padding: 0.2rem 1rem;
    background-color: white;
    color: #d59a18;
  }

  .div-table-row {
    padding: 0 15px 0 15px;
  }

  .div-table {
    padding: 9px 8px 9px 8px;
  }

  .div-table-title {
    width: 250px;
    background-color: rgba(153, 186, 221, .5);
    font-weight: normal;
  }

  .div-table-value {
    border: 1px solid #DCDCDC;
    font-weight: 900;
  }

  .btn-success-custom:hover {
    border-color: #008000;
    color: #008000;
    background-color: #ffffff;
  }

  .btn-success-custom {
    border-color: rgba(0, 128, 0, .5);
    color: rgba(0, 128, 0, .5);
  }

  .btn-success-custom-fill {
    background-color: rgba(0, 128, 0, .8);
    opacity: 1;
    color: #FFFFFF;
  }

  .btn-purple:hover {
    border-color: #800080;
    color: #800080;
    background-color: #ffffff;
  }

  .btn-purple {
    border-color: #9932cc;
    color: #9932cc;
  }

  .btn-purple-fill {
    background-color: #9932cc;
    opacity: 1;
    color: #FFFFFF;
  }

  .custom-btn {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
    padding: 5px 9px 5px 9px !important;
    font-size: 14px !important;
  }
</style>
