<template>
  <div>
    <card v-if="firstLoader">
      <div class="row">
        <div class="col-md-12 text-center">
          <b>Please wait while system is loading bill of lading details </b>
        </div>
        <div class="col-md-12 text-center">
          <br>
        </div>
        <div class="col-md-12 text-center">
          <pulse-loader
            :color="color"
            :size="size"
          />
        </div>
      </div>
    </card>

    <card style="font-size: 12px" v-if="!firstLoader">

      <div class="col-12 text-center">
        <pulse-loader
          :loading="loading"
          :color="color"
          :size="size"
        />
      </div>

      <div class="row">
        <div class="col-md-12">
          <ul
            class="nav-justified-top"
            style="padding-left: 10px;padding-right: 10px">
            <li>
              <button @click.prevent="downloadDraftBillOfLading"
                      class="btn btn-olive btn-olive-fill mr-2 custom-btn">
                Download Draft B/L
              </button>
            </li>
            <li v-if="billOfLading.blStatus === 'Approved'">
              <button @click.prevent="downloadCopyBillOfLading"
                      class="btn btn-blue btn-blue-fill mr-2 custom-btn">
                Download Copy Non Negotiable B/L
              </button>
            </li>
            <li v-if="billOfLading.blStatus === 'Approved'">
              <button @click.prevent="downloadCoOBillOfLading"
                      class="btn btn-success-custom btn-success-custom-fill mr-2 custom-btn">
                Download Bill of Lading COO
              </button>
            </li>
            <li v-if="billOfLading.blStatus === 'Approved'">
              <button @click.prevent="downloadBillOfLading"
                      class="btn btn-primary mr-2 custom-btn float-right">
                Download Original B/L
              </button>
            </li>
            <li>
              <button @click.prevent="approveBillOfLading"
                      class="btn btn-blue btn-blue-fill mr-2 custom-btn">
                Approve
              </button>
            </li>
            <li>
              <button @click.prevent="chargeBillOfLading"
                      class="btn btn-olive btn-olive-fill mr-2 custom-btn">
                Charge B/L
              </button>
            </li>
          </ul>
        </div>
      </div>

      <br>

      <!--shipping order details-->
      <div class="legend" v-if=" typeof billOfLading!== 'undefined'">
        <h6 class="legend-title">
          Bill Of Lading Details
        </h6>
        <div class="row div-table-row">
          <div class="col-md-2 div-table div-table-title">
            Bill Of Lading No.
          </div>
          <div class="col-md-2 div-table div-table-value">
            {{ billOfLading.billOfLadingNo }}
          </div>
          <div class="col-md-2 div-table div-table-title">
            Exporter Tin
          </div>
          <div class="col-md-2 div-table div-table-value">
            {{ billOfLading.exporterTin }}
          </div>
          <div class="col-md-2 div-table div-table-title">
            Bill Of Lading Status
          </div>
          <div class="col-md-2 div-table div-table-value" style="color: #651fff; ">
            {{ billOfLading.blStatus }}
          </div>

        </div>
        <br>
        <div class="row div-table-row">
          <div class="col-md-1 div-table div-table-title">
            Shipper Name
          </div>
          <div class="col-md-3 div-table div-table-value">
            {{ billOfLading.exporterName }}
          </div>
          <div class="col-md-1 div-table div-table-title">
            Shipper Address
          </div>
          <div class="col-md-4 div-table div-table-value">
            {{ billOfLading.exporterAddress }}
          </div>

          <div class="col-md-1 div-table div-table-title">
            Shipper City
          </div>
          <div class="col-md-2 div-table div-table-value">
            {{ billOfLading.exporterLocationName }}
          </div>
        </div>
        <br>
        <div class="row div-table-row">
          <div class="col-md-1 div-table div-table-title">
            Shipper Zip Code
          </div>
          <div class="col-md-1 div-table div-table-value">
            {{ billOfLading.exporterLocationCode }}
          </div>

          <div class="col-md-1 div-table div-table-title">
            Shipper Country
          </div>
          <div class="col-md-2 div-table div-table-value">
            {{ billOfLading.exporterLocationName }}
          </div>

          <div class="col-md-2 div-table div-table-title">
            Shipper Contact Person
          </div>
          <div class="col-md-5 div-table div-table-value">
            {{ billOfLading.exporterContactName }}
          </div>
        </div>
        <br>
        <div class="row div-table-row">
          <div class="col-md-2 div-table div-table-title ">
            Shipper Phone No.
          </div>
          <div class="col-md-2 div-table div-table-value ">
            {{ billOfLading.exporterTel }}
          </div>

          <div class="col-md-1 div-table div-table-title ">
            Shipper Email
          </div>
          <div class="col-md-4 div-table div-table-value ">
            {{ billOfLading.email }}
          </div>

          <div class="col-md-1 div-table div-table-title">
            House BL
          </div>
          <div class="col-md-2 div-table div-table-value">
            {{ billOfLading.billOfLadingNo }}
          </div>
        </div>
        <div class="row div-table-row mt-3">
          <div class="col-md-1 div-table div-table-title">
            Consignee Name
          </div>
          <div class="col-md-4 div-table div-table-value">
            {{ billOfLading.consigneeName }}
          </div>
          <div class="col-md-1 div-table div-table-title">
            Consignee Tin
          </div>
          <div class="col-md-6 div-table div-table-value">
            {{ billOfLading.consigneeTin }}
          </div>
        </div>
        <div class="row div-table-row mt-3">
          <div class="col-md-2 div-table div-table-title">
            Consignee Address
          </div>
          <div class="col-md-6 div-table div-table-value">
            {{ billOfLading.consigneeAddress }}
          </div>

          <div class="col-md-1 div-table div-table-title">
            Consignee City
          </div>
          <div class="col-md-3 div-table div-table-value">
            {{ billOfLading.consigneeLocationName }}
          </div>
        </div>
        <div class="row div-table-row mt-3">
          <div class="col-md-2 div-table div-table-title">
            Consignee Zip Code
          </div>
          <div class="col-md-1 div-table div-table-value">
            {{ billOfLading.consigneeLocationCode }}
          </div>

          <div class="col-md-2 div-table div-table-title">
            Consignee Country
          </div>
          <div class="col-md-2 div-table div-table-value">
            {{ billOfLading.consigneeLocationName }}
          </div>

          <div class="col-md-2 div-table div-table-title">
            Consignee Contact Person
          </div>
          <div class="col-md-3 div-table div-table-value">
            {{ billOfLading.consigneeContactName }}
          </div>
        </div>
        <div class="row div-table-row mt-3">
          <div class="col-md-2 div-table div-table-title ">
            Consignee Phone No.
          </div>
          <div class="col-md-2 div-table div-table-value ">
            {{ billOfLading.consigneeTel }}
          </div>

          <div class="col-md-1 div-table div-table-title ">
            Consignee Email
          </div>
          <div class="col-md-3 div-table div-table-value ">
            {{ billOfLading.exporterAddress }}
          </div>
          <div class="col-md-1 div-table div-table-title ">
            Consignee Name
          </div>
          <div class="col-md-3 div-table div-table-value ">
            {{ billOfLading.consigneeName }}
          </div>
        </div>

        <div class="row div-table-row mt-3">
          <div class="col-md-1 div-table div-table-title">
            Notify Address
          </div>
          <div class="col-md-8 div-table div-table-value">
            {{ billOfLading.notiyAddress }}
          </div>

          <div class="col-md-1 div-table div-table-title">
            Notify City
          </div>
          <div class="col-md-2 div-table div-table-value">
            {{ billOfLading.notifyLocationName }}
          </div>
        </div>
        <div class="row div-table-row mt-3">
          <div class="col-md-1 div-table div-table-title">
            Notify Zip Code
          </div>
          <div class="col-md-1 div-table div-table-value">
            {{ billOfLading.notiyTin }}
          </div>

          <div class="col-md-1 div-table div-table-title">
            Notify Country
          </div>
          <div class="col-md-2 div-table div-table-value">
            {{ billOfLading.notifyLocationName }}
          </div>

          <div class="col-md-2 div-table div-table-title">
            Notify Contact Person
          </div>
          <div class="col-md-5 div-table div-table-value">
            {{ billOfLading.notifyContactName }}
          </div>
        </div>
        <div class="row div-table-row mt-3">
          <div class="col-md-1 div-table div-table-title ">
            Notify Phone No.
          </div>
          <div class="col-md-2 div-table div-table-value ">
            {{ billOfLading.notiyTel }}
          </div>

          <div class="col-md-1 div-table div-table-title ">
            Notify Email
          </div>
          <div class="col-md-2 div-table div-table-value ">
            {{ billOfLading.notifyAddress }}
          </div>

          <div class="col-md-1 div-table div-table-title ">
            Notify Name
          </div>
          <div class="col-md-3 div-table div-table-value ">
            {{ billOfLading.notiyName }}
          </div>
          <div class="col-md-1 div-table div-table-title ">
            Created Date
          </div>
          <div class="col-md-1 div-table div-table-value">
            {{ billOfLading.createdAt ? dateToHuman(billOfLading.createdAt): '' }}
          </div>
        </div>
      </div>

      <modal v-if="chargeBl">
        <!--<div slot="header" class="modal-title col-md-12">-->
          <!--<div class="row">-->
            <!--<div class="col-md-12">-->
              <!--<h6>-->
                <!--<b>Charge Bill Of Lading</b>-->
              <!--</h6>-->
            <!--</div>-->
            <!--<div class="col-md-12">-->
              <!--<hr>-->
            <!--</div>-->
          <!--</div>-->
        <!--</div>-->
        <div slot="body">
            <charge-bill-of-lading :billOfLading="billOfLading" @closeModal="closeModal"></charge-bill-of-lading>
        </div>
      </modal>

    </card>

  </div>
</template>


<script>
  import PulseLoader from 'vue-spinner/src/PulseLoader';
  import GlobalModal from '../../../components/globalLoader'
  import AGENCY_API from '../../../api/agency'
  import ChargeBillOfLading from "./chargeBillOfLading";
  import Modal from '../../../components/Inputs/Modal';

  export default {
    name: "billOfLadingDetails",
    components: {
      ChargeBillOfLading,
      PulseLoader,
      GlobalModal,
      Modal
    },

    data() {
      return {
        color: 'green',
        size: '12px',
        id: '',
        billOfLading: {},
        firstLoader: true,
        loading: false,
        chargeBl: false
      }
    },

    created() {
      this.id = this.$route.params.id;
      this.getBillOfLading()
    },

    methods: {
      getBillOfLading() {
        AGENCY_API.getBillOfLading(this.id).then(response => {
          if (response.data.status) {
            this.firstLoader = false;
            this.billOfLading = response.data.data;
          }
        })
      },

      extractPdf: function (resp) {
        const blobUrl = window.URL.createObjectURL(new Blob([resp.data], {type: 'application/pdf'}));
        const link = document.createElement('a');
        link.href = blobUrl;
        link.download = "Bill_of_lading.pdf";
        document.body.appendChild(link);
        link.click();
      },


      downloadBillOfLading() {
        this.loading = true;
        AGENCY_API.printBillofLading(this.id).then((resp) => {
          this.loading = false;
          this.extractPdf(resp);
        }).catch((error) => {
          this.loading = false;
          this.notifyUser('error', 'Error', 'Failed to Download ')
        });
      },

      downloadDraftBillOfLading() {
        this.loading = true;
        AGENCY_API.printDraftBillofLading(this.id).then((resp) => {
          this.loading = false;
          this.extractPdf(resp);
        }).catch((error) => {
          this.loading = false;
          this.notifyUser('error', 'Error', 'Failed to Download ')
        });
      },

      downloadCopyBillOfLading() {
        this.loading = true;
        AGENCY_API.printCopyBillofLading(this.id).then((resp) => {
          this.loading = false;
          this.extractPdf(resp);
        }).catch((error) => {
          this.loading = false;
          this.notifyUser('error', 'Error', 'Failed to Download ')
        });
      },

      downloadCoOBillOfLading() {
        this.loading = true;
        AGENCY_API.printCoOBillofLading(this.id).then((resp) => {
          this.loading = false;
          this.extractPdf(resp);
        }).catch((error) => {
          this.loading = false;
          this.notifyUser('error', 'Error', 'Failed to Download ');
        });
      },

      approveBillOfLading() {
        this.loading = true;
        if (this.billOfLading.blStatus !== "Approved") {
          AGENCY_API.updateBillOfLadings(this.id, "APPROVED").then((resp) => {
            this.loading = false;
            //this.extractPdf(resp);
            this.notifyUser('success', 'Success', 'Successfully approved bill of lading');
            this.getBillOfLading();
          }).catch((error) => {
            this.loading = false;
            this.notifyUser('error', 'Error', error);
          });
        } else {
          this.notifyUser('success', 'Success', 'Bill of lading Approved');
          this.loading = false;
        }
      },

      chargeBillOfLading() {
        this.chargeBl = true;
      },

      closeModal(value) {
        console.log("clicked");
        this.chargeBl = value;
      }
    }
  }
</script>

<style scoped>

  .custom-btn-all {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
    padding: 5px 9px 5px 9px !important;
    font-size: 14px !important;
    width: auto;
  }

  .modal-mask {
    position: fixed;
    z-index: 4910;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .8);
    display: table;
    transition: opacity .3s ease;
    overflow-y: auto;
  }

  .medium-modal-dialog {
    max-width: calc(80vw) !important;
  }

  tbody tr {
    color: #212529 !important;
    font-weight: 400 !important;
  }

  thead th {
    font-weight: 600 !important;
  }

  .legend {
    position: relative;
    border: 1px solid #DCDCDC;
    padding: 1.5rem 1rem 1rem 1rem;
    margin-bottom: 1rem;
    border-radius: 3px;
    margin-top: 1rem;
  }

  .legend-title {
    background-color: inherit;
    position: absolute;
    top: -0.6rem;
    padding: 0.2rem 1rem;
    background-color: white;
    color: #d59a18;
  }

  .btn-space {
    margin-right: 10px !important;
  }

  ul.nav-justified-top {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    background-color: #ededee;
    border-radius: 3px;
  }

  ul.nav-justified-top li {
    float: left;
  }

  ul.nav-justified-top li a:hover {
    color: #d59a18;
  }

  ul.nav-justified-top li a {
    display: block;
    text-align: center;
    padding: 16px 20px 10px 21px;
    text-decoration: none;
    color: blue;
    font-weight: bold;
    text-transform: uppercase;
  }

  .text-link {
    color: blue;
  }

  .text-link:hover {
    color: #d59a18;
    cursor: pointer;
  }

  .custom-btn {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
    padding: 5px 9px 5px 9px !important;
    font-size: 14px !important;
  }

  .btn-success-custom:hover {
    border-color: #008000;
    color: #008000;
    background-color: #ffffff;
  }

  .btn-success-custom:hover .file-excel {
    color: green;
  }

  .btn-success-custom {
    border-color: rgba(0, 128, 0, .5);
    color: rgba(0, 128, 0, .5);
  }

  .btn-success-custom-fill {
    background-color: rgba(0, 128, 0, .8);
    opacity: 1;
    color: #FFFFFF;
  }

  .btn-blue:hover {
    border-color: #0000ff;
    color: #0000ff;
    background-color: #ffffff;
  }

  .btn-blue {
    border-color: #4169e1;
    color: #4169e1;
  }

  .btn-blue-fill {
    background-color: #4169e1;
    opacity: 1;
    color: #FFFFFF;
  }

  .btn-olive:hover {
    border-color: #4B0082;
    color: #4B0082;
    background-color: #ffffff;
  }

  .btn-olive:hover .file-pdf {
    color: red;
  }

  .btn-olive {
    border-color: #6A5ACD;
    color: #6A5ACD;
  }

  .btn-olive-fill {
    background-color: #6A5ACD;
    opacity: 1;
    color: #FFFFFF;
  }

  .btn-purple:hover {
    border-color: #800080;
    color: #800080;
    background-color: #ffffff;
  }

  .btn-purple {
    border-color: #9932cc;
    color: #9932cc;
  }

  .btn-purple-fill {
    background-color: #9932cc;
    opacity: 1;
    color: #FFFFFF;
  }

  .btn-linen:hover {
    border-color: #D2691E;
    color: #D2691E;
    background-color: #ffffff;
  }

  .btn-linen {
    border-color: rgba(210, 105, 30, .5);
    color: rgba(210, 105, 30, .5);
  }

  .btn-linen-fill {
    background-color: rgb(210, 105, 30);
    opacity: 1;
    color: #FFFFFF;
  }

  .chargedStatus {
    color: darkgoldenrod;
  }

  .error {
    color: #dc3545;
    margin-left: 2px;
    margin-top: 2px;
  }

  .loader-div {
    line-height: 40px;
  }

  .loader-div-gif {
    line-height: 50px;
  }

  .div-table-row {
    padding: 0 15px 0 15px;
  }

  .div-table {
    padding: 9px 8px 9px 8px;
  }

  .div-table-title {
    width: 250px;
    background-color: rgba(153, 186, 221, .5);
    font-weight: normal;
  }

  .div-table-value {
    border: 1px solid #DCDCDC;
    font-weight: 900;
  }

  label {
    position: relative;
    top: -7px;
    right: 0;
    margin: 0;
    padding: 0;
  }

  .special-bottom {
    margin-bottom: -1px;
  }

  ul.nav-justified {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    /*background-color: #ededee;*/
    border-radius: 3px;
  }

  ul.nav-justified li {
    float: left;
    border-top: 1px solid #DCDCDC;
    border-left: 1px solid #DCDCDC;
    border-right: 1px solid #DCDCDC;
    border-bottom: none !important;
    margin-right: 15px;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
  }

  ul.nav-justified li a {
    display: block;
    text-align: center;
    padding: 8px 15px 8px 15px !important;
    width: auto;
    text-decoration: none;
    color: #000000;
    font-weight: bold;
    text-transform: uppercase;
  }

  ul.nav-justified li :hover {
    color: #d59a18;
    padding: 16px 20px 10px 21px;
    background-color: #ffffff;
    cursor: pointer;
  }

  .tab-content {
    border-top: 1px solid #DCDCDC;
    border-radius: 5px;
  }


  .is-active-containers, .is-active-containers :hover {
    color: #ffffff !important;
    padding: 0 0 2px 0;
    background-color: green !important;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
  }


  .is-active-waiting-control-number, .is-active-waiting-control-number :hover {
    color: #ffffff !important;
    padding: 0 0 2px 0;
    background-color: #DCDCDC !important;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
  }

  .is-active-consignments, .is-active-consignments:hover {
    color: #ffffff !important;
    padding: 0 0 2px 0;
    background-color: #3498db !important;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
  }

  .align-center-flex {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    flex-grow: 1;
  }

  .avg-width {
    width: 50vw !important;
  }
</style>
