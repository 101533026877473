<template>
  <div>
    <transition
      name="addItemModal col-md-12"
      v-if="showConsignmentDetails"
    >
      <div>
        <div
          class="modal  modal-mask"
          style="display: block"
        >
          <div
            class="modal-dialog medium-modal-dialog"
            role="document"
          >
            <div class="modal-content col-md-12">
              <div class="modal-header col-md-12">
                <div class="modal-title col-md-12">
                  <div class="row">

                    <div class="col-md-12">
                      <b> Consignment Details</b>
                    </div>
                    <div class="col-md-12">
                      <hr>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="modal-body col-md-12">
                  <!--  VIEW BILL OF LADING DETAILS  -->
                  {{consignmentDetails}}
                  <card>
                    <div class="row div-table-row">
                      <div class="col-md-3 div-table-title">
                        Master Bill Of Lading
                      </div>
                      <div class="col-md-3 div-table-value">
                        {{consignmentDetails.masterBillOfLading}}
                      </div>

                      <div class="col-md-3 div-table-title">
                        Port of Origin
                      </div>
                      <div class="col-md-3 div-table-value">
                        {{consignmentDetails.portOfOrigin}}
                      </div>
                    </div>
                    <br>
                    <div class="row div-table-row">
                      <div class="col-md-3 div-table-title">
                        Port of Loading
                      </div>
                      <div class="col-md-3 div-table-value">
                        {{consignmentDetails.portOfLoading}}
                      </div>
                      <div class="col-md-3 div-table-title">
                        Cargo Classfication
                      </div>
                      <div class="col-md-3 div-table-value">
                        {{consignmentDetails.cargoClassification}}
                      </div>
                    </div>
                    <br>
                    <div class="row div-table-row">
                      <div class="col-md-3 div-table-title">
                        Place of Delivery
                      </div>
                      <div class="col-md-3 div-table-value">
                        {{consignmentDetails.placeOfDelivery}}
                      </div>

                      <div class="col-md-3 div-table-title">
                        Place of Destination
                      </div>
                      <div class="col-md-3 div-table-value">
                        {{consignmentDetails.placeOfDestination}}
                      </div>
                    </div>
                    <br>
                    <div class="row div-table-row" v-if="consignmentDetails.consigneeName">
                      <div class="col-md-3 div-table-title">
                        Consignee Name
                      </div>
                      <div class="col-md-3 div-table-value">
                        {{ consignmentDetails.consigneeName}}
                      </div>

                      <div class="col-md-3 div-table-title">
                        Consignee Telephone
                      </div>
                      <div class="col-md-3 div-table-value">
                        {{ consignmentDetails.consigneeTel}}
                      </div>
                    </div>
                    <br v-if="consignmentDetails.consigneeName">
                    <div class="row div-table-row" v-if="consignmentDetails.consigneeName">
                      <div class="col-md-3 div-table-title">
                        Consignee Location
                      </div>
                      <div class="col-md-3 div-table-value">
                        {{ consignmentDetails.consigneeLocationName}}
                      </div>
                      <div class="col-md-3 div-table-title">
                        Consignee TIN.
                      </div>
                      <div class="col-md-3 div-table-value">
                        {{ consignmentDetails.consigneeTin}}
                      </div>
                    </div>
                    <br v-if="consignmentDetails.consigneeName">
                    <div class="row div-table-row">
                      <div class="col-md-3 div-table-title">
                        Consignee Address
                      </div>
                      <div class="col-md-9 div-table-value">
                        {{ consignmentDetails.consigneeAddress}}
                      </div>

                    </div>
                    <br v-if="consignmentDetails.consignorName">
                    <div class="row div-table-row" v-if="consignmentDetails.consignorName">
                      <div class="col-md-3 div-table-title">
                        Consignor Name
                      </div>
                      <div class="col-md-3 div-table-value">
                        {{ consignmentDetails.consignorName}}
                      </div>

                      <div class="col-md-3 div-table-title">
                        Consignor Tel.
                      </div>
                      <div class="col-md-3 div-table-value">
                        {{ consignmentDetails.consignorTel}}
                      </div>
                    </div>
                    <br v-if="consignmentDetails.consignorName">
                    <div class="row div-table-row" v-if="consignmentDetails.consignorName">
                      <div class="col-md-3 div-table-title">
                        Consignor Location
                      </div>
                      <div class="col-md-3 div-table-value">
                        {{ consignmentDetails.consignorLocationName}}
                      </div>
                      <div class="col-md-3 div-table-title">
                        Consignor TIN.
                      </div>
                      <div class="col-md-3 div-table-value">
                        {{ consignmentDetails.consignorTin}}
                      </div>
                    </div>
                    <br v-if="consignmentDetails.consignorName">
                    <div class="row div-table-row" v-if="consignmentDetails.consignorName">
                      <div class="col-md-3 div-table-title">
                        Consignor Address
                      </div>
                      <div class="col-md-9 div-table-value">
                        {{ consignmentDetails.consignorAddress}}
                      </div>

                    </div>
                    <br v-if="consignmentDetails.notifyName">

                    <div class="row div-table-row" v-if="consignmentDetails.notifyName">
                      <div class="col-md-3 div-table-title">
                        Notify Party Name
                      </div>
                      <div class="col-md-3 div-table-value">
                        {{ consignmentDetails.notifyName}}
                      </div>

                      <div class="col-md-3 div-table-title">
                        Notify Party Tel.
                      </div>
                      <div class="col-md-3 div-table-value">
                        {{ consignmentDetails.notifyTel}}
                      </div>
                    </div>
                    <br v-if="consignmentDetails.notifyName">
                    <div class="row div-table-row" v-if="consignmentDetails.notifyName">
                      <div class="col-md-3 div-table-title">
                        Notify Party Location
                      </div>
                      <div class="col-md-3 div-table-value">
                        {{ consignmentDetails.notifyLocationName}}
                      </div>
                      <div class="col-md-3 div-table-title">
                        Notify Party TIN.
                      </div>
                      <div class="col-md-3 div-table-value">
                        {{ consignmentDetails.notifyTin}}
                      </div>
                    </div>
                    <br v-if="consignmentDetails.notifyName">
                    <div class="row div-table-row" v-if="consignmentDetails.notifyName">
                      <div class="col-md-3 div-table-title">
                        Notify Party Address
                      </div>
                      <div class="col-md-9 div-table-value">
                        {{ consignmentDetails.notifyAddress}}
                      </div>
                    </div>
                    <br v-if="consignmentDetails.exporterName">
                    <div class="row div-table-row" v-if="consignmentDetails.exporterName">
                      <div class="col-md-3 div-table-title">
                        Exporter Name
                      </div>
                      <div class="col-md-3 div-table-value">
                        {{ consignmentDetails.exporterName}}
                      </div>
                      <div class="col-md-3 div-table-title">
                        Exporter Tel.
                      </div>
                      <div class="col-md-3 div-table-value">
                        {{ consignmentDetails.exporterTel}}
                      </div>
                    </div>
                    <br v-if="consignmentDetails.exporterName">
                    <div class="row div-table-row" v-if="consignmentDetails.exporterName">
                      <div class="col-md-3 div-table-title">
                        Exporter Location
                      </div>
                      <div class="col-md-3 div-table-value">
                        {{ consignmentDetails.exporterLocationName}}
                      </div>
                      <div class="col-md-3 div-table-title">
                        Exporter TIN.
                      </div>
                      <div class="col-md-3 div-table-value">
                        {{ consignmentDetails.exporterTin}}
                      </div>
                    </div>
                    <br v-if="consignmentDetails.exporterName">
                    <div class="row div-table-row" v-if="consignmentDetails.exporterName">
                      <div class="col-md-3 div-table-title">
                        Exporter Address
                      </div>
                      <div class="col-md-9 div-table-value">
                        {{ consignmentDetails.exporterAddress}}
                      </div>
                    </div>
                    <br v-if="!consignmentDetails.forwarderName">
                    <div class="row div-table-row" v-if="!consignmentDetails.forwarderName">
                      <div class="col-md-3 div-table-title">
                        Forwarder Name
                      </div>
                      <div class="col-md-3 div-table-value">
                        {{ consignmentDetails.forwarderName}}
                      </div>

                      <div class="col-md-3 div-table-title">
                        Forwarder Tel.
                      </div>
                      <div class="col-md-3 div-table-value">
                        {{ consignmentDetails.forwarderTel}}
                      </div>
                    </div>
                    <br v-if="!consignmentDetails.forwarderName">
                    <div class="row div-table-row" v-if="!consignmentDetails.forwarderName">
                      <div class="col-md-3 div-table-title">
                        Forwarder Location
                      </div>
                      <div class="col-md-3 div-table-value">
                        {{ consignmentDetails.forwarderLocationName}}
                      </div>
                      <div class="col-md-3 div-table-title">
                        Forwarder TIN.
                      </div>
                      <div class="col-md-3 div-table-value">
                        {{ consignmentDetails.forwarderTin}}
                      </div>
                    </div>
                    <br v-if="!consignmentDetails.forwarderName">
                    <div class="row div-table-row" v-if="!consignmentDetails.forwarderName">
                      <div class="col-md-3 div-table-title">
                        Forwarder Address
                      </div>
                      <div class="col-md-9 div-table-value">
                        {{ consignmentDetails.forwarderAddress}}
                      </div>

                    </div>

                    <div class="legend">
                      <h6 class="legend-title"> Goods' Details</h6>
                      <data-table
                        :has-additional-details="true"
                        :has-manage-button="false"
                        :has-search-form="false"
                        :has-view-button="false"
                        :is-pageable="false"
                        :hasFilter="false"
                        :hasPagination="false"
                        :popup-keys="popupKeyDetails"
                        :table-data="consignmentDetails.goodDetails"
                        :table-data-keys="goodsDetailsKeys"
                        :table-headers="goodsDetailsHeaders"
                        :permissions="[ {key: 'searchPermission', value: 'DELIVERY-ODER_REQUEST_ALL_SEARCH_GET'} ]"
                      ></data-table>
                    </div>
                  </card>
                  <div class="text-center row pt-2">
                    <div class="col-md-12">
                      <button @click="closeModal()" class="btn btn-fill btn-outline-info float-right mb-2 mr-2 custom-btn-all"
                              type="button">
                        Close
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>

    <ul class="nav-justified pt-2 align-center-flex">
      <li>
        <a :class="[ isMainActive('CONSIGNMENTS') ? 'is-active-consignments' : '' ]" @click.prevent="setStatusActive('CONSIGNMENTS')" style="width: 30vw">Consignments</a>
      </li>
      <li>
        <a :class="[ isMainActive('CONTAINERS') ? 'is-active-containers' : '' ]" @click.prevent="setStatusActive('CONTAINERS')" style="width: 30vw">Containers</a>
      </li>
    </ul>
    <br>

    <data-table
      :date-prop="'Created At '"
      :has-manage-button="isManageable"
      :has-search-form="false"
      :has-view-button="isViewable"
      :is-first="isFirst"
      :is-last="isLast"
      :is-pageable="isPageable"
      :hasFilter="false"
      :hasPagination="false"
      :loading-title="currentLoadingTitle"
      :my-first-loader="myFirstLoader"
      :no-data-found="noDataFound"
      :placeholder="'Search by Delivery Order Number'"
      :permissions="[ {key: 'searchPermission', value: 'DELIVERY-ODER_REQUEST_ALL_SEARCH_GET'} ]"
      :table-data="requests"
      :table-data-keys="tableDataKeys"
      :table-headers="tableHeaders"
      :total-elements="totalElements"
      :total-pages="totalPages"
      @goToView="goToView"
    />
      <!-- @goToPage="goToPage"
      @goToView="goToView"
      @onChange="onChange" -->
  </div>

</template>

<script>
  import DataTable from '../../../components/DataTable/dataTable';

  export default {
        name: "consinmentDetails",
    components:{
      DataTable
    },

    created() {
          this.setStatusActive('CONSIGNMENTS');
    },

    props:['manifestDetails'],

    watch: {
      manifestDetails: function(newVal, oldVal) { // watch it
        this.setStatusActive('CONSIGNMENTS');
      }
    },

      data(){
        return {
          isManageable: true,
          isViewable: false,
          pageNo: 0,
          pageSize: 15,
          pageCount: 1,
          totalElements: '',
          totalPages: '',
          isFirst: false,
          isLast: false,
          pageNumber: '',
          myFirstLoader: false,
          isPageable: true,
          currentLoadingTitle: '',
          noDataFound: false,
          currentStatus: 'CONSIGNMENTS',
          requests:[],
          consignmentData: [],
          consignmentDetails: {},
          showConsignmentDetails: false,

          showContainerDetails: false,
          containerDetails: {},
          sealNumberKeys: ['sealNumber', 'sealNumberIssuerName', 'sealNumberIssuerType', 'sealNumberCondition'],
          sealNumberHeaders: ['Seal Number', 'seal No Issuer Name', 'seal No Issuer Type', 'seal No Condition'],

          tableDataKeysConsignments: ['masterBillOfLading', 'consigneeName', 'consignorName', 'notifyName', 'shippingAgentName',],
          tableDataHeadersConsignments: ['Master Bill of Lading', 'Consignee Name', 'Consignor Name', 'Notify Name', 'Shipping Agent Name'],

          tableDataKeysContainers: ['masterBillOfLading', 'containerNo', 'typeOfContainer', 'packageUnit', 'grossWeight', 'numberOfPackage'],
          tableDataHeadersContainers: ['Master Bill of Lading', 'Container No', 'Type of Container', 'Package Unit', 'Gross Weight', 'Number of Packages'],

          tableDataKeys: ['applicationReference', 'senderId', 'transportMeansName', 'controlReferenceNumber', 'messageTypeEnum', 'voyageNumber'],
          tableHeaders: ['TASAC Ref No.', 'Carrier ID', 'Vessel Name', 'Control Reference No.', 'Message Type', 'Voyage Number'],

          goodsDetailsHeaders: ['Container No.', 'Package Quantity', 'package Type', 'Description', 'gross Weight', 'Marks & Numbers'],
          goodsDetailsKeys: ['containerNo', 'packageQuantity', 'packageType', 'description', 'grossWeight', 'marksNumbers'],

          popupKeyDetails: ['packageQuantity', 'packageType', 'grossWeight', 'grossWeightUnit', 'netWeight',
            'netWeightUnit', 'volume', 'volumeUnit', 'length', 'width',
            'height', 'goodsValue', 'goodsValueCurrency', 'marksNumbers', 'vehicleVIN',
            'vehicleModel', 'vehicleMake', 'vehicleOwnDrive', 'dangerousGoodsInformation'],

        }
      },

      methods:{

        setupActiveTab() {
          this.currentLoadingTitle = "Please wait while the system is loading " + this.currentStatus.toLowerCase() + " delivery orders"
          if (this.currentStatus === 'CONSIGNMENTS') {
            this.tableDataKeys = this.tableDataKeysConsignments
            this.tableHeaders = this.tableDataHeadersConsignments
            this.pageSize = 15;
            this.pageNo = 0;
            this.pageNumber = 0
            this.isManageable = false;
            this.isViewable = true;
            this.formatDataForTable();
            this.enablePagination(this.consignmentData)
            this.slicer()
          } else {
            this.tableDataKeys = this.tableDataKeysContainers;
            this.tableHeaders = this.tableDataHeadersContainers;
            this.pageSize = 15;
            this.pageNo = 0;
            this.pageNumber = 0;
            this.isViewable = true;
            this.isManageable = false;

            this.enablePagination(this.containerData);
            this.slicer();

          }
        },

        formatDataForTable() {
          if(this.manifestDetails.length > 0){

          }
          this.consignmentData = this.manifestDetails.manifestBL;
          // Extract good placement from internal array to external good details object
          this.containerData = this.manifestDetails.containers;
          // this.setupActiveTab()
        },

        slicer() {
          let startPoint = this.pageSize * this.pageNo;
          let endPoint = startPoint + this.pageSize;
          if(this.consignmentData){
            if (this.currentStatus === 'CONSIGNMENTS') {
              this.requests = this.consignmentData.slice(startPoint, endPoint);
              this.enablePagination(this.consignmentData)
            } else {
              this.requests = this.containerData.slice(startPoint, endPoint);
              this.enablePagination(this.containerData);
            }
          }

        },

        enablePagination(data) {
          if(data!==undefined){
            this.pageNumber = this.pageNo;
            this.noDataFound = data.length <= 0;
            this.totalElements = data ? data.length : 0;
            this.totalPages = Math.ceil(this.totalElements / this.pageSize);
          }

        },

        isMainActive(statusClass) {
          return this.currentStatus === statusClass;
        },
        setStatusActive(status) {
          this.currentStatus = status;
          this.setupActiveTab();

        },

        goToPage(page) {
          this.pageNo = page
          this.pageNumber = this.pageNo;
          if (this.pageNo === 0) {
            this.isFirst = true
            this.isLast = false
          }
          if (this.pageNo === this.totalPages) {
            this.isFirst = false
            this.isLast = true
          } else {
            this.isLast = false
            this.isFirst = false
          }
          this.slicer()
        },

        goToView(data) {
          if (this.currentStatus === 'CONSIGNMENTS') {
            this.consignmentDetails = data;
            if (data.goodDetails) {
              for (let j = 0; j < data.goodDetails.length; j++) {
                data.goodDetails[j].containerNo = data.goodDetails[j].placements[0]?.containerNo ? data.goodDetails[j].placements[0].containerNo : ''
              }
            }
            this.showConsignmentDetails = true;
          } else {
            this.containerDetails = data;
            this.showContainerDetails = true;
          }
        },

        closeModal(){
          this.showConsignmentDetails = false;
        },

        onChange(event) {
          this.pageSize = event
          this.pageNo = 0
          this.isFirst = true
          this.slicer();
        },

      }
    }
</script>

<style scoped>

  .modal-mask {
    position: fixed;
    z-index: 4910;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .8);
    display: table;
    transition: opacity .3s ease;
    overflow-y: auto;
  }

  .medium-modal-dialog {
    max-width: calc(80vw) !important;
  }

  tbody tr {
    color: #212529 !important;
    font-weight: 400 !important;
  }

  thead th {
    font-weight: 600 !important;
  }

  .legend {
    position: relative;
    border: 1px solid #DCDCDC;
    padding: 1.5rem 1rem 1rem 1rem;
    margin-bottom: 1rem;
    border-radius: 3px;
    margin-top: 1rem;
  }

  .legend-title {
    background-color: inherit;
    position: absolute;
    top: -0.6rem;
    padding: 0.2rem 1rem;
    background-color: white;
    color: #d59a18;
  }

  .btn-space {
    margin-right: 10px !important;
  }

  .custom-btn {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
    padding: 5px 9px 5px 9px !important;
    font-size: 14px !important;
    width: 120px;
    background-color: #FFFFFF !important;
  }

  .chargedStatus {
    color: darkgoldenrod;
  }

  .error {
    color: #dc3545;
    margin-left: 2px;
    margin-top: 2px;
  }

  .loader-div {
    line-height: 40px;
  }

  .loader-div-gif {
    line-height: 50px;
  }

  .div-table-row {
    padding: 0 15px 0 15px;
  }

  .div-table {
    padding: 9px 8px 9px 8px;
  }

  .div-table-title {
    width: 250px;
    background-color: rgba(153, 186, 221, .5);
    font-weight: normal;
  }

  .div-table-value {
    border: 1px solid #DCDCDC;
    font-weight: 900;
  }

  label {
    position: relative;
    top: -7px;
    right: 0;
    margin: 0;
    padding: 0;
  }

  .special-bottom {
    margin-bottom: -1px;
  }




  .legend {
    position: relative;
    border: 1px solid #DCDCDC;
    padding: 1.5rem 1rem 1rem 1rem;
    margin-bottom: 1rem;
    border-radius: 3px;
    margin-top: 1rem;
  }

  .legend-title {
    background-color: inherit;
    position: absolute;
    top: -0.6rem;
    padding: 0.2rem 1rem;
    background-color: white;
    color: #d59a18;
  }

  ul.nav-justified {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    /*background-color: #ededee;*/
    /*border-radius: 3px;*/
    border-bottom: 1px solid #DCDCDC;
  }

  ul.nav-justified li {
    float: left;
    border-top: 1px solid #DCDCDC;
    border-left: 1px solid #DCDCDC;
    border-right: 1px solid #DCDCDC;
    border-bottom: none !important;
    margin-right: 20px;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
  }


  ul.nav-justified li a {
    display: block;
    text-align: center;
    padding: 8px 15px 8px 15px !important;
    width: 224px;
    /*width: 40vw;*/
    text-decoration: none;
    color: #000000;
    font-weight: bold;
    text-transform: uppercase;
  }

  ul.nav-justified li :hover {
    color: #d59a18;
    padding: 16px 20px 10px 21px;
    background-color: #ffffff;
  }

  div.nav-justified {
      list-style-type: none;
      margin: 0;
      padding: 0;
      overflow: hidden;
      /* background-color: #ededee; */
      border-radius: 3px;
      border: 1px solid #ededee;
  }

  div.nav-justified div {
      float: left;
      border: none;
      position: relative;
  }

  div.nav-justified div a {
      display: block;
      text-align: center;
      padding: 16px 0px 10px 0px;
      text-decoration: none;
      color: #000000;
      font-weight: bold;
      text-transform: uppercase;
      font-size: 12px !important;
  }

  div.nav-justified div::after {
      content: '';
      position: absolute;
      width: 0;
      height: 3px;
      left: 50%;
      bottom: 0;
      background-color: #d59a18;
      transition: all ease-in-out .2s;
  }

  div.nav-justified div:hover::after {
      width: 100%;
      left: 0;
  }

  .tab-content {
    border-top: 1px solid #DCDCDC;
    border-radius: 5px;
  }


  .is-active-containers, .is-active-containers :hover {
    color: #ffffff !important;
    padding: 0 0 2px 0;
    background-color: green !important;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
  }


  .is-active-waiting-control-number, .is-active-waiting-control-number :hover {
    color: #ffffff !important;
    padding: 0 0 2px 0;
    background-color: #DCDCDC !important;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
  }

  .is-active-consignments, .is-active-consignments:hover {
    color: #ffffff !important;
    padding: 0 0 2px 0;
    background-color: #3498db !important;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
  }

</style>
