<template>
  <div>
    <table class="table table-striped">
      <thead>
        <tr>
          <th scope="col">
            #
          </th>
          <th scope="col">
            Shipping Order Number
          </th>
          <th scope="col">
            Date Created
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(order,index) in shippingOrders">
          <th scope="row">
            {{ index + 1 }}
          </th>
          <td>{{ order.shippingOrderNo!==null? order.shippingOrderNo:"Not Generated" }}</td>
          <td>{{ order.createdAt!==null? dateToHuman(order.createdAt) :"" }}</td>
          <td>
            <button
              class="btn btn-success"
              @click="selectedShippingOrder(order.id)"
            >
              Manage
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: 'ListShippingOrder',
  // props:["shippingOrders"],

  created () {
  },
  data () {
    return {
      shippingOrders: [
        {
          id: 1,
          customerId: 4678,
          customerCode: null,
          shippingOrderNo: null,
          vessel: null,
          shipperCodeTin: '474747',
          shipperName: 'Deonatus Mwilanga',
          shipperAddress: 'Manzese og',
          shipperCity: 'Dar es Salaam',
          shipperZipCode: '255',
          shipperCountry: 'TANZANIA',
          shipperContactPerson: 'Benja',
          shipperTel: '3277283',
          shipperEmail: 'd@me.com',
          consigneeCodeTin: '987664',
          consigneeName: 'Kindamba',
          consigneeAddress: 'London kwakishua avenue',
          consigneeCity: 'Londo',
          consigneeZipCode: '37783',
          consigneeCountry: 'TANZANIA',
          consigneeContactPerson: 'Ringo',
          consigneeTel: '723723823',
          consigneeEmail: 'k@me.com',
          notiyCodeTin: '783883',
          notiyName: 'Yohana Tubuike',
          notiyAddress: 'New York Ushuani',
          notiyCity: 'New York',
          notiyZipCode: '6774',
          notiyCountry: 'TANZANIA',
          notiyContactPerson: 'Marijani',
          notiyTel: '998774774',
          notiyEmail: 'yohana@gmail.com',
          expectedDepatureDate: '2020-09-08T00:00:00',
          expectedArrivalDate: '2020-09-30T00:00:00',
          termOfDelivery: 'CIP',
          shippingOrderStatus: 'Received',
          shippingOrderContainers: null,
          shippingOrderGoods: null,
          closed: false,
          createdAt: '2020-09-08T13:37:29.968',
          lastUpdatedAt: '2020-09-08T13:37:29.968'
        }
      ]
    }
  },

  methods: {
    dateToHuman (stringDate) {

      if (stringDate !== null || stringDate !== undefined) {
        const splittedDate = stringDate.split('T')
        return splittedDate[0]
      }
    },

    selectedShippingOrder (shippingOrderId) {
      this.$router.push({ name: 'shippingorder', params: { shippingOrderId } })
    }
  }

}
</script>

<style scoped>

</style>
