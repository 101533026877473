<template>
  <div class="col-md-12">
    <h4>Shipping Order/Consignee Details</h4>
    <hr>
    <div class="row">
      <div class="col-md-12 legend">
        <h6 class="legend-title">Details</h6>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="packaging" class="custom-font">Packaging type</label>
              <select class="form-control" id="packaging" v-model="details.containerized">
                <option></option>
                <option :value="true">Container</option>
                <option :value="false">Non Containerized</option>
              </select>
            </div>
          </div>
          <div :class="[details.containerized ? 'col-md-3' : '']" v-if="details.containerized">
            <div class="form-group">
              <label
                for="numberOfContainer"
                class="custom-font"
              >Number Of Container</label>
              <input
                type="text"
                class="form-control"
                id="numberOfContainer"
                v-model="details.numberOfContainer"
              >
            </div>
          </div>
          <div :class="[details.containerized ? 'col-md-3' : 'col-md-6']">
            <div class="form-group">
              <label for="TermOfDelivery" class="custom-font">Freight</label>
              <select class="form-control" id="TermOfDelivery" v-model="details.termOfDelivery">
                <option></option>
                <option value="Prepaid">Prepaid</option>
                <option value="Collect">Collect</option>
                <option value="As Per Charter Party">AS PER CHARTER PARTY</option>
              </select>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label
                for="shipperNameForInvoice"
                class="custom-font"
              >Payer Name</label>
              <input
                type="text"
                class="form-control"
                id="shipperNameForInvoice"
                v-model="details.shipperNameForInvoice"
              >
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label
                for="shipperTelForInvoice"
                class="custom-font"
              >Payer Mobile</label>
              <input
                type="text"
                class="form-control"
                id="shipperTelForInvoice"
                v-model="details.shipperTelForInvoice"
              >
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12 legend">
        <h6 class="legend-title">
          Shippers Details
        </h6>
        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <label
                for="shipperName"
                class="custom-font"
              >Shipper Name</label>
              <input
                type="text"
                class="form-control"
                id="shipperName"
                v-model="details.shipperName"
              >
              <div v-if="shipperNameError">
                <div class="error text-danger">
                  <span v-if="!$v.details.shipperName.required">Field is required</span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group custom-font">
              <label
                for="shipperAddress"
                class="custom-font"
              >Shippers Address</label>
              <input
                type="text"
                class="form-control"
                id="shipperAddress"
                placeholder="Street Address"
                v-model="details.shipperAddress"
              >
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="packaging" class="custom-font">Shippers City</label>
              <input
                type="text"
                class="form-control"
                id="city"
                placeholder="City"
                v-model="details.shipperCity"
              >
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <label for="packaging" class="custom-font">Shippers Zip Code</label>
              <input
                type="text"
                class="form-control"
                id="zip"
                placeholder="Postal/Zip Code"
                v-model="details.shipperZipCode"
              >
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label class="custom-font">Select Country</label>
              <model-list-select :list="countries"
                                 v-model="details.shipperCountry"
                                 option-value="name"
                                 :custom-text="codeAndCountry"
                                 placeholder="select Shipper Country">
              </model-list-select>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="packaging" class="custom-font">Shippers Email</label>
              <input
                type="email"
                class="form-control"
                id="semail"
                placeholder="Email"
                v-model="details.shipperEmail"
              >
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <label for="packaging" class="custom-font">Shippers Telephone Number</label>
              <input
                type="text"
                class="form-control"
                id="phone"
                placeholder="phone"
                v-model="details.shipperTel"
              >
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="packaging" class="custom-font">Shippers Contact Person</label>
              <input
                type="text"
                class="form-control"
                id="contactP"
                placeholder="Contact Person"
                v-model="details.shipperContactPerson"
              >
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="packaging" class="custom-font">Shipper Tin</label>
              <input
                type="text"
                class="form-control"
                id="shipperCodeTin"
                placeholder="Shipper Code Tin"
                v-model="details.shipperCodeTin"
              >
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-12 legend">
        <h6 class="legend-title">
          Consignee Details
        </h6>

        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <label
                for="consigneeName"
                class="custom-font"
              >Consignee Name</label>
              <input
                type="text"
                class="form-control"
                id="consigneeName"
                v-model="details.consigneeName"
              >
              <div v-if="consigneeError">
                <div class="error text-danger">
                  <span v-if="!$v.details.consigneeName.required">Field is required</span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group custom-font">
              <label
                for="consigneeAddress"
                class="custom-font"
              >Consignee Address</label>
              <input
                type="text"
                class="form-control"
                id="consigneeAddress"
                placeholder="Street Address"
                v-model="details.consigneeAddress"
              >
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="packaging" class="custom-font">Consignee City</label>
              <input
                type="text"
                class="form-control"
                id="consigneecity"
                placeholder="City"
                v-model="details.consigneeCity"
              >
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <label for="packaging" class="custom-font">Consignee Zip Code</label>
              <input
                type="text"
                class="form-control"
                id="consigneezip"
                placeholder="Postal/Zip Code"
                v-model="details.consigneeZipCode"
              >
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label class="custom-font">Select Country</label>
              <model-list-select :list="countries"
                                 v-model="details.consigneeCountry"
                                 option-value="name"
                                 :custom-text="codeAndCountry"
                                 placeholder="select Shipper Country">
              </model-list-select>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="packaging" class="custom-font">Consignee Email</label>
              <input
                type="email"
                class="form-control"
                id="consigneeemail"
                placeholder="Email"
                v-model="details.consigneeEmail"
              >
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <label for="packaging" class="custom-font">Consignee Telephone</label>
              <input
                type="text"
                class="form-control"
                id="Consigneephone"
                placeholder="phone"
                v-model="details.consigneeTel"
              >
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="packaging" class="custom-font">Consignee Contact Person</label>
              <input
                type="text"
                class="form-control"
                id="consigneeContactPerson"
                placeholder="Consignee Contact Person"
                v-model="details.consigneeContactPerson"
              >
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="packaging" class="custom-font">Consignee Tin</label>
              <input
                type="text"
                class="form-control"
                id="consigneeCodeTin"
                placeholder="Shipper Code Tin"
                v-model="details.consigneeCodeTin"
              >
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-12 legend">
        <h6 class="legend-title">
          Notify Details
        </h6>

        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <label
                for="consigneeName"
                class="custom-font"
              >Notify Name</label>
              <input
                type="text"
                class="form-control"
                id="notiyName"
                v-model="details.notiyName"
              >
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group custom-font">
              <label
                for="consigneeAddress"
                class="custom-font"
              >Notify Address</label>
              <input
                type="text"
                class="form-control"
                id="notiyAddress"
                placeholder="Street Address"
                v-model="details.notiyAddress"
              >
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label
                for="consigneeName"
                class="custom-font"
              >Notify City</label>
              <input
                type="text"
                class="form-control"
                id="notiyCity"
                placeholder="Notify City"
                v-model="details.notiyCity"
              >
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <label
                for="consigneeName"
                class="custom-font"
              >Notify Tin</label>
              <input
                type="text"
                class="form-control"
                id="notiyZipCode"
                placeholder="Notify Postal/Zip Code"
                v-model="details.notiyZipCode"
              >
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label class="custom-font">Select Notify Country</label>
              <model-list-select :list="countries"
                                 v-model="details.notiyCountry"
                                 option-value="name"
                                 :custom-text="codeAndCountry"
                                 placeholder="select Shipper Country">
              </model-list-select>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label
                for="consigneeName"
                class="custom-font"
              >Notify Email</label>
              <input
                type="email"
                class="form-control"
                id="notiyEmail"
                placeholder="Email"
                v-model="details.notiyEmail"
              >
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <label
                for="notiyTel"
                class="custom-font"
              >Notify Telephone</label>
              <input
                type="text"
                class="form-control"
                id="notiyTel"
                placeholder="phone"
                v-model="details.notiyTel"
              >
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label
                for="consigneeName"
                class="custom-font"
              >Notify Contact Person</label>
              <input
                type="text"
                class="form-control"
                id="notiyContactPerson"
                placeholder="Notify Contact Person"
                v-model="details.notiyContactPerson"
              >
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label
                for="consigneeName"
                class="custom-font"
              >Notify Tin</label>
              <input
                type="text"
                class="form-control"
                id="notiyCodeTin"
                placeholder="Notify Code Tin"
                v-model="details.notiyCodeTin"
              >
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-12 legend">
        <h6 class="legend-title">
          Cargo Details
        </h6>

        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label
                for="shipperName"
                class="custom-font"
              >Cargo Is Ready By</label>
              <br />
              <date-picker style="width:100% !important;"
                           v-model="details.expectedDepatureDate"
                           type="datetime"
                           format="YYYY-MM-DD HH:mm:ss"></date-picker> {{ details.expectedDepatureDate !== null ? details.expectedDepatureDate : '' }}
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label
                for="shipperName"
                class="custom-font"
              >Desired Arrival Date</label>
              <br />
              <date-picker style="width:100% !important;"
                           v-model="details.expectedArrivalDate"
                           type="datetime"
                           format="YYYY-MM-DD HH:mm:ss"></date-picker> <!--{{ details.expectedDepatureDate !== null ? details.expectedDepatureDate.toISOString() : '' }} -->
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label class="custom-font">Select Loading Port</label>
              <model-select :options="SeaPorts"
                            v-model="details.originPort"
                            placeholder="Select Loading Port">
              </model-select>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label class="custom-font">Select Discharge Port</label>
              <model-select :options="SeaPorts"
                            v-model="details.destinationPort"
                            placeholder="Select Discharge Port">
              </model-select>
            </div>
          </div>
          <div class="col-md-12">
            <div class="form-group">
              <label for="destinationPlace">Destination Place</label>
              <input
                type="text"
                class="form-control"
                id="destinationPlace"
                v-model="details.destinationPlace"
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
    import containerSize from "./containerSize";
    import {CARGO_UNITS} from "../../../mixins/constants";
    import {ModelListSelect, ModelSelect} from "vue-search-select";
    import CrudNotification from "../../../components/DataTable/crudNotification";
    import PulseLoader from "vue-spinner/src/PulseLoader";
    import getcountries from "./countries";
    import ports from "./ports";
    import DatePicker from 'vue2-datepicker';
    import 'vue2-datepicker/index.css';

    export default {
        name: "shipOrderSub",

      components: {
        ModelListSelect,
        CrudNotification,
        PulseLoader,
        ModelSelect,
        DatePicker

      },

      created() {
          this.getSeaPorts();
          this.getCountries();
      },

      watch:{
          $data:{
            handler:function (val,oldVal) {
              this.$emit('detailsData',this.details);
            },
            deep:true
          }
      },

      data(){
          return{
            containerSize: containerSize,
            packageTypes:[],
            SeaPorts: [],
            getDataPorts:ports,
            countries: [],
            cargoUnits: CARGO_UNITS,
            selectedPort: "",
            selectedPackageTypeId:"",

            details: {
              packageType:null,
              cityCode: null,
              countryCode: null,
              destinationPort: null,
              originPort: null,
              destinationPlace: null,
              containerized: false,
              containerSize: null,
              shippingOrderNo: null,
              shipperCodeTin: null,
              shipperName: null,
              shipperAddress: null,
              shipperCity: null,
              shipperZipCode: null,
              shipperCountry: null,
              shipperContactPerson: null,
              shipperTel: null,
              shipperEmail: null,
              consigneeCodeTin: null,
              consigneeName: null,
              consigneeAddress: null,
              consigneeCity: null,
              consigneeZipCode: null,
              consigneeCountry: null,
              consigneeContactPerson: null,
              consigneeTel: null,
              consigneeEmail: null,
              expectedDepatureDate: null,
              expectedArrivalDate: null,

              notiyAddress: null,
              notiyCity: null,
              notiyCodeTin: null,
              notiyContactPerson: null,
              notiyCountry: null,
              notiyEmail: null,
              notiyName: null,
              notiyTel: null,
              notiyZipCode: null,
              termOfDelivery: null,
              shippingOrderGoodsDtos: [],
              shipperNameForInvoice: null,
              shipperTelForInvoice: null,

              shipperNameError:false,
              consigneeError:false,
            },

            shipperNameError:false,
            consigneeError:false,
          }
      },

      methods:{

          populateDetails(data){

            this.details=data;
          },

        getContainerName(data){
          return `${data.descriptionofCodeGroup} - ${data.containerCode}`
        },

        getCountries(){

          getcountries.map((country)=>{
            this.countries.push(country);
          })
        },

        codeAndCountry(data){
          return `${data.name}(${data.code})`
        },

        getSeaPorts(){

          this.getDataPorts.map((port)=>{
            this.SeaPorts.push(
              { value: port, text: port}
            );
          })
        },

        changed(e){
          console.log(e);
        },

      }
    }
</script>

<style scoped>

</style>
