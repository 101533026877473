<template>
  <div>
    <card>
      <div class="nav-justified row">
        <div class="col-md-2">
          <a @click.prevent="setActiveOnClick('CREATED')"
              :class="[ isActive('CREATED') ? 'is-active-verified' : '' ]" href="#CREATED">Created</a>
        </div>
        <div class="col-md-2">
          <a @click.prevent="setActiveOnClick('CHECKED')"
              :class="[ isActive('CHECKED') ? 'is-active-waiting-control-number' : '' ]"
              href="#CHECKED">Checked</a>
        </div>
        <div class="col-md-2">
          <a @click.prevent="setActiveOnClick('APPROVED')"
              :class="[ isActive('APPROVED') ? 'is-active-major' : '' ]" href="#APPROVED">Approved</a>
        </div>
        <div class="col-md-2">
          <a @click.prevent="setActiveOnClick('CANCELLED')"
              :class="[ isActive('CANCELLED') ? 'is-active-returned' : '' ]" href="#CANCELLED">Cancelled</a>
        </div>
        <div class="col-md-2">
          <a @click.prevent="setActiveOnClick('ISSUED')"
              :class="[ isActive('ISSUED') ? 'is-active-new' : '' ]" href="#ISSUED">Issued</a>
        </div>
      </div>
      <div class="tab-content py-2" id="myTabContent">
        <div class="tab-pane fade pt-3" :class="{ 'active show': isActive(currentStatus) }" :id="currentStatus">
          <data-table
          :table-headers="tableHeaders"
          :table-data="filteredRequests"
          :table-data-keys="tableDataKeys"
          :my-first-loader="myFirstLoader"
          :page-size-prop="pageSize"
          :page-no="pageNo"
          :is-pageable="isPageable"
          :total-elements="totalElements"
          :total-pages="totalPages"
          :is-first="isFirst"
          :hasFilter="false"
          :is-last="isLast"
          :no-data-found="noDataFound"
          :page-number="pageNumber"
          :is-hovered="isHovered"
          :placeholder="'Search by Delivery Order Number'"
          :permissions="[ {key: 'searchPermission', value: 'BILLS_SEARCH_BILLS_GET'} ]"
          :date-prop="'Created At '"
          :has-manage-button="true"
          :reload-all-request="reloadRequests"
          :loading-title="currentLoadingTitle"
          @showToolTip="showToolTip"
          @hideToolTip="hideToolTip"
          @goToPage="goToPage"
          @searchRequest="searchDONumber"
          @reloadByIcon="reloadByIcon"
          @onChange="onChange"
          @goToApplication="goToApplication"
        >
          <div slot="pagination">
              <paginate
                v-model="getIsPage"
                :page-count=parseInt(totalPages)
                :page-range="3"
                :margin-pages="2"
                :click-handler="goToPage"
                :prev-text="'Prev'"
                :next-text="'Next'"
                :container-class="'pagination'"
                :page-class="'page-item'">
              </paginate>
            </div>
          </data-table>
        </div>
      </div>
      <!-- <ul class="nav-justified">
        <li>
          <a @click.prevent="setActiveOnClick('CREATED')" :class="[ isActive('CREATED') ? 'is-active-shipping-order' : '' ]" >Created</a>
        </li>
        <li>
          <a @click.prevent="setActiveOnClick('CHECKED')" :class="[ isActive('CHECKED') ? 'is-active-verified' : '' ]">Checked</a>
        </li>
        <li>
          <a @click.prevent="setActiveOnClick('APPROVED')" :class="[ isActive('APPROVED') ? 'is-active-major' : '' ]">Approved</a>
        </li>
        <li>
          <a @click.prevent="setActiveOnClick('CANCELLED')" :class="[ isActive('CANCELLED') ? 'is-active-delivery-order' : '' ]">Cancelled</a>
        </li>
        <li>
          <a @click.prevent="setActiveOnClick('ISSUED')" :class="[ isActive('ISSUED') ? 'is-active-issued' : '' ]">Issued</a>
        </li>
      </ul>

      <br>

      <data-table
        :table-headers="tableHeaders"
        :table-data="requests"
        :table-data-keys="tableDataKeys"
        :my-first-loader="myFirstLoader"
        :page-size-prop="pageSize"
        :page-no="pageNo"
        :is-pageable="isPageable"
        :total-elements="totalElements"
        :total-pages="totalPages"
        :is-first="isFirst"
        :is-last="isLast"
        :no-data-found="noDataFound"
        :page-number="pageNumber"
        :placeholder="'Search by Delivery Order Number'"
        :date-prop="'Created At '"
        :has-manage-button="true"
        :reload-all-request="reloadRequests"
        :loading-title="currentLoadingTitle"
        @goToPage="goToPage"
        @searchRequest="searchDONumber"
        @reloadByIcon="reloadByIcon"
        @onChange="onChange"
        @goToApplication="goToApplication"
      /> -->
    </card>

    <global-modal v-if="myLoader">
      <div slot="globalLoader">
        <div class="row text-center">
          <div class="col-md-12">
            Loading, Please wait
          </div>
          <div class="col-md-12">
            <br>
          </div>
          <div class="col-md-12">
            <pulse-loader
              :loading="loading"
              :color="color"
              :size="size"
            />
          </div>
        </div>
      </div>
    </global-modal>
  </div>
</template>

<script>
import PulseLoader from 'vue-spinner/src/PulseLoader';
import GlobalModal from '../../../components/globalLoader';
import DataTable from '../../../components/DataTable/dataTable';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'DeliveryOrderList',
  components: {
    PulseLoader,
    GlobalModal,
    DataTable
  },
  created () {
    this.$store.dispatch('pagenames/currentPageName', 'Delivery Orders');

    this.finalUserPermissions = JSON.parse(localStorage.getItem('browser-local-permits'));
    // this.loadPageableDeliveryOrdersOnCreated(this.pageNo, this.pageSize);
    this.dispatchStatus(this.currentStatus);
    this.loadPageableDeliveryOrdersByStatus();
    this.getCurrentLoadingTitle();
  },
  data () {
    return {
      currentLoadingTitle:'',
      pageNo: 0,
      pageSize: 15,
      totalElements: '',
      totalPages: '',
      isFirst: false,
      isLast: false,
      pageNumber: '',
      isPageable: true,
      requests: [],
      myLoader: false,
      myFirstLoader: false,
      reloadRequests: false,
      tableHeaders: ['BL Number', 'Delivery Order Number',  'Delivery Order Date', 'Agent', 'DO Status'],
      tableDataKeys: ['blNo', 'doNumber', 'doDate', 'cfaAgent', 'status'],
      noDataFound:false,
      isPage: 0,
      currentStatus: 'CREATED',
      isHovered: [],
      filteredRequests: []
    };
  },
  computed: {

    ...mapGetters('deliveryOrder', [

      'getMyLoader',
      'getPageNo',
      'getRequestList',
      'getNoDataFound',
      'getSuccessAlert',
      'getErrorMessage',
      'getErrorAlert',
      'getSuccessMessage',
      'getDeliveryOrderStatus',
      'getMyFirstLoader'

    ]),

    getIsPage: {

      get: function () {

        return this.isPage

      },
      set: function (newValue) {

        this.isPage = newValue;

      }

    }

  },
  methods: {

    ...mapActions('deliveryOrder', [
      'loadAllDeliveryOrder',
      'searchDeliveryOrders',
      'loadAllDeliveryOrdersByStatusPageable'
    ]),

    loadParameters (pageNo, pageSize) {
      return new Promise((resolve) => {
        this.$store.dispatch('deliveryOrder/pageSizing', this.pageSize);
        this.$store.dispatch('deliveryOrder/pageNumbering', this.pageNo);
        resolve();
      });
    },

    showToolTip(object) {

      this.isHovered = [object];
    },

    hideToolTip(object) {

      this.isHovered.splice(this.isHovered.indexOf(object), 1)

    },

    setActiveOnClick(status){
      this.currentStatus = status;
      this.dispatchStatus(status);
      this.pageNo = 0;
      this.pageSize = 15;
      this.loadPageableDeliveryOrdersByStatus()
    },

    isActive(statusClass){

      return this.currentStatus === statusClass
    },

    dispatchStatus(status){
      return new Promise((resolve ) =>{
        this.$store.dispatch('deliveryOrder/deliveryOrderStatusValue', status)
        resolve();
      })
    },

    getCurrentLoadingTitle(){
      this.currentLoadingTitle =  "Please wait while the system is loading " + this.currentStatus.toLowerCase() + " delivery orders"
    },

    sortingData(data) {

      for(let x = 0 ; x < data.length ; x++) {

        const requestObject = {
          ...data[x],
          cfaAgent: data[x].agent,
          doDate: this.dateTimeToHuman(data[x].doDate)
          }

          this.filteredRequests.push(requestObject)

      }

    },

    loadPageableDeliveryOrdersByStatus(){

      this.getCurrentLoadingTitle()
      this.myFirstLoader = true
      this.filteredRequests = []
      this.loadParameters().then(()=>{
        this.loadAllDeliveryOrdersByStatusPageable({}).then(()=>{
          this.myFirstLoader = this.getMyFirstLoader;
          // this.requests = this.extractAgentFromObject(JSON.parse(JSON.stringify(this.getRequestList.content)));
          this.requests = this.getRequestList.content
          this.sortingData(this.requests)
          this.isLast = this.getRequestList.last;
          this.totalElements = this.getRequestList.totalElements;
          this.totalPages = this.getRequestList.totalPages;
          this.pageNumber = this.getRequestList.number;
          this.isFirst = this.getRequestList.first;
          this.isLast = this.getRequestList.last;
          this.noDataFound = this.getRequestList.empty;
        }).catch(()=>{

        });

      });
    },

    loadPageableDeliveryOrdersOnCreated (pageNo, pageSize) {

      this.filteredRequests = []

      this.loadParameters(pageNo, pageSize).then(() => {
        this.myFirstLoader = true;
        this.loadAllDeliveryOrder({}).then(() => {
          this.myFirstLoader = this.getMyFirstLoader;
          // this.requests = this.extractAgentFromObject(JSON.parse(JSON.stringify(this.getRequestList.content)));
          this.requests = this.getRequestList.content
          this.sortingData(this.requests)
          this.isLast = this.getRequestList.last;
          this.totalElements = this.getRequestList.totalElements;
          this.totalPages = this.getRequestList.totalPages;
          this.pageNumber = this.getRequestList.number;
          this.isFirst = this.getRequestList.first;
          this.isLast = this.getRequestList.last;
          this.noDataFound = this.getNoDataFound;
        }).catch(() => {

        });
      });
    },
    /**
     * Helper function to parse date and extract agent name from agent object
    **/
    extractAgentFromObject (requestList) {
      for (let i = 0; i < requestList.length; i++) {
        requestList[i].doDate = this.dateTimeToHuman(requestList[i].doDate);
        requestList[i].expireDate = this.dateTimeToHuman(requestList[i].expireDate);
        requestList[i].agentName = requestList[i].agent.agentName;
      }
      return requestList;
    },
    onChange (event) {
      this.pageSize = event;
      this.pageNo = 0;
      this.loadPageableDeliveryOrdersByStatus();
    },
    goToPage (page) {
      this.pageNo = page;
      this.loadPageableDeliveryOrdersByStatus();
    },
    searchDONumber (searchParam) {
      // this.requests = [];
      if (searchParam !== null && searchParam !== 'undefined') {
        this.$store.dispatch('deliveryOrder/searchingParameter', searchParam);
        this.searchDeliveryOrders({}).then(() => {
          this.requests = this.extractAgentFromObject(JSON.parse(JSON.stringify([this.getRequestList])));
          this.isPageable = false;
        }).catch(() => {

        });
        this.reloadRequests = true;
      }
    },
    reloadByIcon () {
      this.reloadRequests = false;
      this.isPageable = true;
      this.requests = [];
      this.loadPageableDeliveryOrdersByStatus();
    },
    goToApplication (data) {
      this.$router.push('/shipping-agency/delivery-orders/order-details/' + data.deliveryOderId);
    }
  }

};
</script>

<style scoped>
  /* ul.nav-justified {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    border-radius: 3px;
  }

  ul.nav-justified li {
    float: left;
    border-top: 1px solid #DCDCDC;
    border-left: 1px solid #DCDCDC;
    border-right: 1px solid #DCDCDC;
    border-bottom: none !important;
    margin-right: 20px;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
  }


  ul.nav-justified li a {
    display: block;
    text-align: center;
    padding: 8px 15px 8px 15px !important;
    width: calc((100vw - 25vw) / 5);
    text-decoration: none;
    color: #000000;
    font-weight: bold;
    text-transform: uppercase;
  }

  ul.nav-justified li :hover {
    color: #d59a18;
    padding: 16px 20px 10px 21px;
    background-color: #ffffff;
  } */

  /* .tab-content {
    border-top: 1px solid #DCDCDC;
    border-radius: 5px;
  }

  .is-active-new, .is-active-new :hover {
    color: #ffffff !important;
    padding: 0 0 2px 0;
    background-color: gray !important;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
  }

  .is-active-verified, .is-active-verified :hover {
    color: #ffffff !important;
    padding: 0 0 2px 0;
    background-color: #3498db !important;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
  }

  .is-active-major, .is-active-major :hover {
    color: #ffffff !important;
    padding: 0 0 2px 0;
    background-color: #2F4F4F !important;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
  }

  .is-active-minor, .is-active-minor :hover {
    color: #ffffff !important;
    padding: 0 0 2px 0;
    background-color: green !important;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
  }

  .is-active-returned, .is-active-returned :hover {
    color: #ffffff !important;
    padding: 0 0 2px 0;
    background-color: red !important;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
  }

  .is-active-waiting-control-number , .is-active-waiting-control-number :hover {
    color: #ffffff !important;
    padding: 0 0 2px 0;
    background-color: #DCDCDC !important;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
  }

  .is-active-shipping-order , .is-active-shipping-order:hover {
    color: #ffffff !important;
    padding: 0 0 2px 0;
    background-color: #556b2f !important;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
  }

  .is-active-delivery-order , .is-active-delivery-order:hover {
    color: #ffffff !important;
    padding: 0 0 2px 0;
    background-color: #d2b48c !important;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
  }

  .is-active-issued,.is-active-issued:hover {
    color: #ffffff !important;
    padding: 0 0 2px 0;
    background-color: blueviolet !important;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
  } */

  .rejected{
    color: red;
  }

  div.nav-justified {
      list-style-type: none;
      margin: 0;
      padding: 0;
      overflow: hidden;
      /* background-color: #ededee; */
      border-radius: 3px;
      border: 1px solid #ededee;
  }

  div.nav-justified div {
      float: left;
      border: none;
      position: relative;
  }

  div.nav-justified div a {
      display: block;
      text-align: center;
      padding: 16px 0px 10px 0px;
      text-decoration: none;
      color: #000000;
      font-weight: bold;
      text-transform: uppercase;
      font-size: 12px !important;
  }

  div.nav-justified div::after {
      content: '';
      position: absolute;
      width: 0;
      height: 3px;
      left: 50%;
      bottom: 0;
      background-color: #d59a18;
      transition: all ease-in-out .2s;
  }

  div.nav-justified div:hover::after {
      width: 100%;
      left: 0;
  }

  .is-active-verified {
    box-shadow: inset 0 -3px 0 0 #3498db;
    color: #3498db !important;
  }
  .is-active-waiting-control-number {
    box-shadow: inset 0 -3px 0 0 #d59a18;
    color: #d59a18 !important;
  }
  .is-active-major {
    box-shadow: inset 0 -3px 0 0 #2F4F4F;
    color: #2F4F4F !important;
  }
  .is-active-minor {
    box-shadow: inset 0 -3px 0 0 green;
    color: green !important;
  }
  .is-active-returned {
    box-shadow: inset 0 -3px 0 0 red;
    color: red !important;
  }
  .is-active-new {
    box-shadow: inset 0 -3px 0 0 gray;
    color: gray !important;
  }
  .is-active-all {
    box-shadow: inset 0 -3px 0 0 brown;
    color: brown !important;
  }

  .tab-content {
    /* border-top: 1px solid #DCDCDC; */
    border-radius: 5px;
  }
</style>
