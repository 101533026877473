<template>

  <div>
    <crud-notification
      :error-alert="ErrorAlert"
      :error-message="ErrorMessage"
      :success-alert="SuccessAlert"
      :success-message="SuccessMessage"
      @enter="enter"
      @fadeMe="fadeMe"
    />
    <card>
      <div class="row">
        <div class="col-md-12">
          <button
            @click.prevent="openModal('ADD')"
            class="btn btn-success mr-2 custom-btn"
            style="float:right;"
          >
            <b>Add Tally Tariff Configuration</b>
          </button>

        </div>
      </div>
      <br>
      <data-table
        :date-prop="'Created At '"
        :has-delete-button="true"
        :has-edit-button="true"
        :has-manage-button="false"
        :has-view-button="false"
        :is-first="isFirst"
        :is-last="isLast"
        :is-pageable="isPageable"
        :loading-title="'Please wait while the system is loading Tariff Configurations'"
        :my-first-loader="myFirstLoader"
        :no-data-found="noDataFound"
        :page-no="pageNo"
        :page-number="pageNumber"
        :page-size-prop="pageSize"
        :has-search-form="false"
        :reload-all-request="reloadAllRequest"
        :table-data="requests"
        :table-data-keys="tableDataKeys"
        :table-headers="tableHeaders"
        :total-elements="totalElements"
        :total-pages="totalPages"
        @goToDelete="goToDelete"
        @goToEdit="goToEdit"
        @goToPage="goToPage"
        @onChange="onChange"
      />
    </card>
    <modal v-if="showModal">
      <div slot="header">
        <div class="row">
          <div class="col-md-12"><b>
            {{ modal_title }}
          </b></div>
        </div>
      </div>
      <div slot="body">
        <card>
          <form method="POST">

            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label for="usage1">Usage</label>
                  <select class="form-control" id="usage1" v-model="usage">
                    <option value="LOADING_UNLOADING_FEES">LOADING UNLOADING FEES</option>
                    <option value="STUFFING_DESTUFFING_FEES">STUFFING DESTUFFING FEES</option>
                    <option value="SHIPPING_EXPORT_FEES">SHIPPING EXPORT FEES</option>
                  </select>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6 form-group ">
                <label for="active2">Active Status</label>
                <select
                  class="form-control"
                  id="active2"
                  v-model="active"
                >
                  <option value=""/>
                  <option :value="true">
                    Active
                  </option>
                  <option :value="false">
                    Not Active
                  </option>
                </select>
                <div v-if="activeError">
                  <div
                    class="error text-danger"
                    v-if="!$v.active.required"
                  >
                    This field is required
                  </div>
                </div>
              </div>
              <div class="col-md-6 form-group">
                <label for="cargoType">Cargo Type</label>
                <select
                  class="form-control"
                  id="cargoType"
                  v-model="cargoType"
                >
                  <option value="CONTAINER">
                    CONTAINER
                  </option>
                  <option value="VEHICLE">
                    VEHICLE
                  </option>
                  <option value="DRY_BULK">
                    DRY BULK
                  </option>
                  <option value="LIQUID_BULK">
                    LIQUID BULK
                  </option>
                  <option value="GENERAL_CARGO">
                    GENERAL CARGO
                  </option>
                </select>
                <div v-if="cargoTypeError">
                  <div
                    class="error text-danger"
                    v-if="!$v.cargoType.required"
                  >
                    This field is required
                  </div>
                </div>
              </div>
            </div>
            <div class="row" v-if="usage === 'SHIPPING_EXPORT_FEES'">
              <div class="col-md-6 form-group">
                <label for="shippingOrderFees">Select Charge Type</label>
                <select class="form-control" id="shippingOrderFees" v-model="shippingOrderFees">
                  <option value="SHIPPING_ORDER_BL_CHARGE">SHIPPING ORDER BL CHARGE</option>
                  <option value="SHIPPING_ORDER_BL_AMENDMENT_CHARGE">SHIPPING ORDER BL AMENDMENT_CHARGE</option>
                </select>
                <div v-if="shippingOrderFeesError">
                  <div
                    class="error text-danger"
                    v-if="!$v.shippingOrderFees.required"
                  >
                    This field is required
                  </div>
                </div>
              </div>
            </div>

            <div class="row" v-if="this.cargoType === 'CONTAINER'">
              <div
                class="col-md-6"
                v-if="this.cargoType==='CONTAINER'"
              >
                <label >Select Container Sizes</label>
                <model-list-select
                  :custom-text="getContainerName"
                  :list="containerSize"
                  class="form-control"
                  option-value="containerCode"
                  placeholder="Container Sizes"
                  v-model="isoContainer"
                />
                <div v-if="isoContainerError">
                  <div
                    class="error text-danger"
                    v-if="!$v.isoContainer.required"
                  >
                    This field is required
                  </div>
                </div>
              </div>
              <div
                class="col-md-6 form-group"
                v-if="this.cargoType==='CONTAINER'"

              >
                <label for="containerTypeSelect">Select Container Type</label>
                <select class="form-control" name="containerTypeSelect" id="containerTypeSelect" v-model="containerType" placeholder="Select Container Type">
                  <option value="true">FULL CONTAINER</option>
                  <option value="false">EMPTY CONTAINER</option>
                </select>
                <div v-if="containerTypeError">
                  <div
                    class="error text-danger"
                    v-if="!$v.containerType.required"
                  >
                    This field is required
                  </div>
                </div>
              </div>

            </div>
            <div class="row">
              <div class="col-md-6 form-group">
                <label for="tariffItem">Tariff Item</label>
                <select
                  class="form-control"
                  id="tariffItem"
                  v-model="tariffItemId"
                >
                  <option value=""/>
                  <option
                    :value="item.tariffItemId"
                    v-for="(item,index_01) in tariffItems" :key="index_01"
                  >
                    {{ item.tariffItemName }}
                  </option>
                </select>
                <div v-if="tariffItemIdError">
                  <div
                    class="error text-danger"
                    v-if="!$v.tariffItemId.required"
                  >
                    This field is required
                  </div>
                </div>
              </div>
              <div class="col-md-6 form-group">
                <label for="tariffFees">Tariff Fees</label>
                <select
                  class="form-control"
                  id="tariffFees"
                  v-model="tariffFeeId"
                >
                  <option value=""/>
                  <option
                    :value="fee.tariffFeeId" v-for="(fee,index) in tariffFees" :key="index">
                    {{ fee.tariffFeeName }}
                  </option>
                </select>
                <div v-if="tariffFeeIdError">
                  <div
                    class="error text-danger"
                    v-if="!$v.tariffFeeId.required"
                  >
                    This field is required
                  </div>
                </div>
              </div>
            </div>


            <div class="text-center row">
              <div class="col-md-7 text-right loader-div">
                <span v-if="editMyLoader">Please wait ....</span>
              </div>
              <div class="col-md-2 left loader-div-gif">
                <pulse-loader
                  :loading="loading"
                  :color="color"
                  :size="size"
                  v-if="editMyLoader"
                />
              </div>
              <div class="col-md-3">
                <button
                  type="button"
                  class="btn btn-fill btn-outline-info float-right"
                  style="margin-inline-start:20px"
                  @click.prevent="closeModal"
                >
                  Close
                </button>
                <button
                  type="submit"
                  class="btn btn-primary btn-space  float-right"
                  data-dismiss="modal"
                  @click.prevent="submit"
                >
                  Save
                </button>
              </div>
            </div>
          </form>
        </card>
      </div>
    </modal>
  </div>
</template>

<script>
  import {ModelListSelect} from 'vue-search-select'
  import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
  import API_REVENUE from '../../../api/rev'
  import API from '../../../api/'
  import {required} from 'vuelidate/lib/validators'
  import containerSize from "../../shippingAgency/application/containerSize";
  import CrudNotification from '../../../components/DataTable/crudNotification'
  import DataTable from '../../../components/DataTable/updatedTable'
  import Modal from '../../../components/Inputs/Modal'
  import {mapActions, mapGetters} from 'vuex'

  export default {
    name: 'TallyTariffConf',
    components: {
      ModelListSelect,
      PulseLoader,
      CrudNotification,
      DataTable,
      Modal
    },
    validations: {
      active: {required},
      isoContainer: {required},
      tariffFeeId: {required},
      tariffItemId: {required},
      cargoType: {required},
      shippingOrderFees: {required}
    },

    created() {

      this.$store.dispatch('pagenames/currentPageName', 'Tariff Configurations')

      this.finalUserPermissions = JSON.parse(localStorage.getItem('browser-local-permits'))

      this.loadPageableTallyConfigurationsOnCreated();
      this.getTariffItems()
      this.getTariffFees()
    },
    computed: {
      ...mapGetters('tallyTariffConfiguration', [

        'getTallyTariffConfigId',
        'getTallyTariffs',
        'getMyFirstLoader',
        'getNoDataFound',
        'getSuccessAlert',
        'getErrorMessage',
        'getErrorAlert',
        'getSuccessMessage',
        'getMyLoader',
        'getPageNo',

      ])
    },
    data() {
      return {
        containerSize: containerSize,
        active: true,
        shippingOrderFees: "",
        activeError: false,
        isoContainer: null,
        isoContainerError: false,
        destuffing: false,
        tariffItemId: '',
        tariffItemIdError: false,
        tariffFeeId: '',
        tariffFeeIdError: false,
        cargoType: '',
        cargoTypeError: false,
        shippingOrderFeesError: false,
        usage: "",
        containerTypeError: false,
        tallyId:'',
        showIsoContainer: false,

        containerSizes: [
          {value: 'C_45G1'}, {value: 'C_22G1'}, {value: 'C_20G0'}, {value: 'C_20R0'}, {value: 'C_20U0'}, {value: 'C_20G2'}, {value: 'C_20P0'}, {value: 'C_20P1'}, {value: 'C_20T0'}, {value: 'C_20B0'}, {value: 'C_22G0'}, {value: 'C_22V1'}, {value: 'C_22R0'}, {value: 'C_22R1'},
          {value: 'C_22U0'}, {value: 'C_22U1'}, {value: 'C_22G2'}, {value: 'C_22P0'}, {value: 'C_22P1'}, {value: 'C_22P3'}, {value: 'C_22T0'}, {value: 'C_22T5'}, {value: 'C_22B0'}, {value: 'C_25R1'}, {value: 'C_25G0'}, {value: 'C_25G1'}, {value: 'C_25R0'}, {value: 'C_25U0'},
          {value: 'C_25G2'}, {value: 'C_25P0'}, {value: 'C_25P3'}, {value: 'C_25T0'}, {value: 'C_25B0'}, {value: 'C_40G0'}, {value: 'C_40R0'}, {value: 'C_40U0'}, {value: 'C_40G2'}, {value: 'C_40P0'}, {value: 'C_40P3'}, {value: 'C_40T0'}, {value: 'C_40B0'}, {value: 'C_42G0'},
          {value: 'C_42G1'}, {value: 'C_42V1'}, {value: 'C_42R0'}, {value: 'C_42R1'}, {value: 'C_42U0'}, {value: 'C_42U1'}, {value: 'C_42G2'}, {value: 'C_42P0'}, {value: 'C_42P1'}, {value: 'C_42P3'}, {value: 'C_42T0'}, {value: 'C_42B0'}, {value: 'C_45G0'}, {value: 'C_45R0'},
          {value: 'C_45R1'}, {value: 'C_45U0'}, {value: 'C_45G2'}, {value: 'C_45P0'}, {value: ''}, {value: 'C_45T0'}, {value: 'C_45B0'}, {value: 'C_L5G0'}, {value: 'C_L5R0'}, {value: 'C_L5U0'}, {value: 'C_L5G2'}, {value: 'C_L5P0'}, {value: 'C_L5P3'}, {value: 'C_L5T0'},
          {value: 'C_L5B0'}
        ],
        containerType: '',
        containerTypeList:[
          {containerTypeText: 'FULL', containerTypeValue:'true' },
          {containerTypeText: 'EMPTY', containerTypeValue:'false'}],
        tariffItems: [],
        tariffFees: [],
        color: 'green',
        size: '15px',
        loading: false,

        SuccessAlert: false,
        ErrorAlert: false,
        SuccessMessage: '',
        ErrorMessage: '',
        showModal: false,
        modalType: '',
        isHovered: [],
        saveDetails: {},
        ifThereIsParam: '',
        editDetails: false,
        finalUserPermissions: [],

        tableHeaders: ['Tariff Fee', 'Tariff Item', 'Container Type', 'Cargo Type', 'Usage'],
        tableDataKeys: ['tariffFeeName', 'tariffItemName', 'isoContainer', 'cargoType', 'usage'],
        requests: [],
        reloadAllRequest: false,
        pageNo: 0,
        pageSize: 15,
        pageCount: 1,
        sortBy: 'createdAt',
        totalPages: 1,
        isPageable: true,
        pageNumber: 0,
        isFirst: true,
        isLast: false,
        nodataFound: false,
        myFirstLoader: false,
        totalElements: '',
        noDataFound: false,
        editMyLoader: false,
      }
    },

    methods: {
      ...mapActions('tallyTariffConfiguration', [
        'getPageableTallyTariffs',
        'getAllTallyTariffs',
        'addTallyTariff',
        'updateTallyTariff',
        'deleteTallyTariff'
      ]),
      loadParameters() {
        return new Promise((resolve) => {
          this.$store.dispatch('tallyTariffConfiguration/pageSizing', this.pageSize)
          this.$store.dispatch('tallyTariffConfiguration/pageNumbering', this.pageNo)

          resolve()
        })
      },

      loadSaveDetailsParameters (data) {
        return new Promise((resolve) => {
          this.$store.dispatch('tallyTariffConfiguration/savingDetails', data)
          resolve()
        })
      },
      loadPageableTallyConfigurationsOnCreated() {
        this.loadParameters().then(() => {
          this.myFirstLoader = true;
          this.getPageableTallyTariffs({}).then(() => {
            this.requests = this.formatDetails(this.getTallyTariffs.content);
            console.table(this.getTallyTariffs.content)
            // this.pageCount = this.getPageCount;//
            this.myFirstLoader = this.getMyFirstLoader
            this.isLast = this.getTallyTariffs.last
            this.totalElements = this.getTallyTariffs.totalElements
            this.totalPages = this.getTallyTariffs.totalPages
            this.pageNumber = this.getTallyTariffs.number
            this.isFirst = this.getTallyTariffs.first
            this.isLast = this.getTallyTariffs.last
            this.noDataFound = this.getNoDataFound
            this.pageNo = this.getPageNo
          }).catch(() => {
            this.myFirstLoader = this.getMyFirstLoader;
          })
        }).catch(() => {

        })
      },

      addTallyCargoTariff(data){
        this.loadSaveDetailsParameters(data).then(()=>{
          this.addTallyTariff({}).then(()=>{
            this.editMyLoader = this.getMyFirstLoader;
            this.SuccessAlert = this.getSuccessAlert;
            this.ErrorMessage = this.getErrorAlert;
            this.SuccessMessage = 'Tally Cargo Tariff Saved Successfully'
            this.ErrorMessage = 'Sorry, something went wrong'
            this.closeModal();
            this.loadPageableTallyConfigurationsOnCreated();
          }).catch(()=>{
            this.closeModal()
            this.loadPageableTallyConfigurationsOnCreated()
            this.editMyLoader = this.getMyFirstLoader;
            this.SuccessAlert = this.getSuccessAlert;
            this.ErrorMessage = this.getErrorAlert;
            this.SuccessMessage = ''
            this.ErrorMessage = 'Sorry, something went wrong'
          })
        }).catch(()=>{

        })
      },

      editTallyCargoTariff(data){
        this.loadSaveDetailsParameters(data).then(()=>{
          this.updateTallyTariff({}).then(()=>{
            this.editMyLoader = this.getMyFirstLoader;
            this.SuccessAlert = this.getSuccessAlert;
            this.ErrorAlert = this.getErrorAlert;
            this.SuccessMessage = 'Tally Cargo Tariff Saved Successfully'
            this.ErrorMessage = 'Sorry, something went wrong'
            this.closeModal();
            this.loadPageableTallyConfigurationsOnCreated();
          }).catch(()=>{
            this.closeModal()
            // this.loadPageableTallyConfigurationsOnCreated()
            this.SuccessAlert = false;
            this.ErrorAlert = true;
            this.SuccessMessage = ''
            this.ErrorMessage = 'Sorry, something went wrong'
          })
        }).catch(()=>{

        })
      },


      formatDetails(data) {
        if (data) {
          for (let i = 0; i < data.length; i++) {
            data[i].tariffFeeName = data[i]?.tariffFee?.tariffFeeName;
            data[i].tariffItemName = data[i]?.tariffItem?.tariffItemName;
          }
        }
        return data
      },

      goToPage(page) {
        this.pageNo = page
        this.loadPageableTallyConfigurationsOnCreated()
      },

      onChange(event) {
        this.pageSize = event
        this.pageNo = 0
        this.loadPageableTallyConfigurationsOnCreated()
      },

      goToEdit(data) {
        this.tallyId = data.id
        this.active =  data.active;
        this.isoContainer =  data.isoContainer
        this.tariffFeeId = data.tariffFee.tariffFeeId;
        this.tariffItemId = data.tariffItem.tariffItemId;
        this.cargoType =  data.cargoType;
        this.usage =  data.usage;
        this.shippingOrderFees =  data.shippingOrderFees;
        this.containerType = data.fullContainer;
        this.openModal('EDIT');
    },
      goToDelete(data) {
        this.$dialog.confirm ('Delete tariff configuration for <br> <b>[ ' + data.usage + ' ] </b>  with tariff fee <b> [' + data.tariffFeeName + ' ] </b> <br> and tariff Item <b>[' + data.tariffItemName + '] </b>', {
          loader: true,
          html: true,
          okText: 'YES'
        }).then((dialog) =>{
          this.$store.dispatch('tallyTariffConfiguration/tallyTariffConfigurationId', data.id)

          this.deleteTallyTariff({}).then(()=>{
            this.editMyLoader = this.getMyFirstLoader;
            this.SuccessAlert = this.getSuccessAlert;
            this.ErrorAlert = this.getErrorAlert;
            this.SuccessMessage = 'Tally Cargo Tariff Deleted Successfully'
            this.ErrorMessage = 'Sorry, something went wrong'
            this.closeModal();
            this.loadPageableTallyConfigurationsOnCreated();
          }).catch(()=>{
            this.closeModal()
            // this.loadPageableTallyConfigurationsOnCreated()
            this.SuccessAlert = false;
            this.ErrorAlert = true;
            this.SuccessMessage = ''
            this.ErrorMessage = 'Sorry, something went wrong'
          })
          dialog.close()
        }).catch(()=>{

        });
      },

      closeModal(){
        this.showModal = false
        this.active = ''
        this.isoContainer = ''
        this.tariffFeeId = ''
        this.tariffItemId = ''
        this.cargoType = ''
        this.usage = ''
        this.shippingOrderFees = ''
        this.containerType = '';
        this.tallyId = '';
        this.shippingOrderFeesError = false;
        this.activeError = false;
        this.isoContainerError = false;
        this.tariffItemIdError = false;
        this.tariffFeeIdError = false;
        this.cargoTypeError = false;
        this.containerTypeError = false;

      },

      openModal(modalType){
        if (modalType === 'ADD'){
          this.showModal = true;
          this.modalType = modalType
          this.modal_title = 'ADD TALLY TARIFF CONFIGURATION'
        }else if(modalType === 'EDIT'){
          this.showModal = true
          this.modal_title = 'EDIT TALLY TARIFF CONFIGURATION'
          this.modalType = modalType;
        }

      },
      getContainerName(data) {
        return `${data.descriptionofCodeGroup} - ${data.containerCode}`
      },

      getTariffItems() {
        API_REVENUE.getTariffItems().then((response) => {
          this.tariffItems = response.data.data
        })
      },

      getTariffFees() {
        API_REVENUE.getActiveTariffFee().then((response) => {
          this.tariffFees = response.data.data
        })
      },

      submit() {
        if (
          this.active !== '' &&
          this.tariffItemId !== '' &&
          this.tariffFeeId !== '' &&
          this.cargoType !== ''
        ) {
          this.activeError = false;
          this.isoContainerError = false;
          this.tariffItemIdError = false
          this.tariffFeeIdError = false
          this.cargoTypeError = false

          let data = {};

          if (this.cargoType === 'CONTAINER' && (this.isoContainer === '' || this.containerType === '')  ) {
            if (this.isoContainer === ''){
              this.isoContainerError = true
            }
            if (this.containerType === ''){
              this.containerTypeError = true
            }
          }

          data = {
              id: this.tallyId ? this.tallyId : null,
              active: this.active,
              isoContainer: this.isoContainer,
              tariffFeeId: this.tariffFeeId,
              tariffItemId: this.tariffItemId,
              cargoType: this.cargoType,
              usage: this.usage,
              shippingOrderFees: this.shippingOrderFees ? this.shippingOrderFees : null ,
              fullContainer: this.containerType === "true" ? true : false,
            }
          this.loading = true;

          if (this.modalType === 'EDIT'){
            this.editTallyCargoTariff(data)
          }else if (this.modalType === 'ADD'){
            this.addTallyCargoTariff(data)
          }
        } else {
          this.activeError = true;
          this.tariffItemIdError = true;
          this.tariffFeeIdError = true;
          this.cargoTypeError = true;
          this.shippingOrderFeesError = true;
        }
      },

      enter: function (el, done) {
        const that = this

        setTimeout(function () {
          that.SuccessAlert = false
          that.ErrorAlert = false
        }, 3000) // hide the message after 3 seconds
      },

      fadeMe: function () {
        if (this.SuccessAlert) {
          this.SuccessAlert = !this.SuccessAlert
        } else {
          this.ErrorAlert = !this.ErrorAlert
        }
      }
    }
  }
</script>

<style scoped>
  .custom-btn {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
    padding: 5px 9px 5px 9px !important;
    font-size: 14px;
    /*width: 180px;*/
    background-color: #FFFFFF !important;
  }
  label {
    position: relative;
    top: -7px;
    right: 0;
    margin: 0;
    padding: 0;
  }

  .special-bottom {
    margin-bottom: -1px;
  }
</style>
