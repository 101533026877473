<template>
  <div>
    <div class="legend">
      <h6 class="legend-title">
        Bill Of Lading Charging
      </h6>
    <card style="font-size: 12px">
      <!--shipping order details-->
      <div class="legend" v-if=" typeof billOfLading!== 'undefined'">
        <h6 class="legend-title">
          Bill Of Lading Details
        </h6>
        <div class="row div-table-row">
          <div class="col-md-2 div-table div-table-title">
            Bill Of Lading No.
          </div>
          <div class="col-md-2 div-table div-table-value">
            {{ billOfLading.billOfLadingNo }}
          </div>
          <div class="col-md-2 div-table div-table-title">
            Vessel Name
          </div>
          <div class="col-md-2 div-table div-table-value">
            {{ billOfLading.vesselName }}
          </div>
          <div class="col-md-2 div-table div-table-title">
            Bill Of Lading Status
          </div>
          <div class="col-md-2 div-table div-table-value" style="color: #651fff; ">
            {{ billOfLading.blStatus }}
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6 form-group">
          <label for="tariffItem">Tariff Item</label>
          <select class="form-control" id="tariffItem" v-model="tariffItemId">
            <option :value="item.tariffItemId" v-for="(item,index_01) in tariffItems" :key="index_01">{{ item.tariffItemName }}</option>
          </select>
          <div v-if="tariffItemIdError">
            <div class="error text-danger" v-if="!$v.tariffItemId.required">This field is required</div>
          </div>
        </div>
        <div class="col-md-6 form-group">
          <label for="tariffFees">Tariff Fees</label>
          <select class="form-control" id="tariffFees" v-model="tariffFeeId">
            <option :value="fee.tariffFeeId" v-for="(fee,index) in tariffFees" :key="index"> {{ fee.tariffFeeName }} </option>
          </select>
          <div v-if="tariffFeeIdError">
            <div class="error text-danger" v-if="!$v.tariffFeeId.required">This field is required</div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <model-list-select :list="cfAgents"
                             v-model="selectedAgent"
                             option-value="code"
                             :custom-text="codeAndNameAndDesc"
                             placeholder="Select Clearing And Forwarding Agent">
          </model-list-select>
          <div v-if="selectedAgentError">
            <div class="error text-danger">
              <span v-if="!$v.selectedAgent.required">Field is required</span>
            </div>
          </div>
        </div>
      </div>
      <button
        @click.prevent="closeModal"
        class="btn btn-fill btn-outline-info float-right btn-space"
        type="button">Close
      </button>
      <button
        @click.prevent="submitCharge"
        class="btn btn-primary float-right btn-space"
        data-dismiss="modal"
        type="submit">Save
      </button>
    </card>
  </div>
  </div>
</template>

<script>
    import { ModelListSelect } from 'vue-search-select';
    import API_REVENUE from '../../../api/rev';
    import API_AGENCY from '../../../api/agency'

    export default {
        name: "chargeBillOfLading",

        components: {
          ModelListSelect
        },

        props: {
          // type, required and default are optional, you can reduce it to 'options: Object'
          billOfLading: { type: Object, required: false},
        },

        data() {
          return {
            tariffItems: [],
            tariffFees: [],
            cfAgents:[],
            selectedAgent:{
              code: "",
              desc: "",
              name: ""
            },
            billOfLadingCharge: {},
            tariffFeeId: '',
            tariffItemId: '',
            tariffFeeIdError: false,
            tariffItemIdError: false,
            selectedAgentError: false
          }
        },

        created() {
          this.getActiveCharges();
          this.getTariffFees();
          this.getTariffItems();
          this.getAllCfAgents();
          console.log(this.tariffItems);
        },

        methods: {

          getActiveCharges() {
            API_AGENCY.getBillOfLadingCharge(this.billOfLading.id).then(response => {
              if (response.status) {
                this.billOfLadingCharge = response.data.data;
              }
            })
          },

          getTariffItems() {
            API_REVENUE.getTariffItemsActiveByService('AGENCY').then((response) => {
              this.tariffItems = response.data.data
            })
          },

          getTariffFees() {
            API_REVENUE.getActiveTariffFee().then((response) => {
              this.tariffFees = response.data.data
            })
          },

          getAllCfAgents(){
            API_AGENCY.getAllCfAgentsNonPegeable().then((response)=>{
              response.data.data.content.map((data)=>{
                this.cfAgents.push({ code: data.cfAgentId, name: data.agentName, desc: 'desc01' },)
              })
            }).catch(()=>{

            })
          },

          submitCharge() {
            let chargeObject = {
              billOfLadingId: this.billOfLading.id,
              cfaAgentId: this.selectedAgent.code,
              tariffFeeId: this.tariffFeeId,
              tariffItemId: this.tariffItemId
            };

            API_AGENCY.addBillOfLadingCharge(chargeObject).then((resp) => {
              this.loading = false;
              if (resp.status) {
                this.notifyUser('success', 'Success', 'Successfully added charge to bill of lading');
                this.closeModal();
              } else {
                this.notifyUser('error', 'Error', resp.message);
              }
            }).catch((error) => {
              this.loading = false;
              this.notifyUser('error', 'Error', 'Failed to add charge ')
            });
          },

          codeAndNameAndDesc(data){
            return `${data.name} `
          },

          closeModal() {
            this.$emit('closeModal', false);
          }

        }
    }
</script>

<style scoped>
  ul.nav-justified {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    background-color: #ededee;
    border-radius: 3px;
  }

  ul.nav-justified li {
    float: left;
  }

  ul.nav-justified li a:hover {
    color: #d59a18;
  }

  ul.nav-justified li a {
    display: block;
    text-align: center;
    padding: 16px 20px 10px 21px;
    text-decoration: none;
    color: blue;
    font-weight: bold;
    text-transform: uppercase;
  }
  .div-table-row {
    padding: 0 15px 0 15px;
  }

  .div-table {
    padding: 9px 8px 9px 8px;
  }

  .div-table-title {
    width: 250px;
    background-color: rgba(153, 186, 221, .5);
    font-weight: normal;
  }

  .div-table-value {
    border: 1px solid #DCDCDC;
    font-weight: 900;
  }

  label {
    position: relative;
    top: -7px;
    right: 0;
    margin: 0;
    padding: 0;
  }
  .legend {
    position: relative;
    border: 1px solid #DCDCDC;
    padding: 1.5rem 1rem 1rem 1rem;
    margin-bottom: 1rem;
    border-radius: 3px;
    margin-top: 1rem;
  }

  .legend-title {
    background-color: inherit;
    position: absolute;
    top: -0.6rem;
    padding: 0.2rem 1rem;
    background-color: white;
    color: #d59a18;
  }
  .btn-success-custom:hover {
    border-color: #008000;
    color: #008000;
    background-color: #ffffff;
  }

  .btn-success-custom {
    border-color: rgba(0, 128, 0, .5);
    color: rgba(0, 128, 0, .5);
  }

  .btn-success-custom-fill {
    background-color: rgba(0, 128, 0, .8);
    opacity: 1;
    color: #FFFFFF;
  }

  /*.custom-btn {*/
  /*margin-top: 5px !important;*/
  /*margin-bottom: 5px !important;*/
  /*padding: 5px 9px 5px 9px !important;*/
  /*font-size: 14px !important;*/
  /*}*/

  .custom-btn {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
    color: #054114;
    padding: 5px 9px 5px 9px !important;
    background-color: #FFFFFF !important;
  }

  .legend {
    position: relative;
    border: 1px solid #DCDCDC;
    padding: 1.5rem 1rem 1rem 1rem;
    margin-bottom: 1rem;
    border-radius: 3px;
    margin-top: 1rem;
  }

  .legend-title {
    background-color: inherit;
    position: absolute;
    top: -0.6rem;
    padding: 0.2rem 1rem;
    background-color: white;
    color: #d59a18;
  }

  .div-table-value{
    border: 1px solid #DCDCDC;
  }
  .div-table-row {
    padding: 0 15px 0 15px;
  }

  .div-table {
    padding: 9px 8px 9px 8px;
  }

  .div-table-title {
    width: 250px;
    background-color: rgba(153, 186, 221, .5);
    font-weight: normal;
  }

  .div-table-value {
    border: 1px solid #DCDCDC;
    font-weight: 900;
  }

  h3,h4 {
    width: 100%;
    text-align: center;
    border-bottom: 1px solid green;
    line-height: 0.1em;
    margin: 10px 0 20px;
  }

  h3 span,h4 span {
    background:#fff;
    padding:0 10px;
  }
</style>
