<template>
  <div>
    <card>
      <data-table
        :table-headers="tableHeaders"
        :table-data="requests"
        :table-data-keys="tableDataKeys"
        :my-first-loader="myFirstLoader"
        :page-size-prop="pageSize"
        :page-no="pageNo"
        :is-pageable="false"
        :no-data-found="noDataFound"
        :total-elements="totalElements"
        :total-pages="totalPages"
        :is-first="isFirst"
        :is-last="isLast"
        :page-number="pageNumber"
        :date-prop="'Created At '"
        :has-manage-button="false"
        :has-view-button="true"
        :has-search-form="false"
        @goToView="goToView"
        :loading-title="'Please wait while system is loading assignments'"
      />
      <div class="row">
        <div class="col-md-12">
          <button
            class="btn btn-default btn-fill btn-space"
            style="float:right;"
            @click.prevent="goBack"
          >
            <font-awesome-icon icon="undo" /> &nbsp; Back
          </button>
        </div>
      </div>
    </card>

    <global-modal v-if="myLoader">
      <div slot="globalLoader">
        <div class="row text-center">
          <div class="col-md-12">
            Loading, Please wait
          </div>
          <div class="col-md-12">
            <br>
          </div>
          <div class="col-md-12">
            <pulse-loader
              :loading="loading"
              :color="color"
              :size="size"
            />
          </div>
        </div>
      </div>
    </global-modal>
  </div>
</template>

<script>
import PulseLoader from 'vue-spinner/src/PulseLoader';
import GlobalModal from '../../../components/globalLoader';
import DataTable from '../../../components/DataTable/updatedTable';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'ShippingOrderAttachments',
  components: {
    PulseLoader,
    GlobalModal,
    DataTable
  },
  created () {
    this.$store.dispatch('pagenames/currentPageName', 'Shipping Order Attachments');
    this.shippingOrderId = this.$route.params.shippingOrderId;

    this.finalUserPermissions = JSON.parse(localStorage.getItem('browser-local-permits'));
    this.loadShippingOrderAttachments();
  },
  data () {
    return {

      shippingOrderId: '',

      pageNo: 0,
      pageSize: 15,
      totalElements: '',
      totalPages: '',
      isFirst: false,
      isLast: false,
      noDataFound:false,
      pageNumber: '',
      isPageable: true,
      requests: [],
      myLoader: false,
      myFirstLoader: false,
      reloadRequests: false,
      tableHeaders: ['Created At', 'Title', 'Cargo Name', 'Progress Description'],
      tableDataKeys: ['createdAt', 'title', 'cargoName', 'progressDescription']

    };
  },
  computed: {
    ...mapGetters('shippingOrder', [

      'getMyLoader',
      'getPageNo',
      'getRequestList',
      'getNoDataFound',
      'getSuccessAlert',
      'getErrorMessage',
      'getErrorAlert',
      'getSuccessMessage',
      'getMyFirstLoader'

    ]),

    ...mapGetters('global', [

        'getGlobalLoader'

      ])
  },
  methods: {

    ...mapActions('global', [

      'loadFileFromMinio'

    ]),

    loadParameters (fileUrl, fileName) {
      
      return new Promise((resolve) => {

        this.$store.dispatch('global/fileUrling', fileUrl)
        this.$store.dispatch('global/fileNaming', fileName)

        resolve()
      })

    },

    getFileFromMinio (fileUrl, fileName) {

      this.loadParameters(fileUrl, fileName).then(() => {

      this.loadFileFromMinio({}).then((res) => {

          if(res.status === 204) {

              this.loadNotification('warn', 4000, 'File Download',
              'File not found, please contact system admin',
              'response', 1000 , true  , true)

          } else {

            this.loadNotification('success', 4000, 'File Download',
              'File has been successfully downloaded, please have a look at it',
              'response', 1000 , true  , true)


          }

            this.globalLoader = this.getGlobalLoader
        
        }).catch(() => {

          this.loadNotification('error', 4000, 'File Download',
              'File not downloaded, please check your internet connection',
              'response', 1000 , true  , true)

          this.globalLoader = this.getGlobalLoader

        })

        this.globalLoader = this.getGlobalLoader

      }).catch(() => {

      })

    },

    ...mapActions('shippingOrder', [
      'getShippingOrderAttachmentByShippingOrderId'
    ]),
    loadParameters () {
      return new Promise((resolve) => {
        this.$store.dispatch('shippingOrder/requestid', this.shippingOrderId);
        resolve();
      });
    },

    loadShippingOrderAttachments () {
      this.loadParameters().then(() => {
        this.getShippingOrderAttachmentByShippingOrderId({}).then(() => {
          this.myFirstLoader = this.getMyFirstLoader;
          this.requests = this.formatCargoActivities(JSON.parse(JSON.stringify(this.getRequestList)));
          this.noDataFound = this.getNoDataFound;
          this.ErrorAlert = this.getErrorAlert;
          this.ErrorMessage = 'Sorry, something went wrong';
        }).catch(() => {});
      });
    },
    formatCargoActivities (data) {
      if (data !== null) {
        for (let i = 0; i < data.length; i++) {
          data[i].cargoName = data[i].shippingOrderGood ? data[i].shippingOrderGood.cargoName : 'N/A';
          data[i].progressDescription = data[i].shippingOrderProgress ? data[i].shippingOrderProgress.descrition : 'N/A'
        }
        return data;
      } else {
        this.noDataFound = true;
        return data;
      }
    },

    goBack () {
      history.back();
    },

    goToView (data) {
      // this.getMinioFile(data.attachmentUrl, 'view', data.title);
      this.getFileFromMinio(data.attachmentUrl , data.attachmentName)
      // this.$router.push('/shipping-agency/booking/view/shippingorder/cargo-details/activity-progress/' + data.id);
      // this.$router.push('/shipping-agency/delivery-orders/order-details/' + data.deliveryOderId);
    }
  }

};
</script>

<style scoped>

</style>
