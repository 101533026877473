<template>
  <div>
    <card>
      <data-table
        :table-headers="tableHeaders"
        :table-data="requests"
        :table-data-keys="tableDataKeys"
        :my-first-loader="myFirstLoader"
        :page-size-prop="pageSize"
        :page-no="pageNo"
        @onChange="onChange"
        :total-elements="totalElements"
        :total-pages="totalPages"
        :is-first="isFirst"
        :is-last="isLast"
        :page-number="pageNumber"
        @goToPage="goToPage"
        :no-data-found="noDataFound"
        :has-manage-button="true"
        :is-pageable="true"
        :has-view-button="false"
        :has-search-form="true"
        :placeholder="'Search by Voyage No., Control Ref. or TASAC Ref. No.'"
        :reload-all-request="reloadAllRequest"
        @searchRequest="searchVoyageNo"
        @reloadByIcon="reloadByIcon"
        @goToApplication="goToApplication"
        :loading-title="'Please wait while the system is loading EDI Documents'"
      />
    </card>
  </div>
</template>
<script>
  import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
  import Modal from '../../../components/Inputs/Modal'
  import {mapActions, mapGetters} from 'vuex'
  import DataTable from '../../../components/DataTable/updatedTable'
  export default {

    name: 'manifestList',

    components: {
      PulseLoader,
      Modal,
      DataTable,
    },

    created() {
      this.documentType = this.$route.params.direction
      let formattedDocumentType = this.documentType.charAt(0).toUpperCase() + this.documentType.slice(1).toLowerCase();
      this.$store.dispatch('pagenames/currentPageName', formattedDocumentType + ' EDI Documents List ')

      this.finalUserPermissions = JSON.parse(localStorage.getItem('browser-local-permits'))
      // this.reloadByIcon()
      this.isPageable = true
      this.noDataFound = false
      this.reloadAllRequest = false
      this.$store.dispatch('v2DocumentControl/queryingParam', '');
      this.loadEdiDocumentsListPageable()
    },
    watch: {
      $route(to, from) {
        if (to != from) {
          this.documentType = this.$route.params.direction
          let formattedDocumentType = this.documentType.charAt(0).toUpperCase() + this.documentType.slice(1).toLowerCase();
          this.$store.dispatch('pagenames/currentPageName', formattedDocumentType + ' EDI Documents List ')

          this.isPageable = true
          this.noDataFound = false
          this.reloadAllRequest = false
          this.$store.dispatch('v2DocumentControl/queryingParam', '');
          this.loadEdiDocumentsListPageable()
        }
      }
    },
    computed: {

      ...mapGetters('v2DocumentControl', [

        'getMyFirstLoader',

        'getSuccessAlert',
        'getErrorMessage',
        'getErrorAlert',
        'getSuccessMessage',

        'getPageNo',
        'getPageSize',
        'getQueryParam',

        'getManifestList',
        'getNoDataFound',


      ])

    },

    data() {
      return {
        requests: [],
        finalUserPermissions: [],

        documentType: '',

        searchParameter: '',
        hasSearchedVariable: false,
        reloadAllRequest: false,
        noDataFound: false,

        pageNo: 0,
        pageSize: 15,
        pageCount: 1,
        totalElements: '',
        totalPages: '',
        isFirst: false,
        isLast: false,
        pageNumber: '',
        myFirstLoader: false,
        isPageable: true,

        tableDataKeys: ['createDateTime', 'applicationReference', 'carrierId',
         'transportMeansName', 'controlReferenceNumber', 'messageTypeEnum', 'voyageNumber'],

        tableHeaders: ['Created Date', 'TASAC Ref No.', 'Carrier ID',
         'Vessel Name', 'Control Reference No.', 'Message Type', 'Voyage Number'],

        color: 'green',
        size: '15px',
        loading: true,

        vesselDetails: {},

        SuccessAlert: false,
        ErrorAlert: false,
        showModal: false,
        myLoader: false,
      }
    },

    methods: {
      ...mapActions('v2DocumentControl', [
        'loadManifestCargoPageable',
      ]),

      loadParameters() {
        return new Promise((resolve) => {
          this.$store.dispatch('v2DocumentControl/pageSize', this.pageSize)
          this.$store.dispatch('v2DocumentControl/pageNumber', this.pageNo)
          this.$store.dispatch('v2DocumentControl/documentType', this.documentType);
          resolve()
        })
      },

      loadEdiDocumentsListPageable() {
        this.loadParameters().then(() => {
          this.myFirstLoader = true
          this.loadManifestCargoPageable().then(() => {
            this.myFirstLoader = this.getMyFirstLoader
            this.totalElements = this.getManifestList.totalElements
            this.totalPages = this.getManifestList.totalPages
            this.pageNumber = this.getManifestList.number
            this.isFirst = this.getManifestList.first
            this.isLast = this.getManifestList.last
            this.requests = this.formatDocumentDetails(this.getManifestList.content)
            this.noDataFound = this.getManifestList.empty
          }).catch(() => {
            this.noDataFound = this.getNoDataFound;
            if (this.getErrorAlert){
              this.notifyUser('error', 'An Error Occurred', 'Sorry, something went wrong')
            }
            this.myFirstLoader = this.getMyFirstLoader;
            // this.ErrorMessage = 'Sorry, something went wrong'
          })
        }).catch(() => {
        })
      },

      formatDocumentDetails(data) {
        if (data) {
          for (let i = 0; i < data.length; i++) {
            if (data[i].messageTypeId === 'CUSCAR') {
              data[i].messageTypeEnum = 'Manifest'
            }
            if (data[i].messageTypeId === 'COPRAR') {
              data[i].messageTypeEnum = 'Discharge/Loading'
            }
            if (data[i].messageTypeId === 'IFTDGN') {
              data[i].messageTypeEnum = 'Dangerous Goods'
            }
            if (data[i].messageTypeId === 'BAPLIE') {
              data[i].messageTypeEnum = 'Bay/Storage Plan'
            }
          }
        }
        return data
      },

      reloadByIcon() {
        this.isPageable = true
        this.noDataFound = false
        this.reloadAllRequest = false
        this.$store.dispatch('v2DocumentControl/queryingParam', '');
        this.loadEdiDocumentsListPageable()
      },

      onChange(event) {
        this.pageSize = event
        this.pageNo = 0
        this.loadEdiDocumentsListPageable()
      },
      goToPage(page) {
        this.pageNo = page
        this.loadEdiDocumentsListPageable()
      },

      closeModal() {
        this.showModal = false
      },

      searchVoyageNo(queryParam) {
        this.reloadAllRequest = true
        this.$store.dispatch('v2DocumentControl/queryingParam', queryParam);
        this.loadEdiDocumentsListPageable()
      },


      fadeMe: function () {
        if (this.SuccessAlert) {
          this.SuccessAlert = !this.SuccessAlert
        } else {
          this.ErrorAlert = !this.ErrorAlert
        }
      },

      enter: function (el, done) {
        var that = this

        setTimeout(function () {
          that.SuccessAlert = false
          that.ErrorAlert = false
        }, 3000) // hide the message after 3 seconds
      },

      goToApplication(details) {
        document.cookie = 'browser-local-cashed-url-back=' + this.$route.fullPath + ';Path = /;SameSite = Strict'
        this.$router.push('/document-control/document-view/' + this.documentType + '/' + +details.cargoManifestId)
      },


    }
  }
</script>
<style scoped lang="scss">
  .btn-space {
    margin-right: 10px !important;
  }

  .special-limit {
    max-width: 100px !important;
    min-width: 90px !important;
  }

  .loader-div {
    line-height: 40px;
  }

  .loader-div-gif {
    line-height: 50px;
  }

  .fileUpload {
    position: relative;
    overflow: hidden;
    margin: 0.5em 5px;
    font-size: 1em;
    letter-spacing: 0.02em;
    line-height: 1.5;
    text-decoration: none;
    /*top:-7px;*/
    font-weight: normal;
  }

  .fileUpload input.upload {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    padding: 0;
    cursor: pointer;
    opacity: 0;
    filter: alpha(opacity=0);
  }

  .fileUpload span {
    cursor: pointer;
  }

  label {
    position: relative;
    top: -7px;
    right: 0;
    margin: 0;
    padding: 0;
  }

  .n-light {
    margin: 10px;
    margin-bottom: 0;
    border-radius: 3px;
    font-size: 13px;
    padding: 10px 20px;
    color: #495061;
    background: #EAF4FE;
    border: 1px solid #D4E8FD;
  .notification-title {
    letter-spacing: 1px;
    text-transform: uppercase;
    font-size: 10px;
    color: #2589F3;
  }
  }
</style>
