<template>
  <div>
    <card>

<!--      <modal v-if="showModal">-->
<!--        <div slot="body">-->
<!--          <div class="col-md-12" v-if="attachmentModal">-->
<!--            <div class="row">-->
<!--              <div class="col-md-12">-->
<!--                <strong>Attachments</strong>-->
<!--                <div class="input-group mb-3">-->
<!--                  <div class="col-md-6 mt-3">-->
<!--                    <div class="custom-file">-->
<!--                      <input type="file" class="custom-file-input" id="inputGroupFile01" @change="selectedFile" accept=".pdf">-->
<!--                      <label class="custom-file-label" for="inputGroupFile01">{{fileData!==''? fileData.name:'Choose File'}}</label>-->
<!--                    </div>-->
<!--                    <div v-if="fileDataError">-->
<!--                      <div-->
<!--                        class="error"-->
<!--                        v-if="!$v.fileData.required"-->
<!--                      >-->
<!--                        This field is required-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div class="col-md-6 mt-3">-->
<!--                    <select class="custom-select" v-model="selectedAttachmentTypeId">-->
<!--                      <option selected>Select Attachment type</option>-->
<!--                      <option :value="attachment.attachmentTypeId" v-for="(attachment,index) in attachmentTypes">{{attachment.attachmentTypesName}}</option>-->
<!--                    </select>-->
<!--                    <div v-if="selectedAttachmentTypeIdError">-->
<!--                      <div class="error" v-if="!$v.selectedAttachmentTypeId.required">-->
<!--                        This field is required-->
<!--                      </div>-->
<!--                    </div>-->

<!--                  </div>-->
<!--                  <div class="col-md-12 mt-3">-->
<!--                    <div class="form-group">-->
<!--                      <textarea class="form-control" placeholder="Description" rows="3" v-model="description"/>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div class="col-md-12 mt-2">-->
<!--                    <button class="btn btn-default btn-fill float-right" @click="closeModal">Close</button>-->
<!--                    <button class="btn btn-success float-right mr-3" @click="uploadFile">Save</button>-->
<!--                  </div>-->
<!--                  <div class="col-md-12">-->
<!--                    <div class="text-center">-->
<!--                      <pulse-loader-->
<!--                        :loading="loading"-->
<!--                        :color="color"-->
<!--                        :size="size"-->
<!--                        style="margin-top: 4px;"-->
<!--                      />-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div class="col-md-12 mt-3">-->
<!--                    <div class="legend">-->
<!--                      <h6 class="legend-title">-->
<!--                        Files-->
<!--                      </h6>-->
<!--                      <div class="col-md-12">-->
<!--                        <strong>Icons</strong><br/>-->
<!--                        <span class="mr-2"><strong class="mr-2">Print</strong>-->
<!--                          <font-awesome-icon icon="print" :style="{ color: 'green' }" size="lg"/>-->
<!--                        </span>-->
<!--                        <span>-->
<!--                          <strong class="mr-2">Delete</strong>-->
<!--                          <font-awesome-icon icon="trash-alt" :style="{ color: 'red' }" size="lg"/>-->
<!--                        </span>-->
<!--                      </div>-->
<!--                      <card>-->
<!--                        <table class="table">-->
<!--                          <thead>-->
<!--                          <tr>-->
<!--                            <th scope="col">#</th>-->
<!--                            <th scope="col">File Type</th>-->
<!--                            <th scope="col">Action</th>-->
<!--                          </tr>-->
<!--                          </thead>-->
<!--                          <tbody>-->
<!--                          <tr v-for="(file,index) in uploadedFiles">-->
<!--                            <th scope="row">{{index + 1}}</th>-->
<!--                            <td>{{file.attachmentType? file.attachmentType.attachmentTypesName:''}}</td>-->
<!--                            <td>-->
<!--                              <span class="ml-4 mr-5 pointer" @click="downloadFile(downloadFile(file.attachmentUrl))">-->
<!--                                <font-awesome-icon icon="print" :style="{ color: 'green' }" size="lg"/>-->
<!--                              </span>-->
<!--                              <span class="pointer">-->
<!--                                <font-awesome-icon icon="trash-alt" :style="{ color: 'red' }" size="lg" @click="deleteAttachment(file.id)"/>-->
<!--                              </span>-->
<!--                            </td>-->
<!--                          </tr>-->
<!--                          </tbody>-->
<!--                        </table>-->
<!--                      </card>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </modal>-->
      <div class="row">
        <div class="col-md-12">
          <div class="text-center">
            <pulse-loader
              :loading="loading"
              :color="color"
              :size="size"
              style="margin-top: 4px;"
            />
          </div>
        </div>
      </div>
      <div class="legend">
        <h6 class="legend-title">Delivery Order Request Details</h6>

        <div class="row div-table-row">
          <div class="col-md-2 div-table div-table-title">BL of Lading</div>
          <div class="col-md-3 div-table div-table-value">
            {{
            orderRequest.blNumber != null
            ? orderRequest.blNumber
            : "Not Specified"
            }}
          </div>
          <div class="col-md-2 div-table div-table-title">Consignee Name</div>
          <div class="col-md-2 div-table div-table-value">
            {{
            orderRequest.consigneeName != null
            ? orderRequest.consigneeName
            : "Not Specified"
            }}
          </div>
          <div class="col-md-1 div-table div-table-title">Delivery Type</div>
          <div class="col-md-2 div-table div-table-value">
            {{
            orderRequest.cargoDeliveryType != null
            ? orderRequest.cargoDeliveryType
            : "Not Specified"
            }}
          </div>
        </div>
        <br />
        <div class="row div-table-row">
          <div class="col-md-2 div-table div-table-title">Destination</div>
          <div class="col-md-2 div-table div-table-value">
            {{
            orderRequest.destination != null
            ? orderRequest.destination
            : "Not Specified"
            }}
          </div>
          <div class="col-md-2 div-table div-table-title">
            Consignee Phone Number
          </div>
          <div class="col-md-2 div-table div-table-value">
            {{
            orderRequest.mobileNumber != null
            ? orderRequest.mobileNumber
            : "Not Specified"
            }}
          </div>
          <div class="col-md-2 div-table div-table-title">Created Date</div>
          <div class="col-md-2 div-table div-table-value">
            {{
            orderRequest.createdDate != null
            ? dateTimeToHuman(orderRequest.createdDate)
            : "Not Specified"
            }}
          </div>
        </div>
        <br />
        <div class="row div-table-row">
          <div class="col-md-2 div-table div-table-title">Description</div>
          <div class="col-md-2 div-table div-table-value">
            {{
            orderRequest.description != null
            ? orderRequest.description
            : "Not Specified"
            }}
          </div>

          <div class="col-md-2 div-table div-table-title">Email</div>
          <div class="col-md-2 div-table div-table-value">
            {{
            orderRequest.emailAddress != null
            ? orderRequest.emailAddress
            : "Not Specified"
            }}
          </div>

          <div class="col-md-2 div-table div-table-title">Status</div>
          <div class="col-md-2 div-table div-table-value" v-bind:class="{ rejected: orderRequest.status==='REJECTED' ,created:orderRequest.status!=='REJECTED'}">
            {{
            orderRequest.status != null
            ? orderRequest.status
            : "Not Specified"
            }}
          </div>
          <div class="col-md-2 div-table div-table-title mt-2">Reject Reason</div>
          <div class="col-md-10 div-table div-table-value mt-2" v-bind:class="{ rejected: orderRequest.status==='REJECTED' ,created:orderRequest.status!=='REJECTED'}">
            {{
            orderRequest.rejectionReason != null
            ? orderRequest.rejectionReason
            : "Not Specified"
            }}
          </div>
        </div>
      </div>
<!--      <div class="legend">-->
<!--        <div class="row div-table-row">-->
<!--          <div class="col-md-2 div-table div-table-title">Upload Attachments</div>-->
<!--          <div class="col-md-10 div-table div-table-value">-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="input-group mb-3">-->

<!--          <div class="col-md-8 mt-3">-->
<!--            <select class="custom-select" v-model="selectedAttachmentTypeId">-->
<!--              <option selected>Select Attachment type</option>-->
<!--              <option :value="attachment.attachmentTypeId" v-for="(attachment,index) in attachmentTypes">{{attachment.attachmentTypesName}}</option>-->
<!--            </select>-->
<!--            <div v-if="selectedAttachmentTypeIdError">-->
<!--              <div class="error" v-if="!$v.selectedAttachmentTypeId.required">-->
<!--                This field is required-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="col-md-4 mt-3">-->
<!--            <div class="custom-file">-->
<!--              <input type="file"id="inputGroupFile01" ref="fileupload" @change="selectedFile" accept=".pdf">-->
<!--            </div>-->
<!--            <div v-if="fileDataError">-->
<!--              <div-->
<!--                class="error"-->
<!--                v-if="!$v.fileData.required"-->
<!--              >-->
<!--                This field is required-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="col-md-12 mt-3">-->
<!--            <div class="form-group">-->
<!--              <textarea class="form-control" placeholder="Description" rows="3" v-model="description"/>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="col-md-12 mt-2">-->
<!--            <button class="btn btn-success float-right mr-3" @click="uploadFile">Save</button>-->
<!--          </div>-->
<!--          <div class="col-md-12">-->
<!--            <div class="text-center">-->
<!--              <pulse-loader-->
<!--                :loading="loading"-->
<!--                :color="color"-->
<!--                :size="size"-->
<!--                style="margin-top: 4px;"-->
<!--              />-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->

      <div class="legend">
        <div class="row div-table-row">
          <div class="col-md-2 div-table div-table-title">Request Attachments</div>
          <div class="col-md-10 div-table div-table-value">
          </div>
        </div>
        <table class="table">
          <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">File Type</th>
            <th scope="col">Action</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(file,index) in uploadedFiles" :key="index">
            <th scope="row">{{index + 1}}</th>
            <td>{{file.attachmentType? file.attachmentType.attachmentTypesName:''}}</td>
            <td>
                <span class="ml-4 mr-5 pointer" @click="getFileFromMinio(file.attachmentUrl , 'ATTACHMENT')">
                  <font-awesome-icon icon="print" :style="{ color: 'green' }" size="lg"/>
                </span>
              <span class="pointer" v-if="orderRequest.status==='CREATED'">
                <font-awesome-icon icon="trash-alt" :style="{ color: 'red' }" size="lg" @click="deleteAttachment(file.id)"/>
              </span>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <br />
      <div class="legend" v-if="orderRequest.status === 'ACCEPTED'">
        <h6 class="legend-title">Bill of Lading Details</h6>

        <div class="row div-table-row">
          <div class="col-md-1 div-table div-table-title">
            Consignee Address
          </div>
          <div class="col-md-3 div-table div-table-value">
            {{
            blDetails[0].consigneeAddress != null
            ? blDetails[0].consigneeAddress
            : "Not Specified"
            }}
          </div>

          <div class="col-md-2 div-table div-table-title">Notifiy Address.</div>
          <div class="col-md-2 div-table div-table-value">
            {{
            blDetails[0].notifyAddress != null
            ? blDetails[0].notifyAddress
            : "Not Specified"
            }}
          </div>

          <div class="col-md-1 div-table div-table-title">Description.</div>
          <div class="col-md-3 div-table div-table-value">
            {{
            blDetails[0].goodDetails? blDetails[0].goodDetails[0].description != null ? blDetails[0].goodDetails[0].description:'Not Specified' : "Not Specified"
            }}
          </div>
        </div>
      </div>
    </card>
    <global-modal v-if="globalLoader">
      <div slot="globalLoader">
        <div class="row text-center">
          <div class="col-md-12">
            Downloading requested file, Please wait
          </div>
          <div class="col-md-12">
            <br>
          </div>
          <div class="col-md-12">
            <pulse-loader
              :loading="loading"
              :color="color"
              :size="size"
            />
          </div>
        </div>
      </div>
    </global-modal>
  </div>
</template>

<script>
  import DCAPI from "../../../api/dc";
  import AGENCY_API from "../../../api/agency";
  import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
  import {required} from "vuelidate/lib/validators";
  import Modal from '../../../components/Inputs/Modal';
  import { mapActions, mapGetters } from 'vuex';
  import GlobalModal from '../../../components/globalLoader'

  export default {
    name: "deliveryOrderRequestDetails",

    components:{
      PulseLoader,
      GlobalModal,
      Modal
    },

    validations:{
      selectedAgent:{required},
      description:{required},
      selectedAttachmentTypeId:{required},
      fileData:{required},

    },

    created() {
      this.getBlNumberDetails();
      this.getAttachmentTypes();
      this.listAllFiles();
    },

    computed: {

      ...mapGetters('global', [

        'getGlobalLoader'

      ])

    },

    data() {
      return {
        uploadedFiles:[],
        blDetails: [{}],
        orderRequestId: {},
        orderRequest: {},
        globalLoader: false,
        loader: false,
        doRequest: {},
        showModal:false,
        color: 'green',
        size: '12px',
        loading: false,
        cancelReason:"",
        attachmentModal:false,
        selectedAgentError:false,
        selectedAttachmentTypeIdError:false,
        fileDataError:false,
        selectedRequestId:'',
        fileData:'',
        selectedAttachmentTypeId:'',
        attachmentTypes:[],
        description:"",


      };
    },
    methods: {

      ...mapActions('global', [

      'loadFileFromMinio'

    ]),

    loadParameters (fileUrl, fileName) {

      return new Promise((resolve) => {

        this.$store.dispatch('global/fileUrling', fileUrl)
        this.$store.dispatch('global/fileNaming', fileName)

        resolve()
      })

    },

    getFileFromMinio (fileUrl, fileName) {
        console.log(3187382)
        console.log(fileUrl);

      this.loadParameters(fileUrl, fileName).then(() => {

      this.loadFileFromMinio({}).then((res) => {

          if(res.status === 204) {

              this.loadNotification('warn', 4000, 'File Download',
              'File not found, please contact system admin',
              'response', 1000 , true  , true)

          } else {

            this.loadNotification('success', 4000, 'File Download',
              'File has been successfully downloaded, please have a look at it',
              'response', 1000 , true  , true)


          }

            this.globalLoader = this.getGlobalLoader

        }).catch(() => {

          this.loadNotification('error', 4000, 'File Download',
              'File not downloaded, please check your internet connection',
              'response', 1000 , true  , true)

          this.globalLoader = this.getGlobalLoader

        })

        this.globalLoader = this.getGlobalLoader

      }).catch(() => {

      })

    },

      selectedFile(event){
        console.log(event)
        this.fileData = event.target.files[0]
      },


      getBlNumberDetails() {
        this.orderRequestId = this.$route.params.blNumber;
        AGENCY_API.getSingleDeliveryOrderRequest(this.orderRequestId).then((response) => {
            this.orderRequest = response.data.data;

            if (this.orderRequest.status === "ACCEPTED") {
              DCAPI.getBlDetails(this.orderRequest.blNumber,this.orderRequest.voyageNo)
                .then((response) => {
                  console.log("kk",response);
                  this.blDetails = response.data.data;
                })
                .catch((error) => {
                  console.log(error);
                });
            }else {
              this.blDetails=[{}];
            }
          })
          .catch((error) => {
            console.log(error);
          });
      },

      listAllFiles(){
        this.loading=true;
        AGENCY_API.getDeliveryOrderAttachments(this.$route.params.blNumber).then((resp)=>{
          this.uploadedFiles=resp.data.data;
          this.loading=false;
        }).catch(()=>{
          this.loading=false;
        });
      },

      uploadFile(){

        if (!this.$v.selectedAttachmentTypeId.$invalid && !this.$v.fileData.$invalid) {
          this.loading=true;
          this.selectedAttachmentTypeIdError=false;
          this.fileDataError=false;

          let formData = new FormData();
          formData.append('file', this.fileData);

          AGENCY_API.uploadDOattachment(formData, this.selectedAttachmentTypeId,this.$route.params.blNumber,this.description).then((resp)=>{
            this.selectedAttachmentTypeId='';
            this.description='';
            this.fileData='';
            this.loading=false;
            this.listAllFiles();

            let form = document.getElementById('form');
            this.$refs.fileupload.value=null;
            parentNode.insertBefore(f,ref);
          }).catch((e)=>{
            this.loading=false;
          })
        }else {
          this.selectedAttachmentTypeIdError=true;
          this.fileDataError=true;
        }

      },

      deleteAttachment(attachmentId){
        this.loading=true;
        AGENCY_API.deleteDOAttachment(attachmentId).then((resp)=>{
          this.listAllFiles();
          this.loading=false;
        }).catch((err)=>{
          this.loading=false;
        })
      },

      getAttachmentTypes(){
        AGENCY_API.getDeliveryOrderAttachmentsTypes().then((resp)=>{
          this.attachmentTypes=resp.data.data;
        });
      },

      openModal(type=null){
        if(type==='ATTACHMENTS'){
          this.attachmentModal=true;
          this.showModal=true;
          this.selectedRequestId=this.$route.params.blNumber;
          this.listAllFiles();
        }
      },

      closeModal(){
        this.showModal=false;
        this.attachmentModal=false;
        this.selectedRequestId="";
      },
    },
  };
</script>

<style scoped>
  .div-table-row {
    padding: 0 15px 0 15px;
  }

  .div-table {
    padding: 9px 8px 9px 8px;
  }

  .div-table-title {
    width: 250px;
    background-color: rgba(153, 186, 221, 0.5);
    font-weight: normal;
  }

  .div-table-value {
    border: 1px solid #dcdcdc;
    font-weight: 900;
  }

  label {
    position: relative;
    top: -7px;
    right: 0;
    margin: 0;
    padding: 0;
  }
  tbody tr {
    color: #212529 !important;
    font-weight: 400 !important;
  }

  thead th {
    font-weight: 600 !important;
  }

  .legend {
    position: relative;
    border: 1px solid #dcdcdc;
    padding: 1.5rem 1rem 1rem 1rem;
    margin-bottom: 1rem;
    border-radius: 3px;
    margin-top: 1rem;
  }

  .legend-title {
    position: absolute;
    top: -0.6rem;
    padding: 0.2rem 1rem;
    background-color: white;
    color: #d59a18;
  }
  .pointer{
    cursor: pointer;
  }

  .rejected{
    color: red;
  }

  .created{
    color: green;
  }

  .pointer{
    cursor: pointer;
  }
</style>
