<template>
    <div>
        <card>
            <div class="row">
                <div class="col-md-4"></div>
                <div class="col-md-4">
                    <label>Select Operation Type</label>
                    <select class="form-control" v-model="stuffingType" @change="loadPageableRequestsAfterCreated">
                        <option disabled value="">Select Operation Type
                        </option>
                        <option v-for="operationType in operationTypes" :value="operationType">
                            {{ operationType }}
                        </option>
                    </select>
                </div>
                <div class="col-md-4"></div>
            </div>
            <br>
            <hr>
            <br>
            <data-table
                :table-headers="tableHeaders"
                :table-data="requests"
                :table-data-keys="tableDataKeys"
                :page-size-prop="pageSize"
                :page-no="pageNo"
                :my-loader="myLoader"
                :reload-all-request="reloadAllRequest"
                :my-first-loader="myFirstLoader"
                :no-data-found="noDataFound"
                :search-parameter-prop="searchParameter"
                :loading-title="'Please wait while system is loading tally sheets'"
                :has-manage-button="true"
                :placeholder="'Search by voyage or call id'"
                :total-elements="totalElements"
                :total-pages="totalPages"
                :is-first="isFirst"
                :is-last="isLast"
                :page-number="pageNumber"
                @onChange="onChange"
                @goToPage="goToPage"
                @goToApplication="goToApplication"
            />
        </card>
        <global-modal v-if="myLoader">
            <div slot="globalLoader">
                <div class="row text-center">
                    <div class="col-md-12">
                        Loading, Please wait
                    </div>
                    <div class="col-md-12">
                        <br>
                    </div>
                    <div class="col-md-12">
                        <pulse-loader
                            :loading="loading"
                            :color="color"
                            :size="size"
                        />
                    </div>
                </div>
            </div>
        </global-modal>
    </div>
</template>

<script>
    import {mapGetters, mapActions} from 'vuex'
    import PulseLoader from 'vue-spinner/src/PulseLoader'
    import GlobalModal from '../../../components/globalLoader'
    import DataTable from '../../../components/DataTable/updatedTable'
    import Modal from '../../../components/Inputs/Modal'
    import {ModelListSelect} from 'vue-search-select'

    export default {

        name: 'ShippingTally',

        components: {

            PulseLoader,
            GlobalModal,
            DataTable,
            Modal,
            ModelListSelect

        },

        created() {
            this.$store.dispatch('pagenames/currentPageName', 'Shipping Tally Sheet');

            this.finalUserPermissions = JSON.parse(localStorage.getItem('browser-local-permits'));
            this.loadPageableRequestOnCreated();
        },
        computed: {

            ...mapGetters('notifications', [

                'getNewAppPage'

            ]),

            ...mapGetters('shippingtallying', [

                'getRequestList',
                'getPageCount',
                'getMyFirstLoader',
                'getNoDataFound',
                'getSuccessAlert',
                'getErrorMessage',
                'getErrorAlert',
                'getSuccessMessage',
                'getReloadAllRequest',
                'getMyLoader',
                'getSearchParameter',
                'getHasSearchedVariable',
                'getPageNo',
                'getRequestId',
                'getTotalElement',
                'getTotalPage',
                'getPageNumber',
                'getIsFirst',
                'getIsLast'
            ])

        },

        data() {
            return {

                pageNo: 0,
                pageSize: 15,
                totalElements: '',
                totalPages: '',
                isFirst: false,
                isLast: false,
                pageNumber: '',
                stuffingType: 'DISCHARGING',

                color: 'green',
                size: '12px',
                loading: true,

                searchParameter: '',
                hasSearchedVariable: false,
                reloadAllRequest: false,
                noDataFound: false,
                pdfFormatError: false,
                loadAllHeaderLoader: false,
                allAttachmentVerified: false,
                myLoader: false,
                myFirstLoader: false,
                isSearchable: false,
                requests: [],

                ifThereIsParam: '',

                finalUserPermissions: [],
                tableHeaders: ['Voyage', 'Vessel Call ID', 'Completed', 'Created At'],
                tableDataKeys: [ 'voyage', 'vesselCallId', 'completed', 'createdAt'],

                operationTypes: ['DISCHARGING', 'LOADING', 'STUFFING', 'DESTUFFING']

            }
        },

        methods: {

            ...mapActions('shippingtallying', [

                'loadShippingTallySheetsOnCreated',
                'loadShippingTallySheets',
                'loadShippingTallySheetsForStuffingType'

            ]),

            loadParameters() {
                return new Promise((resolve) => {
                    this.$store.dispatch('shippingtallying/pageSizing', this.pageSize)
                    this.$store.dispatch('shippingtallying/pageNumbering', this.pageNo)
                    this.$store.dispatch('shippingtallying/stuffingtyping', this.stuffingType)

                    resolve()
                })
            },

            setAllGetters(flag) {

                this.requests = this.getRequestList
                flag === 'afterCreated' ? this.myLoader = this.getMyLoader : ''
                flag === 'onCreated' ? this.myFirstLoader = this.getMyFirstLoader : ''
                this.noDataFound = this.getNoDataFound
                this.SuccessMessage = this.getSuccessAlert
                this.ErrorMessage = this.getErrorMessage
                this.ErrorAlert = this.getErrorAlert
                this.SuccessAlert = this.getSuccessMessage
                this.pageNo = this.getPageNo
                this.pageSize = this.getPageSize

                this.totalElements = this.getTotalElement
                this.totalPages = this.getTotalPage
                this.pageNumber = this.getPageNumber
                this.isFirst = this.getIsFirst
                this.isLast = this.getIsLast

            },

            loadPageableRequestOnCreated() {
                this.loadParameters().then(() => {
                    this.loadShippingTallySheetsOnCreated({}).then(() => {
                        this.setAllGetters('onCreated')
                    }).catch(() => {

                    })

                    this.myFirstLoader = this.getMyFirstLoader
                }).catch(() => {

                })
            },

            loadPageableRequestsAfterCreated() {
                if (this.stuffingType === 'DESTUFFING' || this.stuffingType === 'STUFFING' ){
                  this.loadPageableStuffTypeRequestsAfterCreated()
                }else{
                  this.loadParameters().then(() => {
                    this.loadShippingTallySheets({}).then(() => {
                      this.setAllGetters('afterCreated')
                    })

                    this.myLoader = this.getMyLoader
                  }).catch(() => {

                  })
                }
            },

          loadPageableStuffTypeRequestsAfterCreated(){
              this.loadParameters().then(()=>{
                this.loadShippingTallySheetsForStuffingType({}).then(()=>{
                  this.setAllGetters('afterCreated')
                })
              }).catch(()=>{

              })
          },

            onChange(event) {
                this.$store.dispatch('shippingtallying/pageSizing', event)
                this.$store.dispatch('shippingtallying/pageNumbering', 0)

                this.pageSize = this.getPageSize
                this.pageNo = this.getPageNo

                if (this.getHasSearchedVariable) {
                    this.searchRequestByName(this.getSearchParameter)
                } else {
                    this.loadPageableRequestsAfterCreated()
                  }

            },

            goToApplication(documentId) {
                document.cookie = 'browser-local-cashed-url-back=' + this.$route.fullPath + ';Path = /;SameSite = Strict'
                // this.$router.push('/shipping-tallying/shipping-tally-operations-reports/' + documentId.id)
                this.$router.push('/shipping-tallying/shipping-tally-operations-reports/'  + this.stuffingType+ '/' + documentId.id)

            },

            goToPage(page) {
                this.pageNo = page

                this.loadPageableRequestsAfterCreated()
            }
        }
    }

</script>

<style scoped>

</style>
