<template>
  <div>
    <card>
      <data-table
        :table-headers="tableHeaders"
        :table-data="requests"
        :table-data-keys="tableDataKeys"
        :my-first-loader="myFirstLoader"
        :page-size-prop="pageSize"
        :page-no="pageNo"
        :is-pageable="isPageable"
        :total-elements="totalElements"
        :total-pages="totalPages"
        :is-first="isFirst"
        :is-last="isLast"
        :red-status-key="redStatusKey"
        :no-data-found="noDataFound"
        :page-number="pageNumber"
        @goToPage="goToPage"
        :placeholder="'Search by Delivery Order Number'"
        :date-prop="'Created At '"
        :has-manage-button="true"
        :reload-all-request="reloadRequests"
        @searchRequest="searchDONumber"
        @reloadByIcon="reloadByIcon"
        @onChange="onChange"
        @goToApplication="goToApplication"
        :loading-title="currentLoadingTitle"
      />

    </card>
  </div>
</template>

<script>
  import PulseLoader from 'vue-spinner/src/PulseLoader';
  import GlobalModal from '../../../components/globalLoader';
  import DataTable from '../../../components/DataTable/updatedTable';
  import { mapActions, mapGetters } from 'vuex';
  import AGENCYAPI from '../../../api/agency';

  export default {
    name: "approveDeliveryOrderRequests",
    components: {
      PulseLoader,
      GlobalModal,
      DataTable
    },
    created() {
      this.getRequests();
    },
    data(){
      return{
        tableHeaders:['Cargo Delivery Type','Destination','BlNumber','consignee Name','Voyage Number','Email','Mobile Number','Description','Status','Date'],
        tableDataKeys:['cargoDeliveryType','destination','blNumber','consigneeName','voyageNumber','emailAddress','mobileNumber','description','status','createdDate'],
        requests:[],
        myFirstLoader:false,
        pageNo: 0,
        pageSize: 15,
        isPageable:true,
        totalElements: '',
        totalPages: '',
        isFirst: false,
        isLast: false,
        myLoader: false,
        noDataFound:false,
        pageNumber: '',
        reloadRequests: false,
        currentLoadingTitle:'',
        redStatusKey:{
          key:'status',
          value:"REJECTED"
        },
      }
    },
    computed:{
      ...mapGetters('deliveryorderrequest', [
        'getMyFirstLoader',
        'getRequestList',

      ])
    },
    methods:{

      ...mapActions('deliveryorderrequest',[
        'loadDoRequests'
      ]),

      getRequests(){
        this.loadDoRequests({}).then(()=>{
          this.myFirstLoader = this.getMyFirstLoader;

          this.getRequestList.content.map((data)=>{
            let type=this.resolveDeliveryType(data.cargoDeliveryType);
            data.cargoDeliveryType=type;
          });

          this.getRequestList.content.map((data)=>{
            if(data.status==="ACCEPTED"){
              this.requests.push(data)
            }
          });

          this.isLast = this.getRequestList.last;
          this.totalElements = this.getRequestList.totalElements;
          this.totalPages = this.getRequestList.totalPages;
          this.pageNumber = this.getRequestList.number;
          this.isFirst = this.getRequestList.first;
          this.noDataFound = this.getRequestList.empty;
        })
      },

      goToPage (page) {
        this.pageNo = page;
        this.loadPageableDeliveryOrdersByStatus();
      },
      searchDONumber (searchParam) {
        // this.requests = [];
        if (searchParam !== null && searchParam !== 'undefined') {
          this.$store.dispatch('deliveryOrder/searchingParameter', searchParam);
          this.searchDeliveryOrders({}).then(() => {
            this.requests = this.extractAgentFromObject(JSON.parse(JSON.stringify([this.getRequestList])));
            this.isPageable = false;
          }).catch(() => {

          });
          this.reloadRequests = true;
        }
      },
      reloadByIcon () {
        this.reloadRequests = false;
        this.isPageable = true;
        this.requests = [];
        this.loadPageableDeliveryOrdersByStatus();
      },
      onChange (event) {
        this.pageSize = event;
        this.pageNo = 0;
        this.loadPageableDeliveryOrdersByStatus();
      },
      goToApplication (data) {
        this.$router.push('/shipping-agency/requests-details/delivery-order/' + data.id);
      },
      getCurrentLoadingTitle(){
        this.currentLoadingTitle =  "Please wait while the system is loading " + this.currentStatus.toLowerCase() + " delivery orders"
      },

      resolveDeliveryType(type){

        if(type==='LOCAL_DELIVERY'){
          return 'Local Delivery';
        }else if(type==='TRANSIT'){
          return 'Transit';
        }else {
          return 'Not Specified';
        }
      }
    }
  }
</script>

<style scoped>

</style>
