<template>
  <div>
    <card>
      <div class="row">
        <div class="col-md-12">
          <crud-notification
            :error-message="ErrorMessage"
            :success-message="SuccessMessage"
            :error-alert="ErrorAlert"
            :success-alert="SuccessAlert"
            @fadeMe="fadeMe"
            @enter="enter"
          />
        </div>
      </div>

<!--      <div class="row">-->
<!--        <div class="col-md-12">-->
<!--          <button-->
<!--            class="btn btn-success custom-btn mr-2"-->
<!--            style="float: right"-->
<!--            @click.prevent="addAssignment"-->
<!--          >-->
<!--            Add Assignment-->
<!--          </button>-->
<!--        </div>-->
<!--      </div>-->

      <br>
      <div class="legend">
        <h6 class="legend-title">
          Cargo Details
        </h6>

        <div class="row div-table-row">
          <div class="col-md-1 div-table div-table-title">
            Cargo Name
          </div>
          <div class="col-md-4 div-table div-table-value">
            {{ cargoDetails.cargoName }}
          </div>
          <div class="col-md-1 div-table div-table-title">
            No. Of Packages
          </div>
          <div class="col-md-2 div-table div-table-value">
            {{ cargoDetails.numberOfPackages ? cargoDetails.numberOfPackages : 'N/A' }}
          </div>
          <div class="col-md-2 div-table div-table-title">
            Shipping Order Status
          </div>
          <div class="col-md-2 div-table div-table-value">
            {{ cargoDetails.shippingOrderStatus }}
          </div>
        </div>
        <br>
        <div class="row div-table-row">
          <div class="col-md-1 div-table div-table-title">
            Cargo Description
          </div>
          <div class="col-md-5 div-table div-table-value">
            {{ cargoDetails.cargoDescription }}
          </div>
          <div class="col-md-1 div-table div-table-title">
            Volume (CBM)
          </div>
          <div class="col-md-2 div-table div-table-value">
            {{ cargoDetails.volumeCbm }}
          </div>
          <div class="col-md-1 div-table div-table-title">
            Weight
          </div>
          <div class="col-md-2 div-table div-table-value">
            {{ cargoDetails.weight }} ( {{ cargoDetails.weightUnit }} )
          </div>
        </div>
        <br>
        <div class="row div-table-row">
          <div class="col-md-1 div-table div-table-title">
            Special Cargo
          </div>
          <div class="col-md-2 div-table div-table-value">
            {{ cargoDetails.specialCargos ? cargoDetails.specialCargos: 'NONE' }}
          </div>
          <div class="col-md-1 div-table div-table-title">
            Package Type
          </div>
          <div class="col-md-2 div-table div-table-value">
            {{ cargoDetails.packageType }}
          </div>
          <div class="col-md-1 div-table div-table-title">
            Containers
          </div>
          <div class="col-md-2 div-table div-table-value">
            {{ cargoDetails.containers ? cargoDetails.containers : 'NONE' }}
          </div>
          <div class="col-md-1 div-table div-table-title">
            Created At
          </div>
          <div class="col-md-2 div-table div-table-value">
            {{ cargoDetails.createdAt ? dateToHuman(cargoDetails.createdAt) : 'N/A' }}
          </div>
        </div>
      </div>

<!--      <div class="legend">-->
<!--        <h6 class="legend-title">-->
<!--          Assigned Activities-->
<!--        </h6>-->

<!--        <data-table-->
<!--          :table-headers="tableHeaders"-->
<!--          :table-data="requests"-->
<!--          :table-data-keys="tableDataKeys"-->
<!--          :my-first-loader="myFirstLoader"-->
<!--          :is-pageable="false"-->
<!--          :no-data-found="noDataFound"-->
<!--          :is-first="true"-->
<!--          :is-last="false"-->
<!--          :date-prop="'Created At '"-->
<!--          :has-manage-button="true"-->
<!--          :has-search-form="false"-->
<!--          :has-view-button="true"-->
<!--          :has-edit-button="true"-->
<!--          :has-delete-button="true"-->
<!--          @goToDelete="goToDelete"-->
<!--          @goToEdit="goToEdit"-->
<!--          @goToView="goToView"-->
<!--          @goToApplication="goToApplication"-->
<!--          :loading-title="'Please wait while system is loading Outward Activities'"-->
<!--        />-->
<!--      </div>-->

      <div class="row">
        <div class="col-md-12">
          <button
            class="btn btn-default btn-fill btn-space"
            style="float:right;"
            @click.prevent="goBack"
          >
            <font-awesome-icon icon="undo" /> &nbsp; Back
          </button>
        </div>
      </div>
    </card>
    <modal v-if="showModal">
      <div
        slot="header"
        class="modal-title col-md-12"
      >
        <div class="row">
          <div class="col-md-12">
            <h6>
              <b v-if="isSave"> Assign Activity</b>
              <b v-if="!isSave"> Edit Activity Assignment</b>
            </h6>
          </div>
          <div class="col-md-12">
            <hr>
          </div>
        </div>
      </div>
      <div slot="body">
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label>Activity</label>
              <model-list-select
                :list="getactivity"
                v-model="activity.activityId"
                option-value="id"
                :custom-text="activityName"
                class="form-control"
                placeholder="Select Activity"
              />
              <div
                class="error text-danger"
                v-if="activityIdError"
              >
                <span v-if="!$v.activity.activityId.required">Field is required</span>
              </div>
            </div>
            <div class="form-group">
              <label>Officers</label>
              <model-list-select
                :list="allUsers"
                v-model="activity.officerId"
                option-value="id"
                :custom-text="OfficerName"
                class="form-control"
                placeholder="Select Officer"
              />
              <div
                class="error text-danger"
                v-if="officerIdError"
              >
                <span v-if="!$v.activity.officerId.required">Field is required</span>
              </div>
            </div>
            <div class="form-group">
              <label for="description">Description</label>
              <textarea
                class="form-control"
                id="description"
                placeholder="Description"
                v-model="activity.descrition"
                rows="3"
              />
              <div
                class="error text-danger"
                v-if="descritionError"
              >
                <span v-if="!$v.activity.descrition.required">Field is required</span>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-5">
          <div class="col-md-12">
            <button
              class="btn btn-fill btn-default float-right mr-2"
              @click="closeModal"
            >
              Close
            </button>
            <button
              type="submit"
              class="btn btn-primary mr-3 float-right"
              v-if="isSave"
              @click.prevent="assignActivityFunc"
            >
              Save
            </button>
            <button
              type="submit"
              class="btn btn-primary mr-3 float-right"
              v-if="!isSave"
              @click.prevent="editActivityFunc"
            >
              Edit
            </button>
          </div>
        </div>
      </div>
    </modal>
    <modal v-if="isView">
      <div
        slot="header"
        class="modal-title col-md-12"
      >
        <div class="row">
          <div class="col-md-12">
            <h6>
              <b> View Activity Details</b>
            </h6>
          </div>
          <div class="col-md-12">
            <hr>
          </div>
        </div>
      </div>
      <div slot="body">
        <div class="row">
          <div class="col-md-4">
            <base-input
              type="text"
              label="Activity Name"
              placeholder="Activity Name"
              :value="assignmentActivityDetails.activityName"
              class="special-bottom"
              :readonly="true"
            />
          </div>

          <div class="col-md-4">
            <base-input
              type="text"
              label="Assigned Officer"
              placeholder="Assigned Officer"
              :value="assignmentActivityDetails.officerDetails"
              class="special-bottom"
              :readonly="true"
            />
          </div>
          <div class="col-md-4">
            <base-input
              type="text"
              label="Assigned Officer Email"
              placeholder="Assigned Officer Email"
              :value="assignmentActivityDetails.officerEmail"
              class="special-bottom"
              :readonly="true"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <base-input
              type="text"
              :is-text-area="true"
              label="Activity Description"
              placeholder="Activity Description"
              :value="assignmentActivityDetails.descrition"
              class="special-bottom"
              :readonly="true"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <base-input
              type="text"
              label="Date of Creation"
              placeholder="Date of Creation"
              :value="dateToHuman(assignmentActivityDetails.createdAt)"
              class="special-bottom"
              :readonly="true"
            />
          </div>
          <div class="col-md-6">
            <base-input
              type="text"
              label="Completion Status "
              placeholder="Completion Status "
              :value="assignmentActivityDetails.completionStatus"
              class="special-bottom"
              :readonly="true"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <base-input
              type="text"
              :is-text-area="true"
              label="Additional Activity Description"
              placeholder="Additional Activity Description"
              :value="assignmentActivityDetails.activity.description"
              class="special-bottom"
              :readonly="true"
            />
          </div>
        </div>
        <div class="row mt-5">
          <div class="col-md-12">
            <button
              class="btn btn-fill btn-default float-right mr-2"
              @click="closeModal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </modal>
    <global-modal v-if="myFirstLoader">
      <div slot="globalLoader">
        <div class="row text-center">
          <div class="col-md-12">
            Loading, Please wait
          </div>
          <div class="col-md-12">
            <br>
          </div>
          <div class="col-md-12">
            <pulse-loader
              :loading="loading"
              :color="color"
              :size="size"
            />
          </div>
        </div>
      </div>
    </global-modal>
  </div>
</template>

<script>
import AGENYAPI from '../../../api/agency';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue';
import Modal from '../../../components/Inputs/Modal';
import { required } from 'vuelidate/lib/validators';
import API from '../../../api';
import { ModelListSelect } from 'vue-search-select';
import CrudNotification from '../../../components/DataTable/crudNotification';
import { mapActions, mapGetters } from 'vuex';
import DataTable from '../../../components/DataTable/updatedTable';
import GlobalModal from '../../../components/globalLoader';
export default {
  name: 'ShippingOrderActivityAssignment',

  validations: {
    Cargodetails: {
      cargoDescription: { required },
      cargoName: { required },
      termOfDelivery: { required }
    },
    activity: {
      descrition: { required },
      officerId: { required },
      activityId: { required }
    }
  },

  components: {
    PulseLoader,
    Modal,
    ModelListSelect,
    CrudNotification,
    DataTable,
    GlobalModal
  },

  created () {
    this.$store.dispatch('pagenames/currentPageName', 'Cargo Details');
    this.cargoId = this.$route.params.cargoId;
    this.loadCargoDetailsOnCreated();
    this.loadCargoActivitiesOnCreated();

    this.getAllUsers();
  },
  computed: {
    ...mapGetters('shippingOrder', [
      'getRequestList',
      'getRequestList2',
      'getPageCount',
      'getMyFirstLoader',
      'getNoDataFound',
      'getSuccessAlert',
      'getErrorMessage',
      'getErrorAlert',
      'getSuccessMessage',
      'getReloadAllRequest',
      'getMyLoader',
      'getPageNo'
    ])

  },
  data () {
    return {
      cargos: [],
      assignmentActivityDetails: {},
      shippingOrder: {
        canceled: false,
        cityCode: null,
        closed: false,
        consigneeAddress: '',
        consigneeCity: '',
        consigneeCodeTin: '',
        consigneeContactPerson: '',
        consigneeCountry: '',
        consigneeEmail: '',
        consigneeName: '',
        consigneeTel: '',
        consigneeZipCode: '',
        countryCode: null,
        createdAt: '',
        customerCodeTansad: null,
        expectedArrivalDate: '',
        expectedDepatureDate: '',
        id: 1,
        lastUpdatedAt: '',
        notiyAddress: '',
        notiyCity: '',
        notiyCodeTin: '',
        notiyContactPerson: '',
        notiyCountry: '',
        notiyEmail: '',
        notiyName: '',
        notiyTel: '',
        notiyZipCode: '',
        partialClosed: false,
        shipperAddress: '',
        shipperCity: '',
        shipperCodeTin: '',
        shipperContactPerson: '',
        shipperCountry: '',
        shipperEmail: '',
        shipperName: '',
        shipperTel: '',
        shipperZipCode: '',
        shippingOrderContainers: null,
        shippingOrderGoods: null,
        shippingOrderNo: null,
        shippingOrderStatus: '',
        termOfDelivery: '',
        vessel: null
      },

      cargoId: '',

      color: 'green',
      size: '15px',

      ErrorMessage: '',
      SuccessAlert: false,
      ErrorAlert: false,
      SuccessMessage: '',
      loading: false,
      smallLoading: false,
      newCargo: false,
      cargoDetails: {},
      viewCargoDetails: false,
      isView: false,
      requests: [],
      tableHeaders: ['Activity Name', 'Completion Status', 'Assigned Officer', 'Officer Email'],
      tableDataKeys: ['activityName', 'completionStatus', 'officerDetails', 'officerEmail'],
      myFirstLoader: false,
      noDataFound: false,

      showModal: false,
      assignActivity: false,
      isSave: false,

      Cargodetails: {
        cargoDescription: '',
        cargoName: '',
        numberOfPackages: 0,
        packageType: '',
        specialCargos: [],
        volumeCbm: '',
        weight: '',
        weightUnit: 'G',
        termOfDelivery: ''
      },

      cargoDescriptionError: false,
      cargoNameError: false,
      termOfDeliveryError: false,

      allUsers: [],

      activity: {
        activityId: '',
        descrition: '',
        officerId: '',
        shippingOrderGoodId: '',
        shippingOrderId: parseInt(this.$route.params.shippingOrderId)
      },
      descritionError: false,
      officerIdError: false,
      activityIdError: false,
      selectedCargo: {},
      getactivity: [],
      cargoActivities: []

    };
  },

  methods: {
    ...mapActions('shippingOrder', [
      'deleteShippingOrderCargoActivity',
      'getShippingOrderGoodsById',
      'getShippingOrderGoodActivities',
      'assignShippingOrderCargoActivity',
      'updateShippingOrderCargoActivityAssignment'

    ]),

    loadCargoDetailsOnCreated () {
      this.$store.dispatch('shippingOrder/requestid', this.cargoId);
      this.getShippingOrderGoodsById({}).then(() => {
        this.myLoader = this.getMyFirstLoader;
        this.cargoDetails = this.getRequestList;
        this.shippingOrder = this.cargoDetails[0].shippingOrder;
      }).catch(() => {

      });
    },
    formatCargoActivities (data) {
      if (data !== null) {
        for (let i = 0; i < data.length; i++) {
          data[i].officerDetails = data[i].officer.firstName + ' ' +
                data[i].officer.middleName + ' ' + data[i].officer.lastName;
          data[i].activityName = data[i].activity.name;
          data[i].completionStatus = data[i].completed ? 'YES' : 'NO';
          data[i].officerEmail = data[i].officer.email;
        }
        return data;
      } else {
        this.noDataFound = true;
        return data;
      }
    },

    goBack () {
      history.back();
    },
    addAssignment () {
      this.isSave = true;
      this.showModal = true;
      this.assignActivity = true;
    },
    closeModal () {
      this.showModal = false;
      this.assignActivity = false;
      this.isView = false;
    },

    goToDelete (activityDetails) {
      this.$dialog.confirm('You are about to delete the Activity, are you sure?', {
        loader: true,
        html: true
      }).then((dialog) => {
        this.$store.dispatch('shippingOrder/requestid', activityDetails.id);
        this.deleteShippingOrderCargoActivity({}).then(() => {
          // this.smallLoading = this.getMyLoader;
          this.SuccessAlert = this.getSuccessAlert;
          this.ErrorAlert = this.getErrorAlert;
          this.SuccessMessage = ' Activity deleted.';
          this.loadCargoActivitiesOnCreated();
        }).catch((e) => {
          this.ErrorMessage = 'Failed To Delete Activity';
          this.ErrorAlert = true;
        });
        dialog.close();
      }).catch((e) => {});
    },
    goToEdit (activityDetails) {
      this.activity = {};
      this.activity.activityId = activityDetails.activity.id;
      this.activity.officerId = activityDetails.officer.id;
      this.activity.descrition = activityDetails.descrition;
      this.activity.shippingOrderGoodId = this.cargoId;
      this.activity.shippingOrderId = this.shippingOrder.id;
      this.activity.id = activityDetails.id;
      this.isSave = false;
      this.showModal = true;
      this.isView = false;
      this.assignActivity = true;
    },
    goToView (activityDetails) {
      this.assignmentActivityDetails = activityDetails;
      this.isView = true;
      this.showModal = false;
    },
    goToApplication (activityDetails) {
      document.cookie = 'browser-local-cashed-url-back=' + this.$route.fullPath + ';Path = /;SameSite = Strict';
      this.$router.push('/shipping-agency/booking/view/shippingorder/cargo-details/activity-progress/' + activityDetails.id);
    },

    assignActivityFunc () {
      if (this.activity.descrition === '' ||
          this.activity.activityId === '' ||
          this.activity.officerId === '') {
        this.activityIdError = true;
        this.officerIdError = true;
        this.descritionError = true;
      } else {
        this.activityIdError = false;
        this.officerIdError = false;
        this.descritionError = false;

        this.activity.shippingOrderGoodId = this.cargoId;
        this.activity.shippingOrderId = this.shippingOrder.id;

        this.$store.dispatch('shippingOrder/savingDetails', this.activity);

        this.assignShippingOrderCargoActivity({}).then(() => {
          this.SuccessMessage = 'Activity Assignment Saved Successfully';
          this.ErrorMessage = 'Sorry, Something went wrong...';
          this.ErrorAlert = this.getErrorAlert;
          this.SuccessAlert = this.getSuccessAlert;
          this.myFirstLoader = this.getMyFirstLoader;
          this.loadCargoActivitiesOnCreated();
          this.activity = {};
          this.closeModal();
        });
      }
    },

    editActivityFunc () {
      if (this.activity.descrition === '' ||
          this.activity.activityId === '' ||
          this.activity.officerId === '') {
        this.activityIdError = true;
        this.officerIdError = true;
        this.descritionError = true;
      } else {
        this.activityIdError = false;
        this.officerIdError = false;
        this.descritionError = false;

        this.activity.shippingOrderGoodId = this.cargoId;
        this.activity.shippingOrderId = this.shippingOrder.id;

        this.$store.dispatch('shippingOrder/savingDetails', this.activity);

        this.updateShippingOrderCargoActivityAssignment({}).then(() => {
          this.SuccessMessage = 'Activity Assignment Edited Successfully';
          this.ErrorMessage = 'Sorry, Something went wrong...';
          this.ErrorAlert = this.getErrorAlert;
          this.SuccessAlert = this.getSuccessAlert;
          this.myFirstLoader = this.getMyFirstLoader;
          this.loadCargoActivitiesOnCreated();
          this.activity = {};
          this.closeModal();
        });
      }
    },

    activityName (data) {
      return data.name;
    },

    /**
       * @return {string}
       */
    OfficerName (data) {
      return `${data.firstName}  ${data.lastName}`;
    },

    getAllActivities () {
      AGENYAPI.getActivities().then((response) => {
        this.getactivity = response.data.data;
      }).catch(() => {
        this.ErrorMessage = 'Failed To Load Activities';
        this.SuccessAlert = false;
        this.ErrorAlert = true;
        this.SuccessMessage = '';
      });
    },

    getAllUsers () {
      API.getUsers().then((response) => {
        this.allUsers = response.data.data;
      }).catch(() => {

      });
    },

    dateToHuman (stringDate) {
      if (stringDate !== null || true || stringDate !== 'undefined') {
        const splitDate = stringDate.split('T');
        return splitDate[0];
      }
    },

    enter: function (el, done) {
      var that = this;

      setTimeout(function () {
        that.SuccessAlert = false;
        that.ErrorAlert = false;
      }, 3000); // hide the message after 3 seconds
    },
    fadeMe: function () {
      if (this.SuccessAlert) {
        this.SuccessAlert = !this.SuccessAlert;
      } else {
        this.ErrorAlert = !this.ErrorAlert;
      }
    }

  }
};
</script>

<style scoped>
.legend {
    position: relative;
    border: 1px solid #DCDCDC;
    padding: 1.5rem 1rem 1rem 1rem;
    margin-bottom: 1rem;
    border-radius: 3px;
    margin-top: 1rem;
  }

  .legend-title {
    background-color: inherit;
    position: absolute;
    top: -0.6rem;
    padding: 0.2rem 1rem;
    background-color: white;
    color: #d59a18;
  }

  .div-table-value{
    border: 1px solid #DCDCDC;
  }
  .div-table-row {
    padding: 0 15px 0 15px;
  }

  .div-table {
    padding: 9px 8px 9px 8px;
  }

  .div-table-title {
    width: 250px;
    background-color: rgba(153, 186, 221, .5);
    font-weight: normal;
  }

  .div-table-value {
    border: 1px solid #DCDCDC;
    font-weight: 900;
  }

  h3 {
    width: 100%;
    text-align: center;
    border-bottom: 1px solid green;
    line-height: 0.1em;
    margin: 10px 0 20px;
  }

  h3 span {
    background:#fff;
    padding:0 10px;
  }

</style>
