<template>
  <div>
    <crud-notification
      :error-message="ErrorMessage"
      :success-message="SuccessMessage"
      :error-alert="ErrorAlert"
      :success-alert="SuccessAlert"
      @fadeMe="fadeMe"
      @enter="enter"
    />
    <card>
      <div class="row">
        <div class="col-md-12">
          <button
            class="btn btn-success mr-2 custom-btn"
            style="float:right; width: 180px"
            @click.prevent="openModal('ADD')"
          >
            <b>Add Activity</b>
          </button>
        </div>
      </div>
      <br>
      <data-table
        :table-headers="tableHeaders"
        :table-data="requests"
        :table-data-keys="tableDataKeys"
        :my-first-loader="myFirstLoader"
        :page-size-prop="pageSize"
        :page-no="pageNo"
        :is-pageable="false"
        :total-elements="totalElements"
        :no-data-found="noDataFound"
        :is-first="isFirst"
        :is-last="isLast"
        :total-pages="totalPages"
        :page-number="pageNumber"
        :date-prop="'Created At '"
        :has-manage-button="false"
        :has-search-form="false"
        :has-view-button="true"
        :has-edit-button="true"
        :has-delete-button="true"
        :reload-all-request="reloadAllRequest"
        @goToEdit="goToEdit"
        @goToDelete="goToDelete"
        @goToView="goToView"
        :loading-title="'Please wait while system is loading Activities'"
      />
    </card>

    <global-modal v-if="myLoader">
      <div slot="globalLoader">
        <div class="row text-center">
          <div class="col-md-12">
            Loading, Please wait
          </div>
          <div class="col-md-12">
            <br>
          </div>
          <div class="col-md-12">
            <pulse-loader
              :loading="loading"
              :color="color"
              :size="size"
            />
          </div>
        </div>
      </div>
    </global-modal>
    <modal v-if="showModal">
      <div
        slot="header"
        class="modal-title col-md-12"
      >
        <div class="row">
          <div
            class="col-md-12"
            v-if="EditModalDiv"
          >
            <b v-if="!isView"> Edit Activity</b>
            <b v-if="isView"> View Activity</b>
          </div>
          <div
            class="col-md-12"
            v-if="AddModalDiv"
          >
            <b> Register Outward Activity</b>
          </div>
          <div class="col-md-12">
            <hr>
          </div>
        </div>
      </div>

      <div slot="body">
        <card>
          <!--      ADD FORM      -->
          <form
            method="POST"
            @submit.prevent="saveFormDetails"
            v-if="AddModalDiv"
          >
            <div class="row">
              <div class="col-md-6">
                <base-input
                  type="text"
                  label="Activity Name"
                  placeholder="Activity Name"
                  v-model="outwardActivitiesDetails.name"
                  class="special-bottom"
                />
                <div v-if="activityNameError">
                  <div
                    class="error"
                    v-if="!$v.outwardActivitiesDetails.name.required"
                  >
                    This field is required
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <label for="addShippingOrderStatus"> Shipping Order Statuses</label>
                <multi-list-select
                  :list="shippingOrderStatusEnum"
                  option-value="value"
                  option-text="value"
                  name="shippingOrderStatus"
                  id="addShippingOrderStatus"
                  :selected-items="outwardActivitiesDetails.shippingOrderStatus"
                  placeholder="Select shipping order statuses"
                  @select="onSelect"
                />
                <div v-if="shippingOrderStatusError">
                  <div
                    class="error"
                    v-if="!$v.outwardActivitiesDetails.shippingOrderStatus.required"
                  >
                    This field is required
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <input
                  type="checkbox"
                  :id="'closingActivity'"
                  name="'Is a closing activity?'"
                  v-model="outwardActivitiesDetails.closingActivity"
                  class="special-bottom"
                >
                &nbsp;<label :for="'closingActivity'"> Is a Closing Activity?</label>&nbsp;&nbsp;
                <div v-if="closingActivityError">
                  <div
                    class="error"
                    v-if="!$v.outwardActivitiesDetails.closingActivity.required"
                  >
                    This field is required
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <input
                  type="checkbox"
                  v-model="outwardActivitiesDetails.addAttachmentToShipOrder"
                  :id="outwardActivitiesDetails.addAttachmentToShipOrder"
                  name="' Requires Attachments?'"
                  class="special-bottom"
                  :checked="true"
                >
                &nbsp;<label :for="outwardActivitiesDetails.addAttachmentToShipOrder"> Requires Attachments? </label>&nbsp;&nbsp;
                <div v-if="addAttachmentToShipOrderError">
                  <div
                    class="error"
                    v-if="!$v.outwardActivitiesDetails.addAttachmentToShipOrder.required"
                  >
                    This field is required
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <input
                  type="checkbox"
                  v-model="outwardActivitiesDetails.notifyPart"
                  :id="'notifyPart'"
                  name="'Notify Relevant Parties?'"
                  class="special-bottom"
                  :checked="true"
                >
                &nbsp;<label :for="'notifyPart'"> Notify Relevant Parties?</label>&nbsp;&nbsp;
                <div v-if="notifyPartiesError">
                  <div
                    class="error"
                    v-if="!$v.outwardActivitiesDetails.notifyPart.required"
                  >
                    This field is required
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <label>Activity Description</label>
                <textarea
                  class="special-bottom form-control col-md-12"
                  placeholder="Activity Description"
                  rows="5"
                  type="textarea"
                  v-model="outwardActivitiesDetails.description"
                />
                <div v-if="descriptionError">
                  <div
                    class="error"
                    v-if="!$v.outwardActivitiesDetails.description.required"
                  >
                    This field is required
                  </div>
                </div>
              </div>
            </div>

            <div class="text-center row">
              <div class="col-md-7 text-right loader-div">
                <span v-if="editMyLoader">Please wait ....</span>
              </div>
              <div class="col-md-2 left loader-div-gif">
                <pulse-loader
                  :loading="loading"
                  :color="color"
                  :size="size"
                  v-if="editMyLoader"
                />
              </div>
              <div
                class="col-md-3"
                v-if="AddModalDiv"
              >
                <button
                  type="button"
                  class="btn btn-fill btn-outline-info float-right"
                  style="margin-inline-start:20px"
                  @click="closeModal()"
                >
                  Close
                </button>
                <button
                  type="submit"
                  class="btn btn-primary btn-space  float-right"
                  data-dismiss="modal"
                >
                  Save
                </button>
              </div>
            </div>
          </form>

          <!--     EDIT FORM       -->
          <form
            method="POST"
            @submit.prevent="editFormDetails"
            v-if="EditModalDiv"
          >
            <div class="row">
              <div class="col-md-6">
                <base-input
                  type="text"
                  label="Activity Name"
                  placeholder="Activity Name"
                  v-model="outwardActivitiesDetails.name"
                  class="special-bottom"
                  :readonly="isView"
                />
                <div v-if="activityNameError">
                  <div
                    class="error"
                    v-if="!$v.outwardActivitiesDetails.name.required"
                  >
                    This field is required
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <label for="editShippingOrderStatus"> Shipping Order Statuses</label>
                <multi-list-select
                  :list="shippingOrderStatusEnum"
                  option-value="value"
                  option-text="value"
                  name="shippingOrderStatus"
                  id="editShippingOrderStatus"
                  :selected-items="outwardActivitiesDetails.shippingOrderStatus"
                  placeholder="Select shipping order statuses"
                  @select="onSelect"
                  :is-disabled="isView"
                />
                <div v-if="shippingOrderStatusError">
                  <div
                    class="error"
                    v-if="!$v.outwardActivitiesDetails.shippingOrderStatus.required"
                  >
                    This field is required
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <input
                  type="checkbox"
                  :id="'closingActivity'"
                  name="'Is a closing activity?'"
                  v-model="outwardActivitiesDetails.closingActivity"
                  class="special-bottom"
                  :disabled="isView"
                >
                &nbsp;<label :for="'closingActivity'"> Is a Closing Activity?</label>&nbsp;&nbsp;
              </div>
              <div class="col-md-4">
                <input
                  type="checkbox"
                  v-model="outwardActivitiesDetails.addAttachmentToShipOrder"
                  :id="outwardActivitiesDetails.addAttachmentToShipOrder"
                  name="' Requires Attachments?'"
                  class="special-bottom"
                  :checked="true"
                  :disabled="isView"
                >
                &nbsp;<label :for="outwardActivitiesDetails.addAttachmentToShipOrder"> Requires Attachments? </label>&nbsp;&nbsp;
              </div>
              <div class="col-md-4">
                <input
                  type="checkbox"
                  v-model="outwardActivitiesDetails.notifyPart"
                  :id="'notifyPart'"
                  name="'Notify Relevant Parties?'"
                  class="special-bottom"
                  :checked="true"
                  :disabled="isView"
                >
                &nbsp;<label :for="'notifyPart'"> Notify Relevant Parties?</label>&nbsp;&nbsp;
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <label>Activity Description</label>
                <textarea
                  class="special-bottom form-control col-md-12"
                  placeholder="Activity Description"
                  rows="5"
                  type="textarea"
                  v-model="outwardActivitiesDetails.description"
                  :readonly="isView"
                />
                <div v-if="descriptionError">
                  <div
                    class="error"
                    v-if="!$v.outwardActivitiesDetails.description.required"
                  >
                    This field is required
                  </div>
                </div>
              </div>
            </div>

            <div class="text-center row">
              <div class="col-md-7 text-right loader-div">
                <span v-if="editMyLoader">Please wait ....</span>
              </div>
              <div class="col-md-2 left loader-div-gif">
                <pulse-loader
                  :loading="loading"
                  :color="color"
                  :size="size"
                  v-if="editMyLoader"
                />
              </div>
              <div
                class="col-md-3"
                v-if="EditModalDiv"
              >
                <button
                  type="button"
                  class="btn btn-fill btn-outline-info float-right"
                  style="margin-inline-start:20px"
                  @click="closeModal()"
                >
                  Close
                </button>
                <button
                  type="submit"
                  class="btn btn-primary btn-space  float-right"
                  data-dismiss="modal"
                  v-if="!isView"
                >
                  Save
                </button>
              </div>
            </div>
          </form>
        </card>
      </div>
    </modal>
  </div>
</template>

<script>
import PulseLoader from 'vue-spinner/src/PulseLoader';
import GlobalModal from '../../../components/globalLoader';
import DataTable from '../../../components/DataTable/updatedTable';
import Modal from '../../../components/Inputs/Modal';
import { mapActions, mapGetters } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import CrudNotification from '../../../components/DataTable/crudNotification';
import { MultiSelect, MultiListSelect } from 'vue-search-select';

export default {
  name: 'OutwardActivitiesList',
  components: {
    CrudNotification,
    PulseLoader,
    GlobalModal,
    DataTable,
    Modal,
    MultiSelect,
    MultiListSelect
  },
  created () {
    this.$store.dispatch('pagenames/currentPageName', 'Activities');

    this.finalUserPermissions = JSON.parse(localStorage.getItem('browser-local-permits'));
    this.loadPageableOutwardActivitiesOnCreated();
  },
  computed: {
    ...mapGetters('outwardActivities', [

      'getRequestList',
      'getPageCount',
      'getMyFirstLoader',
      'getNoDataFound',
      'getSuccessAlert',
      'getErrorMessage',
      'getErrorAlert',
      'getSuccessMessage',
      'getReloadAllRequest',
      'getMyLoader',
      'getSearchParameter',
      'getHasSearchedVariable',
      'getPageNo',
      // "getCustomerName",

      'getRequestId'
      // "getBlNumber",
    ])
  },
  validations: {
    outwardActivitiesDetails: {
      addAttachmentToShipOrder: { required },
      closingActivity: { required },
      description: { required },
      name: { required },
      notifyPart: { required },
      shippingOrderStatus: { required }
    }
  },
  data () {
    return {

      shippingOrderStatusEnum: [{ value: 'Received' }, { value: 'Accepted' }, { value: 'Rejected' },
        { value: 'RequireAdditionDetails' }, { value: 'Canceled' }, { value: 'Approved' }, { value: 'WaitingContainer' },
        { value: 'ContainerAllocated' }, { value: 'Stuffed' }, { value: 'LoadingDeclarationSubmited' },
        { value: 'LoadingPermissionReceived' }, { value: 'GMCReceived' }, { value: 'GateCheckIn' }, { value: 'Scanned' },
        { value: 'CarriedIn' }, { value: 'ScheduledForTransport' }, { value: 'MateReceiptReceived' },
        { value: 'StowageBayPlanReceived' }, { value: 'BlInstructionConfirmed' }, { value: 'BlInfoRejected' },
        { value: 'BlVerified' }, { value: 'Exported' }, 'Closed'],

      outwardActivitiesDetails: {
        addAttachmentToShipOrder: true,
        closingActivity: false,
        description: '',
        name: '',
        notifyPart: true,
        shippingOrderStatus: []
      },

      items: [],
      closingActivityError: false,
      descriptionError: false,
      shippingOrderStatusError: false,
      notifyPartiesError: false,
      activityNameError: false,
      addAttachmentToShipOrderError: false,

      AddModalDiv: false,
      EditModalDiv: false,
      showModal: false,
      editMyLoader: false,
      isView: false,

      pageNo: 0,
      pageSize: 15,
      pageCount: 1,
      sortBy: 'createdAt',
      totalPages: 1,
      isPageable: true,
      pageNumber: 0,
      isFirst: true,
      isLast: false,
      nodataFound: false,
      myFirstLoader: false,
      totalElements: '',

      color: 'green',
      size: '12px',
      loading: true,
      editable: true,
      searchParameter: '',
      hasSearchedVariable: false,
      reloadAllRequest: false,
      noDataFound: false,
      myLoader: false,
      requests: [],

      SuccessAlert: false,
      ErrorAlert: false,

      SuccessMessage: '',
      ErrorMessage: '',

      saveDetails: {},
      finalUserPermissions: [],

      tableHeaders: ['Name', 'Is Closing Activity', 'Notify Parties?', 'Requires Attachments?'],
      tableDataKeys: ['name', 'ca', 'np', 'aa']

    };
  },
  methods: {
    ...mapActions('outwardActivities', [
      'loadOutwardActivityPageable',
      'deleteOutwardActivity',
      'addOutwardActivity',
      'updateOutwardActivity'
    ]),
    loadParameters () {
      return new Promise((resolve) => {
        this.$store.dispatch('outwardActivities/pageSizing', this.pageSize);
        this.$store.dispatch('outwardActivities/pageNumbering', this.pageNo);
        resolve();
      });
    },
    loadPageableOutwardActivitiesOnCreated () {
      this.loadParameters().then(() => {
        this.myFirstLoader = true;
        this.loadOutwardActivityPageable({}).then(() => {
          this.myLoader = this.getMyFirstLoader;
          this.requests = [];
          this.requests = this.formatOutwardActivities(this.getRequestList);
          this.myFirstLoader = this.getMyFirstLoader;
          this.noDataFound = this.getNoDataFound;
        }).catch(() => {
        });
      });
    },
    goToDelete (data) {
      this.$dialog.confirm('You are about to delete outward Activity <br> <br>' +
          '[ ' + data.name + '] ' +
          'Are you sure! ', {
        loader: true,
        html: true

      }).then((dialog) => {
        this.$store.dispatch('outwardActivities/requestid', data.id);

        this.deleteOutwardActivity({}).then(() => {
          this.SuccessAlert = this.getSuccessAlert;
          this.ErrorAlert = this.getErrorAlert;
          this.SuccessMessage = ', outward activity deleted.';
          this.ErrorMessage = 'Sorry, something went wrong.';
          this.loadPageableOutwardActivitiesOnCreated();
        }).catch(() => {
          this.ErrorAlert = this.getErrorAlert;
        });

        dialog.close();
      }).catch(() => {});
    },
    editFormDetails () {
      if (this.$v.outwardActivitiesDetails.description.$invalid ||
          this.$v.outwardActivitiesDetails.name.$invalid ||
          this.$v.outwardActivitiesDetails.shippingOrderStatus.$invalid) {
        if (this.$v.outwardActivitiesDetails.description.$invalid) {
          this.descriptionError = true;
        }
        if (this.$v.outwardActivitiesDetails.name.$invalid) {
          this.activityNameError = true;
        }

        if (this.$v.outwardActivitiesDetails.shippingOrderStatus.$invalid) {
          this.shippingOrderStatusError = true;
        }
      } else {
        this.outwardActivitiesDetails.shippingOrderStatus = this.cleanShippingOrderStatus(this.outwardActivitiesDetails.shippingOrderStatus);
        this.$store.dispatch('outwardActivities/savingDetails', this.outwardActivitiesDetails);
        this.myLoader = true;
        this.updateOutwardActivity({}).then(() => {
          this.SuccessAlert = this.getSuccessAlert;
          this.ErrorAlert = this.getErrorAlert;
          this.SuccessMessage = this.getSuccessMessage;
          this.ErrorMessage = this.getErrorMessage;
          this.loadPageableOutwardActivitiesOnCreated();
          this.myLoader = false;
        }).catch(() => {
          this.myLoader = false;
        });
        this.closeModal();
        this.outwardActivitiesDetails.shippingOrderStatus = [];
      }
    },
    saveFormDetails () {
      if (this.$v.outwardActivitiesDetails.description.$invalid ||
          this.$v.outwardActivitiesDetails.name.$invalid ||
          this.$v.outwardActivitiesDetails.shippingOrderStatus.$invalid) {
        if (this.$v.outwardActivitiesDetails.description.$invalid) {
          this.descriptionError = true;
        }
        if (this.$v.outwardActivitiesDetails.name.$invalid) {
          this.activityNameError = true;
        }

        if (this.$v.outwardActivitiesDetails.shippingOrderStatus.$invalid) {
          this.shippingOrderStatusError = true;
        }
      } else {
        this.outwardActivitiesDetails.shippingOrderStatus = this.cleanShippingOrderStatus(this.outwardActivitiesDetails.shippingOrderStatus);
        this.$store.dispatch('outwardActivities/savingDetails', this.outwardActivitiesDetails);
        this.myLoader = true;
        this.addOutwardActivity({}).then(() => {
          this.SuccessAlert = this.getSuccessAlert;
          this.ErrorAlert = this.getErrorAlert;
          this.SuccessMessage = this.getSuccessMessage;
          this.ErrorMessage = this.getErrorMessage;

          this.loadPageableOutwardActivitiesOnCreated();
          this.myLoader = false;
        }).catch(() => {
          this.myLoader = false;
        });
        this.closeModal();
        this.outwardActivitiesDetails.shippingOrderStatus = [];
      }
    },

    openModal (modalType) {
      if (modalType === 'ADD') {
        this.showModal = true;
        this.AddModalDiv = true;
      }
      if (modalType === 'EDIT') {
        this.showModal = true;
        this.EditModalDiv = true;
      }
      if (modalType === 'VIEW') {
        this.showModal = true;
        this.EditModalDiv = true;
        this.isView = true;
      }
    },
    closeModal () {
      this.showModal = false;
      this.EditModalDiv = false;
      this.AddModalDiv = false;
      this.UploadModalDiv = false;
      this.isView = false;

      this.outwardActivitiesDetails = {};
      this.outwardActivitiesDetails.shippingOrderStatus = [];

      this.closingActivityError = false;
      this.descriptionError = false;
      this.shippingOrderStatusError = false;
      this.notifyPartiesError = false;
      this.activityNameError = false;
      this.addAttachmentToShipOrderError = false;
    },
    goToEdit (data) {
      let i = 0;
      const temp = [];

      for (i = 0; i < data.shippingOrderStatus.length; i++) {
        temp.push({ value: data.shippingOrderStatus[i] });
      }
      this.outwardActivitiesDetails = JSON.parse(JSON.stringify(data));
      this.outwardActivitiesDetails.shippingOrderStatus = temp;
      this.openModal('EDIT');
    },
    goToView (data) {
      let i = 0;
      const temp = [];

      for (i = 0; i < data.shippingOrderStatus.length; i++) {
        temp.push({ value: data.shippingOrderStatus[i] });
      }
      this.outwardActivitiesDetails = JSON.parse(JSON.stringify(data));
      this.outwardActivitiesDetails.shippingOrderStatus = temp;
      this.openModal('VIEW');
    },
    cleanShippingOrderStatus (data) {
      const temp = [];
      let i = 0;
      for (i = 0; i < data.length; i++) {
        temp.push(data[i].value);
      }
      return temp;
    },
    formatOutwardActivities (data) {
      let i = 0;
      if (data !== null && data !== 'undefined ') {
        for (i; i < data.length; i++) {
          data[i].ca = data[i].closingActivity ? 'YES' : 'NO';
          data[i].np = data[i].notifyPart ? 'YES' : 'NO';
          data[i].aa = data[i].addAttachmentToShipOrder ? 'YES' : 'NO';
        }
      }
      return data;
    },
    enter: function (el, done) {
      var that = this;

      setTimeout(function () {
        that.SuccessAlert = false;
        that.ErrorAlert = false;
      }, 3000); // hide the message after 3 seconds
    },
    fadeMe: function () {
      if (this.SuccessAlert) {
        this.SuccessAlert = !this.SuccessAlert;
      } else {
        this.ErrorAlert = !this.ErrorAlert;
      }
    },
    onSelect (items, lastSelectItem) {
      this.outwardActivitiesDetails.shippingOrderStatus = items;
      this.lastSelectItem = lastSelectItem;
    }

  }
};
</script>
<style scoped>
  .btn-space {
    margin-right: 10px !important;
  }
  .custom-btn {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
    padding: 5px 9px 5px 9px !important;
    font-size: 14px;
    width: 120px;
    background-color: #FFFFFF !important;
  }

  .special-limit {
    max-width: 100px !important;
    min-width: 90px !important;
  }

  .loader-div {
    line-height: 40px;
  }

  .loader-div-gif {
    line-height: 50px;
  }

  .fileUpload input.upload {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    padding: 0;
    cursor: pointer;
    opacity: 0;
    filter: alpha(opacity=0);
  }

  .fileUpload span {
    cursor: pointer;
  }

  .error{
    color:#dc3545;
    margin-left: 2px;
    margin-top: 2px;
  }

  .special-bottom {
    margin-bottom: -1px;
  }
</style>
