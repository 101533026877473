<template>
<card>
  <div>
    <div class="col-12 text-center">
      <pulse-loader
        :loading="exloading"
        :color="color"
        :size="size"
      />
    </div>
<!--    billOfLadingNo:"",-->
<!--    portOfOrigin:"",-->
<!--    placeOfDestination:"",-->
<!--    placeOfDelivery:"",-->
<!--    portOfLoading:"",-->
<!--    vesselName:"",-->
<!--    exporterName:"",-->
<!--    exporterTin:"",-->
<!--    consigneeName:"",-->
<!--    consigneeTin:"",-->
<!--    consignorName:"",-->
<!--    consignorTin:"",-->
<!--    notifyName:"",-->
<!--    notifyTin:"",-->
<!--    startDate:"",-->
<!--    endDate:"",-->
<!--    tableDataKeys:['billOfLadingNo','exporterName','exporterTel','portOfLoading','placeOfDelivery','consigneeName','vesselName','createDateTime'],-->

    <div class="row back-color-vat">
      <div class="col-md-12 mb-3">
        <div class="row mb-3">
          <div class="col-md-4">
            <label for="fileNo" class="form-label">Bill of Lading</label>
            <input type="text" class="form-control" id="fileNo" v-model="billOfLadingNo" placeholder="Bill of Lading Number">
          </div>

          <div class="col-md-4">
            <label for="eN" class="form-label">Exporter Name</label>
            <input type="text" class="form-control" id="eN" v-model="exporterName" placeholder="Exporter Name">
          </div>

          <div class="col-md-4">
            <label for="portL" class="form-label">Port of Loading</label>
            <input type="text" class="form-control" id="portL" v-model="portOfLoading" placeholder="Port of Loading">
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-md-6">
            <label for="pL" class="form-label">Place of Delivery</label>
            <input type="text" class="form-control" id="pL" v-model="placeOfDelivery" placeholder="Place of Delivery">
          </div>

          <div class="col-md-6">
            <label for="vN" class="form-label">Vessel Name</label>
            <input type="text" class="form-control" id="vN" v-model="vesselName" placeholder="Vessel Name">
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-md-6">
            <label for="sD" class="form-label">Start Date</label>
            <input type="date" class="form-control" id="sD" v-model="startDate" placeholder="Start Date">
          </div>
          <div class="col-md-6">
            <label for="eD" class="form-label">End Date</label>
            <input type="date" class="form-control" id="eD" v-model="endDate" placeholder="End Date">
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 mb-3">
            <label for="cN" class="form-label">Consignee Name</label>
            <input type="text" class="form-control" id="cN" v-model="consigneeName" placeholder="Consignee Name">
          </div>
          <div class="col-md-12">
            <button
              @click.prevent="resetParams"
              class="btn btn-fill btn-outline-info float-right custom-btn-all"
              data-dismiss="modal"
            >
              Clear filter
            </button>
            <button
              @click.prevent="loadBillOfLadingList"
              class="btn mr-2 btn-primary float-right custom-btn-all"
              data-dismiss="modal"
            >
              Filter
            </button>
          </div>
        </div>

      </div>
    </div>
    <div class="col-md-12">
      <data-table
        :tableHeaders="tableHeaders"
        :tableData="filteredRequests"
        :tableDataKeys="tableDataKeys"
        :pageSizeProp="pageSize"
        :pageNo="pageNo"
        :hasFilter="false"
        :myLoader="loading"
        :myFirstLoader="loading"
        :noDataFound="noDataFound"
        :searchParameterProp="searchParameter"
        :loadingTitle="'Please wait while system is loading Bill of Lading List'"
        :hasManageButton="false"
        :totalElements="totalElements"
        :totalPages="totalPages"
        :isFirst="isFirst"
        :isLast="isLast"
        :isHovered="[]"
        :pageNumber="pageNumber"
        :has-custom-button="true"
        custom-button-name="Manage"
        :is-hovered="false"
        :permissions="[ {key: 'searchPermission', value: 'CFAREPORT_ASYNC_REPORT_GET'} ]"
        @fireCustomMethod="viewBillOfLading"
        @onChange="onChange"
      >
        <div slot = "pagination">
          <paginate
            v-model="getIsPage"
            :page-count=parseInt(totalPages)
            :page-range="3"
            :margin-pages="2"
            :click-handler="goToPage"
            :prev-text="'Prev'"
            :next-text="'Next'"
            :container-class="'pagination'"
            :page-class="'page-item'">
          </paginate>
        </div>
      </data-table>

    </div>

  </div>

</card>
</template>

<script>
  import AGENCY_API from "../../../api/agency";
  import {ModelListSelect} from 'vue-search-select';
  import PulseLoader from "vue-spinner/src/PulseLoader";

    export default {
        name: "billOfLadingList",

      components: {
        PulseLoader,
        ModelListSelect,
        DataTable: () => import('../../../components/DataTable/dataTable'),
      },

      created() {
        this.$store.dispatch('pagenames/currentPageName', 'Bill of Lading List');

        this.loadBillOfLadingList();
      },

      computed:{
        getIsPage: {

          get: function () {
            return this.isPage
          },
          set: function (newValue) {
            this.isPage = newValue;
          }

        }

      },

      data(){
          return{
            filteredRequests:[],
            tableHeaders:['BILL OF LADING','EXPORTER','EXPORTER TEL','PORT OF LOADING','PLACE OF DELIVERY','CONSIGNEE','VESSEL NAME','DATE CREATED'],
            tableDataKeys:['billOfLadingNo','exporterName','exporterTel','portOfLoading','placeOfDelivery','consigneeName','vesselName','createDateTime'],
            loading:false,
            exloading:false,
            pageNo: 0,
            pageSize: 15,
            totalElements: "",
            totalPages: "",
            isFirst: false,
            isLast: false,
            pageNumber: '',
            pageCount: 1,
            noDataFound:false,
            searchParameter: '',
            isPage: 0,

            params:"",

            billOfLadingNo:"",
            portOfOrigin:"",
            placeOfDestination:"",
            placeOfDelivery:"",
            portOfLoading:"",
            vesselName:"",
            exporterName:"",
            exporterTin:"",
            consigneeName:"",
            consigneeTin:"",
            consignorName:"",
            consignorTin:"",
            notifyName:"",
            notifyTin:"",
            startDate:"",
            endDate:"",

            color: 'green',
            size: '12px',


          }
      },

      methods:{

        viewBillOfLading(object) {
          document.cookie = 'browser-local-cashed-url-back=' + this.$route.fullPath + ';Path = /;SameSite = Strict'
          this.$router.push({name: 'billOfLadingDetails', params: { id : object.id }})

        },

        sortingData(data) {


          for(let x = 0 ; x < data.length ; x++) {

            const requestObject = {

              ...data[x],
              createDateTime: this.dateTimeToHuman(data[x].createDateTime),
            };

            this.filteredRequests.push(requestObject)

          }

        },

        resetParams(){

          this.billOfLadingNo="";
          this.portOfOrigin="";
          this.placeOfDestination="";
          this.placeOfDelivery="";
          this.portOfLoading="";
          this.vesselName="";
          this.exporterName="";
          this.exporterTin="";
          this.consigneeName="";
          this.consigneeTin="";
          this.consignorName="";
          this.consignorTin="";
          this.notifyName="";
          this.notifyTin="";
          this.startDate="";
          this.endDate="";
        },

        setParams(){


          this.params='size=' + this.pageSize + '&page=' + this.pageNo;
          this.billOfLadingNo!==""? this.params= this.params  +  "&billOfLadingNo=" + this.billOfLadingNo:"";
          this.portOfOrigin!==""? this.params= this.params  +  "&portOfOrigin=" + this.portOfOrigin:"";
          this.placeOfDestination!==""? this.params= this.params  +  "&placeOfDestination=" + this.placeOfDestination:"";
          this.placeOfDelivery!==""? this.params= this.params  +  "&placeOfDelivery=" + this.placeOfDelivery:"";
          this.portOfLoading!==""? this.params= this.params  +  "&portOfLoading=" + this.portOfLoading:"";
          this.vesselName!==""? this.params= this.params  +  "&vesselName=" + this.vesselName:"";
          this.exporterName!==""? this.params= this.params  +  "&exporterName=" + this.exporterName:"";
          this.exporterTin!==""? this.params= this.params  +  "&exporterTin=" + this.exporterTin:"";
          this.consigneeName!==""? this.params= this.params  +  "&consigneeName=" + this.consigneeName:"";
          this.consigneeTin!==""? this.params= this.params  +  "&consigneeTin=" + this.consigneeTin:"";
          this.consignorName!==""? this.params= this.params  +  "&consignorName=" + this.consignorName:"";
          this.consignorTin!==""? this.params= this.params  +  "&consignorTin=" + this.consignorTin:"";
          this.notifyName!==""? this.params= this.params  +  "&notifyName=" + this.notifyName:"";
          this.notifyTin!==""? this.params= this.params  +  "&notifyTin=" + this.notifyTin:"";
          this.startDate!==""? this.params= this.params  +  "&startDate=" + this.startDate:"";
          this.endDate!==""? this.params= this.params  +  "&endDate=" + this.endDate:"";

        },

        loadBillOfLadingList(){

          this.setParams();
          this.loading=true;
          this.filteredRequests=[];

          AGENCY_API.gillBillOfLadingList(this.params).then((resp)=>{
            this.loading=false;

            if(resp.data.status) {
              this.totalPages = resp.data.data.totalPages;
              this.totalElements = resp.data.data.totalElements;
              this.pageNumber = resp.data.data.number;
              this.isFirst = resp.data.data.first;
              this.isLast = resp.data.data.isLast;

              if (resp.data.data.content.length > 0) {
                this.noDataFound = false;
                this.sortingData(resp.data.data.content);
              } else {
                this.noDataFound = true;
              }
            }

            }).catch((error)=>{
            this.loading=false;
            this.notifyUser('error','Error','Failed to load Bill of Ladings contact System Administrator');
          })
        },

        onChange(event) {
          this.pageSize=event;
          this.pageNo=0;
        },

        goToPage(page) {

          if(page > 0) {page = page - 1;}
          this.isPage = page + 1;
          this.pageNo=this.isPage;

          this.loadBillOfLadingList();

        },

      }
    }
</script>

<style scoped>
  .back-color-vat {
    background-color: rgba(216, 225, 232, .3);
  }

  .custom-btn, .custom-btn-all {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .custom-btn:hover, .custom-btn-all:hover {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
</style>
