<template>
  <div>
    <div class="legend">
      <h6 class="legend-title">
        Add Cargo On Board
      </h6>
      <card style="font-size: 12px">
        <!--shipping order details-->
        <!--<div class="legend" v-if=" typeof tallyDocument!== 'undefined'">-->
          <!--<h6 class="legend-title">-->
            <!--Tally Notification Details-->
          <!--</h6>-->
        <!--</div>-->

        <div class="row div-table-row">
          <div class="col-md-2 div-table div-table-title">
            Operation Type.
          </div>
          <div class="col-md-2 div-table div-table-value">
            {{ tallyDocument.operationType }}
          </div>
          <div class="col-md-2 div-table div-table-title">
            Vessel Name
          </div>
          <div class="col-md-2 div-table div-table-value">
            {{ tallyDocument.notification.vesselName }}
          </div>
          <div class="col-md-2 div-table div-table-title">
            Voyage
          </div>
          <div class="col-md-2 div-table div-table-value" style="color: #651fff; ">
            {{ tallyDocument.notification.voyage }}
          </div>
        </div>

        <div class="row mt-5">
          <div class="col-md-6">
            <div
              class="form-group"
              v-if=" tallyType !== 'DISCHARGING' &&  tallyType !== 'LOADING' &&  tallyType !== '' ">
              <label class="notification-title" for="teuEmptyQty">20' Empty Container Quantity</label>
              <input
                class="form-control"
                id="teuEmptyQty"
                type="number"
                v-model="teuEmptyQuantity"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div
              class="form-group"
              v-if=" tallyType !== 'DISCHARGING' &&  tallyType !== 'LOADING' &&  tallyType !== '' ">
              <label class="notification-title" for="teuFullQuantity">20' Full Container Quantity</label>
              <input
                class="form-control"
                id="teuFullQuantity"
                type="number"
                v-model="teuFullQuantity"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div
              class="form-group"
              v-if=" tallyType !== 'DISCHARGING' &&  tallyType !== 'LOADING' &&  tallyType !== '' ">
              <label class="notification-title" for="feuEmptyQty">40' Empty Container Quantity</label>
              <input
                class="form-control"
                id="feuEmptyQty"
                type="number"
                v-model="feuEmptyQuantity"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div
              class="form-group"
              v-if=" tallyType !== 'DISCHARGING' &&  tallyType !== 'LOADING' &&  tallyType !== '' ">
              <label class="notification-title" for="feuFullQty">40' Full Container Quantity</label>
              <input
                class="form-control"
                id="feuFullQty"
                type="number"
                v-model="feuFullQuantity"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div
              class="form-group"
              v-if=" tallyType !== 'DISCHARGING' &&  tallyType !== 'LOADING' &&  tallyType !== '' ">
              <label class="notification-title" for="vehicleQuantity">Vehicle Quantity</label>
              <input
                class="form-control"
                id="vehicleQuantity"
                type="number"
                v-model="vehicleQuantity"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div
              class="form-group"
              v-if=" tallyType !== 'DISCHARGING' &&  tallyType !== 'LOADING' &&  tallyType !== '' ">
              <label class="notification-title" for="packageQuantity">Package Quantity</label>
              <input
                class="form-control"
                id="packageQuantity"
                type="number"
                v-model="packageQuantity"
              />
            </div>
          </div>
        </div>

        <button
          @click.prevent="closeModal"
          class="btn btn-fill btn-outline-info float-right btn-space"
          type="button">Close
        </button>
        <button
          @click.prevent="submitCharge"
          class="btn btn-primary float-right btn-space"
          data-dismiss="modal"
          type="submit">Save
        </button>
      </card>
    </div>
  </div>
</template>

<script>
  import { ModelListSelect } from 'vue-search-select';
  import TALLY_API from '../../../api/tally'

  export default {
    name: "tallyBalanceModel",

    components: {
      ModelListSelect
    },

    props: {
      // type, required and default are optional, you can reduce it to 'options: Object'
      tallyDocument: { type: Object, required: false},
    },

    data() {
      return {
        teuEmptyQuantity: "",
        teuFullQuantity: "",
        feuEmptyQuantity: "",
        feuFullQuantity: "",
        vehicleQuantity: "",
        packageQuantity: ""
      }
    },

    created() {
      // this.getActiveCharges();
      // this.getTariffFees();
      // this.getTariffItems();
      // this.getAllCfAgents();
      // console.log(this.tariffItems);
    },

    methods: {

      submitCharge() {
        let chargeObject = {
          notificationId: this.tallyDocument.notification.id,
          teuEmptyQuantity: this.teuEmptyQuantity,
          teuFullQuantity: this.teuFullQuantity,
          feuEmptyQuantity: this.feuEmptyQuantity,
          feuFullQuantity: this.feuFullQuantity,
          vehicleQuantity: this.vehicleQuantity,
          packageQuantity: this.packageQuantity
        };

        TALLY_API.addCargoBalance(chargeObject).then((resp) => {
          this.loading = false;
          if (resp.status) {
            this.notifyUser('success', 'Success', 'Successfully added charge to bill of lading');
            this.closeModal();
          } else {
            this.notifyUser('error', 'Error', resp.message);
          }
        }).catch((error) => {
          this.loading = false;
          this.notifyUser('error', 'Error', 'Failed to add charge ')
        });
      },

      closeModal() {
        this.$emit('closeModal', false);
      }

    }
  }
</script>

<style scoped>
  ul.nav-justified {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    background-color: #ededee;
    border-radius: 3px;
  }

  ul.nav-justified li {
    float: left;
  }

  ul.nav-justified li a:hover {
    color: #d59a18;
  }

  ul.nav-justified li a {
    display: block;
    text-align: center;
    padding: 16px 20px 10px 21px;
    text-decoration: none;
    color: blue;
    font-weight: bold;
    text-transform: uppercase;
  }
  .div-table-row {
    padding: 0 15px 0 15px;
  }

  .div-table {
    padding: 9px 8px 9px 8px;
  }

  .div-table-title {
    width: 250px;
    background-color: rgba(153, 186, 221, .5);
    font-weight: normal;
  }

  .div-table-value {
    border: 1px solid #DCDCDC;
    font-weight: 900;
  }

  label {
    position: relative;
    top: -7px;
    right: 0;
    margin: 0;
    padding: 0;
  }
  .legend {
    position: relative;
    border: 1px solid #DCDCDC;
    padding: 1.5rem 1rem 1rem 1rem;
    margin-bottom: 1rem;
    border-radius: 3px;
    margin-top: 1rem;
  }

  .legend-title {
    background-color: inherit;
    position: absolute;
    top: -0.6rem;
    padding: 0.2rem 1rem;
    background-color: white;
    color: #d59a18;
  }
  .btn-success-custom:hover {
    border-color: #008000;
    color: #008000;
    background-color: #ffffff;
  }

  .btn-success-custom {
    border-color: rgba(0, 128, 0, .5);
    color: rgba(0, 128, 0, .5);
  }

  .btn-success-custom-fill {
    background-color: rgba(0, 128, 0, .8);
    opacity: 1;
    color: #FFFFFF;
  }

  /*.custom-btn {*/
  /*margin-top: 5px !important;*/
  /*margin-bottom: 5px !important;*/
  /*padding: 5px 9px 5px 9px !important;*/
  /*font-size: 14px !important;*/
  /*}*/

  .custom-btn {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
    color: #054114;
    padding: 5px 9px 5px 9px !important;
    background-color: #FFFFFF !important;
  }

  .legend {
    position: relative;
    border: 1px solid #DCDCDC;
    padding: 1.5rem 1rem 1rem 1rem;
    margin-bottom: 1rem;
    border-radius: 3px;
    margin-top: 1rem;
  }

  .legend-title {
    background-color: inherit;
    position: absolute;
    top: -0.6rem;
    padding: 0.2rem 1rem;
    background-color: white;
    color: #d59a18;
  }

  .div-table-value{
    border: 1px solid #DCDCDC;
  }
  .div-table-row {
    padding: 0 15px 0 15px;
  }

  .btn-space {
    margin-right: 10px !important;
  }

  .div-table {
    padding: 9px 8px 9px 8px;
  }

  .div-table-title {
    width: 250px;
    background-color: rgba(153, 186, 221, .5);
    font-weight: normal;
  }

  .div-table-value {
    border: 1px solid #DCDCDC;
    font-weight: 900;
  }

  h3,h4 {
    width: 100%;
    text-align: center;
    border-bottom: 1px solid green;
    line-height: 0.1em;
    margin: 10px 0 20px;
  }

  h3 span,h4 span {
    background:#fff;
    padding:0 10px;
  }
</style>
