<template>
  <div>
    <card>
      <crud-notification
        :error-message="ErrorMessage"
        :success-message="SuccessMessage"
        :error-alert="ErrorAlert"
        :success-alert="SuccessAlert"
        @fadeMe="fadeMe"
        @enter="enter"
      />
      <div class="row" v-if="isValidUser">
        <div class="col-md-12">
          <button
            class="btn btn-success custom-btn mr-2"
            style="float: right; width: 200px"
            @click.prevent="addActivityProgress"
          >
            Add Activity Progress
          </button>
        </div>
      </div>
      <div class="legend">
        <h6 class="legend-title">
          Activity Details
        </h6>

        <div class="row div-table-row">
          <div class="col-md-1 div-table div-table-title">
            Activity Name
          </div>
          <div class="col-md-4 div-table div-table-value">
            {{ activityAssignmentDetails.activity.name }}
          </div>
          <div class="col-md-1 div-table div-table-title">
            Assigned Officer
          </div>
          <div class="col-md-2 div-table div-table-value">
            {{ officerName(activityAssignmentDetails.officer) }}
          </div>
          <div class="col-md-2 div-table div-table-title">
            Assigned Officer Email
          </div>
          <div class="col-md-2 div-table div-table-value">
            {{ activityAssignmentDetails.officer.email }}
          </div>
        </div>
        <br>
        <div class="row div-table-row">
          <div class="col-md-2 div-table div-table-title">
            Date of Creation
          </div>
          <div class="col-md-2 div-table div-table-value">
            {{ activityAssignmentDetails.createdAt ? dateToHuman(activityAssignmentDetails.createdAt) : "N/A" }}
          </div>

          <div class="col-md-2 div-table div-table-title">
            Activity Description
          </div>
          <div class="col-md-6 div-table div-table-value">
            {{ activityAssignmentDetails.descrition }}
          </div>
        </div>
        <br>
        <div class="row div-table-row">
          <div class="col-md-2 div-table div-table-title">
            Additional Activity Description
          </div>
          <div class="col-md-6 div-table div-table-value">
            {{ activityAssignmentDetails.activity.description }}
          </div>

          <div class="col-md-2 div-table div-table-title">
            Completion Status
          </div>
          <div class="col-md-2 div-table div-table-value">
            {{ activityAssignmentDetails.completed ? 'YES': 'NO' }}
          </div>
        </div>
        <br>
        <div class="row div-table-row">
          <div class="col-md-2 div-table div-table-title">
            Terms Of Delivery
          </div>
          <div class="col-md-2 div-table div-table-value">
            {{ activityAssignmentDetails.shippingOrder.termOfDelivery }}
          </div>

          <div class="col-md-2 div-table div-table-title">
            Shipping Order No
          </div>
          <div class="col-md-2 div-table div-table-value">
            {{ activityAssignmentDetails.shippingOrder.shippingOrderNo }}
          </div>

          <div class="col-md-2 div-table div-table-title">
            Shipping Order Status
          </div>
          <div class="col-md-2 div-table div-table-value">
            {{ activityAssignmentDetails.shippingOrder.shippingOrderStatus ? formatActivityStatus(activityAssignmentDetails.shippingOrder.shippingOrderStatus) : '' }}
          </div>
        </div>
      </div>

      <div class="legend">
        <h6 class="legend-title">
          Activity Progress
        </h6>

        <data-table
          :table-headers="tableHeaders"
          :table-data="requests"
          :table-data-keys="tableDataKeys"
          :my-first-loader="myFirstLoader"
          :is-pageable="false"
          :no-data-found="noDataFound"
          :is-first="true"
          :is-last="false"
          :date-prop="'Created At'"
          :has-manage-button="false"
          :has-search-form="false"
          :has-view-button="false"
          :has-edit-button="false"
          :has-delete-button="false"
          :loading-title="'Please wait while system is loading Outward Activities'"
        />
      </div>

      <div class="row">
        <div class="col-md-12">
          <button
            class="btn btn-default btn-fill btn-space"
            style="float:right;"
            @click.prevent="goBack"
          >
            <font-awesome-icon icon="undo" /> &nbsp; Back
          </button>
        </div>
      </div>
    </card>
    <global-modal v-if="myFirstLoader">
      <div slot="globalLoader">
        <div class="row text-center">
          <div class="col-md-12">
            Loading, Please wait
          </div>
          <div class="col-md-12">
            <br>
          </div>
          <div class="col-md-12">
            <pulse-loader
              :loading="loading"
              :color="color"
              :size="size"
            />
          </div>
        </div>
      </div>
    </global-modal>

    <modal v-if="showModal">
      <div
        slot="header"
        class="modal-title col-md-12"
      >
        <div class="row">
          <div
            class="col-md-12"
          >
            <b> Add Assignment Progress</b>
          </div>
          <div class="col-md-12">
            <hr>
          </div>
        </div>
      </div>
      <div slot="body">
        <card>
          <!--      ADD FORM      -->
          <form
            method="POST"
            @submit.prevent="saveFormDetails"
          >
            <div class="row">
              <div class="col-md-6">
                <base-input
                  type="text"
                  :is-text-area="true"
                  label="Description"
                  placeholder="Description"
                  v-model="assignmentProgressDetails.descrition"
                  class="special-bottom"
                />
                <div v-if="assignmentProgressDescriptionError">
                  <div class="error"
                       v-if="!$v.assignmentProgressDetails.descrition.required"
                  >
                    This field is required
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <label> Current Shipping Order Status</label>
                <model-list-select
                  :list="outwardActivityStatusList"
                  v-model="assignmentProgressDetailsShippingOrderStatus"
                  class="form-control special-bottom"
                  option-value="data"
                  option-text="name"
                  placeholder="Select Current Shipping Order Status"
                />
                <div v-if="assignmentProgressStatusError">
                  <div class="error" v-if="!$v.assignmentProgressDetailsShippingOrderStatus.required">
                    This field is required
                  </div>
                </div>
              </div>
            </div>
            <div class="row" v-if="addAttachmentToShipOrder">
              <div class="col-md-12">
                <div class="fileUpload btn btn-info btn-fill">
                  <span>Choose Activity Attachment..</span>
                  <input
                    type="file"
                    name="attachment"
                    ref="file"
                    class="upload"
                    id="first_upload"
                    @change.prevent="uploadFile"
                  >
                </div>
                <label
                  for="first_upload"
                  id="label-for-upload"
                >
                  {{ file ? 'Selected File: ' + '" ' + file + ' "' : 'No File Selected' }}
                </label>
                <div
                  v-if="pdfFormatError"
                  class="error" style="padding-top: 5px; padding-left: 3px"
                >
                  Attach a pdf document only.
                </div>
              </div>
            </div>

            <div class="row" v-if="file">
              <div class="col-md-6">
                <base-input
                  type="text"
                  label="Attachment Title"
                  placeholder="Attachment Title"
                  v-model="tempDetails.title"
                  class="special-bottom"
                />
                <div v-if="attachmentTitleError">
                  <div class="error"
                       v-if="!$v.tempDetails.title.required"
                  >
                    This field is required
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <base-input
                  type="text"
                  :is-text-area="true"
                  label="Attachment Description"
                  placeholder="Attachment Description"
                  v-model="tempDetails.description"
                  class="special-bottom"
                />
                <div v-if="attachmentDescriptionError">
                  <div class="error"
                       v-if="!$v.tempDetails.description.required"
                  >
                    This field is required
                  </div>
                </div>
              </div>
            </div>
            <div class="text-center row">
              <div class="col-md-7 text-right loader-div">
                <span v-if="editMyLoader">Please wait ....</span>
              </div>
              <div class="col-md-2 left loader-div-gif">
                <pulse-loader
                  :loading="loading"
                  :color="color"
                  :size="size"
                  v-if="editMyLoader"
                />
              </div>
              <div
                class="col-md-3"
              >
                <button
                  type="button"
                  class="btn btn-fill btn-outline-info float-right"
                  style="margin-inline-start:20px"
                  @click="closeModal"
                >
                  Close
                </button>
                <button
                  type="submit"
                  class="btn btn-primary btn-space  float-right"
                  data-dismiss="modal"
                >
                  Save
                </button>
              </div>
            </div>
          </form>
        </card>
      </div>
    </modal>
  </div>
</template>

<script>
import Modal from '../../../components/Inputs/Modal';
import { ModelListSelect } from 'vue-search-select';
import CrudNotification from '../../../components/DataTable/crudNotification';
import DataTable from '../../../components/DataTable/updatedTable';
import { mapGetters, mapActions } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import GlobalModal from '../../../components/globalLoader';

export default {
  name: 'ShippingOrderActivityProgress',
  components: {
    Modal,
    ModelListSelect,
    CrudNotification,
    DataTable,
    GlobalModal
  },
  created () {
    this.$store.dispatch('pagenames/currentPageName', 'Assignment Progress');
    this.activityAssignmentId = this.$route.params.activityAssignmentId;
    this.loadActivityAssignmentDetailsOnCreated();
    // this.getAllUsers();
  },
  computed: {
    ...mapGetters('shippingOrder', [
      'getRequestList',
      'getRequestList2',
      'getProgressDetails',
      'getAttachmentResults',
      'getPageCount',
      'getMyFirstLoader',
      'getNoDataFound',
      'getSuccessAlert',
      'getErrorMessage',
      'getErrorAlert',
      'getSuccessMessage',
      'getReloadAllRequest',
      'getMyLoader',
      'getPageNo'
    ]),
    ...mapGetters('outwardActivities', [
      'getRequestList2'
    ])
  },
  data () {
    return {
      color: 'green',
      size: '15px',

      activityAssignmentId: '',
      activityId: '',
      goodsId: '',
      shippingOrderId: '',
      ErrorMessage: '',
      SuccessAlert: false,
      ErrorAlert: false,
      SuccessMessage: '',
      loading: false,
      smallLoading: false,
      newCargo: false,
      activityAssignmentDetails: {},
      viewCargoDetails: false,
      isView: false,
      requests: [],
      tableHeaders: ['Date',  'Status', 'Description'],
      tableDataKeys: ['createdAt', 'currentStatus', 'descrition'],
      myFirstLoader: false,
      noDataFound: false,
      outwardActivityStatusList: [],
      tempDetails: {
        title: '',
        description: ''
      },
      progressResults:{},
      isValidUser: false,
      file: '',
      fileData: '',
      myFlag: true,
      isProgressAndAttachment: false,
      assignmentProgressDetailsShippingOrderStatus: '',
      showModal: false,
      editMyLoader: false,
      pdfFormatError: false,
      addAttachmentToShipOrder: false,
      assignmentProgressDescriptionError: false,
      assignmentProgressStatusError: false,
      attachmentTitleError: false,
      attachmentDescriptionError: false,
      assignmentProgressDetails: {
        activityAssignmentId: '',
        activityId: '',
        descrition: '',
        shippingOrderId: '',
        shippingOrderStatus: ''
      }
    };
  },
  validations: {
    assignmentProgressDetails: {
      shippingOrderStatus: { required },
      descrition: { required }
    },
    assignmentProgressDetailsShippingOrderStatus: { required },
    tempDetails:{
      description: {required},
      title: { required }
    }
  },
  methods: {
    ...mapActions('shippingOrder', [
      'addShippingOrderActivityAssignmentProgress',
      'getShippingOrderActivityAssignmentProgressById',
      'getShippingOrderActivityAssignmentByActivityAssignmentId',
      'getShippingOrderProgressByShippingOrderIdAndActivityIdAndGoodId',
      'addShippingOrderActivityAttachment',
      'updateShippingOrderActivityAttachment'
    ]),
    ...mapActions('outwardActivities', [
      'getOutwardActivityByActivityId'
    ]),
    loadActivityAssignmentDetailsOnCreated () {
      this.$store.dispatch('shippingOrder/requestid', this.activityAssignmentId);
      this.getShippingOrderActivityAssignmentByActivityAssignmentId({}).then(() => {
        this.activityAssignmentDetails = this.getRequestList;
        this.myFirstLoader = this.getMyFirstLoader;
        this.addAttachmentToShipOrder = this.activityAssignmentDetails.activity.addAttachmentToShipOrder;
        this.activityId = this.activityAssignmentDetails.activity.id;
        this.goodsId = this.activityAssignmentDetails.shippingOrderGood.id;
        this.shippingOrderId = this.activityAssignmentDetails.shippingOrderGood.shippingOrder.id;
        this.loadActivityProgressOnCreated();
        this.getOutwardActivityStatusList(this.activityId);
        this.isValidUser = this.checkUser(this.activityAssignmentDetails.officer.id);
      }).catch((e) => {});
    },
    checkUser (assignedOfficerId) {
      const currentUser = window.$cookies.get('browser-local-permits-agent');
      return parseInt(currentUser) === parseInt(assignedOfficerId);
    },
    loadActivityProgressParameters () {
      return new Promise((resolve) => {
        this.$store.dispatch('shippingOrder/activityId', this.activityId);
        this.$store.dispatch('shippingOrder/shippingOrderId', this.shippingOrderId);
        this.$store.dispatch('shippingOrder/goodsId', this.goodsId);
        resolve();
      });
    },
    loadActivityProgressOnCreated () {
      this.loadActivityProgressParameters().then(() => {
        this.getShippingOrderProgressByShippingOrderIdAndActivityIdAndGoodId({}).then(() => {
          this.myFirstLoader = this.getMyFirstLoader;
          this.requests = this.formatProgress(this.getProgressDetails);
          this.noDataFound = this.getNoDataFound;
        }).catch((e) => {});
      });
    },
    getOutwardActivityStatusList (activityId) {
      this.$store.dispatch('outwardActivities/requestid', activityId);
      this.getOutwardActivityByActivityId({}).then(() => {
        this.outwardActivityStatusList = this.formatActivityStatus(JSON.parse(JSON.stringify(this.getRequestList2.shippingOrderStatus)));
      }).catch((e) => {});
    },
    saveFormDetails () {
      if (!this.myFlag){
        if (this.tempDetails.title === ''){
          return this.attachmentTitleError = true;
        }
        if (this.tempDetails.description === ''){
          return this.attachmentDescriptionError = true;
        }
      }
      if (this.assignmentProgressDetails.descrition === '') {
        return this.assignmentProgressDescriptionError = true;
      }
      if (this.assignmentProgressDetailsShippingOrderStatus === '') {
        return this.assignmentProgressStatusError = true;
      }
      else {
        this.assignmentProgressDetails.shippingOrderStatus = [this.assignmentProgressDetailsShippingOrderStatus];
        this.assignmentProgressDetails.activityId = parseInt(this.activityId);
        this.assignmentProgressDetails.shippingOrderId = parseInt(this.shippingOrderId);
        this.assignmentProgressDetails.activityAssignmentId = parseInt(this.activityAssignmentId);
        this.$store.dispatch('shippingOrder/savingDetails', this.assignmentProgressDetails);
        this.addShippingOrderActivityAssignmentProgress({}).then(() => {
          this.editMyLoader = this.getMyFirstLoader;
          this.SuccessAlert = this.getSuccessAlert;
          this.ErrorAlert = this.getErrorAlert;
          this.progressResults = this.getAttachmentResults;

          if (!this.myFlag) {
            this.isProgressAndAttachment = true;
            this.saveAttachment(this.isProgressAndAttachment);
          } else {
            this.SuccessMessage = 'Progress saved successfully';
            this.ErrorMessage = 'Sorry, something went wrong';
          }
          this.loadActivityAssignmentDetailsOnCreated();
          this.closeModal();
        }).catch((e) => {});
      }
    },
    saveAttachment (isProgressAndAttachment) {
      if (this.tempDetails.title === ''){
        this.attachmentTitleError = true;
      }
      if (this.tempDetails.description === ''){
        this.attachmentDescriptionError = true;
      }else{
        let formAttachmentDto = {};
        formAttachmentDto.description = this.tempDetails.description;
        formAttachmentDto.title = this.tempDetails.title;
        formAttachmentDto.shippingOrderId =this.progressResults.shippingOrder.shippingOrderId;
        formAttachmentDto.shippingOrderProgressId = this.progressResults.id;

        const formAttachment = new FormData();
        formAttachment.append('file', this.fileData);
        const attachmentData = {}
        // attachmentData.title = this.
        formAttachment.append('attachmentDto', new Blob([JSON.stringify(formAttachmentDto)],
          {
            type: 'application/json'
          }));
        this.$store.dispatch('shippingOrder/savingDetails', formAttachment);
        this.addShippingOrderActivityAttachment({}).then(() => {
          this.SuccessAlert = this.getSuccessAlert;
          this.ErrorAlert = this.getErrorAlert;
          if (isProgressAndAttachment) {
            this.SuccessMessage = 'Assignment Progress and Attachment Saved Successfully';
            this.ErrorMessage = 'Sorry, something went wrong';
          } else {
            this.SuccessMessage = 'Attachment Saved Successfully';
            this.ErrorMessage = 'Sorry, something went wrong';
          }
          this.closeModal();
        }).catch(() => {});

      }
    },
    onSubmit () {
      var formData = new FormData();

      formData.append('file', document.forms.userForm.file.files[0]);
      formData.append('user', new Blob([JSON.stringify({
        firstName: document.getElementById('firstName').value,
        lastName: document.getElementById('lastName').value
      })], {
        type: 'application/json'
      }));
      var boundary = Math.random().toString().substr(2);
      fetch('/upload', {
        method: 'post',
        body: formData
      }).then(function (response) {
        if (response.status !== 200) {
          alert('There was an error!');
        } else {
          alert('Request successful');
        }
      }).catch(function (err) {
        alert('There was an error!');
      });
    },
    uploadFile () {
      this.fileData = this.$refs.file.files[0];

      this.file = this.fileData.name;

      if (this.fileData.type !== 'application/pdf') {
        this.pdfFormatError = true;
        this.myFlag = true;
        this.fileData = '';
        this.file = '';
      } else {
        this.pdfFormatError = false;
        this.myFlag = false;
      }
    },

    formatActivityStatus (data) {
      if (data) {
        if (typeof (data) === 'string') {
          let temp = '';
          temp = data.replace(/([A-Z])/g, ' $1');
          temp = temp.charAt(0).toUpperCase() + temp.slice(1);
          return temp;
        } else {
          const output = [];
          let temp = '';
          for (let i = 0; i < data.length; i++) {
            temp = data[i].replace(/([A-Z])/g, ' $1');
            temp = temp.charAt(0).toUpperCase() + temp.slice(1);
            output.push({ name: temp, data: data[i] });
          }
          return output;
        }
      } else {
        return data;
      }
    },
    formatProgress (data) {
      if (data) {
        for (let i = 0; i < data.length; i++) {
          data[i].currentStatus = this.formatActivityStatus(data[i].shippingOrderStatus[0]);
        }
        return data;
      } else {
        return this.noDataFound;
      }
    },
    addActivityProgress () {
      this.showModal = true;
    },
    closeModal () {
      this.showModal = false;
      this.assignmentProgressDetails = {
        activityAssignmentId: '',
        activityId: '',
        descrition: '',
        shippingOrderId: '',
        shippingOrderStatus: ''
      };
      this.assignmentProgressDetailsShippingOrderStatus = '';
      this.myFlag = false;
      this.isProgressAndAttachment = false;
      this.fileData = '';
      this.file = '';
      this.tempDetails= {
        title :'',
        description: ''
      };
    },
    officerName (data) {
      return `${data.firstName} ${data.middleName} ${data.lastName}`;
    },
    enter: function (el, done) {
      var that = this;

      setTimeout(function () {
        that.SuccessAlert = false;
        that.ErrorAlert = false;
      }, 3000); // hide the message after 3 seconds
    },
    fadeMe: function () {
      if (this.SuccessAlert) {
        this.SuccessAlert = !this.SuccessAlert;
      } else {
        this.ErrorAlert = !this.ErrorAlert;
      }
    },
    goBack () {
      history.back();
    }
  }
};
</script>

<style scoped>

  .fileUpload {
    position: relative;
    overflow: hidden;
    margin: 0.5em 5px;
    font-size: 1em;
    letter-spacing: 0.02em;
    line-height: 1.26;
    text-decoration: none;
    top: 16px;
  }

  .fileUpload input.upload {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    padding: 0;
    cursor: pointer;
    opacity: 0;
    filter: alpha(opacity=0);
  }
  .custom-btn {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
    padding: 5px 9px 5px 9px !important;
    font-size: 14px !important;
    width: 120px;
    background-color: #FFFFFF !important;
  }
  .legend {
    position: relative;
    border: 1px solid #DCDCDC;
    padding: 1.5rem 1rem 1rem 1rem;
    margin-bottom: 1rem;
    border-radius: 3px;
    margin-top: 1rem;
  }

  .legend-title {
    background-color: inherit;
    position: absolute;
    top: -0.6rem;
    padding: 0.2rem 1rem;
    background-color: white;
    color: #d59a18;
  }
  .error{
    color:#dc3545;
    margin-left: 2px;
    margin-top: 2px;
  }
  .div-table-value{
    border: 1px solid #DCDCDC;
  }
  .div-table-row {
    padding: 0 15px 0 15px;
  }

  .div-table {
    padding: 9px 8px 9px 8px;
  }

  .div-table-title {
    width: 250px;
    background-color: rgba(153, 186, 221, .5);
    font-weight: normal;
  }

  .div-table-value {
      border: 1px solid #DCDCDC;
      font-weight: 900;
  }
</style>
