<template>
<div class="row">
  <div class="col-md-12">
    <strong>{{this.title}}</strong>
    <div class="input-group mb-3">
      <div class="col-md-6 mt-3">
        <div class="custom-file">
          <input type="file" class="custom-file-input" id="inputGroupFile01" @change="selectedFile" accept=".pdf">
          <label class="custom-file-label" for="inputGroupFile01">{{fileData!==''? fileData.name:'Choose File'}}</label>
        </div>
        <div v-if="fileDataError">
          <div
            class="error"
            v-if="!$v.fileData.required"
          >
            This field is required
          </div>
        </div>

      </div>
      <div class="col-md-6 mt-3">
        <select class="custom-select" v-model="selectedAttachmentTypeId">
          <option selected>Select Attachment type</option>
          <option :value="attachment.attachmentTypeId" v-for="(attachment,index) in attachmentTypes">{{attachment.attachmentTypesName}}</option>
        </select>
        <div v-if="selectedAttachmentTypeIdError">
          <div
            class="error"
            v-if="!$v.selectedAttachmentTypeId.required"
          >
            This field is required
          </div>
        </div>

      </div>
      <div class="col-md-12 mt-3">
        <div class="form-group">
           <textarea
             class="form-control"
             placeholder="Description"
             rows="3"
             v-model="description"
           >
           </textarea>
        </div>
      </div>
      <div class="col-md-12 mt-2">
        <button class="btn btn-default btn-fill float-right" @click="closeModal">Close</button>
        <button class="btn btn-success float-right mr-3" @click="uploadFile">Save</button>
      </div>
      <div class="col-md-12">
        <div class="text-center">
          <pulse-loader
            :loading="loading"
            :color="color"
            :size="size"
            style="margin-top: 4px;"
          />
        </div>
      </div>
      <div class="col-md-12 mt-3">
        <div class="legend">
          <h6 class="legend-title">
            Files
          </h6>
          <div class="col-md-12">
            <strong>Icons</strong><br/>
            <span class="mr-2">
              <strong class="mr-2">Print</strong>
              <font-awesome-icon icon="print" :style="{ color: 'green' }" size="lg"/>
            </span>
            <span>
              <strong class="mr-2">Delete</strong>
              <font-awesome-icon icon="trash-alt" :style="{ color: 'red' }" size="lg"/>
            </span>
          </div>
          <table class="table">
            <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">File Type</th>
              <th scope="col">Action</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(file,index) in uploadedFiles" :key="index">
              <th scope="row">{{index + 1}}</th>
              <td>{{file.attachmentType.attachmentTypesName}}</td>
              <td>
                <span class="ml-4 mr-5 pointer" @click="getFileFromMinio(file.attachmentUrl , attachment.attachmentName)">
                  <font-awesome-icon icon="print" :style="{ color: 'green' }" size="lg"/>
                </span>
                <span class="pointer">
                  <font-awesome-icon icon="trash-alt" :style="{ color: 'red' }" size="lg" @click="deleteAttachment(file.id)"/>
                </span>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <global-modal v-if="globalLoader">
      <div slot="globalLoader">
        <div class="row text-center">
          <div class="col-md-12">
            Downloading requested file, Please wait
          </div>
          <div class="col-md-12">
            <br>
          </div>
          <div class="col-md-12">
            <pulse-loader
              :loading="loading"
              :color="color"
              :size="size"
            />
          </div>
        </div>
      </div>
    </global-modal>
</div>
</template>

<script>

  import AGENCY_API from "../../../api/agency";
  import PulseLoader from 'vue-spinner/src/PulseLoader.vue';
  import GlobalModal from '../../../components/globalLoader'
  import { required } from 'vuelidate/lib/validators';
  import { mapActions, mapGetters } from 'vuex';


  export default {
        name: "uploadSA",

    components:{
      PulseLoader,
      GlobalModal,
    },

    validations: {
      selectedAttachmentTypeId:{required},
      fileData:{required}
    },

    created() {
      this.getAttachmentTypes();
      this.listAllFiles();
    },

    props:{
      title:'',
      vesselPaticularId:'',
    },

    computed: {

    ...mapGetters('global', [

      'getGlobalLoader'

    ])

  },

    data(){

          return {
            fileData:'',
            attachmentTypes:[],
            color: 'green',
            size: '12px',
            loading:false,
            description:"",
            globalLoader: false,
            selectedAttachmentTypeId:'',
            selectedAttachmentTypeIdError:false,
            fileDataError:false,
            uploadedFiles:[]
          }
      },

      methods:{

        ...mapActions('global', [

      'loadFileFromMinio'

    ]),

    loadParameters (fileUrl, fileName) {
      
      return new Promise((resolve) => {

        this.$store.dispatch('global/fileUrling', fileUrl)
        this.$store.dispatch('global/fileNaming', fileName)

        resolve()
      })

    },

    getFileFromMinio (fileUrl, fileName) {

      this.loadParameters(fileUrl, fileName).then(() => {

      this.loadFileFromMinio({}).then((res) => {

          if(res.status === 204) {

              this.loadNotification('warn', 4000, 'File Download',
              'File not found, please contact system admin',
              'response', 1000 , true  , true)

          } else {

            this.loadNotification('success', 4000, 'File Download',
              'File has been successfully downloaded, please have a look at it',
              'response', 1000 , true  , true)


          }

            this.globalLoader = this.getGlobalLoader
        
        }).catch(() => {

          this.loadNotification('error', 4000, 'File Download',
              'File not downloaded, please check your internet connection',
              'response', 1000 , true  , true)

          this.globalLoader = this.getGlobalLoader

        })

        this.globalLoader = this.getGlobalLoader

      }).catch(() => {

      })

    },

        selectedFile(event){
          this.fileData = event.target.files[0]
        },

        getAttachmentTypes(){
          AGENCY_API.getAttachmentTypes().then((resp)=>{
            this.attachmentTypes=resp.data.data;
          });
        },

        listAllFiles(){
          this.loading=true;
          AGENCY_API.getAllFiles(this.vesselPaticularId).then((resp)=>{
            this.uploadedFiles=resp.data.data;
            this.loading=false;
          }).catch(()=>{
            this.loading=false;
          });
        },

        uploadFile(){

          if (!this.$v.selectedAttachmentTypeId.$invalid && !this.$v.fileData.$invalid) {
            this.loading=true;
            this.selectedAttachmentTypeIdError=false;
            this.fileDataError=false;

            let formData = new FormData();
            formData.append('file', this.fileData);

            AGENCY_API.uploadSOF(formData,this.vesselPaticularId ,this.selectedAttachmentTypeId,this.descrition).then((resp)=>{
              this.selectedAttachmentTypeId='';
              this.description='';
              this.fileData='';
              this.loading=false;
              this.listAllFiles();
            }).catch((e)=>{
              this.loading=false;
            })
          }else {
            this.selectedAttachmentTypeIdError=true;
            this.fileDataError=true;
          }

        },

        closeModal(){
          this.$emit('closeModal');
        },

        deleteAttachment(attachmentId){
          this.loading=true;
          AGENCY_API.deleteAttachment(attachmentId).then((resp)=>{
            this.listAllFiles();
            this.loading=false;
          }).catch((err)=>{
            this.loading=false;
          })
        }

      }
    }
</script>

<style scoped>
  .error{
    color:#dc3545;
    margin-left: 2px;
    margin-top: 2px;
  }

  .legend {
    position: relative;
    border: 1px solid #DCDCDC;
    padding: 1.5rem 1rem 1rem 1rem;
    margin-bottom: 1rem;
    border-radius: 3px;
    margin-top: 1rem;
  }

  .legend-title {
  //background-color: inherit;
    position: absolute;
    top: -0.6rem;
    padding: 0.2rem 1rem;
    background-color: white;
    color: #d59a18;
  }

  .pointer{
    cursor: pointer;
  }

</style>
