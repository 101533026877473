<template>
  <div>
    <card>
      <data-table
        :table-headers="tableHeaders"
        :table-data="requests"
        :table-data-keys="tableDataKeys"
        :my-first-loader="myFirstLoader"
        :page-size-prop="pageSize"
        :page-no="pageNo"
        :is-pageable="isPageable"
        :total-elements="totalElements"
        :total-pages="totalPages"
        :is-first="isFirst"
        :is-last="isLast"
        :no-data-found="noDataFound"
        :page-number="pageNumber"
        :coloredStatus="true"
        :loading-title="currentLoadingTitle"
        :permissions="[ {key: 'searchPermission', value: 'DELIVERY-ODER_REQUEST_ALL_SEARCH_GET'} ]"
        :date-prop="'Created At '"
        :has-manage-button="true"
        :hasFilter="false"
        :reload-all-request="reloadRequests"
        @searchRequest="searchDONumber"
        @reloadByIcon="reloadByIcon"
        @goToApplication="goToApplication"
        @onChange="onChange"
        @goToPage="goToPage"
      >
      <div slot = "pagination">
        <paginate
            v-model="getIsPage"
            :page-count=parseInt(totalPages)
            :page-range="3"
            :margin-pages="2"
            :click-handler="goToPage"
            :prev-text="'Prev'"
            :next-text="'Next'"
            :container-class="'pagination'"
            :page-class="'page-item'">
        </paginate>
      </div>
      </data-table>
    </card>

    <global-modal v-if="myLoader">
      <div slot="globalLoader">
        <div class="row text-center">
          <div class="col-md-12">
            Loading, Please wait
          </div>
          <div class="col-md-12">
            <br>
          </div>
          <div class="col-md-12">
            <pulse-loader
              :loading="loading"
              :color="color"
              :size="size"
            />
          </div>
        </div>
      </div>
    </global-modal>
  </div>
</template>

<script>
  import PulseLoader from 'vue-spinner/src/PulseLoader';
  import GlobalModal from '../../../components/globalLoader';
  import DataTable from '../../../components/DataTable/dataTable';
  import { mapActions, mapGetters } from 'vuex';

  export default {
    name: 'DeliveryOrderList',
    components: {
      PulseLoader,
      GlobalModal,
      DataTable
    },
    created () {
      this.loadData();
    },
    watch: {

      '$route.path': function(val, oldVal){
        console.log('urururururur');
        this.loadData();
      }
    },


    data () {
      return {
        currentLoadingTitle:'',
        pageNo: 0,
        pageSize: 15,
        totalElements: '',
        totalPages: '',
        isFirst: false,
        isLast: false,
        pageNumber: '',
        isPageable: true,
        requests: [],
        myLoader: false,
        myFirstLoader: false,
        reloadRequests: false,
        tableHeaders: ['BL No.', 'Delivery Order No.',  'DO Date', 'Agent', 'DO Status'],
        tableDataKeys: ['blNo', 'doNumber', 'doDate', 'agentName', 'status'],
        noDataFound:false,
        isPage: 0
      };
    },
    computed: {
      ...mapGetters('deliveryOrder', [

        'getMyLoader',
        'getPageNo',
        'getRequestList',
        'getNoDataFound',
        'getSuccessAlert',
        'getErrorMessage',
        'getErrorAlert',
        'getSuccessMessage',
        'getDeliveryOrderStatus',
        'getMyFirstLoader'

      ]),

      getIsPage: {

        get: function () {
            return this.isPage
        },
        set: function (newValue) {
            this.isPage = newValue;
        }

      }
    },
    methods: {
      ...mapActions('deliveryOrder', [
        'loadAllDeliveryOrder',
        'searchDeliveryOrders',
        'loadAllDeliveryOrdersByStatusPageable'
      ]),

      loadData(){
        this.$store.dispatch('pagenames/currentPageName', 'Delivery Order Check');

        this.finalUserPermissions = JSON.parse(localStorage.getItem('browser-local-permits'));
        // this.loadPageableDeliveryOrdersOnCreated(this.pageNo, this.pageSize);
        this.currentStatus=this.$route.params.status;
        this.dispatchStatus(this.currentStatus);
        this.loadPageableDeliveryOrdersByStatus();
        this.getCurrentLoadingTitle();
      },

      loadParameters (pageNo, pageSize) {
        return new Promise((resolve) => {
          this.$store.dispatch('deliveryOrder/pageSizing', this.pageSize);
          this.$store.dispatch('deliveryOrder/pageNumbering', this.pageNo);
          resolve();
        });
      },
      setStatusActive(status){
        this.currentStatus = status;
        this.dispatchStatus(status);
        this.pageNo = 0;
        this.pageSize = 15;
        this.loadPageableDeliveryOrdersByStatus()
      },
      isMainActive(statusClass){
        return this.currentStatus === statusClass;
      },
      dispatchStatus(status){
        return new Promise((resolve ) =>{
          this.$store.dispatch('deliveryOrder/deliveryOrderStatusValue', status)
          resolve();
        })
      },
      getCurrentLoadingTitle(){
        this.currentLoadingTitle =  "Please wait while the system is loading " + this.currentStatus.toLowerCase() + " delivery orders"
      },
      loadPageableDeliveryOrdersByStatus(){
        this.getCurrentLoadingTitle()
        this.myFirstLoader = true;
        this.loadAllDeliveryOrdersByStatusPageable({}).then(()=>{
          this.myFirstLoader = this.getMyFirstLoader;
          this.requests = this.extractAgentFromObject(JSON.parse(JSON.stringify(this.getRequestList.content)));
          console.log(this.requests);
          this.isLast = this.getRequestList.last;
          this.totalElements = this.getRequestList.totalElements;
          this.totalPages = this.getRequestList.totalPages;
          this.pageNumber = this.getRequestList.number;
          this.isFirst = this.getRequestList.first;
          this.isLast = this.getRequestList.last;
          this.noDataFound = this.getRequestList.empty;
        }).catch(()=>{

        });
      },


      loadPageableDeliveryOrdersOnCreated (pageNo, pageSize) {
        this.loadParameters(pageNo, pageSize).then(() => {
          this.myFirstLoader = true;
          this.loadAllDeliveryOrder({}).then(() => {
            // console.log(this.getRequestList)
            this.myFirstLoader = this.getMyFirstLoader;
            this.requests = this.extractAgentFromObject(JSON.parse(JSON.stringify(this.getRequestList.content)));
            this.isLast = this.getRequestList.last;
            this.totalElements = this.getRequestList.totalElements;
            this.totalPages = this.getRequestList.totalPages;
            this.pageNumber = this.getRequestList.number;
            this.isFirst = this.getRequestList.first;
            this.isLast = this.getRequestList.last;
            this.noDataFound = this.getNoDataFound;
          }).catch(() => {

          });
        });
      },
      /**
       * Helper function to parse date and extract agent name from agent object
       **/
      extractAgentFromObject (requestList) {
        for (let i = 0; i < requestList.length; i++) {
          requestList[i].doDate = this.dateTimeToHuman(requestList[i].doDate);
          requestList[i].expireDate = this.dateTimeToHuman(requestList[i].expireDate);
          requestList[i].agentName = requestList[i].agent.agentName;
        }
        return requestList;
      },
      onChange (event) {
        this.pageSize = event;
        this.pageNo = 0;
        this.isPage = 0
        this.loadPageableDeliveryOrdersByStatus();
      },
      goToPage (page) {

        if(page > 0) {page = page - 1;}
        this.isPage = page + 1
        this.pageNo = page;
        this.loadPageableDeliveryOrdersByStatus();
      },

      searchDONumber (searchParam) {
        // this.requests = [];
        if (searchParam !== null && searchParam !== 'undefined') {
          this.$store.dispatch('deliveryOrder/searchingParameter', searchParam);
          this.searchDeliveryOrders({}).then(() => {
            this.requests = this.extractAgentFromObject(JSON.parse(JSON.stringify([this.getRequestList])));
            this.isPageable = false;
          }).catch(() => {

          });
          this.reloadRequests = true;
        }
      },

      reloadByIcon () {
        this.reloadRequests = false;
        this.isPageable = true;
        this.requests = [];
        this.loadPageableDeliveryOrdersByStatus();
      },
      goToApplication (data) {
        this.$router.push('/shipping-agency/delivery-orders/order-details/' + data.deliveryOderId);
      }
    }

  };
</script>

<style scoped>
  ul.nav-justified {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    /*background-color: #ededee;*/
    border-radius: 3px;
  }

  ul.nav-justified li {
    float: left;
    border-top: 1px solid #DCDCDC;
    border-left: 1px solid #DCDCDC;
    border-right: 1px solid #DCDCDC;
    border-bottom: none !important;
    margin-right: 20px;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
  }


  ul.nav-justified li a {
    display: block;
    text-align: center;
    padding: 8px 15px 8px 15px !important;
    width: calc((100vw - 25vw) / 5);
    text-decoration: none;
    color: #000000;
    font-weight: bold;
    text-transform: uppercase;
  }

  ul.nav-justified li :hover {
    color: #d59a18;
    padding: 16px 20px 10px 21px;
    background-color: #ffffff;
  }

  .tab-content {
    border-top: 1px solid #DCDCDC;
    border-radius: 5px;
  }

  .is-active-new, .is-active-new :hover {
    color: #ffffff !important;
    padding: 0 0 2px 0;
    background-color: gray !important;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
  }

  .is-active-verified, .is-active-verified :hover {
    color: #ffffff !important;
    padding: 0 0 2px 0;
    background-color: #3498db !important;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
  }

  .is-active-major, .is-active-major :hover {
    color: #ffffff !important;
    padding: 0 0 2px 0;
    background-color: #2F4F4F !important;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
  }

  .is-active-minor, .is-active-minor :hover {
    color: #ffffff !important;
    padding: 0 0 2px 0;
    background-color: green !important;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
  }

  .is-active-returned, .is-active-returned :hover {
    color: #ffffff !important;
    padding: 0 0 2px 0;
    background-color: red !important;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
  }

  .is-active-waiting-control-number , .is-active-waiting-control-number :hover {
    color: #ffffff !important;
    padding: 0 0 2px 0;
    background-color: #DCDCDC !important;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
  }

  .is-active-shipping-order , .is-active-shipping-order:hover {
    color: #ffffff !important;
    padding: 0 0 2px 0;
    background-color: #556b2f !important;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
  }

  .is-active-delivery-order , .is-active-delivery-order:hover {
    color: #ffffff !important;
    padding: 0 0 2px 0;
    background-color: #d2b48c !important;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
  }

  .rejected{
    color: red;
  }
</style>
