<template>
  <transition v-if="viewAllDetails" name="col-md-12">
    <div>
      <div
        class="modal modal-mask"
        style="display: block"
      >
        <div
          class="modal-dialog medium-modal-dialog"
          role="document"
        >
          <div class="modal-content col-md-12">
            <div class="modal-header col-md-12">
              <div class="modal-title col-md-12">
                <div class="row">
                  <div class="col-md-12">
                    <b> Vessel Details</b>
                  </div>

                  <div class="col-md-12">

                    <hr>
                  </div>
                  <div class="col-md-12">
                    <button
                      v-if="!hasSaveAction"
                      class="btn btn-primary custom-btn"
                      style="float: right"
                      @click.prevent="hasSaveAction = !hasSaveAction"
                    >
                      Update Details
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <!--  Form  -->
              <div class="modal-body col-md-12">
                <card>
                  <!--      ADD FORM      -->
                  <form>
                    <div id="add-view">

                      <ul class="nav-justified">
                        <li>
                          <a :class="[showStart ? 'is-active-main-details': '']" @click.prevent="showStart = !showStart ">Vessel Details</a>
                        </li>
                        <li>
                          <a :class="[!showStart? 'is-active-additional-details': '']" @click.prevent="showStart = !showStart ">Additional Vessel Particulars</a>
                        </li>
                      </ul>
                      <div v-if="showStart">
                        <!--  row 1 -->
                        <div class="row">
                          <div class="col-md-4">
                            <base-input
                              v-model="vesselDetails.vesselName"
                              type="text"
                              label="Vessel Name"
                              placeholder="Vessel Name"
                              :value="vesselDetails.vesselName"
                              class="special-bottom"
                              :readonly="!hasSaveAction"
                            />
                            <div
                              v-if="!$v.vesselDetails.vesselName.required && isTested"
                              class="error"
                            >
                              This field is required
                            </div>
                          </div>
                          <div class="col-md-4">
                            <base-input
                              v-model="vesselDetails.voyageNo"
                              type="text"
                              label="Vessel Voyage No."
                              placeholder="Vessel Voyage No."
                              :value="vesselDetails.voyageNo"
                              class="special-bottom"
                              oninput="let p=this.selectionStart;this.value=this.value.toUpperCase();this.setSelectionRange(p, p);"
                              :readonly="!hasSaveAction"
                            />
                            <div
                              v-if="!$v.vesselDetails.voyageNo.required && isTested"
                              class="error"
                            >
                              This field is required
                            </div>
                          </div>
                          <div class="col-md-2">
                            <label>Vessel Type</label>
                            <model-list-select
                              v-model="vesselDetails.vesselType"
                              :list="vesselTypeEnum"
                              class="form-control"
                              option-value="vesselType"
                              option-text="vesselType"
                              placeholder="Vessel Type"
                              :is-disabled="!hasSaveAction"
                            />
                            <div
                              v-if="!$v.vesselDetails.vesselType.required && isTested"
                              class="error"
                            >
                              This field is required
                            </div>
                          </div>
                          <div class="col-md-2">
                            <base-input
                              v-model="vesselDetails.carrierName"
                              type="text"
                              label="Vessel Carrier Name"
                              placeholder="Vessel Carrier Name"
                              :value="vesselDetails.carrierName"
                              class="special-bottom"
                              oninput="let p=this.selectionStart;this.value=this.value.toUpperCase();this.setSelectionRange(p, p);"
                              :readonly="!hasSaveAction"
                            />
                            <!--                              <div-->
                            <!--                                class="error"-->
                            <!--                                v-if="!$v.vesselDetails.carrierName.required && isTested"-->
                            <!--                              >-->
                            <!--                                This field is required-->
                            <!--                              </div>-->
                          </div>
                        </div>
                        <!--   row 1.5-->
                        <div class="row">
                          <div class="col-md-2">
                            <base-input
                              v-model="vesselDetails.carrierCode"
                              type="text"
                              label="Vessel Carrier Code"
                              placeholder="Vessel Carrier Code (TASAC)"
                              :value="vesselDetails.carrierCode"
                              class="special-bottom"
                              :readonly="true"
                              oninput="let p=this.selectionStart;this.value=this.value.toUpperCase();this.setSelectionRange(p, p);"
                            />
                            <div
                              v-if="!$v.vesselDetails.carrierCode.required && isTested"
                              class="error"
                            >
                              This field is required
                            </div>
                          </div>

                          <div class="col-md-2">
                            <label>Contract Type</label>
                            <model-list-select
                              v-model="vesselDetails.contractType"
                              :list="contractTypeEnum"
                              class="form-control"
                              option-value="contractType"
                              option-text="contractType"
                              placeholder="Contract Type"
                              required="true"
                              :is-disabled="!hasSaveAction"
                            />
                            <div
                              v-if="!$v.vesselDetails.contractType.required && isTested"
                              class="error"
                            >
                              This field is required
                            </div>
                          </div>
                          <div class="col-md-2">
                            <base-input
                              v-model="vesselDetails.vesselMaster"
                              type="text"
                              label="Vessel Master Name"
                              placeholder="Vessel Master Name"
                              :value="vesselDetails.vesselMaster"
                              class="special-bottom"
                              oninput="let p=this.selectionStart;this.value=this.value.toUpperCase();this.setSelectionRange(p, p);"
                              :readonly="!hasSaveAction"
                            />
                            <!--                              <div-->
                            <!--                                class="error"-->
                            <!--                                v-if="!$v.vesselDetails.vesselMaster.required && isTested"-->
                            <!--                              >-->
                            <!--                                This field is required-->
                            <!--                              </div>-->
                          </div>
                          <div class="col-md-6">
                            <base-input
                              v-model="vesselDetails.vesselMasterAddress"
                              type="text"
                              label="Vessel Master Address"
                              placeholder="Vessel Master Address"
                              :value="vesselDetails.vesselMasterAddress"
                              class="special-bottom"
                              oninput="let p=this.selectionStart;this.value=this.value.toUpperCase();this.setSelectionRange(p, p);"
                              :readonly="!hasSaveAction"
                            />
                            <!--                              <div-->
                            <!--                                class="error"-->
                            <!--                                v-if="!$v.vesselDetails.vesselMasterAddress.required && isTested"-->
                            <!--                              >-->
                            <!--                                This field is required-->
                            <!--                              </div>-->
                          </div>

                        </div>

                        <!--  row 2 -->
                        <div class="row">

                          <div class="col-md-3">
                            <base-input
                              v-model="vesselDetails.transportMeansName"
                              type="text"
                              label="Transport Means Name"
                              placeholder="Transport Means Name"
                              :value="vesselDetails.transportMeansName"
                              class="special-bottom"
                              :readonly="!hasSaveAction"
                            />
                            <!--                  <div-->
                            <!--                    class="error"-->
                            <!--                    v-if="!$v.vesselDetails.berth.required && isTested"-->
                            <!--                  >-->
                            <!--                    This field is required-->
                            <!--                  </div>-->
                          </div>
                          <div class="col-md-2">
                            <base-input
                              v-model="vesselDetails.transportMeansId"
                              type="text"
                              label="Port of Registry"
                              placeholder="Port of Registry"
                              :value="vesselDetails.transportMeansId"
                              class="special-bottom"
                              :readonly="!hasSaveAction"
                            />
                            <!--                  <div-->
                            <!--                    class="error"-->
                            <!--                    v-if="!$v.vesselDetails.berth.required && isTested"-->
                            <!--                  >-->
                            <!--                    This field is required-->
                            <!--                  </div>-->
                          </div>
                          <div class="col-md-2">
                            <base-input
                              v-model="vesselDetails.transportMeansNationality"
                              type="text"
                              label="Flag"
                              placeholder="Flag"
                              :value="vesselDetails.transportMeansNationality"
                              class="special-bottom"
                              :readonly="!hasSaveAction"
                            />
                            <!--                  <div-->
                            <!--                    class="error"-->
                            <!--                    v-if="!$v.vesselDetails.berth.required && isTested"-->
                            <!--                  >-->
                            <!--                    This field is required-->
                            <!--                  </div>-->
                          </div>
                          <div class="col-md-2">
                            <base-input
                              v-model="vesselDetails.modeOfTransportCoded"
                              type="text"
                              label="Mode of Transport Coded"
                              placeholder="Mode of Transport Coded"
                              :value="vesselDetails.modeOfTransportCoded"
                              class="special-bottom"
                              :readonly="!hasSaveAction"
                            />
                            <!--                              <div-->
                            <!--                                class="error"-->
                            <!--                                v-if="!$v.vesselDetails.etd.required && isTested"-->
                            <!--                              >-->
                            <!--                                This field is required-->
                            <!--                              </div>-->
                          </div>

                          <!--                            <div class="col-md-2">-->
                          <!--                              <base-input-->
                          <!--                                type="text"-->
                          <!--                                label="Transport Stage Type"-->
                          <!--                                placeholder="Transport Stage Type"-->
                          <!--                                v-model="vesselDetails.transportStageType"-->
                          <!--                                :value="vesselDetails.transportStageType"-->
                          <!--                                class="special-bottom"-->
                          <!--                                :readonly="!hasSaveAction"-->
                          <!--                              />-->
                          <!--                              &lt;!&ndash;                  <div&ndash;&gt;-->
                          <!--                              &lt;!&ndash;                    class="error"&ndash;&gt;-->
                          <!--                              &lt;!&ndash;                    v-if="!$v.vesselDetails.berth.required && isTested"&ndash;&gt;-->
                          <!--                              &lt;!&ndash;                  >&ndash;&gt;-->
                          <!--                              &lt;!&ndash;                    This field is required&ndash;&gt;-->
                          <!--                              &lt;!&ndash;                  </div>&ndash;&gt;-->
                          <!--                            </div>-->

                        </div>

                        <!--   row 2.5 -->
                        <div class="row">
                          <div class="col-md-2">
                            <base-input
                              v-model="vesselDetails.arrivalPort"
                              type="text"
                              label="Arrival Port"
                              placeholder="Arrival Port"
                              :value="vesselDetails.arrivalPort"
                              class="special-bottom"
                              oninput="let p=this.selectionStart;this.value=this.value.toUpperCase();this.setSelectionRange(p, p);"
                              :readonly="!hasSaveAction"
                            />
                            <div
                              v-if="!$v.vesselDetails.arrivalPort.required && isTested"
                              class="error"
                            >
                              This field is required
                            </div>
                          </div>
                          <div class="col-md-2">
                            <base-input
                              v-model="vesselDetails.destinationPort"
                              type="text"
                              label="Destination Port"
                              placeholder="Destination Port"
                              :value="vesselDetails.destinationPort"
                              class="special-bottom"
                              :readonly="!hasSaveAction"
                            />
                            <!--                  <div-->
                            <!--                    class="error"-->
                            <!--                    v-if="!$v.vesselDetails.berth.required && isTested"-->
                            <!--                  >-->
                            <!--                    This field is required-->
                            <!--                  </div>-->
                          </div>
                          <div class="col-md-2">
                            <base-input
                              v-model="vesselDetails.nextPort"
                              type="text"
                              label="Next Port"
                              placeholder="Next Port"
                              :value="vesselDetails.nextPort"
                              class="special-bottom"
                              :readonly="!hasSaveAction"
                            />
                            <!--                  <div-->
                            <!--                    class="error"-->
                            <!--                    v-if="!$v.vesselDetails.berth.required && isTested"-->
                            <!--                  >-->
                            <!--                    This field is required-->
                            <!--                  </div>-->
                          </div>
                          <div class="col-md-2">
                            <base-input
                              v-model="vesselDetails.portOfCall"
                              type="text"
                              label="Port of Call"
                              placeholder="Port of Call"
                              :value="vesselDetails.portOfCall"
                              class="special-bottom"
                              :readonly="!hasSaveAction"
                            />
                            <!--                  <div-->
                            <!--                    class="error"-->
                            <!--                    v-if="!$v.vesselDetails.berth.required && isTested"-->
                            <!--                  >-->
                            <!--                    This field is required-->
                            <!--                  </div>-->
                          </div>
                          <div class="col-md-2">
                            <base-input
                              v-model="vesselDetails.terminal"
                              type="text"
                              label="Terminal"
                              placeholder="Terminal"
                              :value="vesselDetails.terminal"
                              class="special-bottom"
                              :readonly="!hasSaveAction"
                            />
                            <!--                  <div-->
                            <!--                    class="error"-->
                            <!--                    v-if="!$v.vesselDetails.berth.required && isTested"-->
                            <!--                  >-->
                            <!--                    This field is required-->
                            <!--                  </div>-->
                          </div>
                          <div class="col-md-2">
                            <base-input
                              v-model="vesselDetails.terminalOperatorCode"
                              type="text"
                              label="Terminal Operator Code"
                              placeholder="Terminal Operator Code"
                              :value="vesselDetails.terminalOperatorCode"
                              class="special-bottom"
                              :readonly="!hasSaveAction"
                            />
                            <!--                  <div-->
                            <!--                    class="error"-->
                            <!--                    v-if="!$v.vesselDetails.berth.required && isTested"-->
                            <!--                  >-->
                            <!--                    This field is required-->
                            <!--                  </div>-->
                          </div>
                        </div>

                        <!--  row 3 -->
                        <div class="row">
                          <div class="col-md-2">
                            <base-input
                              v-model="vesselDetails.controlReferenceNumber"
                              type="text"
                              label="Control Reference Number"
                              placeholder="Control Reference Number"
                              :value="vesselDetails.controlReferenceNumber"
                              class="special-bottom"
                              :readonly="!hasSaveAction"
                            />
                            <!--                  <div-->
                            <!--                    class="error"-->
                            <!--                    v-if="!$v.vesselDetails.berth.required && isTested"-->
                            <!--                  >-->
                            <!--                    This field is required-->
                            <!--                  </div>-->
                          </div>
                          <div class="col-md-2">
                            <base-input
                              v-model="vesselDetails.customOfficeCode"
                              type="text"
                              label="Custom Office Code"
                              placeholder="Custom Office Code"
                              :value="vesselDetails.customOfficeCode"
                              class="special-bottom"
                              :readonly="!hasSaveAction"
                            />
                            <!--                  <div-->
                            <!--                    class="error"-->
                            <!--                    v-if="!$v.vesselDetails.berth.required && isTested"-->
                            <!--                  >-->
                            <!--                    This field is required-->
                            <!--                  </div>-->
                          </div>

                          <div class="col-md-2">
                            <base-input
                              v-model="vesselDetails.eta"
                              type="datetime-local"
                              label="Vessel ETA"
                              placeholder="Vessel ETA"
                              :value="vesselDetails.eta"
                              class="special-bottom"
                              :readonly="!hasSaveAction"
                            />
                            <div
                              v-if="!$v.vesselDetails.eta.required && isTested"
                              class="error"
                            >
                              This field is required
                            </div>
                          </div>
                          <div class="col-md-2">
                            <base-input
                              v-model="vesselDetails.etb"
                              type="datetime-local"
                              label="Vessel ETB"
                              placeholder="Vessel ETB"
                              :value="vesselDetails.etb"
                              class="special-bottom"
                              :readonly="!hasSaveAction"
                            />
                            <div
                              v-if="!$v.vesselDetails.etb.required && isTested"
                              class="error"
                            >
                              This field is required
                            </div>
                          </div>
                          <div class="col-md-2">
                            <base-input
                              v-model="vesselDetails.etd"
                              type="datetime-local"
                              label="Vessel ETD"
                              placeholder="Vessel ETD"
                              :value="vesselDetails.etd"
                              class="special-bottom"
                              :onchange="getExpectedStayTime"
                              :readonly="!hasSaveAction"
                            />
                            <div
                              v-if="!$v.vesselDetails.etd.required && isTested"
                              class="error"
                            >
                              This field is required
                            </div>
                          </div>
                          <div class="col-md-2">
                            <base-input
                              v-model="vesselDetails.modeOfTransport"
                              type="text"
                              label="Mode of Transport"
                              placeholder="Mode of Transport"
                              :value="vesselDetails.modeOfTransport"
                              class="special-bottom"
                              :readonly="!hasSaveAction"
                            />
                            <!--                              <div-->
                            <!--                                class="error"-->
                            <!--                                v-if="!$v.vesselDetails.etd.required && isTested"-->
                            <!--                              >-->
                            <!--                                This field is required-->
                            <!--                              </div>-->
                          </div>
                        </div>

                        <!-- row 3.5  -->
                        <div class="row">

                          <div class="col-md-8">
                            <base-input
                              v-model="vesselDetails.operation"
                              type="text"
                              label="Vessel Operations"
                              placeholder="Vessel Operations"
                              :value="vesselDetails.operation"
                              class="special-bottom"
                              oninput="let p=this.selectionStart;this.value=this.value.toUpperCase();this.setSelectionRange(p, p);"
                              :readonly="!hasSaveAction"
                            />
                            <div
                              v-if="!$v.vesselDetails.operation.required && isTested"
                              class="error"
                            >
                              This field is required
                            </div>
                          </div>
                          <div class="col-md-2">
                            <base-input
                              v-model="vesselDetails.stayTime"
                              type="number"
                              label="Expected Stay Time (Hrs)"
                              placeholder="Expected Stay Time (Hrs)"
                              :value="vesselDetails.stayTime"
                              class="special-bottom"
                              :readonly="!hasSaveAction"
                            />
                            <div
                              v-if="!$v.vesselDetails.stayTime.required && isTested"
                              class="error"
                            >
                              This field is required
                            </div>
                          </div>

                          <div class="col-md-2">
                            <base-input
                              v-model="vesselDetails.draft"
                              type="number"
                              step="0.0001"
                              label="Vessel Draft (Metres)"
                              placeholder="Vessel Draft (Metres)"
                              :value="vesselDetails.draft"
                              class="special-bottom"
                              :readonly="!hasSaveAction"
                            />
                            <div
                              v-if="!$v.vesselDetails.draft.required && isTested"
                              class="error"
                            >
                              This field is required
                            </div>
                          </div>

                        </div>

                        <!--  row 4 -->
                        <div class="row">
                          <div class="col-md-2">
                            <base-input
                              v-model="vesselDetails.dwt"
                              type="number"
                              step="0.0001"
                              label="Vessel Dead Weight Tonnage (DWT)"
                              placeholder="Vessel Dead Weight Tonnage (DWT)"
                              :value="vesselDetails.dwt"
                              class="special-bottom"
                              :readonly="!hasSaveAction"
                            />
                            <div
                              v-if="!$v.vesselDetails.dwt.required && isTested"
                              class="error"
                            >
                              This field is required
                            </div>
                          </div>
                          <div class="col-md-2">
                            <base-input
                              v-model="vesselDetails.grt"
                              type="number"
                              step="0.0001"
                              label="Vessel Gross Weight Tonnage"
                              placeholder="Vessel Gross Weight Tonnage"
                              :value="vesselDetails.grt"
                              class="special-bottom"
                              :readonly="!hasSaveAction"
                              :onchange="vesselDetails.factor = Math.ceil(vesselDetails.grt/100)"
                            />
                            <div
                              v-if="!$v.vesselDetails.grt.required && isTested"
                              class="error"
                            >
                              This field is required
                            </div>
                          </div>
                          <div class="col-md-2">
                            <base-input
                              v-model="vesselDetails.nrt"
                              type="number"
                              step="0.0001"
                              label="Net Weight Tonnage"
                              placeholder="Net Weight Tonnage"
                              :value="vesselDetails.nrt"
                              class="special-bottom"
                            />
                            <div
                              v-if="!$v.vesselDetails.nrt.required && isTested"
                              class="error"
                            >
                              This field is required
                            </div>
                          </div>
                          <div class="col-md-2">
                            <base-input
                              v-model="vesselDetails.loa"
                              type="number"
                              step="0.0001"
                              label="Length Overall (LOA) (Metres)"
                              placeholder="Length Overall (LOA) (Metres)"
                              :value="vesselDetails.loa"
                              :readonly="!hasSaveAction"
                              class="special-bottom"
                            />
                            <div
                              v-if="!$v.vesselDetails.loa.required && isTested"
                              class="error"
                            >
                              This field is required
                            </div>
                          </div>
                          <div class="col-md-2">
                            <base-input
                              v-model="vesselDetails.factor"
                              type="number"
                              step="0.0001"
                              label="Vessel Factor"
                              placeholder="Vessel Factor"
                              :value="vesselDetails.factor"
                              :readonly="true"
                              class="special-bottom"
                            />
                            <div
                              v-if="!$v.vesselDetails.factor.required && isTested"
                              class="error"
                            >
                              This field is required
                            </div>
                          </div>
                          <div class="col-md-2">
                            <base-input
                              v-model="vesselDetails.beam"
                              type="number"
                              step="0.0001"
                              label="Vessel Beam (Metres)"
                              placeholder="Vessel Beam (Metres)"
                              :value="vesselDetails.beam"
                              class="special-bottom"
                              :readonly="!hasSaveAction"
                            />
                            <!--                  <div-->
                            <!--                    class="error"-->
                            <!--                    v-if="!$v.vesselDetails.beam.required && isTested"-->
                            <!--                  >-->
                            <!--                    This field is required-->
                            <!--                  </div>-->
                          </div>

                        </div>

                      </div>

                      <div v-if="!showStart">

                        <!--  row 6 -->
                        <div class="row">

                          <div class="col-md-3">
                            <base-input
                              v-model="vesselDetails.ata"
                              type="datetime-local"
                              label="Vessel ATA"
                              placeholder="Vessel ATA"
                              :value="vesselDetails.ata"
                              class="special-bottom"
                              :readonly="!hasSaveAction"
                            />
                            <!--                  <div-->
                            <!--                    class="error"-->
                            <!--                    v-if="!$v.vesselDetails.ata.required && isTested"-->
                            <!--                  >-->
                            <!--                    This field is required-->
                            <!--                  </div>-->
                          </div>
                          <div class="col-md-3">
                            <base-input
                              v-model="vesselDetails.ataoa"
                              type="datetime-local"
                              label="Vessel ATAOA"
                              placeholder="Vessel ATAOA"
                              :value="vesselDetails.ataoa"
                              class="special-bottom"
                              :readonly="!hasSaveAction"
                            />
                            <!--                  <div-->
                            <!--                    class="error"-->
                            <!--                    v-if="!$v.vesselDetails.ata.required && isTested"-->
                            <!--                  >-->
                            <!--                    This field is required-->
                            <!--                  </div>-->
                          </div>
                          <div class="col-md-3">
                            <base-input
                              v-model="vesselDetails.atb"
                              type="datetime-local"
                              label="Vessel ATB"
                              placeholder="Vessel ATB"
                              :value="vesselDetails.atb"
                              class="special-bottom"
                              :readonly="!hasSaveAction"
                            />
                            <!--                  <div-->
                            <!--                    class="error"-->
                            <!--                    v-if="!$v.vesselDetails.ata.required && isTested"-->
                            <!--                  >-->
                            <!--                    This field is required-->
                            <!--                  </div>-->
                          </div>
                          <div class="col-md-3">
                            <base-input
                              v-model="vesselDetails.atc"
                              type="datetime-local"
                              label="Vessel ATC"
                              placeholder="Vessel ATC"
                              :value="vesselDetails.atc"
                              class="special-bottom"
                              :readonly="!hasSaveAction"
                            />
                            <!--                  <div-->
                            <!--                    class="error"-->
                            <!--                    v-if="!$v.vesselDetails.atd.required && isTested"-->
                            <!--                  >-->
                            <!--                    This field is required-->
                            <!--                  </div>-->
                          </div>
                        </div>

                        <!--  row 7 -->
                        <div class="row">
                          <div class="col-md-3">
                            <base-input
                              v-model="vesselDetails.atd"
                              type="datetime-local"
                              label="Vessel ATD"
                              placeholder="Vessel ATD"
                              :value="vesselDetails.atd"
                              class="special-bottom"
                              :readonly="!hasSaveAction"
                            />
                            <!--                  <div-->
                            <!--                    class="error"-->
                            <!--                    v-if="!$v.vesselDetails.atd.required && isTested"-->
                            <!--                  >-->
                            <!--                    This field is required-->
                            <!--                  </div>-->
                          </div>
                          <div class="col-md-3">
                            <base-input
                              v-model="vesselDetails.atdoa"
                              type="datetime-local"
                              label="Vessel ATDOA"
                              placeholder="Vessel ATDOA"
                              :value="vesselDetails.atdoa"
                              class="special-bottom"
                              :readonly="!hasSaveAction"
                            />
                            <!--                  <div-->
                            <!--                    class="error"-->
                            <!--                    v-if="!$v.vesselDetails.atd.required && isTested"-->
                            <!--                  >-->
                            <!--                    This field is required-->
                            <!--                  </div>-->
                          </div>
                          <div class="col-md-3">
                            <base-input
                              v-model="vesselDetails.pilotIn"
                              type="datetime-local"
                              label="Pilot In"
                              placeholder="Pilot In"
                              :value="vesselDetails.pilotIn"
                              class="special-bottom"
                              :readonly="!hasSaveAction"
                            />
                            <!--                  <div-->
                            <!--                    class="error"-->
                            <!--                    v-if="!$v.vesselDetails.atd.required && isTested"-->
                            <!--                  >-->
                            <!--                    This field is required-->
                            <!--                  </div>-->
                          </div>
                          <div class="col-md-3">
                            <base-input
                              v-model="vesselDetails.berth"
                              type="text"
                              label="Vessel Berth"
                              placeholder="Vessel Berth"
                              :value="vesselDetails.berth"
                              class="special-bottom"
                              oninput="let p=this.selectionStart;this.value=this.value.toUpperCase();this.setSelectionRange(p, p);"
                              :readonly="!hasSaveAction"
                            />
                            <!--                  <div-->
                            <!--                    class="error"-->
                            <!--                    v-if="!$v.vesselDetails.berth.required && isTested"-->
                            <!--                  >-->
                            <!--                    This field is required-->
                            <!--                  </div>-->
                          </div>
                        </div>

                        <!-- ballast & draft additional 1-->
                        <div class="row">
                          <div class="col-md-2">
                            <base-input
                              v-model="vesselDetails.berthNo"
                              type="number"
                              step="1"
                              label="Berth No."
                              placeholder="Berth No."
                              :value="vesselDetails.berthNo"
                              class="special-bottom"
                              :readonly="!hasSaveAction"
                            />
                            <!--                  <div-->
                            <!--                    class="error"-->
                            <!--                    v-if="!$v.vesselDetails.berth.required && isTested"-->
                            <!--                  >-->
                            <!--                    This field is required-->
                            <!--                  </div>-->
                          </div>
                          <div class="col-md-2" style="align-items: center;justify-items: center;display: grid;">
                            <div class="custom-control custom-checkbox custom-control-inline">
                              <input
                                id="sun"
                                v-model="vesselDetails.ballast"
                                type="checkbox"
                                class="custom-control-input"
                                :readonly="!hasSaveAction"
                              >
                              <label
                                class="custom-control-label"
                                for="sun"
                              >Ballast</label>
                            </div>
                          </div>
                          <div class="col-md-2">
                            <base-input
                              v-model="vesselDetails.draftAfter"
                              type="number"
                              step="0.0001"
                              label="Vessel Draft After "
                              placeholder="Vessel Draft After "
                              :value="vesselDetails.draftAfter"
                              class="special-bottom"
                              :readonly="!hasSaveAction"
                            />
                            <!--                              <div-->
                            <!--                                class="error"-->
                            <!--                                v-if="!$v.vesselDetails.draft.required && isTested"-->
                            <!--                              >-->
                            <!--                                This field is required-->
                            <!--                              </div>-->
                          </div>
                          <div class="col-md-2">
                            <base-input
                              v-model="vesselDetails.draftAfterUnit"
                              type="text"
                              label="Vessel Draft After Units"
                              placeholder="Vessel Draft After Units"
                              :value="vesselDetails.draftAfterUnit"
                              class="special-bottom"
                              :readonly="!hasSaveAction"
                            />
                            <!--                              <div-->
                            <!--                                class="error"-->
                            <!--                                v-if="!$v.vesselDetails.draft.required && isTested"-->
                            <!--                              >-->
                            <!--                                This field is required-->
                            <!--                              </div>-->
                          </div>
                          <div class="col-md-2">
                            <base-input
                              v-model="vesselDetails.draftFore"
                              type="number"
                              step="0.0001"
                              label="Vessel Draft Fore "
                              placeholder="Vessel Draft Fore "
                              :value="vesselDetails.draftFore"
                              class="special-bottom"
                              :readonly="!hasSaveAction"
                            />
                            <!--                              <div-->
                            <!--                                class="error"-->
                            <!--                                v-if="!$v.vesselDetails.draft.required && isTested"-->
                            <!--                              >-->
                            <!--                                This field is required-->
                            <!--                              </div>-->
                          </div>
                          <div class="col-md-2">
                            <base-input
                              v-model="vesselDetails.draftForeUnit"
                              type="text"
                              label="Vessel Draft Fore Units "
                              placeholder="Vessel Draft Fore Units "
                              :value="vesselDetails.draftForeUnit"
                              class="special-bottom"
                              :readonly="!hasSaveAction"
                            />
                            <!--                              <div-->
                            <!--                                class="error"-->
                            <!--                                v-if="!$v.vesselDetails.draft.required && isTested"-->
                            <!--                              >-->
                            <!--                                This field is required-->
                            <!--                              </div>-->
                          </div>
                        </div>

                        <!-- CN Quantity-->
                        <div class="row">
                          <div class="col-md-2">
                            <base-input
                              v-model="vesselDetails.cnQuantityLoaded"
                              type="number"
                              step="1"
                              label="Container Quantity Loaded"
                              placeholder="Container Quantity Loaded"
                              :value="vesselDetails.cnQuantityLoaded"
                              class="special-bottom"
                              :readonly="!hasSaveAction"
                            />
                          </div>
                          <div class="col-md-2">
                            <base-input
                              v-model="vesselDetails.cnWeightLoaded"
                              type="number"
                              step="1"
                              label="Container Weight Loaded"
                              placeholder="Container Weight Loaded"
                              :value="vesselDetails.cnWeightLoaded"
                              class="special-bottom"
                              :readonly="!hasSaveAction"
                            />
                          </div>
                          <div class="col-md-2">
                            <base-input
                              v-model="vesselDetails.cnQuantityAtDestination"
                              type="number"
                              step="1"
                              label="Container Quantity At Destination"
                              placeholder="Container Quantity At Destination"
                              :value="vesselDetails.cnQuantityAtDestination"
                              class="special-bottom"
                              :readonly="!hasSaveAction"
                            />
                          </div>
                          <div class="col-md-2">
                            <base-input
                              v-model="vesselDetails.cnWeightAtDestination"
                              type="number"
                              step="1"
                              label="Container Weight At Destination"
                              placeholder="Container Weight At Destination"
                              :value="vesselDetails.cnWeightAtDestination"
                              class="special-bottom"
                              :readonly="!hasSaveAction"
                            />
                          </div>
                          <div class="col-md-2">
                            <base-input
                              v-model="vesselDetails.cnQuantityAtDischarge"
                              type="number"
                              step="1"
                              label="Container Quantity At Discharge"
                              placeholder="Container Quantity At Discharge"
                              :value="vesselDetails.cnQuantityAtDischarge"
                              class="special-bottom"
                              :readonly="!hasSaveAction"
                            />
                          </div>
                          <div class="col-md-2">
                            <base-input
                              v-model="vesselDetails.cnWeightAtDischarge"
                              type="number"
                              step="1"
                              label="Carton Weight At Discharge"
                              placeholder="Carton Weight At Discharge"
                              :value="vesselDetails.cnWeightAtDischarge"
                              class="special-bottom"
                              :readonly="!hasSaveAction"
                            />
                          </div>
                        </div>

                        <!-- CAR Quantity  -->
                        <div class="row">
                          <div class="col-md-2">
                            <base-input
                              v-model="vesselDetails.carQuantityLoaded"
                              type="number"
                              step="1"
                              label="Car Quantity Loaded"
                              placeholder="Car Quantity Loaded"
                              :value="vesselDetails.carQuantityLoaded"
                              class="special-bottom"
                              :readonly="!hasSaveAction"
                            />
                          </div>
                          <div class="col-md-2">
                            <base-input
                              v-model="vesselDetails.carWeightLoaded"
                              type="number"
                              step="1"
                              label="Car Weight Loaded"
                              placeholder="Car Weight Loaded"
                              :value="vesselDetails.carWeightLoaded"
                              class="special-bottom"
                              :readonly="!hasSaveAction"
                            />
                          </div>
                          <div class="col-md-2">
                            <base-input
                              v-model="vesselDetails.carQuantityAtDestination"
                              type="number"
                              step="1"
                              label="Car Quantity At Destination"
                              placeholder="Car Quantity At Destination"
                              :value="vesselDetails.carQuantityAtDestination"
                              class="special-bottom"
                              :readonly="!hasSaveAction"
                            />
                          </div>
                          <div class="col-md-2">
                            <base-input
                              v-model="vesselDetails.carWeightAtDestination"
                              type="number"
                              step="1"
                              label="Car Weight At Destination"
                              placeholder="Car Weight At Destination"
                              :value="vesselDetails.carWeightAtDestination"
                              class="special-bottom"
                              :readonly="!hasSaveAction"
                            />
                          </div>
                          <div class="col-md-2">
                            <base-input
                              v-model="vesselDetails.carQuantityAtDischarge"
                              type="number"
                              step="1"
                              label="Car Quantity At Discharge"
                              placeholder="Car Quantity At Discharge"
                              :value="vesselDetails.carQuantityAtDischarge"
                              class="special-bottom"
                              :readonly="!hasSaveAction"
                            />
                          </div>
                          <div class="col-md-2">
                            <base-input
                              v-model="vesselDetails.carWeightAtDischarge"
                              type="number"
                              step="1"
                              label="Car Weight At Discharge"
                              placeholder="Car Weight At Discharge"
                              :value="vesselDetails.carWeightAtDischarge"
                              class="special-bottom"
                              :readonly="!hasSaveAction"
                            />
                          </div>
                        </div>

                        <!-- BK Quantity  -->
                        <div class="row">
                          <div class="col-md-2">
                            <base-input
                              v-model="vesselDetails.bkQuantityLoaded"
                              type="number"
                              step="1"
                              label="Bulk Quantity Loaded"
                              placeholder="Bulk Quantity Loaded"
                              :value="vesselDetails.bkQuantityLoaded"
                              class="special-bottom"
                              :readonly="!hasSaveAction"
                            />
                          </div>
                          <div class="col-md-2">
                            <base-input
                              v-model="vesselDetails.bkWeightLoaded"
                              type="number"
                              step="1"
                              label="Bulk Weight Loaded"
                              placeholder="Bulk Weight Loaded"
                              :value="vesselDetails.bkWeightLoaded"
                              class="special-bottom"
                              :readonly="!hasSaveAction"
                            />
                          </div>
                          <div class="col-md-2">
                            <base-input
                              v-model="vesselDetails.bkQuantityAtDestination"
                              type="number"
                              step="1"
                              label="Bulk Quantity At Destination"
                              placeholder="Bulk Quantity At Destination"
                              :value="vesselDetails.bkQuantityAtDestination"
                              class="special-bottom"
                              :readonly="!hasSaveAction"
                            />
                          </div>
                          <div class="col-md-2">
                            <base-input
                              v-model="vesselDetails.bkWeightAtDestination"
                              type="number"
                              step="1"
                              label="Bulk Weight At Destination"
                              placeholder="Bulk Weight At Destination"
                              :value="vesselDetails.bkWeightAtDestination"
                              class="special-bottom"
                              :readonly="!hasSaveAction"
                            />
                          </div>
                          <div class="col-md-2">
                            <base-input
                              v-model="vesselDetails.bkQuantityAtDischarge"
                              type="number"
                              step="1"
                              label="Bulk Quantity At Discharge"
                              placeholder="Bulk Quantity At Discharge"
                              :value="vesselDetails.bkQuantityAtDischarge"
                              class="special-bottom"
                              :readonly="!hasSaveAction"
                            />
                          </div>
                          <div class="col-md-2">
                            <base-input
                              v-model="vesselDetails.bkWeightAtDischarge"
                              type="number"
                              step="1"
                              label="Bulk Weight At Discharge"
                              placeholder="Bulk Weight At Discharge"
                              :value="vesselDetails.bkWeightAtDischarge"
                              class="special-bottom"
                              :readonly="!hasSaveAction"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="text-center row">
                      <div class="col-md-7 text-right loader-div">
                        <span v-if="editMyLoader">Please wait ....</span>
                      </div>
                      <div class="col-md-2 left loader-div-gif">
                        <pulse-loader
                          v-if="editMyLoader"
                          :loading="loading"
                          :color="color"
                          :size="size"
                        />
                      </div>
                      <div
                        class="col-md-3"
                      >
                        <button
                          type="button"
                          class="btn btn-fill btn-outline-info float-right "
                          style="margin-inline-start:20px"
                          @click="closeModal"
                        >
                          Close
                        </button>

                        <button
                          v-if="hasSaveAction"
                          type="submit"
                          class="btn btn-primary btn-space  float-right"
                          data-dismiss="modal"
                          style="width: 200px"
                          @click.prevent="editSaveFormDetails"
                        >
                          Update Vessel Details
                        </button>
                      </div>
                    </div>
                  </form>
                </card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { ModelListSelect } from 'vue-search-select'
import {mapActions} from "vuex";

export default {
  name: 'VesselDetails',
  components: {
    ModelListSelect
  },
  props: {
    viewAllDetails: {
      type: Boolean,
      default: () => false
    },
    vesselData: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      hasSaveAction: false,
      showStart: false,
      isTested: false,
      SuccessAlert: false,
      ErrorAlert: false,
      editMyLoader: false,
      color: 'green',
      size: '12px',
      loading: true,
      vesselTypeEnum: [
        { vesselType: 'Dry Bulk' },
        { vesselType: 'Pure car Carrier' },
        { vesselType: 'Tanker' },
        { vesselType: 'Military' },
        { vesselType: 'Cruise' },
        { vesselType: 'Exhibition' },
        { vesselType: 'Other' }
      ],
      contractTypeEnum: [
        { contractType: 'Charter' },
        { contractType: 'Liner' },
        { contractType: 'Other' }
      ],
      // vesselDetails:{}
    }
  },
  // created() {
  //     this.vesselDetails = JSON.parse(JSON.stringify(this.vesselData))
  // },
  computed: {
    getExpectedStayTime() {
      if (this.vesselDetails.etb && this.vesselDetails.etd) {
        const etaCalc = new Date(this.vesselDetails.etb)
        const etdCalc = new Date(this.vesselDetails.etd)

        const expectedStayTimeMS = Math.abs(etdCalc.getTime() - etaCalc.getTime())
        // convert milliseconds to hrs then round to integer
        const expectedStayTimeHrs = Math.ceil(expectedStayTimeMS / (1000 * 60 * 60))

        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.vesselDetails.stayTime = expectedStayTimeHrs
        return expectedStayTimeHrs
      } else {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.vesselDetails.stayTime = 0
        return 0
      }
    },

    vesselDetails() {
      return JSON.parse(JSON.stringify(this.vesselData))
    }
  },

  validations: {
    vesselDetails: {
      arrivalPort: { required },
      carrierCode: { required },
      contractType: { required },
      draft: { required },
      dwt: { required },
      eta: { required },
      etb: { required }, // new field from UAT 3.
      etd: { required },
      factor: { required },
      grt: { required },
      loa: { required },
      nrt: { required },
      principalParticularId: { required },
      stayTime: { required },
      operation: { required },
      vesselName: { required },
      vesselType: { required },
      voyageNo: { required }
    }
  },

  methods: {
    ...mapActions('vesselParticulars', [
      "updateVesselParticulars",
    ]),
    cleanValues() {
      this.vesselDetails.stayTime = parseInt(this.vesselDetails.stayTime, 10)
      this.vesselDetails.principalParticularId = parseInt(this.vesselDetails.principalParticularId, 10)
      this.vesselDetails.factor = parseFloat(this.vesselDetails?.factor)
      this.vesselDetails.grt	= parseFloat(this.vesselDetails?.grt)
      this.vesselDetails.loa	= parseFloat(this.vesselDetails?.loa)
      this.vesselDetails.nrt	= parseFloat(this.vesselDetails?.nrt)
      this.vesselDetails.beam = parseFloat(this.vesselDetails?.beam)
      this.vesselDetails.draft = parseFloat(this.vesselDetails?.draft)
      this.vesselDetails.dwt = parseFloat(this.vesselDetails?.dwt)
    },

    editSaveFormDetails() {
      this.$v.vesselDetails.$touch()
      this.isTested = true

      this.$v.vesselDetails.$touch()
      if (!this.$v.vesselDetails.$invalid) {
        this.cleanValues()
        this.saveDetails = this.vesselDetails

        this.editPrincipalItems()
        // this.vesselDetails = {}
        this.saveDetails = {}
        this.closeModal()
      }
    },

    async editPrincipalItems() {
      await this.$store.dispatch('vesselParticulars/savingDetails', this.vesselDetails)
      await this.updateVesselParticulars({}).then(() => {
        this.ErrorAlert = this.getError
        if (this.ErrorAlert) {
          this.notifyUser('error', 'An Error Occurred', 'Sorry, something went wrong')
          this.ErrorAlert = false
        }
        this.SuccessAlert = this.getSuccess
        if (this.SuccessAlert) {
          this.notifyUser('success', 'Vessel Edited Successfully!', `Marine vessel ${this.vesselDetails.vesselName} has been edited successfully.`)
          this.SuccessAlert = false
        }
        this.requestRefresh()
      }).catch(() => {
        this.ErrorAlert = this.getError
        if (this.ErrorAlert) {
          this.notifyUser('error', 'An Error Occurred', 'Sorry, something went wrong')
          this.ErrorAlert = false
        }
      })
    },

    requestRefresh() {
      return this.$emit('requestRefresh', true)
    },

    closeModal() {
      return this.$emit('closeModal', true)
    }
  }
}
</script>

<style scoped>
ul.nav-justified {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  /*background-color: #ededee;*/
  border-radius: 3px;
  flex-grow: 1;
  display: flex !important;
  justify-content: space-around;
}

ul.nav-justified li {
  float: left;
  border-top: 1px solid #DCDCDC;
  border-left: 1px solid #DCDCDC;
  border-right: 1px solid #DCDCDC;
  border-bottom: 1px solid #DCDCDC;
  /*margin-right: 20px;*/
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}

ul.nav-justified li a {
  display: block;
  text-align: center;
  padding: 8px 15px 8px 15px !important;
  width: calc((100vw - 25vw) / 2);
  text-decoration: none;
  color: #000000;
  font-weight: bold;
  text-transform: uppercase;
}

ul.nav-justified li :hover {
  color: #d59a18;
  padding: 16px 20px 10px 21px;
  background-color: #ffffff;
}
.is-active-main-details, .is-active-main-details :hover {
  color: #ffffff !important;
  padding: 0 0 2px 0;
  background-color: #3498db !important;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}

.is-active-additional-details, .is-active-additional-details :hover {
  color: #ffffff !important;
  padding: 0 0 2px 0;
  background-color: green !important;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}
.modal-mask {
  position: fixed;
  z-index: 4910;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .8);
  display: table;
  transition: opacity .3s ease;
  overflow-y: auto;
}
.medium-modal-dialog {
  /*width: calc(80vw);*/
  /*min-width: calc(60vw);*/
  max-width: calc(90vw) !important;
}
.legend {
  position: relative;
  border: 1px solid #DCDCDC;
  padding: 1.5rem 1rem 1rem 1rem;
  margin-bottom: 1rem;
  border-radius: 3px;
  margin-top: 1rem;
}

.legend-title {
  background-color: inherit;
  position: absolute;
  top: -0.6rem;
  padding: 0.2rem 1rem;
  background-color: white;
  color: #d59a18;
}

.btn-space {
  margin-right: 10px !important;
}

.custom-btn {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
  padding: 5px 9px 5px 9px !important;
  font-size: 14px !important;
  width: 120px;
  background-color: #FFFFFF !important;
}

.error{
  color:#dc3545;
  margin-left: 2px;
  margin-top: 2px;
}
.loader-div {
  line-height: 40px;
}

.loader-div-gif {
  line-height: 50px;
}
.div-table-row {
  padding: 0 15px 0 15px;
}

.div-table {
  padding: 9px 8px 9px 8px;
}

.div-table-title {
  width: 250px;
  background-color: rgba(153, 186, 221, .5);
  font-weight: normal;
}

.div-table-value {
  border: 1px solid #DCDCDC;
  font-weight: 900;
}

label {
  position: relative;
  top: -7px;
  right: 0;
  margin: 0;
  padding: 0;
}

.special-bottom {
  margin-bottom: -1px;
}
input.hidden-file {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  padding: 0;
  cursor: pointer;
  z-index: -1;
  opacity: 0;
  filter: alpha(opacity=0);
}
input.hidden-file:focus + label {
  outline: 2px solid;  /* example focus style */
}
.custom-label{
  text-transform: none;
  top: 0px;
  font-size: 1em;
  color: #888888;
  cursor: pointer;
}
.radio-labels {
  top: -1px !important;
  padding-left: 10px !important;
}
</style>
