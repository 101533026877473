<template>
  <div>
    <card>
      <data-table
        :table-headers="tableHeaders"
        :table-data="requests"
        :table-data-keys="tableDataKeys"
        :my-first-loader="myFirstLoader"
        :page-size-prop="pageSize"
        :page-no="pageNo"
        :is-pageable="false"
        :total-pages="totalPages"
        :page-number="pageNumber"
        :date-prop="'Created At '"
        :has-delete-button="false"
        :has-view-button="false"
        :has-edit-button="false"
        :has-manage-button="true"
        @goToApplication="goToApplication"
        :loading-title="'Please wait while system is loading your assignment list'"
      />
    </card>

    <global-modal v-if="myLoader">
      <div slot="globalLoader">
        <div class="row text-center">
          <div class="col-md-12">
            Loading, Please wait
          </div>
          <div class="col-md-12">
            <br>
          </div>
          <div class="col-md-12">
            <pulse-loader
              :loading="loading"
              :color="color"
              :size="size"
            />
          </div>
        </div>
      </div>
    </global-modal>
  </div>
</template>

<script>
import PulseLoader from 'vue-spinner/src/PulseLoader';
import GlobalModal from '../../../components/globalLoader';
import DataTable from '../../../components/DataTable/updatedTable';
import { mapActions, mapGetters } from 'vuex';
export default {
  name: 'MyAssignments',
  components: {
    PulseLoader,
    GlobalModal,
    DataTable
  },
  created () {
    this.$store.dispatch('pagenames/currentPageName', 'My Assignments');

    this.finalUserPermissions = JSON.parse(localStorage.getItem('browser-local-permits'));
    this.getMyAssignmentsOnCreated();
  },
  data () {
    return {
      pageNo: 0,
      pageSize: 15,
      totalElements: '',
      totalPages: '',
      isFirst: false,
      isLast: false,
      pageNumber: '',
      isPageable: true,
      requests: [],
      myLoader: false,
      myFirstLoader: false,
      reloadRequests: false,
      tableHeaders: ['Activity Name', 'Description', 'Shipping Order No.', 'Created At', 'Completed'],
      tableDataKeys: ['activityName', 'descrition', 'shippingOrderNo', 'doc', 'completion']
    };
  },
  computed: {
    ...mapGetters('shippingOrder', [
      'getRequestList',
      'getNoDataFound',
      'getSuccessAlert',
      'getErrorMessage',
      'getErrorAlert',
      'getMyFirstLoader'
    ])
  },
  methods: {
    ...mapActions('shippingOrder', [
      'getMyShippingOrderActivityAssignments'
    ]),
    getMyAssignmentsOnCreated () {
      this.getMyShippingOrderActivityAssignments({}).then(() => {
        this.requests = this.formatAssignmentData(this.getRequestList);
        this.myFirstLoader = this.getMyFirstLoader;
        this.noDataFound = this.getNoDataFound;
      }).catch(() => {});
    },
    formatAssignmentData (data) {
      if (data) {
        for (let i = 0; i < data.length; i++) {
          data[i].doc = ` ${data[i].createdAt.split('T')[0]}  ${data[i].createdAt.split('T')[1].split('.')[0]}`;
          data[i].dou = `${data[i].lastUpdatedAt.split('T')[0]} ${data[i].lastUpdatedAt.split('T')[1].split('.')[0]}`;
          data[i].activityName = data[i].activity.name;
          data[i].shippingOrderNo = data[i].shippingOrder.shippingOrderNo ? data[i].shippingOrder.shippingOrderNo : 'Not Generated';
          data[i].completion = data[i].completed ? 'YES' : 'NO';
        }
        return data;
      } else {
        this.noDataFound = true;
        return [];
      }
    },
    goToApplication (activityDetails) {
      document.cookie = 'browser-local-cashed-url-back=' + this.$route.fullPath + ';Path = /;SameSite = Strict';
      this.$router.push('/shipping-agency/booking/view/shippingorder/cargo-details/activity-progress/' + activityDetails.id);
    }
  }
};
</script>

<style scoped>

</style>
