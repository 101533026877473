<template>
  <div>
    <card>
      <div>
        <div
          class="row"
          v-if="!noDataFound"
        >
        </div>
        <div class="legend">
          <h6 class="legend-title">
            Manifests
          </h6>
          <table class="table table-bordered">
            <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Manifest Sender</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(manifest,index) in manifestDetails" :key="index">
              <td scope="row">{{index + 1}}</td>
              <td>{{manifest.sender}}</td>
              <td><button class="btn btn-default custom-btn" @click="viewBlist(manifest)">View</button></td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="row">
          <div class="col-md-12">
            <button
              class="btn btn-default btn-fill btn-space"
              style="float:right;"
              @click.prevent="goBack"
            >
              <font-awesome-icon icon="undo" /> &nbsp; Back
            </button>
          </div>
        </div>
      </div>
    </card>
    <global-modal v-if="myLoader">
      <div slot="globalLoader">
        <div class="row text-center">
          <div class="col-md-12">
            Loading, Please wait
          </div>
          <div class="col-md-12">
            <br>
          </div>
          <div class="col-md-12">
            <pulse-loader
              :loading="loading"
              :color="color"
              :size="size"
            />
          </div>
        </div>
      </div>
    </global-modal>
    <global-modal v-if="noManifest">
      <div slot="globalLoader">
        <div class="row text-center">
          <div class="col-md-12">
            No Manifest Found
          </div>
          <div class="col-md-12">
            <br>
          </div>
          <div class="col-md-12">
            <pulse-loader
              :loading="loading"
              :color="color"
              :size="size"
            />
          </div>
        </div>
      </div>
    </global-modal>
    <transition
      name="addItemModal col-md-12"
      v-if="showModalDoRequest"
    >
      <div>
        <div class="modal modal-mask">
          <div class="modal-dialog medium-modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
              </div>
              <div class="modal-title col-md-12">
              </div>
              <div class="modal-body col-md-12">
                <div class="form-row">
                  <div class="form-group col-md-12">
                    <label for="agent">Agent</label>
                    <input type="text" class="form-control" id="agent" disabled>
                  </div>
                  <div class="form-group col-md-6">
                    <label for="inputBL">BL Of Lading Number</label>
                    <input type="text" class="form-control" id="inputBL" :value="doCustomerRequests[0].blNumber" disabled>
                  </div>
                  <div class="form-group col-md-6">
                    <label for="type">Delivery Type</label>
                    <input type="password" class="form-control" id="type" :value="doCustomerRequests[0].cargoDeliveryType" disabled>
                  </div>
                </div>
                <div class="form-group">
                  <label for="inputAddress">Consignee Name</label>
                  <input type="text" class="form-control" id="inputAddress" :value="doCustomerRequests[0].consigneeName" disabled>
                </div>
                <div class="form-row">
                  <div class="form-group col-md-6">
                    <label for="email">Consignee Email</label>
                    <input type="text" class="form-control" id="email" :value="doCustomerRequests[0].emailAddress" disabled>
                  </div>
                  <div class="form-group col-md-4">
                    <label for="phone">Consignee Phone Number</label>
                    <input type="text" class="form-control" id="phone" :value="doCustomerRequests[0].mobileNumber" disabled>
                  </div>
                  <div class="form-group col-md-2">
                    <label for="destination">Destination</label>
                    <input type="text" class="form-control" id="destination" :value="doCustomerRequests[0].destination" disabled>
                  </div>
                </div>
                <div class="form-group">
                  <label for="description">Description</label>
                  <textarea class="form-control" id="description" placeholder="Required example textarea" :value="doCustomerRequests[0].description" disabled></textarea>
                </div>
                <div class="text-center row">
                  <div class="col-md-7 text-right loader-div">
                    <span v-if="editMyLoader">Please wait ....</span>
                  </div>
                  <div class="col-md-2 left loader-div-gif">
                    <pulse-loader
                      :loading="loading"
                      :color="color"
                      :size="size"
                      v-if="editMyLoader"
                    />
                  </div>
                  <div class="col-md-3">
                    <button
                      type="button"
                      class="btn btn-fill btn-outline-info float-right"
                      style="margin-inline-start:20px"
                      @click="closeModal()"
                    >
                      Close
                    </button>
                    <button
                      type="button"
                      class="btn btn-success btn-outline-info float-right"
                      style="margin-inline-start:20px"
                      @click="addDeliveryOrder()"
                    >
                      Save
                    </button>
                  </div>
                </div>

              </div>

            </div>
          </div>
        </div>
      </div>

    </transition>

    <transition
      name="addItemModal col-md-12"
      v-if="showModal"
    >
      <div>
        <div
          class="modal  modal-mask"
          style="display: block"
        >
          <div
            class="modal-dialog medium-modal-dialog"
            role="document"
          >
            <div class="modal-content col-md-12">
              <div class="modal-header col-md-12">
                <div class="modal-title col-md-12">
                  <div class="row">
                    <div
                      class="col-md-12"
                      v-if="ViewModalDiv"
                    >
                      <b> Bill of Lading Details</b>
                    </div>
                    <div
                      class="col-md-12"
                      v-if="AddModalDiv"
                    >
                      <b> Add Delivery Order</b>
                    </div>
                    <div class="col-md-12">
                      <hr>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="modal-body col-md-12">
                  <!--  VIEW BILL OF LADING DETAILS  -->
                  <card v-if="ViewModalDiv">
                    <div class="row">
                      <div class="col-md-3">
                        <base-input
                          type="text"
                          label="Bill of Lading"
                          placeholder="Bill of Lading"
                          v-model="blDetails.billOfLading"
                          :value="blDetails.billOfLading"
                          class="special-bottom"
                          :readonly="true"
                        />
                      </div>
                      <div class="col-md-2">
                        <base-input
                          type="text"
                          label="Container No."
                          placeholder="Container No."
                          v-model="blDetails.containerNo"
                          :value="blDetails.containerNo"
                          class="special-bottom"
                          :readonly="true"
                        />
                      </div>
                      <div class="col-md-2">
                        <base-input
                          type="text"
                          label="Seal Number"
                          placeholder="Seal Number"
                          v-model="blDetails.sealNumber"
                          :value="blDetails.sealNumber"
                          class="special-bottom"
                          :readonly="true"
                        />
                      </div>
                      <div class="col-md-3">
                        <base-input
                          type="text"
                          label="Document Message No."
                          placeholder="Document Message No."
                          v-model="blDetails.documentMessageNo"
                          :value="blDetails.documentMessageNo"
                          class="special-bottom"
                          :readonly="true"
                        />
                      </div>
                      <div class="col-md-2">
                        <base-input
                          type="text"
                          label="Load Port"
                          placeholder="Load Port"
                          v-model="blDetails.loadPort"
                          :value="blDetails.loadPort"
                          class="special-bottom"
                          :readonly="true"
                        />
                      </div>
                    </div>
                    <!--   row 2   -->
                    <div class="row">
                      <div class="col-md-3">
                        <base-input
                          type="text"
                          label="Package Type"
                          placeholder="Package Type"
                          v-model="blDetails.pktyDetails"
                          :value="blDetails.pktyDetails"
                          class="special-bottom"
                          :readonly="true"
                        />
                      </div>
                      <div class="col-md-3">
                        <base-input
                          type="text"
                          label="Company Code"
                          placeholder="Company Code"
                          v-model="blDetails.companyCode"
                          :value="blDetails.companyCode"
                          class="special-bottom"
                          :readonly="true"
                        />
                      </div>
                      <div class="col-md-2">
                        <base-input
                          type="text"
                          label="Arrival Port"
                          placeholder="Arrival Port"
                          v-model="blDetails.arrivalPort"
                          :value="blDetails.arrivalPort"
                          class="special-bottom"
                          :readonly="true"
                        />
                      </div>
                      <div class="col-md-2">
                        <base-input
                          type="text"
                          label="Discharge Port"
                          placeholder="Discharge Port"
                          v-model="blDetails.dischargePort"
                          :value="blDetails.dischargePort"
                          class="special-bottom"
                          :readonly="true"
                        />
                      </div>

                      <div class="col-md-2">
                        <base-input
                          type="text"
                          label="Consolidation Item No."
                          placeholder="Consolidation Item No."
                          v-model="blDetails.consolidationItemNumber"
                          :value="blDetails.consolidationItemNumber"
                          class="special-bottom"
                          :readonly="true"
                        />
                      </div>
                    </div>
                    <!--   row 3   -->
                    <div class="row">
                      <div class="col-md-2">
                        <base-input
                          type="text"
                          label="Number of Packages"
                          placeholder="Number of Packages"
                          v-model="blDetails.numberOfPackage"
                          :value="blDetails.numberOfPackage"
                          class="special-bottom"
                          :readonly="true"
                        />
                      </div>
                      <div class="col-md-2">
                        <base-input
                          type="text"
                          label="Gross Weight"
                          placeholder="Gross Weight"
                          :value="blDetails.grossWeight + ' ' + blDetails.grossWeightUnit"
                          class="special-bottom"
                          :readonly="true"
                        />
                      </div>
                      <div class="col-md-2">
                        <base-input
                          type="text"
                          label="Tare Weight"
                          placeholder="Tare Weight"
                          :value="blDetails.tareWeight + ' ' + blDetails.tareWeightUnit"
                          class="special-bottom"
                          :readonly="true"
                        />
                      </div>
                      <div class="col-md-2">
                        <base-input
                          type="text"
                          label="Net Weight"
                          placeholder="Net Weight"
                          :value="blDetails.netWeight + ' ' + blDetails.netWeightUnit"
                          class="special-bottom"
                          :readonly="true"
                        />
                      </div>
                      <div class="col-md-2">
                        <base-input
                          type="text"
                          label="Net Volume"
                          placeholder="Net Volume"
                          :value="blDetails.netVolume + ' ' + blDetails.netVolumeUnit"
                          class="special-bottom"
                          :readonly="true"
                        />
                      </div>
                      <div class="col-md-2">
                        <base-input
                          type="text"
                          label="Temperature"
                          placeholder="Temperature"
                          :value="blDetails.temperature"
                          class="special-bottom"
                          :readonly="true"
                        />
                      </div>
                    </div>
                    <!--  row 4     -->
                    <div class="row">
                      <div class="col-md-3">
                        <base-input
                          type="text"
                          label="HS Code"
                          placeholder="HS Code"
                          v-model="blDetails.hsCode"
                          :value="blDetails.hsCode"
                          class="special-bottom"
                          :readonly="true"
                        />
                      </div>
                      <div class="col-md-9">
                        <base-input
                          type="text"
                          label="Marks Numbers"
                          placeholder="Marks Numbers"
                          v-model="blDetails.marksNumbers"
                          :value="blDetails.marksNumbers"
                          class="special-bottom"
                          :readonly="true"
                        />
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <base-input
                          type="text"
                          label="Shipper"
                          placeholder="Shipper"
                          v-model="blDetails.shipper"
                          :value="blDetails.shipper"
                          class="special-bottom"
                          :readonly="true"
                        />
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-12">
                        <base-input
                          type="text"
                          label="Consignee"
                          placeholder="Consignee"
                          v-model="blDetails.consignee"
                          :value="blDetails.consignee"
                          class="special-bottom"
                          :readonly="true"
                        />
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <base-input
                          type="text"
                          label="Description"
                          placeholder="Description"
                          v-model="blDetails.description"
                          :value="blDetails.description"
                          class="special-bottom"
                          :readonly="true"
                        />
                      </div>
                    </div>

                    <br>
                    <div class="text-center row">
                      <div class="col-md-7 text-right loader-div">
                        <!--                      <span v-if="editMyLoader">Please wait ....</span>-->
                      </div>
                      <div class="col-md-2 left loader-div-gif">
                        <!--                      <pulse-loader :loading="loading" :color="color" :size="size" v-if="editMyLoader"></pulse-loader>-->
                      </div>
                      <div class="col-md-3">
                        <button
                          type="button"
                          class="btn btn-fill btn-outline-info float-right"
                          style="margin-inline-start:20px"
                          @click="closeModal()"
                        >
                          Close
                        </button>
                      </div>
                    </div>
                  </card>

                  <!--  ADD DELIVERY ORDER   -->
                  <card v-if="AddModalDiv">
                    <form @submit.prevent="addDeliveryOrder" />
                    <div class="row">
                      <div class="col-md-6">
                        <form
                          @submit.prevent="searchAgent"
                          v-if="hasSearchAgent"
                        >
                          <div class="row">
                            <div class="col-md-10">
                              <base-input
                                type="text"
                                label="Search CF Agent"
                                placeholder="Agent Name"
                                v-model="searchAgentParam"
                                class="special-bottom"
                              />
                            </div>
                            <div
                              class="col-md-2"
                              title="Search Agent"
                              style="padding-top: 43px"
                            >
                              <button
                                type="button"
                                class="input-group-addon"
                                style="float: left"
                                @click.prevent="searchAgent"
                              >
                                <font-awesome-icon icon="search" />
                              </button>
                            </div>
                          </div>
                        </form>

                        <div v-if="!hasSearchAgent">
                          <div class="row">
                            <div
                              class="col-md-10"
                              style="margin-top: 10px"
                            >
                              <label>Select Agent </label>
                              <model-list-select
                                :list="agentOptions"
                                v-model="deliveryOrderDetails.agentId"
                                class="form-control special-bottom"
                                option-value="cfAgentId"
                                option-text="agentName"
                                placeholder="Select Agent"
                              />
                            </div>
                            <div
                              class="col-md-2"
                              style="padding-top: 43px"
                            >
                              <button
                                type="button"
                                title="Reload Search"
                                class="input-group-addon"
                                style="float: left"
                                @click.prevent="reloadSearchAgent"
                              >
                                <font-awesome-icon icon="sync-alt" />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        class="col-md-6"
                        style="padding-top: 10px"
                      >
                        <label>Bill of Lading  </label>
                        <model-list-select
                          :list="ogManifestBl"
                          v-model="deliveryOrderDetails.blNo"
                          class="form-control special-bottom"
                          option-value="billOfLading"
                          option-text="billOfLading"
                          placeholder="Select Bill of Lading"
                        />
                      </div>
                    </div>

                    <br>
                    <div class="row">
                      <div class="col-md-12">
                        <base-input
                          type="text"
                          label="Delivery Order Destination"
                          placeholder="Delivery Order Destination"
                          v-model="deliveryOrderDetails.deliveryPoint"
                          class="special-bottom"
                          :is-text-area="true"
                        />
                      </div>
                    </div>
                    <br>
                    <div class="text-center row">
                      <div class="col-md-7 text-right loader-div">
                        <span v-if="editMyLoader">Please wait ....</span>
                      </div>
                      <div class="col-md-2 left loader-div-gif">
                        <pulse-loader
                          :loading="loading"
                          :color="color"
                          :size="size"
                          v-if="editMyLoader"
                        />
                      </div>
                      <div class="col-md-3">
                        <button
                          type="button"
                          class="btn btn-fill btn-outline-info float-right"
                          style="margin-inline-start:20px"
                          @click="closeModal()"
                        >
                          Close
                        </button>
                        <button
                          type="button"
                          class="btn btn-success btn-outline-info float-right"
                          style="margin-inline-start:20px"
                          @click="addDeliveryOrder()"
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </card>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import DataTable from '../../../components/DataTable/updatedTable'
import GlobalModal from '../../../components/globalLoader'
import { ModelListSelect } from 'vue-search-select'
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import AGENCYAPI from "../../../api/agency";

export default {
  name: 'ManifestDetails',
  components: {
    DataTable,
    GlobalModal,
    ModelListSelect,
    PulseLoader
  },
  props: {
    voyageNo: {
      type: String,
      default: ''
    },
    callId: {
      type: String,
      default: ''
    },
    documentId: {
      type: String,
      default: ''
    },
    requestFlag: {
      type: String,
      default: 'ROUTE'
    }

  },
  created () {
    this.$store.dispatch('pagenames/currentPageName', 'Manifest Details');

    this.finalUserPermissions = JSON.parse(localStorage.getItem('browser-local-permits'));

    if (this.voyageNo !== null && this.voyageNo !== '' && this.voyageNo !== undefined) {
      this.voyageId = this.voyageNo
    } else {
      this.voyageId = this.$route.params.voyageNo;
    }
    this.loadManifestDetailsOnCreated()
  },
  data () {
    return {
      isPageable: true,
      doCustomerRequests:[{}],
      deliveryOrderDetails: {
        deliveryPoint: '',
        agentId: '',
        blNo: ''
      },

      SuccessMessage: '',
      ErrorMessage: '',
      SuccessAlert: false,
      ErrorAlert: false,

      noManifest: false,
      showModalDoRequest:false,

      voyageId: '',
      totalPages: 1,
      pageNo: 0,
      pageSize: 15,
      pageCount: 1,
      pageNumber: 0,
      myFirstLoader: false,
      totalElements: 0,
      isFirst: true,
      isLast: false,
      noDataFound: false,
      reloadAllRequest: false,
      color: 'green',
      size: '12px',
      loading: true,
      myLoader: false,
      editMyLoader: false,
      showModal: false,
      ViewModalDiv: false,
      AddModalDiv: false,
      searchAgentParam: '',
      modal_title: '',

      hasSearchAgent: true,
      agentOptions: [],
      blDetails: {
        agent: '',
        arrivalPort: '',
        billOfLading: '',
        blType: '',
        cancelled: '',
        companyCode: '',
        consignee: '',
        consolidationItemNumber: '',
        containerNo: '',
        createDateTime: '',
        deliveryPort: '',
        description: '',
        destinationPlace: '',
        dimensions: '',
        dischargePort: '',
        documentId: '',
        documentMessageNo: '',
        fobPrice: '',
        fobPriceUnit: '',
        grossVolume: '',
        grossVolumeUnit: '',
        grossWeight: '',
        grossWeightUnit: '',
        hsCode: '',
        insurancePrice: '',
        insurancePriceUnit: '',
        internationalFreightPrice: '',
        internationalFreightPriceUnit: '',
        isCancelled: '',
        isSpecified: '',
        loadPort: '',
        manifestBlId: '',
        marksNumbers: '',
        masterBillOfLading: '',
        measurement: '',
        netVolume: '',
        netVolumeUnit: '',
        netWeight: '',
        netWeightUnit: '',
        notifyName: '',
        notifyName1: '',
        notifyName2: '',
        numberOfPackage: '',
        operationType: '',
        originalLoadPort: '',
        packageType: '',
        sealNumber: '',
        shipper: '',
        specified: '',
        tareWeight: '',
        tareWeightUnit: '',
        temperature: '',
        updateDateTime: ''
      },
      manifestDetails: {},
      manifestBl: [],
      ogManifestBl: [],
      finalUserPermissions: [],
      tableHeaders: ['Bill of Lading', 'Container No.', 'Seal No.', 'Load Port', 'Package Type'],
      tableDataKeys: ['billOfLading', 'containerNo', 'sealNumber', 'loadPort', 'pktyDetails']

    }
  },
  computed: {
    ...mapGetters('manifest', [

      'getReloadAllRequest',
      'getMyLoader',
      'getHasSearchedVariable',
      'getPageNo',
      'getRequestList',
      'getNoDataFound',
      'getSuccessAlert',
      'getErrorMessage',
      'getErrorAlert',
      'getSuccessMessage',
      'getReloadAllRequest'
    ]),
    ...mapGetters('CFAgent', [
      'getAgentRequestList'
    ]),

    ...mapGetters('deliveryOrder', [
      'getSuccessAlert',
      'getErrorMessage',
      'getErrorAlert',
      'getSuccessMessage'
    ])
  },
  methods: {
    ...mapActions('manifest', [
      'getManifestByVoyageNo',
      'selectedManifest'
    ]),
    ...mapActions('CFAgent', [
      'searchCfAgentByAgentName'
    ]),
    ...mapActions('deliveryOrder', [
      'saveDeliveryOrder'
    ]),

    viewBlist(manifest){
      this.$router.push({name:"bllist"});
      this.selectedManifest(manifest)
    },

    viewRequest(BLdetails){
      AGENCYAPI.getCustomerShippingOrderRequestByBlNumber(BLdetails.billOfLading).then((response)=>{
        this.doCustomerRequests=response.data.data;
      }).catch(()=>{

      });
      this.showModalDoRequest=true;
    },

    loadManifestDetailsOnCreated () {
      this.$store.dispatch('manifest/voyageIding', this.voyageId)
      this.myLoader = false;
      this.getManifestByVoyageNo({}).then(() => {
        this.noDataFound = this.getNoDataFound;
        this.manifestDetails = this.getRequestList;

        if (this.manifestDetails[0] !== null &&
          this.manifestDetails[0].manifestBLList !== null &&
          this.manifestDetails[0].manifestBLList !== undefined) {
          this.ogManifestBl = JSON.parse(JSON.stringify(this.getPackageTypeCodeDetails(this.manifestDetails[0].manifestBLList)))
          this.ogManifestBl = this.removeCommaForArray(this.ogManifestBl, 'sealNumber');
          this.manifestBl = JSON.parse(JSON.stringify(this.ogManifestBl));
          this.pageNo = 0;
          this.pageNumber = 0;
          // this.pageSize = this.manifestBl.length;
          this.totalElements = this.manifestBl.length + 1;
          this.totalPages = Math.ceil(this.manifestBl.length / this.pageSize);
          this.paginate(this.ogManifestBl, this.pageSize, 1);

        } else {
          this.noDataFound = true
          this.noManifest = true
          this.setManifest()
        }
        this.myLoader = false
      }).catch(() => {
        this.noDataFound = this.getNoDataFound
        this.myLoader = false
      })
    },
    setManifest () {

      setTimeout(function () {
        that.noManifest = false;
        history.back();
        // that.ErrorAlert = false;
      }, 3000) // hide the message after 3 seconds
    },

    paginate (array, page_size, page_number) {
      this.manifestBl = array.slice((page_number - 1) * page_size, page_number * page_size)
    },

    goBack () {
      history.back()
    },
    addDeliveryOrder () {
      let doBL = this.deliveryOrderDetails.blNo
      this.$store.dispatch('deliveryOrder/savingDetails', this.deliveryOrderDetails)

      this.saveDeliveryOrder({}).then(() => {
        this.SuccessAlert = this.getSuccessAlert
        this.ErrorAlert = this.getErrorAlert
        if  (this.SuccessAlert){
          this.notifyUser('success', 'Delivery order saved successfully!', `Delivery order associated with BL No. ${doBL} has been saved successfully` )
          this.SuccessAlert = false;
        }
        if (this.ErrorAlert){
          this.notifyUser('error','An error occurred', `Sorry, something went wrong, the delivery order was not added. Please try again later`)
          this.ErrorAlert = false;
        }
      }).catch(()=>{
        this.ErrorAlert = this.getErrorAlert;
        if (this.ErrorAlert){
          this.notifyUser('error','An error occurred', `Sorry, something went wrong, the delivery order was not added. Please try again later`)
          this.ErrorAlert = false;
        }
      })
      this.closeModal()
    },
    viewDetails (details) {
      this.blDetails = details
      this.openModal('VIEW')
    },
    closeModal () {
      this.showModal = false;
      this.doCustomerRequests=[{}];
      this.showModalDoRequest=false;
      this.ViewModalDiv = false
      this.AddModalDiv = false
      this.reloadSearchAgent()
      this.blDetails = {}
      this.deliveryOrderDetails = {}
    },
    openModal (modalType) {
      if (modalType === 'VIEW') {
        this.showModal = true
        this.ViewModalDiv = true
        this.modal_title = 'Bill of Lading Details'
      }
      if (modalType === 'ADD-DO') {
        this.showModal = true
        this.AddModalDiv = true
      }
    },
    searchReference (searchParam) {
      this.isPagable = false;
      this.reloadAllRequest = true;
      function search (arr, s) {
        const matches = []; let i; let key;

        for (i = arr.length; i--;) {
          if ((arr[i].hasOwnProperty('containerNo') && arr[i].containerNo.indexOf(s) > -1) ||
            (arr[i].hasOwnProperty('sealNumber') && arr[i].sealNumber.indexOf(s) > -1) ||
            (arr[i].hasOwnProperty('billOfLading') && arr[i].billOfLading.indexOf(s) > -1)) {
            matches.push(arr[i])
          }
        }
        return matches
      };
      this.isPageable = false

      this.manifestBl = search(this.ogManifestBl, searchParam)
    },
    reloadByIcon () {
      this.reloadAllRequest = false
      this.paymentRequest = []
      // this.manifestBl = this.ogManifestBl;
      this.paginate(this.ogManifestBl, this.pageSize, 1)
      this.isPageable = true
    },
    searchAgent () {
      if (this.searchAgentParam !== '') {
        this.hasSearchAgent = false
        this.editMyLoader = true
        this.$store.dispatch('CFAgent/searchingParameter', this.searchAgentParam)
        this.searchCfAgentByAgentName({}).then(() => {
          this.agentOptions = this.getAgentRequestList
          this.editMyLoader = false
        }).catch(() => {
          this.editMyLoader = false
        })
      }
    },
    reloadSearchAgent () {
      this.hasSearchAgent = true
      this.searchAgentParam = ''
    },
    goToPage (page) {
      // this.pageNumber = this.pageNumber + 1;
      this.pageNo = page
      this.pageNumber = this.pageNo
      this.paginate(this.ogManifestBl, this.pageSize, (page + 1))
    },
    onChange (event) {
      this.pageSize = event
      this.totalPages = Math.ceil(this.manifestBl.length / this.pageSize)
      this.paginate(this.ogManifestBl, event, this.pageNo + 1)
    },
    fadeMe: function () {
      if (this.SuccessAlert) {
        this.SuccessAlert = !this.SuccessAlert
      } else {
        this.ErrorAlert = !this.ErrorAlert
      }
    },
    enter: function (el, done) {
      var that = this

      setTimeout(function () {
        that.SuccessAlert = false
        that.ErrorAlert = false
      }, 3000) // hide the message after 3 seconds
    }
  }

}
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 4910;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .8);
  display: table;
  transition: opacity .3s ease;
  overflow-y: auto;
}

.medium-modal-dialog {
  max-width: calc(80vw) !important;
}

tbody tr {
  color: #212529 !important;
//font-weight: 400 !important;
}
thead th {
  font-weight: 600 !important;
}
.legend {
  position: relative;
  border: 1px solid #DCDCDC;
  padding: 1.5rem 1rem 1rem 1rem;
  margin-bottom: 1rem;
  border-radius: 3px;
  margin-top: 1rem;
}

.legend-title {
  background-color: inherit;
  position: absolute;
  top: -0.6rem;
  padding: 0.2rem 1rem;
  background-color: white;
  color: #d59a18;
}

.btn-space {
  margin-right: 10px !important;
}

.custom-btn {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
  padding: 5px 9px 5px 9px !important;
  font-size: 14px !important;
  width: 120px;
  background-color: #FFFFFF !important;
}

.error{
  color:#dc3545;
  margin-left: 2px;
  margin-top: 2px;
}
.loader-div {
  line-height: 40px;
}

.loader-div-gif {
  line-height: 50px;
}
.div-table-row {
  padding: 0 15px 0 15px;
}

.div-table {
  padding: 9px 8px 9px 8px;
}

.div-table-title {
  width: 250px;
  background-color: rgba(153, 186, 221, .5);
  font-weight: normal;
}

.div-table-value {
  border: 1px solid #DCDCDC;
  font-weight: 900;
}

label {
  position: relative;
  top: -7px;
  right: 0;
  margin: 0;
  padding: 0;
}

.special-bottom {
  margin-bottom: -1px;
}
</style>
