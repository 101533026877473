<template>
  <card>
    <div class="row">
      <div
        class="col-md-12 alert-dismissible fade show"
        role="alert"
        :class="[ SuccessAlert ? 'alert-success' : '' , ErrorAlert ? 'alert-danger' : '' ]"
        style="border-radius:5px;"
        @enter="enter"
      >
        <strong v-if="SuccessAlert">Successifully!</strong> <span
          v-if="SuccessAlert"
        >{{ SuccessMessage }}</span>
        <strong v-if="ErrorAlert">Error!</strong> <span v-if="ErrorAlert">{{ ErrorMessage }}</span>

        <button
          type="button"
          class="close"
          data-dismiss="alert"
          aria-label="Close"
          v-if="SuccessAlert"
          style="line-height:2px;"
          @click="fadeMe"
        >
          <span aria-hidden="true">&times;</span>
        </button>
        <button
          type="button"
          class="close"
          data-dismiss="alert"
          aria-label="Close"
          v-if="ErrorAlert"
          style="line-height:2px;"
          @click="fadeMe"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12 text-center">
        <pulse-loader
          :loading="loading"
          :color="color"
          :size="size"
          v-if="loading"
        />
      </div>
      <div class="col-md-12">
        <div class="form-group">
          <label for="name">Name</label>
          <input
            type="tex"
            class="form-control"
            placeholder="Name"
            id="name"
            v-model="name"
          >
          <div v-if="nameError">
            <div
              class="error text-danger"
              v-if="!$v.name.required"
            >
              This field is required
            </div>
          </div>
        </div>
        <div class="form-group mt-3">
          <label for="description">Description</label>
          <textarea
            class="form-control"
            rows="3"
            id="description"
            v-model="description"
          />
          <div v-if="descriptionError">
            <div
              class="error text-danger"
              v-if="!$v.description.required"
            >
              This field is required
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-3">
      <multi-select
        :options="shippingOrder"
        :selected-options="items"
        placeholder="select Shipping Order"
        id="multiSelect"
        @select="onSelect"
      />
      <div v-if="shippingOrderStatusError">
        <div
          class="error text-danger"
          v-if="!$v.shippingOrderStatus.required"
        >
          This field is required
        </div>
      </div>
    </div>
    <div class="form-group mt-3">
      <label for="active">Active Status</label>
      <select
        class="form-control"
        id="active"
        v-model="active"
      >
        <option value="" />
        <option :value="true">
          Active
        </option>
        <option :value="false">
          Not Active
        </option>
      </select>
      <div v-if="activeError">
        <div
          class="error text-danger"
          v-if="!$v.active.required"
        >
          This field is required
        </div>
      </div>
    </div>
    <div class="form-group mt-3">
      <label for="closing">Closing Activity</label>
      <select
        class="form-control"
        id="closing"
        v-model="closingActivity"
      >
        <option value="" />
        <option :value="true">
          Closing
        </option>
        <option :value="false">
          Not Closing
        </option>
      </select>
      <div v-if="closingActivityError">
        <div
          class="error text-danger"
          v-if="!$v.closingActivity.required"
        >
          This field is required
        </div>
      </div>
    </div>
    <div class="form-group">
      <button
        class="btn btn-default"
        @click.prevent="createActivity"
      >
        Submit
      </button>
    </div>
  </card>
</template>

<script>
import { ModelListSelect, MultiSelect } from 'vue-search-select'
import TALLYAPI from '../../../api/tally'
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import { required, minLength } from 'vuelidate/lib/validators'

export default {
  name: 'Activities',

  components: {
    MultiSelect,
    PulseLoader
  },

  validations: {
    name: { required },
    description: { required },
    active: { required },
    closingActivity: { required },
    shippingOrderStatus: { required, minLength: minLength(2) }
  },

  data () {
    return {
      shippingOrder: [
        { value: 'Received', text: 'Received' },
        { value: 'Accepted', text: 'Accepted' },
        { value: 'Rejected', text: 'Rejected' },
        { value: 'RequireAdditionDetails', text: 'RequireAdditionDetails' },
        { value: 'Canceled', text: 'Canceled' },
        { value: 'Approved', text: 'Approved' },
        { value: 'WaitingContainer', text: 'WaitingContainer' },
        { value: 'ContainerAllocated', text: 'ContainerAllocated' },
        { value: 'Stuffed', text: 'Stuffed' },
        { value: 'ScheduledForTransport', text: 'ScheduledForTransport' },
        { value: 'Exported', text: 'Exported' },
        { value: 'Closed', text: 'Closed' }
      ],
      items: [],
      lastSelectItem: {},

      name: '',
      nameError: false,
      description: '',
      descriptionError: false,
      shippingOrderStatus: [],
      shippingOrderStatusError: false,
      active: '',
      activeError: false,
      closingActivity: '',
      closingActivityError: false,

      loading: false,
      color: 'green',
      size: '15px',

      SuccessAlert: false,
      ErrorAlert: false,
      SuccessMessage: '',
      ErrorMessage: ''
    }
  },

  methods: {

    onSelect (items, lastSelectItem) {
      this.items = items
      this.lastSelectItem = lastSelectItem
    },

    createActivity () {
      this.shippingOrder = []
      this.items.map((item) => {
        this.shippingOrder.push(item.value)
      })

      if (this.name !== '' && this.description !== '' && this.active !== '' && this.closingActivity !== '') {
        if (this.shippingOrder.length === 0) {
          this.shippingOrderStatusError = true
        } else {
          this.activeError = false
          this.nameError = false
          this.descriptionError = false
          this.shippingOrderStatusError = false
          this.closingActivityError = false

          this.loading = true

          TALLYAPI.createActivities({

            name: this.name,
            description: this.description,
            shippingOrderStatus: this.shippingOrder,
            active: this.active,
            closingActivity: this.closingActivity

          }).then((response) => {
            this.ErrorMessage = ''
            this.SuccessAlert = false
            this.ErrorAlert = false
            this.SuccessMessage = 'Activity Created SuccessFull'
            this.SuccessAlert = true
            this.loading = false
          }).catch(() => {
            this.ErrorMessage = ', Configuration Failed'
            this.SuccessAlert = false
            this.ErrorAlert = true
            this.SuccessMessage = ''
            this.SuccessAlert = false
            this.loading = false
          })
        }
      } else {
        this.activeError = true
        this.nameError = true
        this.descriptionError = true
        this.closingActivityError = true
      }
    },

    enter: function (el, done) {
      const that = this

      setTimeout(function () {
        that.SuccessAlert = false
        that.ErrorAlert = false
      }, 3000) // hide the message after 3 seconds
    },

    fadeMe: function () {
      if (this.SuccessAlert) {
        this.SuccessAlert = !this.SuccessAlert
      } else {
        this.ErrorAlert = !this.ErrorAlert
      }
    }
  }
}
</script>

<style scoped>

</style>
