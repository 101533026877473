<template>
  <card>
    <div class="row">
      <div
        class="col-md-12 alert-dismissible fade show"
        role="alert"
        :class="[ SuccessAlert ? 'alert-success' : '' , ErrorAlert ? 'alert-danger' : '' ]"
        style="border-radius:5px;"
      >
        <strong v-if="SuccessAlert">Successifully!</strong> <span v-if="SuccessAlert">{{ SuccessMessage }}</span>
        <strong v-if="ErrorAlert">Error!</strong> <span v-if="ErrorAlert">{{ ErrorMessage }}</span>

        <button
          type="button"
          class="close"
          data-dismiss="alert"
          aria-label="Close"
          v-if="SuccessAlert"
          style="line-height:2px;"
        >
          <span aria-hidden="true">&times;</span>
        </button>
        <button
          type="button"
          class="close"
          data-dismiss="alert"
          aria-label="Close"
          v-if="ErrorAlert"
          style="line-height:2px;"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col-12 text-center">
        <pulse-loader
          :loading="loading"
          :color="color"
          :size="size"
          v-if="loading"
        />
      </div>
      <div class="col-md-12">
        <h3>Container Allocation</h3>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="containerNo">Container Number</label>
          <input
            type="text"
            class="form-control"
            id="containerNo"
            placeholder="container Number"
            v-model="details.containerNo"
          >
          <div
            class="error text-danger"
            v-if="containerNoError"
          >
            <span v-if="!$v.details.containerNo.required">Field is required</span>
          </div>
        </div>
        <div class="form-group">
          <label for="containerSize">Container Size</label>
          <input
            type="text"
            class="form-control"
            id="containerSize"
            placeholder="Container Size"
            v-model="details.containerSize"
          >
          <div
            class="error text-danger"
            v-if="containerSizeError"
          >
            <span v-if="!$v.details.containerSize.required">Field is required</span>
          </div>
        </div>
        <div class="form-group">
          <label for="description">Description</label>
          <input
            type="text"
            class="form-control"
            id="description"
            placeholder="Description"
            v-model="details.description"
          >
          <div
            class="error text-danger"
            v-if="descriptionError"
          >
            <span v-if="!$v.details.description.required">Field is required</span>
          </div>
        </div>
        <div class="form-group">
          <label for="grossWeight">Gross Weight</label>
          <input
            type="text"
            class="form-control"
            id="grossWeight"
            placeholder="Gross Weight"
            v-model="details.grossWeight"
          >
          <div
            class="error text-danger"
            v-if="grossWeightError"
          >
            <span v-if="!$v.details.grossWeight.required">Field is required</span>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="grossWeightUnit">Gross Weight Unit</label>
          <input
            type="text"
            class="form-control"
            id="grossWeightUnit"
            placeholder="grossWeightUnit"
            v-model="details.grossWeightUnit"
          >
          <div
            class="error text-danger"
            v-if="grossWeightUnitError"
          >
            <span v-if="!$v.details.grossWeightUnit.required">Field is required</span>
          </div>
        </div>
        <div class="form-group">
          <label for="tareWeight">Tare Weight</label>
          <input
            type="text"
            class="form-control"
            id="tareWeight"
            placeholder="Weight"
            v-model="details.tareWeight"
          >
          <div
            class="error text-danger"
            v-if="containerSizeError"
          >
            <span v-if="!$v.details.tareWeight.required">Field is required</span>
          </div>
        </div>
        <div class="form-group">
          <label for="tareWeightUnit">Tare Weight Unit</label>
          <input
            type="text"
            class="form-control"
            id="tareWeightUnit"
            placeholder="Weight Unit"
            v-model="details.tareWeightUnit"
          >
          <div
            class="error text-danger"
            v-if="tareWeightUnitError"
          >
            <span v-if="!$v.details.tareWeightUnit.required">Field is required</span>
          </div>
        </div>
        <div class="form-group">
          <label for="typeOfContainer">Type Of Container</label>
          <input
            type="text"
            class="form-control"
            id="typeOfContainer"
            placeholder=""
            v-model="details.typeOfContainer"
          >
          <div
            class="error text-danger"
            v-if="containerSizeError"
          >
            <span v-if="!$v.details.typeOfContainer.required">Field is required</span>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <button
          class="btn btn-fill btn-default float-right"
          @click.prevent="assignContainer"
        >
          Submit
        </button>
      </div>
    </div>
  </card>
</template>

<script>
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import AGENYAPI from '../../../api/agency'
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'AssignContainers',

  components: {
    PulseLoader
  },

  validations: {
    details: {
      containerNo: { required },
      containerSize: { required },
      description: { required },
      grossWeight: { required },
      grossWeightUnit: { required },
      shippingOrderId: { required },
      tareWeight: { required },
      tareWeightUnit: { required },
      typeOfContainer: { required }
    }
  },

  data () {
    return {
      details: {
        containerNo: '',
        containerSize: '',
        description: '',
        grossWeight: '',
        grossWeightUnit: '',
        shippingOrderId: this.$route.params.shippingOrderId,
        tareWeight: '',
        tareWeightUnit: 'G',
        typeOfContainer: ''
      },

      containerNoError: false,
      containerSizeError: false,
      descriptionError: false,
      grossWeightError: false,
      grossWeightUnitError: false,
      shippingOrderIdError: false,
      tareWeightError: false,
      tareWeightUnitError: false,
      typeOfContainerError: false,

      color: 'green',
      size: '15px',

      errorMessage: '',
      SuccessAlert: false,
      ErrorAlert: false,
      SuccessMessage: '',
      loading: false
    }
  },
  methods: {

    assignContainer () {
      if (
        this.details.containerNo !== '' &&
                    this.details.containerSize !== '' &&
                    this.details.description !== '' &&
                    this.details.grossWeight !== '' &&
                    this.details.grossWeightUnit !== '' &&
                    this.details.shippingOrderId !== '' &&
                    this.details.tareWeight !== '' &&
                    this.details.tareWeightUnit !== '' &&
                    this.details.typeOfContainer !== ''
      ) {
        this.containerNoError = false
        this.containerSizeError = false
        this.descriptionError = false
        this.grossWeightError = false
        this.grossWeightUnitError = false
        this.shippingOrderIdError = false
        this.tareWeightError = false
        this.tareWeightUnitError = false
        this.typeOfContainerError = false

        this.loading = true
        AGENYAPI.assignContainerToshippingOder(this.details).then(() => {
          this.ErrorMessage = '';
          this.SuccessAlert = true;
          this.ErrorAlert = false;
          this.SuccessMessage = 'Container Assigned';
          this.loading = false;

          this.details = {
            containerNo: '',
            containerSize: '',
            description: '',
            grossWeight: '',
            grossWeightUnit: '',
            shippingOrderId: this.$route.params.shippingOrderId,
            tareWeight: '',
            tareWeightUnit: 'G',
            typeOfContainer: ''
          }
        }).catch(() => {
          this.loading = false;
          this.ErrorMessage = 'Failed To Assign Containers';
          this.SuccessAlert = false;
          this.ErrorAlert = true;
          this.SuccessMessage = '';
        })
      } else {
        this.containerNoError = true
        this.containerSizeError = true
        this.descriptionError = true
        this.grossWeightError = true
        this.grossWeightUnitError = true
        this.shippingOrderIdError = true
        this.tareWeightError = true
        this.tareWeightUnitError = true
        this.typeOfContainerError = true
      }
    }
  }
}
</script>

<style scoped>

</style>
