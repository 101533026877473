<template>
    <div>
        <card>
        <div class="row">
            <div class="col-md-12">
                <button
                    v-if="!hasMainDisbursmentAccount"
                    class="btn btn-success mr-2 custom-btn float-right"
                    @click.prevent="openModal('ADD_MAIN')"
                >
                    <b>Add Disbursement Account</b>
                </button>
                <button
                    v-if="hasMainDisbursmentAccount"
                    class="btn btn-primary mr-2 custom-btn float-right"
                    @click.prevent="openModal('ADD_SUB')"
                >
                    <b>Add Sub-Disbursement</b>
                </button>
            </div>
        </div>
        <hr v-if="filteredRequests.length > 0"/>
        <data-table
        v-if="filteredRequests.length > 0"
        :table-headers="tableHeaders"
        :table-data="filteredRequests"
        :table-data-keys="tableDataKeys"
        :my-first-loader="myFirstLoader"
        :is-pageable="true"
        :page-size-prop="pageSize"
        :page-no="pageNo"
        :total-elements="totalElements"
        :total-pages="totalPages"
        :is-first="isFirst"
        :is-last="isLast"
        :page-number="pageNumber"
        :no-data-found="noDataFound"
        :has-manage-button="true"
        :has-view-button="false"
        :has-search-form="false"
        :has-filter="false"
        :loading-title="'Please wait while the system is loading sub disbursment accounts'"
        :permissions="[ {key: 'searchPermission', value: 'DISBURSEMENT_SUB_LIST_{VESSELID}_GET'} ]"
        @goToApplication="goToApplication"
        @goToPage="goToPage"
        @onChange="onChange"
        >
        <div slot = "pagination">
            <paginate
                v-model="getIsPage"
                :page-count=parseInt(totalPages)
                :page-range="3"
                :margin-pages="2"
                :click-handler="goToPage"
                :prev-text="'Prev'"
                :next-text="'Next'"
                :container-class="'pagination'"
                :page-class="'page-item'">
            </paginate>
        </div>
        </data-table>
        </card>

        <create-sub-disbursement
        :title="'CREATE SUB DISBURSMENT ACCOUNT'"
        :disbursement-account-id="disbursementAccountId"
        :show-add-sub-disbursement="showAddSubDisbursement"
        @closeModal="closeModal"
        @loadPageableRequestOnCreated="loadPageableRequestOnCreated"
        />

        <create-disbursement
        :title="'REGISTER DISBURSEMENT ACCOUNT'"
        :disbursement-account-id="disbursementAccountId"
        :show-add-disbursement="showAddDisbursement"
        :vesselParticularId="parseInt(vesselId)"
        @closeModal="closeModal"
        @getMainDisbursmentAccount="getMainDisbursmentAccount"
        />
    </div>
</template>
<script>

import { mapActions, mapGetters } from 'vuex'
import CreateSubDisbursement from '../sub-disbursment-account/createSubDisbursement'
import CreateDisbursement from '../sub-disbursment-account/createDisbursement'

export default {

    name: 'SubDisbursmentAccountsList',

    components: {

        DataTable: () => import('../../../../components/DataTable/dataTable.vue'),
        CreateSubDisbursement,
        CreateDisbursement

    },

    props: {

        vesselId: {

            type: Number | String

        }

    },

    created () {

        this.finalUserPermissions = JSON.parse(localStorage.getItem('browser-local-permits'))
        this.loadPageableRequestOnCreated()
        this.getMainDisbursmentAccount()

    },

    computed: {

        ...mapGetters('subdisbursment', [

        'getRequestList',
        'getVesselParticularId',
        'getPageCount',
        'getMyFirstLoader',
        'getNoDataFound',
        'getPageNo',
        'getTotalElement',
        'getTotalPage',
        'getPageNumber',
        'getIsFirst',
        'getIsLast',

        ]),


        getIsPage: {

        get: function () {
            return this.isPage
        },
        set: function (newValue) {
            this.isPage = newValue;
        }

        }

    },

    data () {

        return {

        requests: [],
        filteredRequests: [],
        finalUserPermissions: [],
        hasSearchedVariable: false,
        showAddSubDisbursement: false,
        showAddDisbursement: false,
        noDataFound: false,
        isPage: 0,
        pageNo: 0,
        pageSize: 15,
        pageCount: 1,
        totalElements: '',
        totalPages: '',
        isFirst: false,
        isLast: false,
        pageNumber: '',
        myFirstLoader: false,
        hasMainDisbursmentAccount: false,
        disbursementAccountId: 0,

        tableHeaders: ['Account Status', 'Disbursement Type', 'Proforma Number', 'Principal Name'],
        tableDataKeys: ['status', 'disbursementType', 'invoiceNo', 'principalName'],

        myLoader: false,

        }
        
    },

    methods: {

        ...mapActions('subdisbursment', [

        'getSubDisBursemntAccountsByVessel',

        ]),

        ...mapActions('disbursement', [

        'getDisbursementAccountByVesselId',

        ]),

        sortingData(data) {

            for(let x = 0 ; x < data.length ; x++) {

                const requestObject = {

                  ...data[x],
                  principalName: data[x].principalParticularDto !== null ? data[x].principalParticularDto.name : ""

                }

                this.filteredRequests.push(requestObject)

            }

        },

        passTotalSubDisbursment() {

            this.$emit('totalSubDisbursment', this.requests.length)

        },

        setAllGetters() {

            this.requests = this.getRequestList;
            this.myFirstLoader = this.getMyFirstLoader;
            this.noDataFound = this.getNoDataFound;
            this.pageNo = this.getPageNo;
            this.pageSize = this.getPageSize;

            this.totalElements = this.getTotalElement;
            this.totalPages = this.getTotalPage;
            this.pageNumber = this.getPageNumber;
            this.isFirst = this.getIsFirst;
            this.isLast = this.getIsLast;

            this.sortingData(this.requests)

        },

        loadParameters () {

            return new Promise((resolve) => {

                this.$store.dispatch('subdisbursment/pageSizing', this.pageSize)
                this.$store.dispatch('subdisbursment/pageNumbering', this.pageNo)
                this.$store.dispatch('subdisbursment/vesselParticularIding', this.vesselId)

                resolve()
            })

        },

        getMainDisbursmentAccount() {

            this.$store.dispatch('disbursement/requestid', this.vesselId).then(() => {

                this.getDisbursementAccountByVesselId({}).then((response) => {

                    if(response.data.data !== 'NOT_FOUND') {

                        this.hasMainDisbursmentAccount = true
                        this.disbursementAccountId = response.data.data.disbursementAccountId

                    } else {

                        this.hasMainDisbursmentAccount = false

                    }

                }).catch(() => {


                });

            }).catch(() => {


            });

        },

        loadPageableRequestOnCreated() {

            this.filteredRequests = []

            this.loadParameters().then(() => {

                this.getSubDisBursemntAccountsByVessel({}).then(() => {

                    this.setAllGetters()

                }).catch(() => {


                });

                this.myFirstLoader = this.getMyFirstLoader;

            }).catch(() => {


            });

        },

        onChange(event) {

        this.$store.dispatch("subdisbursment/pageSizing", event);
        this.$store.dispatch("subdisbursment/pageNumbering", 0);
        localStorage.removeItem("page-no")
        localStorage.removeItem("page-size")
        this.isPage = 0

        this.pageSize = this.getPageSize;
        this.pageNo = this.getPageNo;

        if (this.getHasSearchedVariable) {

            let firstPart = this.newParams.split("&page=")[0]
            let secondPart = firstPart.split("size=")[0]
            let thirdPart = firstPart.split("size=")[1]
            this.newParams = ""
            this.newParams = secondPart + 'size=' + this.pageSize + 'page=' + 0
            this.pageNoPart = 0

            this.searchGeneralChargeParams(this.newParams , this.pageNoPart);
            this.filteredRequests = []

        } else {

            this.loadPageableRequestOnCreated(this.pageNo , this.pageSize);
            this.filteredRequests = []
        }

        },

        goToPage(page) {

        if(page > 0) {page = page - 1;}
        this.isPage = page + 1

        this.pageNo = page;

        if (this.getHasSearchedVariable) {

            let firstPart = this.newParams.split("page=")[0]
            this.newParams = ""
            this.newParams = firstPart + '&page=' + this.pageNo
            this.pageNoPart = this.isPage

            this.searchGeneralChargeParams(this.newParams, this.pageNoPart);
            this.filteredRequests = []

        } else {

            this.loadPageableRequestOnCreated(this.pageNo , this.pageSize);
            this.filteredRequests = []
        }

        },

        goToApplication (details) {

            let id = ''
            if (typeof (details) === 'number'){
                localStorage.setItem('disbursementType', 'main')
                id = details
            }else if (typeof (details) === "object"){
                localStorage.setItem('disbursementType', 'sub')
                id = details.subDisbursementAccountId
            }
            document.cookie = 'browser-local-cashed-url-back=' + this.$route.fullPath + ';Path = /;SameSite = Strict'
            this.$router.push('/shipping-agency/sub-disbursement-accounts/account-details/' + id)

        },

        openModal(modalType) {

            if (modalType === 'ADD_MAIN') {

                this.showAddDisbursement = true
                this.showAddSubDisbursement = false

            } else { //(modalType === 'ADD_SUB')

                this.showAddSubDisbursement = true
                this.showAddDisbursement = false

            }
        },

        closeModal() {

            this.showAddSubDisbursement = false
            this.showAddDisbursement = false

        },
    }
}
</script>
<style scoped>

.custom-btn {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
    padding: 5px 9px 5px 9px !important;
    font-size: 14px !important;
}

.custom-btn-all {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
    padding: 5px 9px 5px 9px !important;
    font-size: 14px !important;
    width: auto;
}

</style>
