<template>
  <card>
    <div class="container-fluid">
      <div class="row">
        <div class="col-12 text-center">
          <pulse-loader
            :loading="loading"
            :color="color"
            :size="size"
            v-if="loading"
          />
          <crud-notification
            :error-message="ErrorMessage"
            :success-message="SuccessMessage"
            :error-alert="ErrorAlert"
            :success-alert="SuccessAlert"
            @fadeMe="fadeMe"
            @enter="enter"
          />

        </div>
        <ship-order-sub @detailsData="receivingDetailsData" ref="sinkData"></ship-order-sub>
        <div class="col-md-12">
          <div class="row mr-1 ml-1" v-for="(cargo,index) in details.shippingOrderGoodsDtos" :key="index">
            <shipping-order-goods :index="index.toString()" @detailsData="receivingCargoData"></shipping-order-goods>
            <div class="col-md-1 text-center" style="padding-top:180px;font-size:20px;">
                  <span
                    class="text-right"
                    title="Delete Cargo"
                    style="cursor: pointer"
                    @click="removeCargo(index)"
                  >
                    <font-awesome-icon
                      icon="trash-alt"
                      color="red"
                    />
                  </span>
            </div>
            <div class="col-md-12">
              <hr />
            </div>
          </div>
          <div class="row text-right">
            <div class="col-md-12">
              <button class="btn btn-success custom-btn" @click="addCargo">
                Add Cargo
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <hr />
        </div>
      </div>
      <div class="row text-right">
          <div class="col-md-12">
            <button class="btn btn-primary custom-btn" @click="submit">
              Submit
            </button>
          </div>
      </div>
    </div>
  </card>
</template>

<script>
import API from '../../../api/index';
import {ModelListSelect, ModelSelect} from 'vue-search-select';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import AGENYAPI from '../../../api/agency';
import CrudNotification from '../../../components/DataTable/crudNotification';
import containerSize from "./containerSize";
import ports from "./ports";
import getcountries from "./countries";
import {required} from "vuelidate/lib/validators";
import { CARGO_UNITS } from '../../../mixins/constants';
import shipOrderSub from "./shipOrderSub";
import shippingOrderGoods from "./shippingOrderGoods";


import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

export default {
  name: 'BookingAplication',

  components: {
    ModelListSelect,
    CrudNotification,
    PulseLoader,
    ModelSelect,
    DatePicker,
    shipOrderSub,
    shippingOrderGoods
  },

  validations:{
    details:{
      shipperName:{required},
      consigneeName:{required}
    }
  },
  created() {

    this.getSeaPorts();
    this.getCountries();
    this.$store.dispatch('pagenames/currentPageName', 'Application Booking');

  },

  data() {
    return {

      containerSize: containerSize,
      packageTypes:[],
      SeaPorts: [],
      countries: [],
      cargoUnits: CARGO_UNITS,
      selectedPort: "",
      selectedPackageTypeId:"",
      details: {
        packageType:null,
        cityCode: null,
        countryCode: null,
        destinationPort: null,
        originPort: null,
        destinationPlace: null,
        containerized: false,
        containerSize: null,
        shippingOrderNo: null,
        shipperCodeTin: null,
        shipperName: null,
        shipperAddress: null,
        shipperCity: null,
        shipperZipCode: null,
        shipperCountry: null,
        shipperContactPerson: null,
        shipperTel: null,
        shipperEmail: null,
        consigneeCodeTin: null,
        consigneeName: null,
        consigneeAddress: null,
        consigneeCity: null,
        consigneeZipCode: null,
        consigneeCountry: null,
        consigneeContactPerson: null,
        consigneeTel: null,
        consigneeEmail: null,
        expectedDepatureDate: null,
        expectedArrivalDate: null,

        notiyAddress: null,
        notiyCity: null,
        notiyCodeTin: null,
        notiyContactPerson: null,
        notiyCountry: null,
        notiyEmail: null,
        notiyName: null,
        notiyTel: null,
        notiyZipCode: null,
        termOfDelivery: null,
        shippingOrderGoodsDtos: [],
        shipperNameForInvoice: null,
        shipperTelForInvoice: null,

        shipperNameError:false,
        consigneeError:false,
      },
      numberOfCurgoes: 0,

      color: 'green',
      size: '15px',
      loading: false,

      ErrorMessage: '',
      SuccessAlert: false,
      ErrorAlert: false,
      SuccessMessage: '',

      shipperNameError:false,
      consigneeError:false,

    }
  },

  methods: {


    receivingDetailsData(data){
      this.details={
        ...data
      }
    },

    receivingCargoData(data){
      this.details.shippingOrderGoodsDtos[data.index]=data.goods;
    },


    getContainerName(data) {
      return `${data.descriptionofCodeGroup} - ${data.containerCode}`
    },

    getCountries() {

      getcountries.map((country) => {
        this.countries.push(country);
      })
    },

    codeAndCountry(data) {
      return `${data.name}(${data.code})`
    },

    getSeaPorts() {

      ports.map((port) => {
        this.SeaPorts.push(
          {value: port, text: port}
        );
      })
    },

    submit() {
      // this.details={
      //   "packageType": null,
      //   "cityCode": null,
      //   "countryCode": null,
      //   "destinationPort": "LONDON GATEWAY PORT,United Kingdom",
      //   "originPort": "DAR ES SALAAM,Tanzania,United Republic of",
      //   "destinationPlace": "LONDON",
      //   "containerized": true,
      //   "containerSize": null,
      //   "shippingOrderNo": null,
      //   "shipperCodeTin": "3737",
      //   "shipperName": "Deo",
      //   "shipperAddress": "Quen Street",
      //   "shipperCity": "Dar",
      //   "shipperZipCode": "255",
      //   "shipperCountry": "Tanzania, United Republic of",
      //   "shipperContactPerson": "LJK",
      //   "shipperTel": "373737",
      //   "shipperEmail": "d@me.com",
      //   "consigneeCodeTin": "47478",
      //   "consigneeName": "MUTA",
      //   "consigneeAddress": "LNF QUEEN",
      //   "consigneeCity": "LND",
      //   "consigneeZipCode": "3663",
      //   "consigneeCountry": "United Kingdom",
      //   "consigneeContactPerson": "DFG",
      //   "consigneeTel": "484848",
      //   "consigneeEmail": "dn@me.com",
      //   "expectedDepatureDate": "2021-12-01T21:00:00.000Z",
      //   "expectedArrivalDate": "2021-12-03T21:00:00.000Z",
      //   "notiyAddress": "467",
      //   "notiyCity": "LND",
      //   "notiyCodeTin": "373737",
      //   "notiyContactPerson": "DFGT",
      //   "notiyCountry": "United Kingdom",
      //   "notiyEmail": "D@ME.COM",
      //   "notiyName": "FGH",
      //   "notiyTel": "373838",
      //   "notiyZipCode": "37838",
      //   "termOfDelivery": "Prepaid",
      //   "id":0,
      //   "shippingOrderGoodsDtos": [
      //     {
      //       "cargoDescription": "DHDJ",
      //       "cargoName": "ANM",
      //       "shippingOrderGoodId": null,
      //       "marksAndNumbers": "3838",
      //       "netWeight": "37389",
      //       "netWeightUnit": "MT",
      //       "numberOfPackages": "474839",
      //       "packageType": 1,
      //       "shippingOrderId": 0,
      //       "specialCargos": [
      //         {
      //           "goodClass": null,
      //           "maxTmp": 0,
      //           "minTmp": 0,
      //           "specialCargo": "DangerousGoods"
      //         },
      //         {
      //           "goodClass": null,
      //           "maxTmp": "20",
      //           "minTmp": "10",
      //           "specialCargo": "TemperatureControl"
      //         }
      //       ],
      //       "vehicleMake": "373737",
      //       "vehicleModel": "4748",
      //       "vehicleVIN": "4748",
      //       "volumeCbm": "2636",
      //       "volumeUnit": "V",
      //       "weight": "3636",
      //       "weightUnit": "MT"
      //     }
      //   ],
      //   "shipperNameForInvoice": "Deo",
      //   "shipperTelForInvoice": "3287273",
      //   "shipperNameError": false,
      //   "consigneeError": false,
      //   "numberOfContainer": "2373727"
      // };

      console.log('333',this.details)

      let formattedDetailsObject = {
        ...this.details,
        expectedArrivalDate: this.details.expectedArrivalDate !== null ? this.dateTimeToLocalDateTime(new Date(this.details.expectedArrivalDate)): null,
        expectedDepatureDate: this.details.expectedDepatureDate !== null ? this.dateTimeToLocalDateTime(new Date(this.details.expectedDepatureDate)): null
      };

      if (this.details.shippingOrderGoodsDtos.length > 0) {

        //let isCBM=false; this is to check if CBM is inserted,if it required comment isCBM=true and uncomment isCBM=false
        //let isCBM=true;
        let isCBM = false;
        // for(let index=0;index<this.details.shippingOrderGoodsDtos.length;index++){
        //   if(this.details.shippingOrderGoodsDtos[index].volumeCbm==null){
        //     isCBM=true;
        //     //this.details.shippingOrderGoodsDtos[index].volumeCbm==null;
        //
        //   }
        //
        // }

        if (!isCBM) {
          this.ErrorMessage = '';
          this.SuccessAlert = false;
          this.ErrorAlert = false;
          this.SuccessMessage = '';

          console.log("55555",this.details);

          if(this.details.shipperName==="" || this.details.consigneeName===""){
            this.shipperNameError=true;
            this.consigneeError=true;

            this.loadNotification('error', 4000, 'Error',
              "Errors,please input Shipper and Consignee",
              'response', 1000 , true  , true);

          }else {
            AGENYAPI.shiporderBooking(formattedDetailsObject).then((response) => {
              console.log(this.details);
              this.ErrorMessage = ''
              this.SuccessAlert = true
              this.ErrorAlert = false
              this.SuccessMessage = 'Booking Application was Successful'
              this.loading = false
              this.shipperNameError=false;
              this.consigneeError=false;

              this.details = {
                cityCode: null,
                countryCode: null,
                destinationPort: null,
                originPort: null,
                containerized: false,
                containerSize: null,
                shippingOrderNo: null,
                shipperCodeTin: null,
                shipperName: null,
                shipperAddress: null,
                shipperCity: null,
                shipperZipCode: null,
                shipperCountry: null,
                shipperContactPerson: null,
                shipperTel: null,
                shipperEmail: null,
                consigneeCodeTin: null,
                consigneeName: null,
                consigneeAddress: null,
                consigneeCity: null,
                consigneeZipCode: null,
                consigneeCountry: null,
                consigneeContactPerson: null,
                consigneeTel: null,
                consigneeEmail: null,
                expectedDepatureDate: null,
                expectedArrivalDate: null,
                notiyAddress: null,
                notiyCity: null,
                notiyCodeTin: null,
                notiyContactPerson: null,
                notiyCountry: null,
                notiyEmail: null,
                notiyName: null,
                notiyTel: null,
                notiyZipCode: null,
                termOfDelivery: null,
                shippingOrderGoodsDtos: []
              };

              this.$router.push({name: "shippingorder", params: {"shippingOrderId": response.data.data.shippingOrderId}});
            }).catch((error) => {
              this.ErrorMessage = 'Booking Application Failed'
              this.SuccessAlert = false
              this.ErrorAlert = true
              this.SuccessMessage = ''
              this.loading = false
            })

          }
        } else {
          this.ErrorMessage = 'Please Input in CBM Field';
          this.SuccessAlert = false
          this.ErrorAlert = true
          this.SuccessMessage = ''
        }

      } else {

        this.ErrorMessage = 'Please insert At least One Cargo'

        this.loadNotification('error', 4000, 'Error',
          "Please insert At least One Cargo",
          'response', 1000 , true  , true);
        this.SuccessAlert = false
        this.ErrorAlert = true
        this.SuccessMessage = ''
      }
    },


    specialCargoDescriptions(cargo, index, e, inputValue) {
      let specialCargoIndex = this.details.shippingOrderGoodsDtos[index].specialCargos.findIndex((data) => {
        return cargo === data.specialCargo;
      });

      this.details.shippingOrderGoodsDtos[index].specialCargos[specialCargoIndex][inputValue] = e.target.value;

    },

    addSpecialCargos(index, e) {

      let specialCargoIndex = this.details.shippingOrderGoodsDtos[index].specialCargos.findIndex((data) => {
        return e.target.value === data.specialCargo;
      });
      if (specialCargoIndex === -1) {
        this.details.shippingOrderGoodsDtos[index].specialCargos.push(
          {
            goodClass: null,
            maxTmp: 0,
            minTmp: 0,
            specialCargo: e.target.value
          }
        );
      } else {
        this.details.shippingOrderGoodsDtos[index].specialCargos.splice(specialCargoIndex, 1);
      }
    },

    addCargo() {

      this.details.shippingOrderGoodsDtos.push({
        cargoDescription: null,
        cargoName: null,
        numberOfPackages: 0,
        packageType: null,
        specialCargos: [],
        volumeCbm: null,
        weight: null,
        weightUnit: '',
        netWeightUnit: '',
        volumeUnit: '',
        vehicleVIN: '',
        vehicleModel: '',
        vehicleMake: '',
        marksAndNumbers: ''
      })

    },

    fadeMe: function () {
      if (this.SuccessAlert) {
        this.SuccessAlert = !this.SuccessAlert;
      } else {
        this.ErrorAlert = !this.ErrorAlert;
      }
    },
    enter: function (el, done) {
      var that = this;

      setTimeout(function () {
        that.SuccessAlert = false;
        that.ErrorAlert = false;
      }, 3000); // hide the message after 3 seconds
    },

    removeCargo(index) {
      const mm = this.details.shippingOrderGoodsDtos.splice(index, 1)
    },
    ordinal_suffix_of(i) {
      const j = i % 10
      const k = i % 100
      if (j === 1 && k !== 11) {
        return i + 'st'
      }
      if (j === 2 && k !== 12) {
        return i + 'nd'
      }
      if (j === 3 && k !== 13) {
        return i + 'rd'
      }
      return i + 'th'
    }
  }
}
</script>

<style lang="scss" scoped>

label {
        // color: rgba(213,154,24, .9) !important;
        color: black !important;
    }

.custom-btn {
        margin-top: 5px !important;
        margin-bottom: 5px !important;
        padding: 5px 9px 5px 9px !important;
        font-size: 14px !important;
        width: auto;
        background-color: #FFFFFF !important;
    }

.custom-font {
  // color: #4c4c4c !important;
  font-size: 14px !important;
}

::placeholder {

}

h3 {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid green;
  line-height: 0.1em;
  margin: 10px 0 20px;
}

h3 span {
  background: #fff;
  padding: 0 10px;
}

.cargo {

}

.legend {
  position: relative;
  border: 1px solid #dcdcdc;
  padding: 1.5rem 1rem 1rem 1rem;
  margin-bottom: 1rem;
  border-radius: 3px;
  margin-top: 1rem;
}

.legend-title {
  position: absolute;
  top: -0.6rem;
  padding: 0.2rem 1rem;
  background-color: white;
  color: #d59a18;
}

</style>
