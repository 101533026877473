<template>
  <div>
    <modal v-if="showAddDisbursement">
    <div slot="header" class="modal-title col-md-12">
      <div class="row">
        <div class="col-md-12">
          <b> {{ title }}</b>
        </div>
        <div class="col-md-12">
          <hr>
        </div>
      </div>
    </div>
    <div slot="body">
      <form  method="POST" @submit.prevent="saveDisbursementAccount">
        <div class="row">
          <div class="col-md-12">
            <label>Account Type</label>
            <model-list-select
              v-model="disbursementType"
              :list="accountEnum"
              class="form-control"
              option-value="disbursementType"
              option-text="name"
              placeholder="Disbursement Account Type"
            />
            <div v-if="!$v.disbursementType.required && disbursementTypeError" class="text-danger">
              This field is required
            </div>

          </div>
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-12">
                <hr />
              </div>
              <div class="col-md-10 text-right pt-3">
                <pulse-loader :loading="myLoader" :color="color" :size="size" />
              </div>
              <div class="col-md-2 pr-3">
                <button
                  type="button"
                  class="btn btn-fill btn-outline-info custom-btn-all float-right"
                  :disabled="myLoader"
                  @click.prevent="closeModal"
                >
                  Close
                </button>
                <button
                  type="submit"
                  class="btn btn-primary btn-space custom-btn float-right mr-2"
                  :disabled="myLoader"
                  data-dismiss="modal">
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import { ModelListSelect } from 'vue-search-select'
import Modal from '@/components/Inputs/Modal'
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'

export default {

  name: 'DisbursementModal',

  components: {

    ModelListSelect,
    Modal,
    PulseLoader

  },

  props: {

    showAddDisbursement: {

      type: Boolean,
      default: () => false

    },

    title: {

      type: String,
      default: () => 'Add Main Disbursement Account'

    },

    vesselParticularId: {

      type: Number,
      default: () => 0

    }

  },

  data() {

    return {

      color: 'green',
      size: '12px',
      loading: false,

      disbursementTypeError: false,
      accountEnum: [
        // { name: 'Final', disbursementType: 'Final' },
        // { name: 'Other', disbursementType: 'Other' },
        { name: 'Proforma', disbursementType: 'Proforma' }
      ],

      selectExisting: true,

      disbursementType: '',

      saveDetails: {},
      myLoader: false

    }
  },
  computed: {

    ...mapGetters('disbursement', [

      'getMyLoader'

    ])

  },
  validations: {

    disbursementType: { required }

  },

  created() {},

  methods: {

    ...mapActions('disbursement', [

      'createDisbursementAccount'

    ]),

    setAllGetters() {

      this.myLoader = this.getMyLoader

    },

    loadDisbursementAccountParams () {

      let disbursmentObject = {

        disbursementType: this.disbursementType,
        vesselParticularId: this.vesselParticularId

      }

      if(this.$v.disbursementType.$invalid) {

        this.disbursementTypeError = true

      } else {

        this.disbursementTypeError = false

        return new Promise((resolve) => {

        this.$store.dispatch("disbursement/savingDetails", disbursmentObject)

        resolve();

      })

      }

    },

    saveDisbursementAccount() {

      this.loadDisbursementAccountParams().then(() => {

        this.createDisbursementAccount({}).then((response) => {

          this.setAllGetters()

          if(response.data.status) {

            this.loadNotification('success', 4000, 'Main Disbursment Message',' Main isbursment account has been successfully created',
            'response', 1000 , true  , true)

            this.closeModal()
            this.getMainDisbursmentAccount()


          } else {

            this.loadNotification('error', 4000, 'Main Disbursment Message',' Main disbursment account not created, please retry',
            'response', 1000 , true  , true)

          }

        }).catch(() => {})

        this.myLoader = this.getMyLoader

      }).catch(() => {})

    },

    closeModal() {

      return this.$emit('closeModal')

    },

    getMainDisbursmentAccount () {

      return this.$emit('getMainDisbursmentAccount')

    }
  }
}
</script>

<style scoped >

.custom-btn {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
    padding: 5px 9px 5px 9px !important;
    font-size: 14px !important;
}

.custom-btn-all {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
    padding: 5px 9px 5px 9px !important;
    font-size: 14px !important;
    width: auto;
}

</style>
