<!--<template>-->
<!--  <card>-->
<!--    <div>-->
<!--      <div class="col-12 text-center">-->
<!--        <pulse-loader-->
<!--          :loading="loading"-->
<!--          :color="color"-->
<!--          :size="size"-->
<!--          v-if="loading"-->
<!--        />-->
<!--      </div>-->
      <list-shipping-order :shipping-orders="shippingOrders" />
<!--    </div>-->
<!--  </card>-->
<!--</template>-->


<template>
  <div>
    <card>
      <data-table
        :table-headers="tableHeaders"
        :table-data="shippingOrders"
        :table-data-keys="tableDataKeys"
        :my-first-loader="myFirstLoader"
        :page-size-prop="pageSize"
        :page-no="pageNo"
        :is-pageable="isPageable"
        :total-elements="totalElements"
        :total-pages="totalPages"
        :is-first="isFirst"
        :is-last="isLast"
        :page-number="pageNumber"
        @goToPage="goToPage"
        :has-search-form="false"
        :date-prop="'Created At '"
        :has-manage-button="true"
        @onChange="onChange"
        @goToApplication="selectedShippingOrder"
        :loading-title="'Please wait while system is loading Received Shipping Orders'"
      />

    </card>
  </div>
</template>

<script>
import ListShippingOrder from '../subComponents/ListShippingOrder'
import AGENYAPI from '../../../api/agency'
import DataTable from '../../../components/DataTable/updatedTable';

export default {
  name: 'ViewShippingorder',
  components: {
    DataTable,
    ListShippingOrder
  },
  created () {
    this.getShippingOrders(this.pageSize,this.pageNo);
    this.$store.dispatch('pagenames/currentPageName', 'Received Shipping Orders');
    this.finalUserPermissions = JSON.parse(localStorage.getItem('browser-local-permits'));

  },
  data () {

    return {
      shippingOrders: [],
      errorMessage: '',
      SuccessAlert: false,
      ErrorAlert: false,
      SuccessMessage: '',
      loading: false,
      pageSize:15,
      pageNo:0,
      isPageable: true,
      tableHeaders:['Shipping Order No.', 'Shipper', 'Consignee', 'Shipping Order Status'],
      tableDataKeys:['shippingOrderNo','shipperName','consigneeName', 'shippingOrderStatus'],

      myFirstLoader:false,
      totalElements: '',
      totalPages:'',
      isFirst:true,
      isLast: false,
      pageNumber: 0,
      finalUserPermissions:[],
      noDataFound: false,
    }
  },
  methods: {
    onChange (event) {
      this.pageSize = event;
      this.pageNo = 0;
      this.getShippingOrders(this.pageSize, this.pageNo);
    },
    goToPage (page) {
      this.pageNo = page;
      this.getShippingOrders(this.pageSize, this.pageNo);
    },
    removeRejectedShippingOrder(data){
      let orderData=data;
      for(let i=0;i<data.left;i++){
        if(data.shippingOrderStatus==='BlInfoRejected'){
          orderData.splice(i,1);
        }
      }
      return orderData;
    },
    getShippingOrders (pageSize, pageNo) {
      this.myFirstLoader = true;
      AGENYAPI.getshippingOrders(pageSize, pageNo).then((response) => {
        if (response.data.status){
          if (Object.keys(response.data.data).length > 0) {
            this.myFirstLoader = false;
            this.shippingOrders = this.removeRejectedShippingOrder(response.data.data.content);
            this.isLast = response.data.data.last;
            this.totalElements = response.data.data.totalElements;
            this.totalPages = response.data.data.totalPages;
            this.pageNumber = response.data.data.number;
            this.isFirst = response.data.data.first;
            this.isLast = response.data.data.last;
            this.noDataFound = response.data.data.empty;
          }else{
            this.noDataFound = true;
          }
        }else {
          this.noDataFound = true;
          this.ErrorMessage = 'Sorry, something went wrong'
          this.SuccessMessage = '';
          this.SuccessAlert = false;
          this.ErrorAlert = true;
        }

      }).catch((error) => {
        this.ErrorMessage = 'Booking Application Failed'
        this.SuccessAlert = false
        this.ErrorAlert = true
        this.SuccessMessage = ''
        this.loading = false
      })
    },
    selectedShippingOrder (shippingOrder) {
      this.$router.push('/shipping-agency/booking/view/shippingorder/' + shippingOrder.id);
    }
  }
}
</script>

<style scoped>

</style>
