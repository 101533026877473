<template>
  <div>
    <card style="font-size: 12px">
      <div class="row">
        <div class="col-md-12">
          <ul class="nav-justified">
            <li v-if="orderRequest.status === 'CREATED'">
              <button class="btn btn-danger-custom btn-danger-custom-fill mr-2 custom-btn" @click="showModal=true">
                Cancel Request
              </button>
            </li>
            <li v-if="orderRequest.status === 'ACCEPTED'">
              <button class="btn btn-success-custom btn-success-custom-fill mr-2 custom-btn" @click="downloadPdf(deliveryOrderId)">
                Print Delivery Order
              </button>
            </li>
            <li v-if="orderRequest.status === 'CREATED'">
              <button class="btn btn-purple btn-purple-fill mr-2 custom-btn" @click="openModal">
                Create Delivery Order
              </button>
            </li>
          </ul>
        </div>
      </div>
    </card>
    <card>
      <div class="row">
        <div class="col-md-12">
          <div class="text-center">
            <pulse-loader
              :loading="loading"
              :color="color"
              :size="size"
              style="margin-top: 4px;"
            />
          </div>
        </div>
        <div class="col-md-12" v-if="showModal">
          <div class="form-group mt-3">
            <label><strong>Cancel Reason</strong></label>
            <textarea class="form-control" rows="3" placeholder="Cancel Reason" v-model="cancelReason"></textarea>
            <div class="mt-3">
              <button class="btn btn-success float-right ml-2" @click="cancelRequest">Save</button>
              <button class="btn btn-default float-right" @click="showModal=false">Cancel</button>
            </div>
          </div>
        </div>
      </div>
      <div class="legend" style="font-size: 12px">
        <h6 class="legend-title">Delivery Order Request Details</h6>

        <div class="row div-table-row">
          <div class="col-md-3 div-table div-table-title">BL of Lading</div>
          <div class="col-md-3 div-table div-table-value">
            {{
              orderRequest.blNumber != null
                ? orderRequest.blNumber
                : "Not Specified"
            }}
          </div>
          <div class="col-md-3 div-table div-table-title">Consignee Name</div>
          <div class="col-md-3 div-table div-table-value">
            {{
              orderRequest.consigneeName != null
                ? orderRequest.consigneeName
                : "Not Specified"
            }}
          </div>
        </div>
        <br />
        <div class="row div-table-row">
          <div class="col-md-3 div-table div-table-title">Delivery Type</div>
          <div class="col-md-3 div-table div-table-value">
            {{
              orderRequest.cargoDeliveryType != null
                ? orderRequest.cargoDeliveryType
                : "Not Specified"
            }}
          </div>
          <div class="col-md-3 div-table div-table-title">Destination</div>
          <div class="col-md-3 div-table div-table-value">
            {{
              orderRequest.destination != null
                ? orderRequest.destination
                : "Not Specified"
            }}
          </div>
        </div>
        <br />
        <div class="row div-table-row">
          <div class="col-md-3 div-table div-table-title">
            Consignee Phone Number
          </div>
          <div class="col-md-3 div-table div-table-value">
            {{
              orderRequest.mobileNumber != null
                ? orderRequest.mobileNumber
                : "Not Specified"
            }}
          </div>
          <div class="col-md-3 div-table div-table-title">Created Date</div>
          <div class="col-md-3 div-table div-table-value">
            {{
              orderRequest.createdDate != null
                ? orderRequest.createdDate
                : "Not Specified"
            }}
          </div>
        </div>
        <br />
        <div class="row div-table-row">
          <div class="col-md-3 div-table div-table-title">Email</div>
          <div class="col-md-3 div-table div-table-value">
            {{
              orderRequest.emailAddress != null
                ? orderRequest.emailAddress
                : "Not Specified"
            }}
          </div>

          <div class="col-md-3 div-table div-table-title">Status</div>
          <div class="col-md-3 div-table div-table-value">
            {{
              orderRequest.status != null
                ? orderRequest.status
                : "Not Specified"
            }}
          </div>
        </div>
        <br />
        <div class="row div-table-row">
          <div class="col-md-2 div-table div-table-title mt-2">Reject Reason</div>
          <div class="col-md-10 div-table div-table-value mt-2">
            {{
            orderRequest.rejectionReason != null
            ? orderRequest.rejectionReason
            : "Not Specified"
            }}
          </div>
        </div>
        <br />
        <div class="row div-table-row">
          <div class="col-md-2 div-table div-table-title">Description</div>
          <div class="col-md-10 div-table div-table-value">
            {{
              orderRequest.description != null
                ? orderRequest.description
                : "Not Specified"
            }}
          </div>
        </div>
      </div>
      <div class="legend">
        <div class="row div-table-row">
          <div
            class="col-md-12 div-table div-table-title text-center"
            style="border-bottom: 1px solid #a9a9a9"
          >
            Delivery Order Request Attachments
          </div>
        </div>
        <div class="row div-table-row">
          <div
            class="col-md-1 div-table div-table-title text-center"
            style="border-right: 1px solid #a9a9a9"
          >
            #
          </div>
          <div
            class="col-md-7 div-table div-table-title text-center"
            style="border-right: 1px solid #a9a9a9"
          >
            Attachment Type
          </div>
          <div
            class="col-md-4 div-table div-table-title text-center"
            style="border-right: 1px solid #a9a9a9"
          >
            View
          </div>
        </div>
        <div class="row div-table-row" v-if="uploadedFiles.length > 0"
          v-for="(attachment , index_attachment) in uploadedFiles" :key="index_attachment"
        >
          <div class="col-md-1 div-table div-table-value text-center">
            {{ index_attachment + 1 }}
          </div>
          <div class="col-md-7 div-table div-table-value">
            {{ attachment.attachmentType? attachment.attachmentType.attachmentTypesName:'' }}
          </div>
          <div
            class="col-md-4 div-table div-table-value text-center text-link"
            @click.prevent="openPdfViewer(attachment.attachmentUrl , attachment.attachmentName, 'view')"
          >
            click to view
          </div>
        </div>
        <div class="row div-table-row"  v-if="uploadedFiles.length <= 0">
          <div class="col-md-12 div-table div-table-value text-center">
            <b>No attachment(s) found</b>
          </div>
        </div>

        <!-- <h6 class="legend-title">Delivery Order Request Attachments</h6>
        <table class="table">
          <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">File Type</th>
            <th scope="col">Action</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(file,index) in uploadedFiles" :key="index">
            <th scope="row">{{index + 1}}</th>
            <td>{{file.attachmentType? file.attachmentType.attachmentTypesName:''}}</td>
            <td>
                <span class="ml-4 mr-5 pointer" @click="getFileFromMinio(file.attachmentUrl , attachment.attachmentName)">
                  <font-awesome-icon icon="print" :style="{ color: 'green' }" size="lg"/>
                </span>
            </td>
          </tr>
          </tbody>
        </table> -->
      </div>
      <br />
      <div class="legend">
        <div class="col-md-12">
          <div class="text-center">
            <pulse-loader
              :loading="doLoader"
              :color="color"
              :size="size"
              style="margin-top: 4px;"
            />
          </div>
        </div>

        <h6 class="legend-title">Delivery Order</h6>
        <data-table
          :tableHeaders="tableHeaders"
          :tableData="filteredRequests"
          :tableDataKeys="tableDataKeys"
          :loadingTitle="'Please wait while system is loading delivery order'"
          :hasManageButton="false"
          :hasFilter="false"
          :hasPagination="false"
          :isPageable="false"
          :is-hovered="isHovered"
          @showToolTip="showToolTip"
          @hideToolTip="hideToolTip"
          :permissions="[ {key: 'searchPermission', value: 'DELIVERY-ODER_REQUEST_ALL_SEARCH_GET'} ]"
          :buttonList="[{'code' : 'FinishTallying' ,'name' : 'More Details' , 'class' : 'btn-success'}]"
          @goToFinishTallying="viewDo"
        >
      </data-table>
        <!-- <table class="table table-hover">
          <thead>
          <tr>
            <th scope="col">BIL NO</th>
            <th scope="col">DELIVERY ORDER NO</th>
            <th scope="col">DO DATE</th>
            <th scope="col">AGENT</th>
            <th scope="col">DO STATUS</th>
            <th scope="col">ACTION</th>
          </tr>
          </thead>
          <tbody>
          <tr v-if="deliveryOrder.blNo">
            <td >{{deliveryOrder.blNo}}</td>
            <td>{{deliveryOrder.doNumber}}</td>
            <td>{{deliveryOrder.doDate}}</td>
            <td>{{deliveryOrder.agent.agentName}}</td>
            <td :class="deliveryOrder.status==='CANCELLED'? 'rejected':''"><strong>{{deliveryOrder.status}}</strong></td>

            <td>
              <button class="btn btn-success" @click="viewDo(deliveryOrder.deliveryOderId)">View</button>
            </td>
          </tr>
          </tbody>
        </table> -->
      </div>

      <div class="legend" v-if="orderRequest.status === 'ACCEPTED'">
        <h6 class="legend-title">Bill of Lading Details</h6>
        <div class="row div-table-row">
          <div class="col-md-2 div-table div-table-title">
            Consignee Address
          </div>
          <div class="col-md-10 div-table div-table-value">
            {{
              blDetails[0].consigneeAddress != null
                ? blDetails[0].consigneeAddress
                : "Not Specified"
            }}
          </div>
        </div>
        <br />
        <div class="row div-table-row">
          <div class="col-md-2 div-table div-table-title">Notifiy Address</div>
          <div class="col-md-10 div-table div-table-value">
            {{
              blDetails[0].notifyAddress != null
                ? blDetails[0].notifyAddress
                : "Not Specified"
            }}
          </div>
        </div>
        <br />
        <div class="row div-table-row">
          <div class="col-md-2 div-table div-table-title">Description</div>
          <div class="col-md-10 div-table div-table-value" v-if="blDetails[0].goodDetails !== undefined">
            {{
              blDetails[0].goodDetails[0].description != null
                ? blDetails[0].goodDetails[0].description
                : "Not Specified"
            }}
          </div>
        </div>
      </div>
      <div class="row text-right">
        <div class="col-md-8 pt-3">
        </div>
        <div
          class="col-md-4">
          <button
            class="btn btn-default btn-fill custom-btn"
            @click.prevent="goBack"
          >
            <font-awesome-icon icon="undo" /> &nbsp; Back
          </button>
        </div>
      </div>
    </card>
    <modal :maxWidth="800" v-if="openModalBoolean">
      <div slot="body">
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label for="bl">Call ID</label>
                <input type="text" class="form-control" v-model="callId"/>
                <div v-if="callIdError">
                  <div class="error text-danger">
                    <span v-if="!$v.callId.required">Field is required</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row text-right">
            <div class="col-md-12">
              <button type="button" class="btn btn-fill btn-outline-info float-right custom-btn-all" @click.prevent="closeModal">
                Close
              </button>
              <button type="submit" class="btn btn-primary btn-space float-right custom-btn mr-2" data-dismiss="modal" @click.prevent="addDeliveryOrder(orderRequest)">
                Create
              </button>
            </div>
          </div>
      </div>
    </modal>
    <global-modal v-if="globalLoader">
      <div slot="globalLoader">
        <div class="row text-center">
          <div class="col-md-12">
            {{ feedbackMessage }}
          </div>
          <div class="col-md-12">
            <br>
          </div>
          <div class="col-md-12">
            <pulse-loader
              :color="color"
              :size="size"
            />
          </div>
        </div>
      </div>
    </global-modal>
    <pdf-viewer :mySrc="scr" v-if="showPdfViewer" :maxWidth="1800" @closePdfViewer="closePdfViewer"
    @downloadPdf="openPdfViewer(attachmentUrlForDownload, attachmentNameForDownload)"></pdf-viewer>
  </div>
</template>

<script>
import DCAPI from "../../../api/dc";
import AGENCY_API from "../../../api/agency";
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import GlobalModal from '../../../components/globalLoader'
import { mapActions, mapGetters } from 'vuex';
import PdfViewer from '../../../components/pdfViewer.vue'
import Modal from "@/components/Inputs/Modal";
import {required} from "vuelidate/lib/validators";

export default {

  name: "deliveryOrderRequestDetails",

  components:{

    PulseLoader,
    GlobalModal,
    Modal,
    PdfViewer,
    DataTable: () => import('../../../components/DataTable/dataTable')

  },

  created() {

    this.getBlNumberDetails();
    this.listAllFiles();
    this.$store.dispatch('pagenames/currentPageName', 'Delivery Order Request Details')

  },

  computed: {

    ...mapGetters('global', [

      'getGlobalLoader'

    ])

  },

  validations: {

      callId: { required }

    },

  data() {

    return {

      uploadedFiles:[],
      deliveryOrders: [],
      filteredRequests: [],
      blDetails: [{}],
      orderRequestId: {},
      orderRequest: {},
      loader: false,
      doRequest: {},
      showModal:false,
      globalLoader: false,
      color: 'green',
      size: '12px',
      loading: false,
      doLoader:false,
      cancelReason:"",
      feedbackMessage: '',
      isHovered: [],
      deliveryOrder:{
        agent:{}
      },
      callId: '',
      callIdError: '',
      openModalBoolean: false,

      tableHeaders: ['BL/AIRWAY BILL/RCN', 'DELIVERY ORDER NO', 'DO DATE', 'AGENT', 'DO STATUS'],
      tableDataKeys: ['blNo', 'doNumber' ,'doDate', 'cfaAgent', 'status'],

      attachmentNameForDownload: '',
      attachmentUrlForDownload: '',
      showPdfViewer: false

    };

  },

  methods: {

    ...mapActions('global', [

      'loadFileFromMinio'

    ]),

    loadParameters (fileUrl, fileName) {

      return new Promise((resolve) => {

        this.$store.dispatch('global/fileUrling', fileUrl)
        this.$store.dispatch('global/fileNaming', fileName)

        resolve()
      })

    },

    openPdfViewer (fileUrl, fileName, action) {

        this.attachmentNameForDownload = fileName
        this.attachmentUrlForDownload = fileUrl

        this.getFileFromMinio(fileUrl, fileName, action).then(() => {

            this.showPdfViewer = true

        }).catch(() => {})

    },

    closePdfViewer () {

        this.showPdfViewer = false
        this.scr = ''
        this.attachmentNameForDownload = ''
        this.attachmentUrlForDownload = ''

    },

    getFileFromMinio (fileUrl, fileName) {

      this.loadParameters(fileUrl, fileName).then(() => {

      this.loadFileFromMinio({}).then((res) => {

          if(res.status === 204) {

              this.loadNotification('warn', 4000, 'File Download',
              'File not found, please contact system admin',
              'response', 1000 , true  , true)

          } else {

            this.loadNotification('success', 4000, 'File Download',
              'File has been successfully downloaded, please have a look at it',
              'response', 1000 , true  , true)


          }

            this.globalLoader = this.getGlobalLoader
            this.feedbackMessage = 'Downloading requested file, Please wait'

        }).catch(() => {

          this.loadNotification('error', 4000, 'File Download',
              'File not downloaded, please check your internet connection',
              'response', 1000 , true  , true)

          this.globalLoader = this.getGlobalLoader

        })

        this.globalLoader = this.getGlobalLoader

      }).catch(() => {

      })

    },

    showToolTip(id) {
      this.isHovered = [id]
    },

    hideToolTip(id) {
      this.isHovered.splice(this.isHovered.indexOf(id), 1)
    },

    sortingData(data) {

      for(let x = 0 ; x < data.length ; x++) {

        const requestObject = {
            ...data[x],
            doDate: this.dateTimeToHuman(data[x].doDate),
            cfaAgent: data[x].agent
          }

          this.filteredRequests.push(requestObject)

      }

    },

    getDeliveryOrder(DoId){

      this.doLoader = true;
      this.deliveryOrders = []

      AGENCY_API.getDeliveryOrder(DoId).then((resp) => {

        this.doLoader = false;
        this.deliveryOrder = resp.data.data;
        this.deliveryOrders.push(this.deliveryOrder)
        this.sortingData(this.deliveryOrders)

      }).catch(() => {

        this.doLoader = false;
        this.notifyUser('error','Error',"Failed to load Delivery order");
      });
    },

    viewDo(data){

      this.$router.push('/shipping-agency/delivery-orders/order-details/' + data.deliveryOderId);

    },

    getBlNumberDetails() {

      this.orderRequestId = this.$route.params.blNumber;
      AGENCY_API.getSingleDeliveryOrderRequest(this.orderRequestId)

        .then((response) => {

          this.orderRequest = response.data.data;

          if (this.orderRequest.status === "ACCEPTED") {

            DCAPI.getBlDetails(this.orderRequest.blNumber,this.orderRequest.voyageNumber)
              .then((response) => {

                this.blDetails = response.data.data;

              })
              .catch((error) => {

                console.log(error);


              });
          }
          if(this.orderRequest.deliveryOrderId!==null){

            this.getDeliveryOrder(this.orderRequest.deliveryOrderId)

          }

        })
        .catch(() => {

          console.log(error);

        });
    },

    openModal () {

      this.openModalBoolean = true

    },

    closeModal () {

      this.openModalBoolean = false

    },

    addDeliveryOrder(data) {

      if(this.$v.callId.$invalid) {

        this.callIdError = true

      } else {

        this.globalLoader = true

        this.feedbackMessage = 'Creating Delivery Order, Please wait'

        if (data !== null) {

          let doRequest = {}

          this.doRequest = {

            agentId: data.cfaAgentId,
            blNo: data.blNumber,
            deliveryPoint: data.destination,
            voyageNo: data.voyageNumber

          };

          doRequest = {...this.doRequest, callId: data.callId}

          AGENCY_API.saveDeliveryOrder(doRequest, data.id)
            .then((resp) => {
              this.globalLoader = false

              if(resp.status){

                if(resp.data.status){
                  this.getBlNumberDetails();

                  this.loadNotification('success', 4000, 'Delivery Order Request Message',
                    'Delivery order Successfully created',
                    'response', 1000 , true  , true);

                }else {
                  this.loadNotification('error', 4000, 'Failed To create Delivery order',
                    resp.data.message,
                    'response', 1000 , true  , true);
                }
                this.openModalBoolean = false
              }else {
                this.loadNotification('error', 4000, 'Failed To create Delivery order',
                  'Error Please contact System Administrator',
                  'response', 1000 , true  , true);
              }


            })
            .catch(() => {

              this.loadNotification('error', 4000, 'Delivery Order Request Message',
              'Delivery order not approved, please contact system Administrator',
              'response', 1000 , true  , true);

              this.globalLoader = false

            });
        }

      }

    },

    listAllFiles(){

      this.loading=true;

      AGENCY_API.getDeliveryOrderAttachments(this.$route.params.blNumber).then((resp)=>{

        this.uploadedFiles=resp.data.data;
        this.loading=false;

      }).catch(()=>{

        this.loading=false;

      });
    },

    cancelRequest(){

      this.globalLoader = true

      this.feedbackMessage = 'Cancelling Delivery Order, Please wait'

      AGENCY_API.cancelDeliveryOrderRequest(
        {
          deliveryOrderRequestId: this.$route.params.blNumber,
          description: this.cancelReason!==''? this.cancelReason:null,
          status: "REJECTED"
        }).then(()=>{
        this.getBlNumberDetails();
        this.cancelReason="";
        this.showModal=false;
        this.globalLoader = false

        this.loadNotification('success', 4000, 'Delivery Order Request Message',
              'Delivery order has been successfully cancelled,',
              'response', 1000 , true  , true)

      }).catch((err)=>{

        this.globalLoader = false

        this.loadNotification('error', 4000, 'Delivery Order Request Message',
              'Delivery order not cancelled, please check your internet connection',
              'response', 1000 , true  , true)
      })
    },

    downloadPdf() {

      this.$dialog
        .confirm(
          "Have attachments and charges pertaining to the delivery order been verified? ",
          {
            loader: true,
            okText: "Yes",
            html: true,
          }
        )
        .then((dialog) => {
          // this.$store.dispatch('deliveryOrder/requestid', this.deliveryOrderId)
          // this.$store.dispatch('deliveryOrder/categoryIding', this.doDetails.blNo)
          this.globalLoader = true
          this.feedbackMessage = 'Downloading requested file, Please wait'
          AGENCY_API.getDeliveryOderPDF(this.orderRequest.deliveryOrderId)
            .then((response) => {
              //Create a Blob from the PDF Stream
              const file = new Blob([response.data], {
                type: "application/pdf",
              });
              //Build a URL from the file
              const fileURL = URL.createObjectURL(file);
              //Open the URL on new Window
              window.open(fileURL);
              dialog.close();
              this.globalLoader = false

              this.loadNotification('success', 4000, 'Delivery Order Request Message',
              'Delivery order has been successfully downloaded, please have a look at it',
              'response', 1000 , true  , true)

            })
            .catch(() => {

              this.globalLoader = false

              this.loadNotification('error', 4000, 'Delivery Order Request Message',
              'Delivery order not downloaded, please check your internet connection',
              'response', 1000 , true  , true)

              dialog.close();
            });
        })
        .catch(() => {});
    },

    goBack () {

      if (window.$cookies.isKey('browser-local-cashed-url-back')) {

        this.$router.push(window.$cookies.get('browser-local-cashed-url-back'))


        window.$cookies.remove('browser-local-cashed-url-back')

      }

    },

  }

};
</script>

<style scoped>

ul.nav-justified {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    background-color: #ededee;
    border-radius: 3px;
}

ul.nav-justified li {
    float: left;
}

ul.nav-justified li a:hover {
    color: #d59a18;
}

ul.nav-justified li a {
    display: block;
    text-align: center;
    padding: 16px 20px 10px 21px;
    text-decoration: none;
    color: blue;
    font-weight: bold;
    text-transform: uppercase;
}

.custom-btn {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
    padding: 5px 9px 5px 9px !important;
    font-size: 14px !important;
}

.btn-blue:hover {
    border-color: #0000ff;
    color: #0000ff;
    background-color: #ffffff;
}

.btn-blue {
    border-color: #4169e1;
    color: #4169e1;
}

.btn-blue-fill {
    background-color: #4169e1;
    opacity: 1;
    color: #FFFFFF;
}

.btn-purple:hover {
    border-color: #800080;
    color: #800080;
    background-color: #ffffff;
}

.btn-purple {
    border-color: #9932cc;
    color: #9932cc;
}

.btn-purple-fill {
    background-color: #9932cc;
    opacity: 1;
    color: #FFFFFF;
}

.btn-success-custom:hover {
    border-color: #008000;
    color: #008000;
    background-color: #ffffff;
}

.btn-success-custom {
    border-color: rgba(0, 128, 0, .5);
    color: rgba(0, 128, 0, .5);
}

.btn-success-custom-fill {
    background-color: rgba(0, 128, 0, .8);
    opacity: 1;
    color: #FFFFFF;
}

.btn-danger-custom:hover {
    border-color: #ff0000;
    color: #ff0000;
    background-color: #ffffff;
}

.btn-danger-custom {
    border-color: rgba(255, 0, 0, .5);
    color: rgba(255, 0, 0, .5);
}

.btn-danger-custom-fill {
    background-color: rgba(255, 0, 0, .5);
    opacity: 1;
    color: #FFFFFF;
}

.div-table-row {
  padding: 0 15px 0 15px;
}

.div-table {
  padding: 9px 8px 9px 8px;
}

.div-table-title {
  width: 250px;
  background-color: rgba(153, 186, 221, 0.5);
  font-weight: normal;
}

.div-table-value {
  border: 1px solid #dcdcdc;
  font-weight: 900;
}

label {
  position: relative;
  top: -7px;
  right: 0;
  margin: 0;
  padding: 0;
}
tbody tr {
  color: #212529 !important;
  font-weight: 400 !important;
}

thead th {
  font-weight: 600 !important;
}

.legend {
  position: relative;
  border: 1px solid #dcdcdc;
  padding: 1.5rem 1rem 1rem 1rem;
  margin-bottom: 1rem;
  border-radius: 3px;
  margin-top: 1rem;
}

.legend-title {
  position: absolute;
  top: -0.6rem;
  padding: 0.2rem 1rem;
  background-color: white;
  color: #d59a18;
}
  .pointer{
    cursor: pointer;
  }

  .rejected{
    color: red;
  }

  .created{
    color: green;
  }
</style>
