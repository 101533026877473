<template>
  <div>
    <card>
      <data-table
        :tableHeaders="tableHeaders"
        :tableData="filteredRequests"
        :tableDataKeys="tableDataKeys"
        :pageSizeProp="pageSize"
        :pageNo="pageNo"
        :myLoader="myLoader"
        :myFirstLoader="myFirstLoader"
        :noDataFound="noDataFound"
        :searchParameterProp="searchParameter"
        :loadingTitle="'Please wait while system is loading delivery order requests'"
        :hasManageButton="true"
        :totalElements="totalElements"
        :totalPages="totalPages"
        :isFirst="isFirst"
        :isLast="isLast"
        :coloredStatus="true"
        :pageNumber="pageNumber"
        :whichModuleAndType="whichModuleAndType"
        :is-hovered="isHovered"
        :allCustomers="allCustomers"
        :allCfaAgents="allCfaAgents"
        :permissions="[ {key: 'searchPermission', value: 'DELIVERY-ODER_REQUEST_ALL_SEARCH_GET'} ]"
        @resetParams="resetParams"
        @search="searchGeneralChargeParams"
        @showToolTip="showToolTip"
        @hideToolTip="hideToolTip"
        @onChange="onChange"
        @goToPage="goToPage"
        @goToApplication="goToApplication"
      >
      <div slot = "pagination">
        <paginate
            v-model="getIsPage"
            :page-count=parseInt(totalPages)
            :page-range="3"
            :margin-pages="2"
            :click-handler="goToPage"
            :prev-text="'Prev'"
            :next-text="'Next'"
            :container-class="'pagination'"
            :page-class="'page-item'">
        </paginate>
      </div>
      </data-table>
    </card>
    <global-modal v-if="myLoader">
      <div slot="globalLoader">
        <div class="row text-center">
          <div class="col-md-12">
            Loading delivery order requests, Please wait
          </div>
          <div class="col-md-12">
            <br>
          </div>
          <div class="col-md-12">
            <pulse-loader
              :loading="loading"
              :color="color"
              :size="size"
            />
          </div>
        </div>
      </div>
    </global-modal>
  </div>
</template>

<script>

  import {mapGetters, mapActions} from "vuex";
  import GlobalModal from '../../../components/globalLoader'
  import CfaAgents from '../../../assets/files/cfaAgents.json'
  import PulseLoader from 'vue-spinner/src/PulseLoader';

  export default {

    name: 'ChargedApplications',

    data() {

      return {

        finalUserPermissions: [],

        color: 'green',
        size: '13px',
        loading: true,

        requests: [],
        filteredRequests: [],
        noDataFound: false,
        reloadAllRequest: false,
        hasSearchedVariable: false,
        searchParameter: '',
        pageNo: 0,
        pageSize: 15,
        totalElements: "",
        totalPages: "",
        isFirst: false,
        isLast: false,
        pageNumber: '',
        pageCount: 1,
        myLoader: false,
        isHovered: [],
        myFirstLoader: false,
        whichModuleAndType: 'doRequest',
        allCustomers: [],
        CfaAgents: CfaAgents,
        allCfaAgents: [],
        eachRequestId: [],
        chargeRequests: [],
        getRegimes: [],
        getStations: [],
        requestIds: [],
        newParams: '',
        isPage: 0,
        pageNoPart: 0,
        initialParams: '?size=' + 15 + '&page=' + 0,

        tableHeaders: ['BL/AIRWAY BILL/RCN', 'CFA Agent', 'consignee Name', 'destination', 'Voyage Number', 'status', 'created Date'], //, 'last Updated Date'
        tableDataKeys: ['blNumber', 'cfaAgent', 'consigneeName' ,'destination', 'voyageNumber', 'status', 'createdDate'], //, 'lastUpdatedDate'
      }
    },

    computed: {

      ...mapGetters({

        getRequestList: "deliveryorderrequest/getRequestList",
        getMyLoader: "deliveryorderrequest/getMyLoader",
        getMyFirstLoader: "deliveryorderrequest/getMyFirstLoader",
        getPageNo: "deliveryorderrequest/getPageNo",
        getPageSize: "deliveryorderrequest/getPageSize",
        getRequestId: "deliveryorderrequest/getRequestId",
        getTotalElement: "deliveryorderrequest/getTotalElement",
        getTotalPage: "deliveryorderrequest/getTotalPage",
        getPageNumber: "deliveryorderrequest/getPageNumber",
        getIsFirst: "deliveryorderrequest/getIsFirst",
        getIsLast: "deliveryorderrequest/getIsLast",
        getSearchParams: "deliveryorderrequest/getSearchParams",
        getHasSearchedVariable: "deliveryorderrequest/getHasSearchedVariable"

      }),

      ...mapGetters('global' , [

        'getAllCustomers',
        'getAllRegimes',
        'getAllStations'

      ]),

      getIsPage: {

        get: function () {
            return this.isPage
        },
        set: function (newValue) {
            this.isPage = newValue;
        }

      }

    },

    components: {

      GlobalModal,
      PulseLoader,
      DataTable: () => import('../../../components/DataTable/dataTable')

    },

    created() {

      this.finalUserPermissions = JSON.parse(localStorage.getItem('browser-local-permits'))
      this.$store.dispatch('pagenames/currentPageName', 'Delivery Order Requests')
      this.loadRequest()
      this.loadAllCustomers()
      this.loadAllCfaAgents()

    },

    methods: {

      ...mapActions("deliveryorderrequest", [

        "loadDeliveryOrderRequestOnCreated",
        "loadDeliveryOrderRequestAfterCreated",
        "searchWithGeneralChargeParams",

      ]),

      ...mapActions("global", [

        'loadCustomers'

      ]),

      loadAllCustomers() {

          this.loadCustomers({}).then(() => {

            this.allCustomers = this.getAllCustomers;

          }).catch(() => {


          });

      },

      loadAllCfaAgents() {

          this.allCfaAgents = this.CfaAgents.data

      },

      resetParams () {

        this.loadPageableDeliveryOrderRequestOnCreated(this.initialParams , 0)
        this.$store.dispatch("deliveryorderrequest/hasSearchngParams", false)

      },

      loadRequest() {

        if (localStorage.getItem("search-params") !== null) {

          this.newParams = ""
          this.newParams = localStorage.getItem("search-params")

          let secondPart = this.newParams.split("size=")[1]
          this.pageNoPart = parseInt(secondPart.split("page=")[1])
          this.isPage = this.pageNoPart + 1

          this.searchGeneralChargeParams(this.newParams, this.pageNoPart)

        } else {

            this.loadPageableDeliveryOrderRequestOnCreated(this.initialParams , 0)

            this.$store.dispatch("deliveryorderrequest/hasSearchngParams", false);
            this.newParams = ""
            this.pageNoPart = 0

        }

        this.filteredRequests = []

      },

      sortingData(data) {

        for(let x = 0 ; x < data.length ; x++) {

          const requestObject = {
            ...data[x],
              // lastUpdatedDate: this.dateTimeToHuman(data[x].lastUpdatedDate),
              createdDate: this.dateTimeToHuman(data[x].createdDate),
              consigneeName: data[x].consigneeName.toUpperCase()
            }

            this.filteredRequests.push(requestObject)

        }

      },

      loadSearchParameters(params, fromApplicationPageNo) {

        let fromApplicationPageNoOrNot
        typeof fromApplicationPageNo !== 'undefined' ? fromApplicationPageNoOrNot = fromApplicationPageNo : fromApplicationPageNoOrNot = 0

        let firstPart = params.split("&page=")[0]
        let secondPart = firstPart.split("size=")[0]
        this.newParams = ""

        this.isPage = fromApplicationPageNo
        this.newParams = secondPart + 'size=' + this.pageSize + 'page=' + fromApplicationPageNoOrNot

        return new Promise((resolve) => {

          this.$store.dispatch("deliveryorderrequest/pageSizing", this.pageSize);
          this.$store.dispatch("deliveryorderrequest/pageNumbering", 0);
          this.$store.dispatch("deliveryorderrequest/paramsSearching", params);

          resolve();

        })

      },

      setAllGetters(flag) {

        this.requests = this.getRequestList;
        flag === 'afterCreated' ? this.myLoader = this.getMyLoader : '';
        flag === 'onCreated' ? this.myFirstLoader = this.getMyFirstLoader : '';
        this.noDataFound = this.getNoDataFound;
        this.SuccessMessage = this.getSuccessMessage;
        this.ErrorMessage = this.getErrorMessage;
        this.ErrorAlert = this.getErrorAlert;
        this.SuccessAlert = this.getSuccessAlert;
        this.pageNo = this.getPageNo;
        this.pageSize = this.getPageSize;

        this.totalElements = this.getTotalElement;
        this.totalPages = this.getTotalPage;
        this.pageNumber = this.getPageNumber;
        this.isFirst = this.getIsFirst;
        this.isLast = this.getIsLast;

        this.sortingData(this.requests)

      },

      searchGeneralChargeParams(params, fromApplicationPageNo) {

        this.newParams = params

        this.filteredRequests = []

        this.loadSearchParameters(params, fromApplicationPageNo).then(() => {

          this.searchWithGeneralChargeParams({}).then(() => {

            this.setAllGetters('onCreated')

          }).catch(() => {


          });

          this.myFirstLoader = this.getMyFirstLoader;

        }).catch(() => {


        });

      },

      loadPageableDeliveryOrderRequestOnCreated(params, fromApplicationPageNo) {

        this.newParams = params

        this.filteredRequests = []

        this.loadSearchParameters(params, fromApplicationPageNo).then(() => {

          this.loadDeliveryOrderRequestOnCreated({}).then(() => {

            this.setAllGetters('onCreated')

          }).catch(() => {


          });

          this.myFirstLoader = this.getMyFirstLoader;

        }).catch(() => {


        });

      },

      loadPageableDeliveryOrderRequestsAfterCreated(params, fromApplicationPageNo) {

        this.newParams = params

        this.filteredRequests = []

        this.loadSearchParameters(params, fromApplicationPageNo).then(() => {

          this.loadDeliveryOrderRequestAfterCreated({}).then(() => {

            this.setAllGetters('afterCreated')

          }).catch(() => {


          });

          this.myLoader = this.getMyLoader;

        }).catch(() => {


        });

      },

      goToApplication(object) {

        document.cookie = 'browser-local-cashed-url-back=' + this.$route.fullPath + ';Path = /;SameSite = Strict';
        this.$router.push('/shipping-agency/requests-details/delivery-order/' + object.id);

        if (this.getHasSearchedVariable) {

          localStorage.setItem("search-params", this.newParams);
          // localStorage.setItem("cfa-customer", JSON.stringify(this.allCustomers));
          this.newParams = ""

        } else {

          localStorage.setItem("page-no", this.getPageNo);
          localStorage.setItem("page-size", this.getPageSize);

        }

      },

      onChange(event) {

        this.$store.dispatch("deliveryorderrequest/pageSizing", event);
        this.$store.dispatch("deliveryorderrequest/pageNumbering", 0);
        localStorage.removeItem("page-no")
        localStorage.removeItem("page-size")
        this.isPage = 0

        this.pageSize = this.getPageSize;
        this.pageNo = this.getPageNo;

        if (this.getHasSearchedVariable) {

          let firstPart = this.newParams.split("&page=")[0]
          let secondPart = firstPart.split("size=")[0]
          let thirdPart = firstPart.split("size=")[1]
          this.newParams = ""
          this.newParams = secondPart + 'size=' + this.pageSize + '&page=' + 0
          this.pageNoPart = 0

          this.searchGeneralChargeParams(this.newParams , this.pageNoPart);
          this.filteredRequests = []

        } else {

          this.newParams = '?size=' + this.pageSize + '&page=' + this.pageNo
          this.pageNoPart = this.isPage

          this.loadPageableDeliveryOrderRequestOnCreated(this.newParams , 0);
          this.filteredRequests = []

        }

      },

      goToPage(page) {

        this.filteredRequests = []

        if(page > 0) {page = page - 1;}
        this.isPage = page + 1

        this.pageNo = page;

        if (this.getHasSearchedVariable) {

          let firstPart = this.newParams.split("page=")[0]
          this.newParams = ""
          this.newParams = firstPart + '&page=' + this.pageNo
          this.pageNoPart = this.isPage

          this.searchGeneralChargeParams(this.newParams, this.pageNoPart);
          this.filteredRequests = []

        } else {

          this.newParams = '?size=' + this.pageSize + '&page=' + this.pageNo
          this.pageNoPart = this.isPage

          this.loadPageableDeliveryOrderRequestsAfterCreated(this.newParams, this.pageNoPart);
          this.filteredRequests = []
        }

      },

      showToolTip(id) {
        this.isHovered = [id]
      },

      hideToolTip(id) {
        this.isHovered.splice(this.isHovered.indexOf(id), 1)
      }

    }

  }
</script>

<style scoped>

  .btn-space {
    margin-right: 10px !important;
  }

  .btn-success-custom {
    padding: 0 4px 0 4px;
    color: #3cb371;
  }

  .btn-danger-custom {
    padding: 0 4px 0 4px;
    color: #e97451;
  }

  .btn-success-custom:hover {
    cursor: pointer;
    color: green;
  }

  .btn-danger-custom:hover {
    cursor: pointer;
    color: red;
  }

  .table-striped tr {
    line-height: 20px;
  }

  .custom-text-filed {
    border-radius: 5px;
  }

  .btn-disabled:hover {
    cursor: not-allowed;
    color: #dcdcdc !important;
  }

  .my-green-bc {
    background-color: rgba(0, 128, 0, .1);
  }

  .remove-charge-from-list {
    color: red;
  }

  .remove-charge-from-list:hover {
    cursor: pointer;
  }

  .disabled-click {
    pointer-events: none;
    color: #dcdcdc;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
                                     supported by Chrome, Edge, Opera and Firefox */
  }

  .enabled-click {
    pointer-events: auto;
  }

  .search .form-group .input-group .input-group-addon {
    width: 40px;
    text-align: center;
    padding-top: 3.5px;
    background-color: rgba(220, 220, 220, .3);
  }

  .disable-highlight:hover span {
    cursor: pointer;
  }

  .bg-grey {
    background-color: #dcdcdc;
  }

  .refresh-all {
    margin-top: 6px;
    margin-right: 20px
  }

  .refresh-all:hover {
    cursor: pointer;
  }

  .search .form-group .input-group #inputGroup {
    width: 250px;
    padding-top: 3.5px;
  }

  .search .form-group .input-group .input-group-addon:hover {
    cursor: pointer;
    background-color: rgba(220, 220, 220, .5);
  }

  .custom-btn {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
    padding: 5px 9px 5px 9px !important;
    font-size: 14px !important;
    background-color: #FFFFFF !important;
  }

  .table td, .table th {
    text-align: center;
  }

  .text-center {
    text-align: center !important;
  }

  .table-hover tbody tr:hover {
    background-color: rgba(153, 186, 221, .5) !important;
  }

  .more-customer-details:hover {
    cursor: pointer;
    color: #d59a18;
  }

  .close-info {
    color: blue !important;
  }

  .close-info:hover {
    cursor: pointer;
    color: green !important;
  }

</style>
